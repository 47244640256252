import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { savePaymentMethod } from '../actions/cartActions'

function PaymentScreen({history}) {

    const cart = useSelector(state => state.cart)
    const { shippingAddress } = cart

    const dispatch = useDispatch()

    const [paymentMethod,setPaymentMethod] = useState('')
    if (!shippingAddress.address1){
        history.push('/shipping')
    }
    if(cart.cartItems.length===0){
        history.push('/cart')
    }
    // console.log('cartitems',cart.cartItems.length)
    const submitHandler = (e) => {
        e.preventDefault()
        
        if(paymentMethod==='') {
            alert('Please select a payment method.') 
        }else{
            dispatch(savePaymentMethod(paymentMethod))
            history.push('/placeorder')
        }
            

        
    }

    const [showCard, setShowCard]= useState(true);
    const [showPayPal, setShowPayPal]= useState(true);
    // console.log('payment method',paymentMethod)

    return (
        <div>
            {/* <!-- Page Title--> */}
            <div className= "page-title">
            <div className= "container">
                <div className= "column">
                <h1>Checkout</h1>
                </div>
                <div className= "column">
                <ul className= "breadcrumbs">
                    <li><Link to= "/">Home</Link> 
                    </li>
                    <li className= "separator">&nbsp;</li>
                    <li>Checkout</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className= "container padding-bottom-3x mb-2">
            <div className= "row" style={{justifyContent:'center'}}>
                {/* <!-- Checkout Adress--> */}
                <div className= "col-xl-8 col-lg-8">
                <div className= "steps flex-sm-nowrap mb-5">
                    <Link className= "step" to= "/shipping">
                        <h4 className= "step-title"><i className= "icon-check-circle"></i>1. Address</h4>
                    </Link> 
                    
                    <Link className= "step active" to= "/#">
                        <h4 className= "step-title">2. Payment Method</h4>
                    </Link> 
                    
                    <Link className= "step " to= "/#">
                        <h4 className= "step-title">3. Place Order</h4>
                    </Link> 
                    
                    <Link className= "step" to= "/#">
                        <h4 className= "step-title">4. Checkout</h4>
                    </Link> 
                </div>
                <h4>Choose Payment Method</h4>
                <hr className= "padding-bottom-1x" />

                <form onSubmit={submitHandler}>
                <div className= "accordion" id="accordion" role="tablist">
                    { shippingAddress.countryCode === "IN" &&
                    <div className= "card">
                        <div className= "card-header" role="tab">
                            <h6><Link 
                            className= {showCard ? "" : "collapsed"} 
                            onClick={()=>{setShowCard(!showCard)}} 
                            data-toggle="collapse"><i className= "icon-credit-card"></i>Select Payment Option</Link> </h6>
                        </div>
                        <div className= {showCard ? "collapse show" : "collapse"}   id="card" data-parent="#accordion" role="tabpanel">
                            <div className= "card-body">
                            <p>We accept following credit cards:&nbsp;&nbsp;<img className= "d-inline-block align-middle" src="/images/img/credit-cards.png" style={{width: "120px"}} alt="Cerdit Cards" /></p>
                            <div className= "card-wrapper"></div>
                            <div className= "custom-control custom-radio">
                                <input className="custom-control-input" type="radio" 
                                        value='Debit/Credit Card'
                                        onChange={(e)=> setPaymentMethod(e.target.value)}
                                        id="cardPayment" name='radioCard'  checked={paymentMethod !== 'Debit/Credit Card' ? false : true}/>
                                <label className="custom-control-label" htmlFor="cardPayment">Debit/Credit Card </label>

                            </div>
                            <div className= "custom-control custom-radio">
                               
                                <input className="custom-control-input" type="radio" 
                                        value='Net Banking'
                                        onChange={(e)=> setPaymentMethod(e.target.value)}
                                        id="netBanking" name='radioCard'  checked={paymentMethod !== 'Net Banking' ? false : true}/>
                                <label className="custom-control-label" htmlFor="netBanking">Net Banking </label>

                             
                            </div>

                            <div className= "custom-control custom-radio">

                                <input className="custom-control-input" type="radio" 
                                        value='UPI'
                                        onChange={(e)=> setPaymentMethod(e.target.value)}
                                        id="upiPayment" name='radioCard'  checked={paymentMethod !== 'UPI' ? false : true}/>
                                <label className="custom-control-label" htmlFor="upiPayment">UPI</label>

                               
                            </div>
                            
                            </div>
                        </div>
                    </div>
                    }
                    { shippingAddress.countryCode !== "IN" &&
                    <div className= "card">
                        <div className= "card-header" role="tab">
                            <h6><Link 
                            className= {showPayPal ? "" : "collapsed"} 
                            onClick={()=>{setShowPayPal(!showPayPal)}} 
                            data-toggle="collapse"><i className= "icon-credit-card"></i>Select Payment Option</Link> </h6>
                        </div>
                        <div className=  {showPayPal ? "collapse show" : "collapse"} id="paypal" data-parent="#accordion" role="tabpanel">
                            <div className= "card-body">
                            <p>PayPal - the safer, easier way to pay</p>
                            <div className= "custom-control custom-radio">
                                <input className="custom-control-input" type="radio" 
                                value='PayPal'
                                        onChange={(e)=> setPaymentMethod(e.target.value)}
                                        id="paypalPayment" name="radioPayPal"  checked={paymentMethod !== 'PayPal' ? false : true}/>
                                <label className="custom-control-label" htmlFor="paypalPayment">Select PayPal </label>
                            </div>
                            
                            </div>
                        </div>
                    </div>
                   }
                </div>
                <div className= "d-flex justify-content-between paddin-top-1x mt-4">
                    <Link className= "btn btn-outline-secondary" to= "/shipping">
                        <i className= "icon-arrow-left"></i><span className= "hidden-xs-down">&nbsp;Back</span>
                    </Link>
                    
                    <button className= "btn btn-primary" type="submit"><span className= "hidden-xs-down">Continue&nbsp;</span>
                        <i className= "icon-arrow-right"></i>
                    </button> 
                </div>
                </form>
                </div>
                
            </div>
            </div>
            
        </div>
    )
}

export default PaymentScreen
