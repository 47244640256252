import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { productListReducer, 
         productDetailsReducer, 
         productDeleteReducer,
         productCreateReducer,
         productUpdateReducer,
         productReviewCreateReducer,
         productTopRatedReducer
        } from './reducers/productReducers'

import { cartReducer } from './reducers/cartReducers'

import { serviceCartReducer } from './reducers/serviceCartReducer'

import { userLoginReducer, 
         userRegisterReducer, 

         userValidatePhoneReducer,
         userValidatePhoneOtpReducer,
         userValidateEmailReducer,
         userValidateEmailOtpReducer,
         
         userVerifyPhoneReducer,
         userVerifyPhoneOtpReducer,
         userVerifyEmailReducer,
         userVerifyEmailOtpReducer,

         userDetailsReducer,
         userUpdateProfileReducer,
         userListReducer,
         userDeleteReducer,
         userUpdateReducer,

         userPasswordResetReducer,
         userPasswordResetConfirmReducer,
        } from './reducers/userReducers'

import { 
         doctorListReducer,
         doctorDetailsReducer,
         doctorCreateReducer,         
         doctorDeleteReducer,
         doctorUpdateReducer,

        } from './reducers/doctorReducers'

import { orderCreateReducer, 
         orderDetailsReducer, 
         orderPayReducer,
         orderEmailSendReducer,
         orderListMyReducer,
         orderListReducer,  
         orderDeliverReducer, 
         orderShipReducer,
         orderRazorpayCheckoutReducer, 

         freightChargesReducer, 
         freightChargeCreateReducer,
         freightChargeUpdateReducer,
         freightChargeDeleteReducer,
        } from './reducers/orderReducers'

import { pageContentsReducer,
        pageContentReducer,
        pageContentCreateReducer,
        pageContentUpdateReducer
        } from './reducers/pageReducers'

import { serviceListReducer,
         serviceDetailsReducer,
         serviceDeleteReducer,
         serviceCreateReducer,
         serviceUpdateReducer,
         serviceReviewCreateReducer,

         serviceTimeslotsReducer,
         serviceTimeslotCreateReducer,
         serviceTimeslotUpdateReducer,
         serviceTimeslotDeleteReducer,
        } from './reducers/serviceReducers'

import { appointmentCreateReducer, 
         appointmentDetailsReducer, 
         appointmentPayReducer,
         appointmentEmailSendReducer,
         appointmentListMyReducer,
         appointmentListReducer,  
         appointmentAttainReducer, 
         appointmentCancelReducer, 
         appointmentUpdateDoctorReducer,
         appointmentRazorpayCheckoutReducer, 

        } from './reducers/appointmentReducers'

import { currencyReducer } from './reducers/currencyReducers'

const reducer = combineReducers({
    productList: productListReducer,
    productDetails: productDetailsReducer,
    productDelete: productDeleteReducer,
    productCreate: productCreateReducer,
    productUpdate: productUpdateReducer,
    productReviewCreate: productReviewCreateReducer,
    productTopRated: productTopRatedReducer,

    cart: cartReducer,
    serviceCart: serviceCartReducer,

    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,

    userValidatePhone: userValidatePhoneReducer,
    userValidatePhoneOtp: userValidatePhoneOtpReducer,
    userValidateEmail: userValidateEmailReducer,
    userValidateEmailOtp: userValidateEmailOtpReducer,
    
    userVerifyPhone: userVerifyPhoneReducer,
    userVerifyPhoneOtp: userVerifyPhoneOtpReducer,
    userVerifyEmail: userVerifyEmailReducer,
    userVerifyEmailOtp: userVerifyEmailOtpReducer,

    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    userPasswordReset: userPasswordResetReducer,
    userPasswordResetConfirm : userPasswordResetConfirmReducer,

    doctorList: doctorListReducer,
    doctorDetails: doctorDetailsReducer,
    doctorCreate: doctorCreateReducer,
    doctorUpdate: doctorUpdateReducer,
    doctorDelete: doctorDeleteReducer,

    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    orderEmailSend: orderEmailSendReducer,
    orderListMy: orderListMyReducer,
    orderList: orderListReducer,
    orderDeliver: orderDeliverReducer,
    orderShip: orderShipReducer,
    orderRazorpayCheckout: orderRazorpayCheckoutReducer,

    freightCharges: freightChargesReducer,
    freightChargeCreate: freightChargeCreateReducer,
    freightChargeUpdate: freightChargeUpdateReducer,
    freightChargeDelete: freightChargeDeleteReducer,

    pageContents: pageContentsReducer,
    pageContent: pageContentReducer,
    pageContentCreate: pageContentCreateReducer,
    pageContentUpdate: pageContentUpdateReducer,

    currency: currencyReducer,

    // Services Reducers
    serviceList: serviceListReducer,
    serviceDetails: serviceDetailsReducer,
    serviceDelete: serviceDeleteReducer,
    serviceCreate: serviceCreateReducer,
    serviceUpdate: serviceUpdateReducer,
    serviceReviewCreate: serviceReviewCreateReducer,
    serviceTimeslots: serviceTimeslotsReducer,
    serviceTimeslotCreate: serviceTimeslotCreateReducer,
    serviceTimeslotUpdate: serviceTimeslotUpdateReducer,
    serviceTimeslotDelete: serviceTimeslotDeleteReducer,


    appointmentCreate: appointmentCreateReducer,
    appointmentDetails: appointmentDetailsReducer,
    appointmentPay: appointmentPayReducer,
    appointmentEmailSend: appointmentEmailSendReducer,
    appointmentListMy: appointmentListMyReducer,
    appointmentList: appointmentListReducer,
    appointmentAttain: appointmentAttainReducer,
    appointmentCancel: appointmentCancelReducer,
    appointmentUpdateDoctor: appointmentUpdateDoctorReducer,
    appointmentRazorpayCheckout: appointmentRazorpayCheckoutReducer,
})


const cartItemsFromStorage = localStorage.getItem('cartItems') ?
        JSON.parse(localStorage.getItem('cartItems')) : []

const userInfoFromStorage = localStorage.getItem('userInfo') ?
        JSON.parse(localStorage.getItem('userInfo')) : null

const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ?
        JSON.parse(localStorage.getItem('shippingAddress')) : {}

const currencyFromStorage = 'INR'

// const currencyFromStorage = localStorage.getItem('currency') ?
//         localStorage.getItem('currency') : 'INR'

const appointmentServiceFromStorage = localStorage.getItem('appointmentService') ?
        JSON.parse(localStorage.getItem('appointmentService')) : null
    
const appointmentScheduleFromStorage = localStorage.getItem('appointmentSchedule') ?
        JSON.parse(localStorage.getItem('appointmentSchedule')) : null
    
const patientDetailsFromStorage = localStorage.getItem('patientDetails') ?
        JSON.parse(localStorage.getItem('patientDetails')) : null

const servicePaymentMethodFromStorage = localStorage.getItem('servicePaymentMethod') ?
        JSON.parse(localStorage.getItem('servicePaymentMethod')) : null

const initialState = {
    cart: {
            cartItems: cartItemsFromStorage,
            shippingAddress: shippingAddressFromStorage
        },

    serviceCart: {
        appointmentService: appointmentServiceFromStorage,
        appointmentSchedule: appointmentScheduleFromStorage,
        patientDetails: patientDetailsFromStorage,
        servicePaymentMethod: servicePaymentMethodFromStorage,
    },

    userLogin: { userInfo: userInfoFromStorage},
    currency: {currency: currencyFromStorage}
}
const middleware = [thunk]
// console.log('domain',window.location.hostname)

// let store = createStore(reducer, initialState, 
//     applyMiddleware(...middleware))

let store = createStore(reducer, initialState, 
        composeWithDevTools(applyMiddleware(...middleware)))

// if(window.location.hostname === 'localhost'){
//         store = createStore(reducer, initialState, 
//         composeWithDevTools(applyMiddleware(...middleware)))
// }

export default store