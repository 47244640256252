import axios from 'axios'
import { 
    APPOINTMENT_CREATE_REQUEST,
    APPOINTMENT_CREATE_SUCCESS,
    APPOINTMENT_CREATE_FAIL,

    APPOINTMENT_DETAILS_REQUEST,
    APPOINTMENT_DETAILS_SUCCESS,
    APPOINTMENT_DETAILS_FAIL,

    APPOINTMENT_PAY_REQUEST,
    APPOINTMENT_PAY_SUCCESS,
    APPOINTMENT_PAY_FAIL, 

    APPOINTMENT_EMAIL_SEND_REQUEST,
    APPOINTMENT_EMAIL_SEND_SUCCESS,
    APPOINTMENT_EMAIL_SEND_FAIL, 

    APPOINTMENT_LIST_MY_REQUEST,
    APPOINTMENT_LIST_MY_SUCCESS,
    APPOINTMENT_LIST_MY_FAIL, 

    APPOINTMENT_LIST_REQUEST,
    APPOINTMENT_LIST_SUCCESS,
    APPOINTMENT_LIST_FAIL,

    APPOINTMENT_ATTAIN_REQUEST,
    APPOINTMENT_ATTAIN_SUCCESS,
    APPOINTMENT_ATTAIN_FAIL, 
    
    APPOINTMENT_CANCEL_REQUEST,
    APPOINTMENT_CANCEL_SUCCESS,
    APPOINTMENT_CANCEL_FAIL, 
    
    APPOINTMENT_UPDATE_DOCTOR_REQUEST,
    APPOINTMENT_UPDATE_DOCTOR_SUCCESS,
    APPOINTMENT_UPDATE_DOCTOR_FAIL, 

    APPOINTMENT_RZP_CHECKOUT_REQUEST,
    APPOINTMENT_RZP_CHECKOUT_SUCCESS,
    APPOINTMENT_RZP_CHECKOUT_FAIL,

} from '../constants/appointmentConstants'

import { CART_CLEAR_SERVICE } from '../constants/serviceCartConstants'

export const createAppointment = (appointment) => async (dispatch,getState) => {
    try {
        dispatch({
            type: APPOINTMENT_CREATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }


        const { data } = await axios.post(
            `/api/appointments/create/`,   
            appointment,         
            config
        )

        dispatch({
            type: APPOINTMENT_CREATE_SUCCESS,
            payload: data
        })
        
        dispatch({
            type: CART_CLEAR_SERVICE,
            payload: data
        })

        localStorage.removeItem('appointmentService')


    } catch (error) {
        dispatch({
            type: APPOINTMENT_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getAppointmentDetails = (id) => async (dispatch,getState) => {
    try {
        dispatch({
            type: APPOINTMENT_DETAILS_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/appointments/${id}/`,            
            config
        )

        dispatch({
            type: APPOINTMENT_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: APPOINTMENT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const payAppointment = (id,paymentResult) => async (dispatch,getState) => {
    try {
        dispatch({
            type: APPOINTMENT_PAY_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.put(
            `/api/appointments/${id}/pay/`,  
            paymentResult,          
            config
        )

        dispatch({
            type: APPOINTMENT_PAY_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: APPOINTMENT_PAY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const razorpayCheckoutAppointment = (id) => async (dispatch,getState) => {
    try {
        dispatch({
            type: APPOINTMENT_RZP_CHECKOUT_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        console.log('order action config:',config)
        const { data } = await axios.post(
            `/api/appointments/${id}/razorpayCheckoutAppointment/`,  
            {},
            config
        )

        dispatch({
            type: APPOINTMENT_RZP_CHECKOUT_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: APPOINTMENT_RZP_CHECKOUT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const attainAppointment = (appointment) => async (dispatch,getState) => {
    try {
        dispatch({
            type: APPOINTMENT_ATTAIN_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/appointments/${appointment.slug}/attain/`,  
            {},          
            config
        )

        dispatch({
            type: APPOINTMENT_ATTAIN_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: APPOINTMENT_ATTAIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const cancelAppointment = (appointment) => async (dispatch,getState) => {
    try {
        dispatch({
            type: APPOINTMENT_CANCEL_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/appointments/${appointment.slug}/cancel/`,  
            {},          
            config
        )

        dispatch({
            type: APPOINTMENT_CANCEL_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: APPOINTMENT_CANCEL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateDoctorAppointment = (appointment,doctorSlug) => async (dispatch,getState) => {
    try {
        dispatch({
            type: APPOINTMENT_UPDATE_DOCTOR_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/appointments/${appointment.slug}/updateDoctor/`,  
            {doctorSlug},          
            config
        )

        dispatch({
            type: APPOINTMENT_UPDATE_DOCTOR_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: APPOINTMENT_UPDATE_DOCTOR_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const sendEmailAppointment = (id,status) => async (dispatch,getState) => {
    try {
        dispatch({
            type: APPOINTMENT_EMAIL_SEND_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/appointments/${id}/email/${status}/`,     
            {},       
            config
        )

        dispatch({
            type: APPOINTMENT_EMAIL_SEND_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: APPOINTMENT_EMAIL_SEND_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listMyAppointments = () => async (dispatch,getState) => {
    try {
        dispatch({
            type: APPOINTMENT_LIST_MY_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/appointments/myappointments/`,            
            config
        )

        dispatch({
            type: APPOINTMENT_LIST_MY_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: APPOINTMENT_LIST_MY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listAppointments = () => async (dispatch,getState) => {
    try {
        dispatch({
            type: APPOINTMENT_LIST_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/appointments/`,            
            config
        )

        dispatch({
            type: APPOINTMENT_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: APPOINTMENT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
