import React from 'react'
import { Link } from 'react-router-dom'
import "../../index.css"

function DiseasesScreen({match}) {

    const diseaseId = match.params.id
    // console.log('disease id:',diseaseId)

    
    return (
        <div style={{color:"black"}}>

            {/* <!-- Page Title--> */}
                <div className="page-title mb-0">
                <div className="container">
                    <div className="column">
                    <h1>Diseases / {diseaseId==='liver' ? "Liver" : diseaseId==='digestive-system' ? "Digestive System" : diseaseId==='cancer' ? "Cancer" :
                    diseaseId==='posriasis' ? "Psoriasis" : diseaseId==='asthama' ? "Asthama" : diseaseId==='nueron' ? "Neuron" : null}
                    
                    </h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li><Link to="/">Home</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li><Link to="/our-services">Our Services</Link></li>
                        <li className="separator">&nbsp;</li>
                        <li>{diseaseId==='liver' ? "Liver" : diseaseId==='digestive-system' ? "Digestive System" : diseaseId==='cancer' ? "Cancer" :
                            diseaseId==='posriasis' ? "Psoriasis" : diseaseId==='asthama' ? "Asthama" : diseaseId==='nueron' ? "Neuron" : null}</li>
                    </ul>
                    </div>
                </div>
                </div>
                {/* <!-- Page Content--> */}
                <div className="container padding-bottom-2x mb-2 ">
                
                    <div className="row justify-content-center">
                        {/* <!-- Content--> */}
                        <div className="col-xl-12 col-lg-10 order-lg-2 " style={{fontSize:"16px", padding:"0"}}>
                            
                            {diseaseId === 'liver' &&
                            <>
                            <div style={{marginRight:"0px",display:"block",backgroundColor:"#f8f9fa"}} className="mt-0">
                                <div style={{width:"100%",display:"block"}} ><img className="d-block mx-auto " style={{marginRight:"0px",display:"block"}} width="800px" src="/images/liver_banner.jpg" alt="liver-banner" /></div>
                            </div>
                            
                            <hr className="margin-bottom-1x"/>
                             
                            <div class="card bg-secondary text-left mb-3">
                            <div style={{backgroundColor:"#fff"}} class="card-header text-lg"><h4><strong>Liver</strong></h4></div>
                                <div style={{backgroundColor:"white"}} class="card-body">
                                {/* <h4 class="card-title">Secondary card title</h4> */}
                                <p class="card-text">Being protected by the rib cage, your liver cannot be felt physically but it is the seat of your 
                                soul and emotions. It gives signals to guide your dietary choices because everything you put in your mouth must go 
                                through your liver before it does anything useful elsewhere in your body. </p>

                                <p>Your liver is your source of power, your engine and your pharmacist. Your liver likes it bloodline leaky. 
                                    In contrast to the well-sealed vessels that prevent direct contact between blood and most tissues of your body, 
                                    the arteries and veins around your liver are stippled with holes, which means they spray blood right onto the hepatocytes. 
                                    Hepatocyte cells are swimming in blood that make them so incredibly efficient at taking up substances from the blood. 
                                    Weighing in at little over one kg, your liver is a complex natural factory in your body that works 24 hours daily. 
                                    It processes virtually everything you eat, you drink, you breathe in or you rub on your skin, in fact, your liver
                                     performs more than 500 functions which are necessary to your life.</p>

                                <p>
                                Your liver produces bile eliminating toxic substances from your body and aiding with your digestion. It cleanses your blood 
                                while metabolizing alcohol and other drugs and chemicals. It neutralizes and destroys poisonous substances. 
                                Manufactures many of your essential body proteins that transport substances in the body, filters the blood and 
                                resist infections. Regulates your supply of body fuel while producing, storing and supplying quick energy to 
                                keep your mind and body alert and active. It produces, stores and exports fat. Regulates the balance of hormones 
                                including sex hormones, thyroid hormones, cortisone and other adrenal hormones. Regulates your body 's cholesterol 
                                producing cholesterol. It excretes and converts it into other essential substances. It activates the Enzyme. 
                                Stores the glycogen, vitamins, and minerals. It detoxifies and purifies the blood. It is a resilient organ and 
                                easy to ignore until something goes critical. However, liver is the only organ in the body which can regenerate itself 
                                after large portions of it are removed. Small remnants of liver grow back to normal size within a few weeks. But, 
                                Due to its wide- ranging functions your live can be attacked by viruses, toxic substances, contaminants and diseases. 
                                Even under the danger, the liver is very slow to complain. It will continue working even when two-thirds of it has been 
                                damaged. So, you should be very careful and alert about your liver's health.</p>

                                <p>
                                Here are some kinds of liver diseases mentioned:</p>

                                <p>
                                DISEASES caused by viruses, such as hepatitis A, hepatitis B, and hepatitis C
                                DISEASES caused by drugs, poisons, or too much alcohol include fatty liver disease and cirrhosis, jaundice and liver cancer.
                                INHERITED diseases, such as hemochromatosis and Wilson disease.
                                Although SYMPTOMS of liver disease can vary, but they often include swelling of the abdomen and legs, bruising easily, changes in the colour of your stool and urine, and jaundice, or yellowing of the skin and eyes. Sometimes there are no symptoms. Tests such as imaging tests and liver function tests can be checked for liver damage and help to diagnose and treat the liver diseases. </p>
                                </div>
                            </div>
                            </>
                            }

                            {diseaseId === 'digestive-system' &&
                            <>
                            <div style={{marginRight:"0px",display:"block",backgroundColor:"#f8f9fa"}} >
                                <div style={{width:"100%",display:"block"}} ><img className="d-block mx-auto " style={{marginRight:"0px"}} width="800px" src="/images/digestion_banner.jpg" alt="digestion-banner" /></div>
                            </div>
                            
                            <hr className="margin-bottom-1x"/>
                             
                            <div class="card bg-secondary text-left mb-3">
                            <div style={{backgroundColor:"#fff"}} class="card-header text-lg"><h4><strong>Digestive System</strong></h4></div>
                                <div style={{backgroundColor:"white",color:"black"}} class="card-body">
                                {/* <h4 class="card-title">Secondary card title</h4> */}
                                <p >Despite having a huge impact on our whole-body health system Gut is most underrated organ of our body. 
                                    How many of us aware that a healthy gut makes our immune system stronger, it improves our heart health, brain health, 
                                    our mood, our sleep and digestion, and even it may help prevent some cancers and autoimmune diseases.</p>

                                <p>
                                Each of us have about 300 to 500 different species of bacteria in our digestive tract. While some microorganisms are 
                                harmful to our health, many are incredibly beneficial and even necessary to a healthy body.</p>

                                <p>
                                But if we ignore it, our unhealthy gut has many ways to welcome the deadly diseases also. Such as inflammatory bowel 
                                diseases, obesity, diabetes, liver diseases, chronic heart diseases, cancers and autism. Researchers say that our gut may 
                                talk back. Studies show that the balance of bacteria in the gut microbiome may affect our emotions and the way our brain 
                                processes information from our senses, like sights, sounds, flavours, or textures. </p>

                                <p>
                                It is said that changes in that balance may play a role in conditions like autism spectrum disorder, anxiety, 
                                and depression, as well as chronic pain.It may affect other aspects of our health also, such as skin, weight, hormone levels, 
                                ability to absorb nutrients, and even the danger of cancer cells development.</p>

                                <p>
                                The most common signs of unhealthy gut are: <br /><br />
                                <ol>
                                    <li><strong>Upset stomach:</strong> Stomach disturbances like gas, bloating, constipation, diarrhoea, and heartburn can all be signs 
                                        of an unhealthy gut. A balanced gut will have less difficulty processing food and eliminating waste. 
                                    </li><br />
                                    <li><strong>High-sugar diet:</strong> The processed foods and added sugars can decrease the number of good bacteria in our gut. 
                                        This imbalance can cause increased sugar cravings. High amounts of refined sugars have been linked to increased inflammation in the body.
                                    </li><br />
                                    <li><strong>Weight changes:</strong> Gaining or losing weight without making changes to our diet or exercise habits may be a sign of 
                                        an unhealthy gut. It regulates blood sugar, and store fat. Weight loss may be caused by small intestinal bacterial 
                                        overgrowth (SIBO), while weight gain may be caused by insulin resistance or the urge to overeat due to decreased 
                                        nutrient absorption.
                                    </li><br />
                                    <li><strong>Sleep disturbances or constant fatigue:</strong> An unhealthy gut may contribute to sleep disturbances such as insomnia or 
                                        poor sleep, and therefore lead to chronic fatigue. The majority of the body’s serotonin, a hormone that affects 
                                        mood and sleep, is produced in the gut. So gut damage can impair your ability to sleep well. Some sleep disturbances 
                                        have also been linked to risk for fibromyalgia.
                                    </li><br />
                                    <li><strong>Skin irritation:</strong> Skin conditions like eczema may be related to a damaged gut. Inflammation in the gut caused by a 
                                        poor diet or food allergies may cause increased “leaking” of certain proteins out into the body, which can in turn 
                                        irritate the skin and cause conditions such as eczema.
                                    </li><br />
                                    <li><strong>Autoimmune conditions:</strong> Medical researchers are continually finding new evidence of the impact of the gut on the 
                                        immune system Trusted Source. It’s thought that an unhealthy gut may increase systemic inflammation and alter 
                                        the proper functioning of the immune system. This can lead to autoimmune diseases, where the body attacks itself 
                                        rather than harmful invaders. 
                                    </li><br />
                                    <li><strong>Food intolerances:</strong> Food intolerances are the result of difficulty digesting certain foods. The researches and 
                                        studies show the evidence food intolerances may be caused by poor quality of bacteria in the gut. This can lead 
                                        to difficulty digesting the trigger foods and unpleasant symptoms such as bloating, gas, diarrhoea, abdominal pain, 
                                        and nausea. There is some evidence that food allergies may also be related to gut health. There may be a link to the 
                                        pituitary gland, that makes hormones which help set your appetite. That gland can affect the balance of bacteria in 
                                        our gut.
                                    </li><br />
                                    <li><strong>Food intolerances:</strong> Food intolerances are the result of difficulty digesting certain foods. The researches and 
                                        studies show the evidence food intolerances may be caused by poor quality of bacteria in the gut. This can lead 
                                        to difficulty digesting the trigger foods and unpleasant symptoms such as bloating, gas, diarrhoea, abdominal pain, 
                                        and nausea. There is some evidence that food allergies may also be related to gut health. There may be a link to the 
                                        pituitary gland, that makes hormones which help set your appetite. That gland can affect the balance of bacteria in 
                                        our gut.
                                    </li><br />
                                    <li><strong>INTESTINE:</strong> The intestine (bowel, or gut) is the segment of the gastrointestinal tract extending from 
                                        the pyloric sphincter of the stomach to the anus and, as in other mammals, separated in two parts, the small intestine and 
                                        the large intestine.<br/>
                                        The large intestine has 3 primary functions: absorbing water and electrolytes, producing and absorbing vitamins, and forming and
                                        propelling faces toward the rectum for liquidation. It also produces various substances that carry messages to other parts of the body, 
                                        and plays a crucial role in fighting germs and regulating the body’s water balance. There is a particularly high number of nerve cells 
                                        in the wall of the intestine.<br/>

                                        The large intestine, also known as the colon, it includes the mouth, esophagus, stomach, small intestine and rectum. It is 
                                        approximately 5 feet long, making up one-fifth of the length of the gastrointestinal (GI) tract and responsible for processing 
                                        indigestible food material (chyme) after most nutrients are absorbed in the small intestine.<br/>

                                        The large intestine is based on 4 parts. It includes the cecum and ascending colon, transverse colon, descending colon, and sigmoid colon.
                                        The small intestine is located in the gastrointestinal tract, between the stomach and the large intestine. It is, on average, 23ft long and 
                                        is comprised of three structural divisions; the duodenum, jejunum and ileum. The small intestine is mainly involved in the digestion and 
                                        absorption of nutrients. It receives pancreatic secretions and bile through the hepatopancreatic duct which aid with its functions.<br/>

                                        The enzymes break down nutrients such as carbohydrates, proteins or fats into their building blocks. These enzymes are produced in the 
                                        salivary glands in the mouth, in the pancreas and in the intestinal cells. The intestinal cells absorb the building blocks like sugar, 
                                        amino acids or fatty acids together with vitamins, salts and water. From there, most of the nutrients enter the bloodstream to be carried 
                                        to the rest of the body.<br/>

                                        The small intestinal cells also produce various intestinal hormones. These hormones effect things like the production of bile or pancreatic juice.
                                        They also cause more water to be released into the intestine and make you feel full. The histological structure of the small intestine is similar 
                                        to the other organs in the digestive tract. There are four main layers:<br/><br/>
                                        <ul>
                                            <li>
                                            <strong>Mucosa (Innermost layer)</strong> Contains the epithelium, lamina propria and muscularis mucosae.
                                            </li>
                                            <li>
                                            <strong>Submucosa</strong> – Connective tissue layer, which comprises blood vessels, lymphatics and the submucosal network.
                                            </li>
                                            <li>
                                            <strong>Muscularis externa</strong>  – Consists of two smooth muscle layers; the outer longitudinal layer and inner circular layer. 
                                                    The myenteric plexus lies between them.
                                            </li>
                                            <li>
                                            <strong>Adventitia (Outermost layer)</strong> Comprised of loosely arranged fibroblasts and collagen, with the vessels and nerves passing through it. The majority of the small intestine adventitia 
                                                    is covered by mesothelium and is commonly known as serosa.
                                            </li>
                                        </ul>

                                    </li><br />

                                    <li><strong>INFLAMMATORY BOWEL DISEASE (IBD):</strong>  Inflammatory bowel disease (IBD) is a wide-ranging term used to describe disorders involving in chronic 
                                        inflammation of our digestive tract.

                                        Ulcerative colitis is a type of IBD that causes long-lasting inflammation and sores (ulcers) in the innermost lining of our large intestine (colon) 
                                        and rectum.<br/>

                                        Crohn's disease is another type of IBD that characterized by inflammation of the lining of our digestive tract, which mostly spreads deep 
                                        into our affected tissues.

                                        Both ulcerative colitis and Crohn's disease usually involve severe diarrhoea, abdominal pain, fatigue and weight loss.
                                        IBD can be weakening and sometimes leads to mortal complications.

                                        Ulcerative colitis and Crohn's disease have some complications in common and others that are specific to each condition.<br/>
                                        <br /><strong>Complications found in both conditions may contain:</strong> 

                                        <ul>
                                            <li>
                                            <strong>Colon cancer:</strong> Having IBD increases your risk of colon cancer. General colon cancer screening guidelines for people without IBD call for a colonoscopy every 10 years beginning at age 50. Skin, eye and joint inflammation. 
                                                    Certain disorders, arthritis, skin lesions and eye inflammation may occur during IBD flare-ups.
                                            </li>
                                            <li>
                                            <strong>Primary sclerosing cholangitis:</strong> In this condition, inflammation causes scars within the bile ducts, eventually making them 
                                                    narrow and gradually causing liver damage.
                                            </li>
                                            <li>
                                            <strong>Blood clots:</strong> IBD increases the risk of blood clots in veins and arteries.
                                            </li>
                                        </ul>

                                        <br /><strong>Complications of Crohn's disease may include:</strong> 

                                        <ul>
                                            <li>
                                            <strong>Bowel obstruction:</strong> Crohn's disease affects the full thickness of the intestinal wall. Over a period
                                                time, parts of the bowel can thicken and narrow, which may block the flow of digestive contents. We may require surgery 
                                                to remove the diseased portion of our bowel.

                                            </li>
                                            <li>
                                            <strong>Malnutrition:</strong>Abdominal pain, diarrhoea and cramping may make it difficult for us to eat or for 
                                                our intestine to absorb enough nutrients to keep us nourished. It's also common to develop anemia due to low iron 
                                                or vitamin B12 caused by the disease.

                                            </li>
                                            <li>
                                            <strong>Ulcers:</strong> Chronic inflammation can lead to open sores (ulcers) anywhere in our digestive tract, 
                                                including our mouth and anus, and in the genital area.

                                            </li>
                                            <li>
                                            <strong>Fistulas:</strong> Ulcers can extend sometimes completely through the intestinal wall, creating a fistula, 
                                                an abnormal connection between different body parts. Fistulas near or around the anal area are the most common type.
                                                Sometimes, a fistula may become infected and form an abscess.


                                            </li>
                                            <li>
                                            <strong>Anal fissure:</strong> It is a small tear in the tissue that lines the anus or in the skin around the anus
                                                where infections can occur. It's often associated with painful bowel movements and may lead to a perianal fistula. 
                                            </li>
                                        </ul>

                                        <br /><strong>Complications of ulcerative colitis may contain:</strong>

                                        <ul>
                                            <li>
                                            <strong>Toxic megacolon:</strong>  Ulcerative colitis may cause the colon to rapidly widen and swell, it is a serious condition known as toxic megacolon.
                                            </li>
                                            <li>
                                            <strong>Severe dehydration:</strong> Excessive diarrhoea can result in dehydration.
                                            </li>
                                            <li>
                                            <strong>A hole in the colon:</strong> A perforated colon most commonly caused by toxic megacolon, but it may also occur on its own.
                                            </li>
                                        </ul>
                                        
                                    </li><br />

                                    <li><strong>BETA CELLS:</strong> We know that Beta cells are unique cells in the pancreas which produce, 
                                        store and release the hormone insulin. These beta cells are located in the area of the pancreas known as the islets 
                                        of Langerhans. There are at least five different types of islet cells that produce and secrete hormones directly into 
                                        the bloodstream.<br/>
                                        <strong>The role of beta cells: </strong><br/>The main function of a beta cell is to produce and secrete insulin. 
                                            The hormone responsible for regulating levels of glucose in the blood. When blood glucose levels start to rise 
                                            during digestion, beta cells quickly respond by secreting some of their stored insulin while at the same time 
                                            increasing production of the insulin hormone. This rapid response to a spike in blood glucose usually takes about 
                                            ten minutes. In case of type1 diabetes patients, these cells are either attacked and destroyed by the immune system.
                                            In case of type-2 diabetes patients, these cells are unable to produce a sufficient amount of insulin needed for
                                             blood sugar control.
                                        <ul>
                                            <li>
                                            <strong>AMYLIN AND C-PEPTIDE: </strong>In addition to insulin, beta cells also secrete the hormone Amylin and 
                                                called C-peptide, a by-product of insulin production. Amylin slows the rate of glucose entering the bloodstream,
                                                 making it a more short-term regulator of blood glucose levels. C-peptide is a molecule that helps to prevent 
                                                 neuropathy and other vascular complications by assisting in the repair of the muscular layers of the arteries. 
                                                 It is secreted into the bloodstream in equal quantities to insulin.
                                            </li>
                                            <li>
                                            <strong>BETA CELLS IN TYPE 1 DIABETES: </strong>In many a case of type-1 diabetes beta cell dies due to unguided 
                                            attack of body’s its self-immune system. Why and how it happens is unclear but the recent researches and studies 
                                            show that their beta cells (pancreatic cell) overburdened and stressed at the very early stages of the dieses.
                                            </li>
                                            <li>
                                            <strong>BETA CELLS IN TYPE 2 DIABETES </strong> In type 2 diabetes, the body becomes resistant to its insulin and 
                                            attempts to compensate by producing a higher quantity of insulin. Research has shown that chronically elevated 
                                            blood glucose levels (chronic hyperglycemia) over a long period of time can lead to beta cells wearing out, 
                                            referred to as beta cell turnover or beta burnout. Scientists are yet to find the exact cause of the failure of 
                                            beta cells in type 2 diabetes. They hypothesise that glucotoxicity may be one factor amongst other potential 
                                            factors including the effects of lipoproteins, leptin and also cytokines, which are active proteins of the immune 
                                            system.                            

                                            </li>
                                        </ul>

                                    </li>
                                </ol>
                              
                            </p>
                            </div>
                            </div>
                            </>
                            }

                            {diseaseId === 'cancer' &&
                            <>
                            <div style={{marginRight:"0px",display:"block",backgroundColor:"#f8f9fa"}} className="mt-4">
                                <div style={{width:"100%",display:"block"}} ><img className="d-block mx-auto " style={{marginRight:"0px",display:"block"}} width="800px" src="/images/cancer_banner.jpg" alt="cancer-banner" /></div>
                            </div>
                            
                            <hr className="margin-bottom-1x"/>
                             
                            <div class="card bg-secondary text-left mb-3">
                            <div style={{backgroundColor:"#fff"}} class="card-header text-lg"><h4><strong>Cancer</strong></h4></div>
                                <div style={{backgroundColor:"white"}} class="card-body">
                                {/* <h4 class="card-title">Secondary card title</h4> */}
                                <p class="card-text">
                                Cancer is touted to be a deadly and complex ailment. This disease has the capability to grow 
                                out of control and have an effect on the nearby tissues and the organs of the body. 
                                Sometimes, over a long period of time, toxic elements accumulate at a specific organ 
                                of the body and may produce a fatal growth in its surrounding cells which becomes cancerous. 
                                In Ayurvedic therapy, cancer treatment is focussed on recuperating the quality of life of 
                                the patient all the way through the revival of the body’s immune system and recovering 
                                the inbuilt immunity.
                                </p>
                                </div>
                            </div>
                            </>
                            }
                            
                            {diseaseId === 'psoriasis' &&
                            <>
                            <div style={{marginRight:"0px",display:"block",backgroundColor:"#f8f9fa"}} className="mt-4">
                                <div style={{width:"100%",display:"block"}} ><img className="d-block mx-auto " style={{marginRight:"0px",display:"block"}} width="800px" src="/images/psoriasis_banner.jpg" alt="psoriasis-banner" /></div>
                            </div>
                            
                            <hr className="margin-bottom-1x"/>
                             
                            <div class="card bg-secondary text-left mb-3">
                            <div style={{backgroundColor:"#fff"}} class="card-header text-lg"><h4><strong>Psoriasis</strong></h4></div>
                                <div style={{backgroundColor:"white"}} class="card-body">
                                {/* <h4 class="card-title">Secondary card title</h4> */}
                                <p class="card-text">
                                    Psoriasis is a state that causes surplus and rapid growth of skin cells that leads 
                                    to plaque-like deposits on the skin. According to Ayurveda, psoriasis arises due to 
                                    an imbalance of "Vata" and "Kapha” dosha. Ayurveda treatments of psoriasis focus on 
                                    using herbs along with proper diet and lifestyle changes. Panchakarma therapy is the
                                    main Ayurveda treatment used to treat psoriasis. It basically involves plant-based 
                                    medicines and nutritional changes that aim to detoxify and purify the body.
                                </p>
                                </div>
                            </div>
                            </>
                            }
                            
                            {diseaseId === 'asthama' &&
                            <>
                            <div style={{marginRight:"0px",display:"block",backgroundColor:"#f8f9fa"}} className="mt-4">
                                <div style={{width:"100%",display:"block"}} ><img className="d-block mx-auto " style={{marginRight:"0px",display:"block"}} width="800px" src="/images/asthama_banner.jpg" alt="astama-banner" /></div>
                            </div>
                            
                            <hr className="margin-bottom-1x"/>
                             
                            <div class="card bg-secondary text-left mb-3">
                            <div style={{backgroundColor:"#fff"}} class="card-header text-lg"><h4><strong>Asthama</strong></h4></div>
                                <div style={{backgroundColor:"white"}} class="card-body">
                                {/* <h4 class="card-title">Secondary card title</h4> */}
                                <p class="card-text">
                                In Ayurveda, the complexity in the process of breathing is known as Swasa in Sanskrit (Asthma). 
                                Mostly, it originates from the respiratory system and the secondary causes are the other 
                                systems of the body which directly and/or indirectly affect the respiratory system. 
                                According to the Ayurvedic theory, the main cause of Swasa is the Vata and Kapha doshas 
                                combined. According to Ayurveda, the classification of asthma is broadly done into five 
                                main categories: Mahaswasa or Dyspnoea major, Urdhawaswasa or Expiratory Dyspnoea, 
                                Chinna swasa or Chyne- stroke respiration, Kshudra Swasa or Dyspnoea minor, Tamaka 
                                Swasa or Bronchial Asthma. The process of ayurvedic treatment of asthma involves the 
                                movement of the excess Kapha back to the stomach and then eliminating it. The methods 
                                adopted in Ayurveda for this are: Swedana (Sudation), Vamana (Therapeutic emesis), 
                                and Virechana (Therapeutic purgation). As per individual conditions & requirements of 
                                the patients, these procedures are customized.
                                </p>
                                </div>
                            </div>
                            </>
                            }
                            
                            {diseaseId === 'neuron' &&
                            <>
                            <div style={{marginRight:"0px",display:"block",backgroundColor:"#f8f9fa"}} className="mt-4">
                                <div style={{width:"100%",display:"block"}} ><img className="d-block mx-auto " style={{marginRight:"0px",display:"block"}} width="800px" src="/images/neuron_banner.jpg" alt="neuron-banner" /></div>
                            </div>
                            
                            <hr className="margin-bottom-1x"/>
                             
                            <div class="card bg-secondary text-left mb-3">
                            <div style={{backgroundColor:"#fff"}} class="card-header text-lg"><h4><strong>Neuron</strong></h4></div>
                                <div style={{backgroundColor:"white"}} class="card-body">
                                {/* <h4 class="card-title">Secondary card title</h4> */}
                                <p class="card-text">
                                Neuron diseases are the set of diseases that affect the motor neurons which are accountable 
                                for monitoring the muscle movement in the brain and spinal cord of the body. The diseases 
                                concerning neurons affect the motor neurons which consequently causes the degeneration 
                                of the muscles. This, further, causes the loss of mobility in the limbs, problems with 
                                swallowing, proper speech and breathing. The main cause of motor neuron disease is the 
                                Vata dosha that is in control of the cognitive and neo-cognitive functioning of the brain. 
                                The ayurvedic treatment of the plan aims to conciliate the aggravated doshas in the body
                                 while eradicating amassed toxins from the profound layers of body tissues. This helps 
                                 in bringing the balance and harmony of the bodily systems. Ayurvedic treatment to this 
                                 ailment involves the usage of herbal decoctions, oils and other medications. These 
                                 solutions are administered to counterbalance the secretion of a variety of chemical
                                  neurotransmitters and hormones for proper neuron functioning.
                                </p>
                                </div>
                            </div>
                            </>
                            }

                    <div className="row">
                    <div className="col-xl-12 col-lg-12">
                    <div  className= "d-flex justify-content-between ">
                        <Link className= "btn btn-outline-secondary" to="/our-services">
                            <i className= "icon-arrow-left"></i><span className= "hidden-xs-down">&nbsp;Back</span></Link>
                            {/* <Link className= "btn btn-primary" to="checkout-complete.html">Complete Order</Link> */}
                    </div>
                    </div>              
                </div>

                        </div>
                    </div>
                    
                
                    
                    
                    {/* <div className="text-center padding-top-3x mb-30">
                        <h2 className="mb-3">Our Core Team</h2>
                        <p className="text-muted">People behind your awesome shopping experience.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-6 mb-30 text-center"><img className="d-block w-150 mx-auto img-thumbnail rounded-circle mb-2" src="/assets/img/team/01.jpg" alt="Team" />
                        <h6>Grace Wright</h6>
                        <p className="text-muted mb-2">Founder, CEO</p>
                        <div className="social-bar"><Link className="social-button shape-circle sb-facebook" to="#" data-toggle="tooltip" data-placement="top" title="Facebook"><i className="socicon-facebook"></i></Link><Link className="social-button shape-circle sb-twitter" to="#" data-toggle="tooltip" data-placement="top" title="Twitter"><i className="socicon-twitter"></i></Link><Link className="social-button shape-circle sb-google-plus" to="#" data-toggle="tooltip" data-placement="top" title="Google +"><i className="socicon-googleplus"></i></Link></div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-30 text-center"><img className="d-block w-150 mx-auto img-thumbnail rounded-circle mb-2" src="/assets/img/team/02.jpg" alt="Team" />
                        <h6>Taylor Jackson</h6>
                        <p className="text-muted mb-2">Financial Director</p>
                        <div className="social-bar"><Link className="social-button shape-circle sb-skype" to="#" data-toggle="tooltip" data-placement="top" title="Skype"><i className="socicon-skype"></i></Link><Link className="social-button shape-circle sb-facebook" to="#" data-toggle="tooltip" data-placement="top" title="Facebook"><i className="socicon-facebook"></i></Link><Link className="social-button shape-circle sb-paypal" to="#" data-toggle="tooltip" data-placement="top" title="PayPal"><i className="socicon-paypal"></i></Link></div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-30 text-center"><img className="d-block w-150 mx-auto img-thumbnail rounded-circle mb-2" src="/assets/img/team/03.jpg" alt="Team" />
                        <h6>Quinton Cross</h6>
                        <p className="text-muted mb-2">Marketing Director</p>
                        <div className="social-bar"><Link className="social-button shape-circle sb-twitter" to="#" data-toggle="tooltip" data-placement="top" title="Twitter"><i className="socicon-twitter"></i></Link><Link className="social-button shape-circle sb-google-plus" to="#" data-toggle="tooltip" data-placement="top" title="Google +"><i className="socicon-googleplus"></i></Link><Link className="social-button shape-circle sb-email" to="#" data-toggle="tooltip" data-placement="top" title="Email"><i className="socicon-mail"></i></Link></div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-30 text-center"><img className="d-block w-150 mx-auto img-thumbnail rounded-circle mb-2" src="/assets/img/team/04.jpg" alt="Team" />
                        <h6>Liana Mullen</h6>
                        <p className="text-muted mb-2">Lead Designer</p>
                        <div className="social-bar"><Link className="social-button shape-circle sb-behance" to="#" data-toggle="tooltip" data-placement="top" title="Behance"><i className="socicon-behance"></i></Link><Link className="social-button shape-circle sb-dribbble" to="#" data-toggle="tooltip" data-placement="top" title="Dribbble"><i className="socicon-dribbble"></i></Link><Link className="social-button shape-circle sb-instagram" to="#" data-toggle="tooltip" data-placement="top" title="Instagram"><i className="socicon-instagram"></i></Link></div>
                        </div>
                    </div> */}
                </div>
    
            </div>
    )
}

export default DiseasesScreen
