import React from 'react'
import { Link } from 'react-router-dom'

function ContactUsScreen() {
    return (
        <div>

            {/* <!-- Page Title--> */}
                <div className="page-title">
                <div className="container">
                    <div className="column">
                    <h1>Contact Us</h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li><Link to="/">Home</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>Contact Us</li>
                    </ul>
                    </div>
                </div>
                </div>
                {/* <!-- Page Content--> */}
                <div className="container padding-bottom-2x mb-2" style={{minHeight:"70vh"}}>
                <div className="row">
                    <div className="col-md-5">
                    <div className="display-3 text-muted opacity-75 mb-30"><strong>Get In Touch</strong></div>
                    <p>Etechan Nutri Health & Wellness Centre, <br/>
                    110, Pocket I, Jasola, <br/>
                    New Delhi - 110044</p>
                    <ul className="list-icon">
                        <li> <i className="icon-mail text-muted"></i><Link className="navi-link" to="mailto:info@etechanhealthtech.com">info@etechanhealthtech.com</Link></li>
                        <li> <i className="icon-phone text-muted"></i>+91 (11) 4505 6219</li>
                        <li> <i className="icon-clock text-muted"></i>1 - 2 business days</li>
                    </ul>
                    </div>
                    <div className="col-md-6">
                    <div style={{display:"flex", height: "270px"}} className="google-map" data-height="270" 
                            data-address="New York, USA" 
                            data-zoom="9" 
                            data-disable-controls="true" 
                            data-scrollwheel="false" 
                            data-marker-title="We are here1!" 
                            >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7008.493985764322!2d77.283908!3d28.562345!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2a8d58a7d1d24bf2!2sEtechan%20Nutri%20Health%20and%20Wellness%20Centre!5e0!3m2!1sen!2sin!4v1617997348845!5m2!1sen!2sin" 
                        width="600" height="310" style={{border:"0"}} allowFullScreen="" loading="lazy" title="etechanHealthCare"></iframe>

                    </div>
                    </div>
                </div>
                <hr className="margin-top-2x" />
                
                
                </div>
    
            </div>
    )
}

export default ContactUsScreen
