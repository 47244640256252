import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import Message from './Message'

class ErrorBoundary extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             hasError: false
        }
    }    

    static getDerivedStateFromError(error){
        return {
            hasError: true
        }
    }

    

    render() {
        if (this.state.hasError){
            return (
            <>
                <section className="fw-section margin-top-3x" style={{backgroundImage: "url(/images/404-bg.png)"}}>
                    <h1 className="display-404 text-center">404</h1>
                    </section>
                    <div className="container padding-bottom-3x mb-1">
                    <div className="text-center">
                        <h2>Page Not Found</h2>
                        <p>It seems we can’t find the page you are looking for.&nbsp;
                            <Link to="/">Go back to Homepage</Link><br/>
                            Or try using search at the top cneter of the page.
                        </p>
                    </div>
                </div>
                <Message variant="danger">Something Went Wrong. Back to <Link to="/">Home</Link></Message>
            </>
        
        )}
        return this.props.children

    }
}

export default ErrorBoundary

// Usage:
// To use this component wrap the component (where the error is expected) within <ErrorBoundary>...</ErrorBoundary>

