import {
    // All page contents
    PAGE_CONTENTS_REQUEST,
    PAGE_CONTENTS_SUCCESS,
    PAGE_CONTENTS_FAIL,

    // Single page contents
    PAGE_CONTENT_REQUEST, 
    PAGE_CONTENT_SUCCESS,
    PAGE_CONTENT_FAIL,

    PAGE_CONTENT_CREATE_REQUEST,
    PAGE_CONTENT_CREATE_SUCCESS,
    PAGE_CONTENT_CREATE_FAIL,
    PAGE_CONTENT_CREATE_RESET,

    PAGE_CONTENT_UPDATE_REQUEST,
    PAGE_CONTENT_UPDATE_SUCCESS,
    PAGE_CONTENT_UPDATE_FAIL,
    PAGE_CONTENT_UPDATE_RESET,
    
    
} from '../constants/pageConstants'

export const pageContentsReducer = (state = {pageContentsList: []}, action) => {

    switch (action.type) {
        case PAGE_CONTENTS_REQUEST:
            return { loading: true, pageContentsList: [] }

        case PAGE_CONTENTS_SUCCESS:
            return { loading: false, 
                    pageContentsList: action.payload,
                    }

        case PAGE_CONTENTS_FAIL:
            return { loading: false, error: action.payload}

        default:
            return state

    }
}

export const pageContentReducer = (state = {singlePageContent: []}, action) => {

    switch (action.type) {
        case PAGE_CONTENT_REQUEST:
            return { loading: true, ...state }

        case PAGE_CONTENT_SUCCESS:
            return { loading: false, singlePageContent: action.payload }

        case PAGE_CONTENT_FAIL:
            return { loading: false, error: action.payload}

        default:
            return state

    }
}

// export const productDeleteReducer = (state = {}, action) => {

//     switch (action.type) {
//         case PRODUCT_DELETE_REQUEST:
//             return { loading: true }

//         case PRODUCT_DELETE_SUCCESS:
//             return { loading: false, success: true }

//         case PRODUCT_DELETE_FAIL:
//             return { loading: false, error: action.payload}

//         default:
//             return state

//     }
// }

export const pageContentCreateReducer = (state = {}, action) => {

    switch (action.type) {
        case PAGE_CONTENT_CREATE_REQUEST:
            return { loading: true }

        case PAGE_CONTENT_CREATE_SUCCESS:
            return { loading: false, success: true, pageContent: action.payload }

        case PAGE_CONTENT_CREATE_FAIL:
            return { loading: false, error: action.payload}

        case PAGE_CONTENT_CREATE_RESET:
            return {}

        default:
            return state

    }
}

export const pageContentUpdateReducer = (state = { pageContent: {}}, action) => {

    switch (action.type) {
        case PAGE_CONTENT_UPDATE_REQUEST:
            return { loading: true }

        case PAGE_CONTENT_UPDATE_SUCCESS:
            return { loading: false, success: true, pageContent: action.payload }

        case PAGE_CONTENT_UPDATE_FAIL:
            return { loading: false, error: action.payload}

        case PAGE_CONTENT_UPDATE_RESET:
            return { pageContent: {}}

        default:
            return state

    }
}

