import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from '../../components/Loader'
import Message from '../../components/Message'
// import axios from "axios";
import '../../index.css'
// import RelatedProducts  from '../components/RelatedProducts'

import {
  listDoctorDetails,
} from "../../actions/doctorActions";

import ProductCarousel from "../../components/ProductCarousel";

import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";




function DoctorScreen({ match, history }) {

  const doctorDetails = useSelector((state) => state.doctorDetails);
  const { loading, error, doctor } = doctorDetails;

  const dispatch = useDispatch();

  const doctorId = match.params.id;


  useEffect(() => {
    dispatch(listDoctorDetails(doctorId));
  }, [dispatch, doctorId]);


  let imageList = [];
  if (doctor) {
    if (
      doctor.image_1 &&
      doctor.image_1.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':doctor.image_1});
    }
    if (
      doctor.image_2 &&
      doctor.image_2.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':doctor.image_2});
    }
    if (
      doctor.image_3 &&
      doctor.image_3.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':doctor.image_3});
    }
    if (
      doctor.image_4 &&
      doctor.image_4.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':doctor.image_4});
    }
    if (
      doctor.image_5 &&
      doctor.image_5.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':doctor.image_5});
    }
  }

  const getExperience = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)


  return (
    <>
       {loading ? (
        <>
          <Loader />
        </>
      ) : //
      error ? (
        <Message variant="danger">{error}</Message>
      ) : (
      
      <>
        <div className="page-title">
          <div className="container">
            <div className="column">
              <h1>Meet Our Doctors</h1>
            </div>
            <div className="column">
              <ul className="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="separator">&nbsp;</li>
                <li>
                  <Link to="/doctors">Doctors</Link>
                </li>
                <li className="separator">&nbsp;</li>
                <li>{doctor.name}</li>
              </ul>
            </div>
          </div>
        </div>
            <div className="container padding-bottom-3x mt-5">
            <div className="product-card product-list" style={{ verticalAlign: "top"}}>
              <div className="product-thumb" style={{padding:"0px"}}>
                  <ProductCarousel
                      infiniteLoop={false}
                      images={imageList}
                      showThumbs={false}
                      clickable={true}
                    />
              </div>
            <div className="product-card-inner" style={{display:'flex',marginTop:15,justifyContent:'flex-start'}}>
              <div className="product-card-body pt-0" >
                  
                <div className="mt-2">
                  <h5 className="mb-0" >{doctor && doctor.name}</h5>
                  <div className="text-muted text-lg" >{doctor && doctor.professionTitle} {doctor && doctor.degree && ', '+doctor.degree}</div>
                </div>
                
                <hr className="mb-2 mt-2" />
                {/* {doctor.degree && 
                <div className="mb-1"><strong className="text-muted ">{doctor.degree}</strong></div>
                } */}

                {(doctor.specialty_1 || doctor.specialty_2 || doctor.specialty_3 || doctor.specialty_4) ?
                  <>
                    <strong className="text-muted">Specialization:</strong> 
                    <ul className="mb-1" style={{marginLeft:"10px"}}>
                      {doctor.specialty_1 &&
                          <li>{doctor.specialty_1}</li>
                      }
                      {doctor.specialty_2 &&
                          <li>{doctor.specialty_2}</li>
                      }
                      {doctor.specialty_3 &&
                          <li>{doctor.specialty_3}</li>
                      }
                      {doctor.specialty_4 &&
                          <li>{doctor.specialty_4}</li>
                      }
                    </ul>
                  </> : null
                }
                
                {(doctor.association_1 || doctor.association_2 || doctor.association_3 || doctor.association_4) &&
                  <div>
                    <strong className="text-muted">Associations:</strong> 
                    
                    <ul className="mb-1" style={{marginLeft:"10px"}}>
                      {doctor.association_1 &&
                          <li >{doctor.association_1}</li>
                      }
                      {doctor.association_2 &&
                          <li>{doctor.association_2}</li>
                      }
                      {doctor.association_3 &&
                          <li>{doctor.association_3}</li>
                      }
                      {doctor.association_4 &&
                          <li>{doctor.association_4}</li>
                      }
                    </ul>
                    
                  </div> 
                }             
                
                {doctor.experienceFrom && getExperience(doctor.experienceFrom) > 0 &&
                <div >
                <strong className="text-muted">Experience:</strong> {getExperience(doctor.experienceFrom)} years
                </div>
                }
                
                {doctor.services && doctor.services.length > 0 && 
                <div>
                  <strong className="text-muted">Services at Etechan:</strong>
                  <ul className="mb-1" style={{marginLeft:"10px"}}>
                    {doctor.services.map(service => (
                      <li key={service.slug}>{service.name}</li>
                    ))}
                  </ul>
                </div>
                }
                
                
                {doctor.websiteLink &&
                <>
                <strong className="text-muted">Website:</strong> <Link to={doctor.websiteLink}>{doctor.websiteLink}</Link> 
                </>
                }
              </div>
            </div>
          </div>
          
          
          <div
            className="bg-secondary  padding-bottom-1x mt-2 mb-3"
            style={{display: (doctor && !/\S/.test(doctor.description)) || (doctor && !doctor.description)  ? 'none' : 'block'}}
            id="details"
          >
            
            <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p 
                  style={{
                    fontSize: "15px",
                    color: "black",
                    // padding: "10px",
                    whiteSpace: "pre-wrap",
                  }}
                  className="mb-1"
                >
                  {doctor.description}
                </p>
               
              </div>
            
            </div>
          </div>
            
          
          
          </div> 
          <div className="shopping-cart-footer">
              <div className="column">
                <Link className="btn btn-outline-secondary" to="/doctors">
                  <i className="icon-arrow-left"></i>&nbsp;Back
                </Link>
              </div>
              <div className="column"></div>
            </div>
          </div>
      
      
      </>
      
      
      )}

     
    </>
  );
}

export default DoctorScreen;
