export const DOCTOR_LIST_REQUEST = 'DOCTOR_LIST_REQUEST'
export const DOCTOR_LIST_SUCCESS = 'DOCTOR_LIST_SUCCESS'
export const DOCTOR_LIST_FAIL = 'DOCTOR_LIST_FAIL'
export const DOCTOR_LIST_RESET = 'DOCTOR_LIST_RESET'

export const DOCTOR_DETAILS_REQUEST = 'DOCTOR_DETAILS_REQUEST'
export const DOCTOR_DETAILS_SUCCESS = 'DOCTOR_DETAILS_SUCCESS'
export const DOCTOR_DETAILS_FAIL = 'DOCTOR_DETAILS_FAIL'
export const DOCTOR_DETAILS_RESET = 'DOCTOR_DETAILS_RESET'

export const DOCTOR_CREATE_REQUEST = 'DOCTOR_CREATE_REQUEST'
export const DOCTOR_CREATE_SUCCESS = 'DOCTOR_CREATE_SUCCESS'
export const DOCTOR_CREATE_FAIL = 'DOCTOR_CREATE_FAIL'
export const DOCTOR_CREATE_RESET = 'DOCTOR_CREATE_RESET'

export const DOCTOR_UPDATE_REQUEST = 'DOCTOR_UPDATE_REQUEST'
export const DOCTOR_UPDATE_SUCCESS = 'DOCTOR_UPDATE_SUCCESS'
export const DOCTOR_UPDATE_FAIL = 'DOCTOR_UPDATE_FAIL'
export const DOCTOR_UPDATE_RESET = 'DOCTOR_UPDATE_RESET'

export const DOCTOR_DELETE_REQUEST = 'DOCTOR_DELETE_REQUEST'
export const DOCTOR_DELETE_SUCCESS = 'DOCTOR_DELETE_SUCCESS'
export const DOCTOR_DELETE_FAIL = 'DOCTOR_DELETE_FAIL'

// export const DOCTOR_UPDATE_PROFILE_REQUEST = 'DOCTOR_UPDATE_PROFILE_REQUEST'
// export const DOCTOR_UPDATE_PROFILE_SUCCESS = 'DOCTOR_UPDATE_PROFILE_SUCCESS'
// export const DOCTOR_UPDATE_PROFILE_FAIL = 'DOCTOR_UPDATE_PROFILE_FAIL'
// export const DOCTOR_UPDATE_PROFILE_RESET = 'DOCTOR_UPDATE_PROFILE_RESET'
