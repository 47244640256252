import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { createAppointment,
        //  getFreightCharges,
        //  sendEmailOrder 
        } from '../../actions/appointmentActions'
// import {listServiceDetails} from "../../actions/serviceActions";
import { APPOINTMENT_CREATE_RESET } from '../../constants/appointmentConstants'
import { CART_CLEAR_SERVICE } from '../../constants/serviceCartConstants'

function ConfirmAppointmentScreen({history}) {

    const appointmentCreate = useSelector(state => state.appointmentCreate)
    const {appointment, error,success} = appointmentCreate

    // const currencyValue = useSelector(state => state.currency)
    // const {currency} = currencyValue

    const dispatch = useDispatch()

    const serviceCart = useSelector(state => state.serviceCart)
    const { appointmentService, appointmentSchedule, patientDetails} = serviceCart

    // const userLogin = useSelector((state) => state.userLogin);
    // const { userInfo } = userLogin;

    if (patientDetails && patientDetails.countryCode !== "IN"){
        serviceCart.currency = "USD"
        serviceCart.fee = appointmentService.feeDollars
    }else {
        serviceCart.currency = "INR"
        serviceCart.fee = appointmentService.feeRupees
    }

    const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)

    serviceCart.SGST = (serviceCart.fee * appointmentService.SGST / 100).toFixed(2)
    serviceCart.CGST = (serviceCart.fee * appointmentService.CGST / 100).toFixed(2)
    serviceCart.IGST = (serviceCart.fee * appointmentService.IGST / 100).toFixed(2)

    // const prescriptionFromStorage = localStorage.getItem('prescription') ?
    //     localStorage.getItem('prescription') : null
    
    // const reportFromStorage = localStorage.getItem('report') ?
    //     localStorage.getItem('report') : null

    // console.log('prescriptionFromStorage',prescriptionFromStorage)
    // console.log('reportFromStorage',reportFromStorage)
    useEffect(() => {

        if(success){
            

            // const uploadReviewImages = async () => {

            //     const config = {
            //       headers: {
            //         "content-type": "multipart/form-data",
            //         Authorization: `Bearer ${userInfo.token}`,
            //       },
            //     };
            
            //     const formData = new FormData();
            //     if(prescriptionFromStorage){
            //         formData.append("prescription", prescriptionFromStorage);
            //     }else{
            //         formData.append("prescription", '');
            //     }
                
            //     if(reportFromStorage){
            //         formData.append("report", reportFromStorage);
            //     }else{
            //         formData.append("report", '');
            //     }

            //     formData.append("appointment_slug", appointment.slug);
                
            //     try {
            //        await axios.post(
            //         "/api/appointments/uploadApporintmentDocuments/",
            //         formData,
            //         config
            //       );
                  
            //     } catch (error) {
            //       console.log("uploading error!" + error);
            //     }
            //   };
            
            //   uploadReviewImages();
              history.push(`/appointment/${appointment.slug}`)
              dispatch({type: APPOINTMENT_CREATE_RESET})
              dispatch({type: CART_CLEAR_SERVICE})
            
        }
    },[success,history,appointment,dispatch])

    serviceCart.paymentMethod = "Razorpay"
    if (!serviceCart.appointmentSchedule){
        history.push('/patient-details')
    }


    const placeOrder = ()=>{
        var doctorId = ''
        if (appointmentSchedule.doctor){
            doctorId = appointmentSchedule.doctor._id
        }
        dispatch(createAppointment({
            serviceId: appointmentService._id,
            doctorId: doctorId,
            appointmentSchedule: appointmentSchedule,
            patientDetails: patientDetails,
            paymentMethod: serviceCart.paymentMethod,
            fee: serviceCart.fee,
            currency: serviceCart.currency,     
            SGST: serviceCart.SGST,
            CGST: serviceCart.CGST,
            IGST: serviceCart.IGST   
        }))      
        
    }
    return (
        <div>
           
                    <>
            <div className= "page-title">
            <div className= "container">
                <div className= "column">
                <h1>Confirm Appointment</h1>
                </div>
                <div className= "column">
                <ul className= "breadcrumbs">
                    <li><Link to="index.html">Home</Link>
                    </li>
                    <li className= "separator">&nbsp;</li>
                    <li>Confirm Appointment</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className= "container padding-bottom-3x mb-2">
            <div className= "row">
                {/* <!-- Checkout Adress--> */}
                <div className= "col-xl-8 col-lg-8">
                <div className= "steps flex-sm-nowrap mb-5">
                    <Link className= "step " to= {`/schedule-appointment/${appointmentService.slug}`}>
                        <h4 className= "step-title">{appointmentSchedule && appointmentSchedule.date && <i className= "icon-check-circle" style={{color:"#007bff"}}></i>}1. Schedule</h4>
                    </Link> 

                    
                    <Link className= "step " to= "/patient-details">
                        <h4 className= "step-title">{patientDetails && patientDetails.name && <i className= "icon-check-circle" style={{color:"#007bff"}}></i>}2. Patient Details</h4>
                    </Link> 
                    <Link className= "step active" to= "#">
                        <h4 className= "step-title">3. Confirm Appointment</h4>
                    </Link> 
                    
                    <Link className= "step" to= "#">
                        <h4 className= "step-title">3. Pay</h4>
                    </Link> 
                </div>
                <h4 className= "padding-bottom-1x">Review Your Appointment</h4>
                <div className= "table-responsive shopping-cart">
                    <table className= "table">
                    <thead>
                        <tr>
                        <th>Service</th>
                        <th>Time Slot</th>
                        <th>Expert</th>
                        <th>Consulation Fee</th>
                        <th className= "text-center"></th>
                        
                        </tr>
                    </thead>
                    <tbody>
                       
                        <>
                        
                        <tr>
                        <td>
                            <div className= "product-item " style={{maxWidth:"10%"}}>
                                <Link to={`/services/${appointmentService.slug}`} 
                                      style={{textDecoration:"none", color:"#343a40"}} 
                                      className= "text-center" >
                                          <div className="text-left">
                                          <img src={appointmentService.image_1} 
                                                width="100px"
                                                
                                                alt={appointmentService.name} />
                                          </div>
                                          
                                      <div className="text-left text-lg">{appointmentService.name}</div>
                                </Link>
                            
                            </div>
                        </td>
                        <td className= "text-center text-md">
                            <div style={{textAlign:"left"}}>
                            {appointmentSchedule.startTime + " - " + appointmentSchedule.endTime} (IST)<br/>
                            {appointmentSchedule.date}
                            </div>
                        </td>
                        
                        <td className= "text-center text-md">
                            <div style={{textAlign:"left"}}>
                            {appointmentSchedule.doctor ? appointmentSchedule.doctor.name : '-'}
                            </div>
                        </td>
                        
                        <td className= "text-center text-md">
                            <div style={{textAlign:"left"}}>
                            {serviceCart.currency === 'INR' ? '₹': '$'}{serviceCart.fee}
                            </div>
                        </td>
                        {/* <td className= "text-center text-lg">{currency === 'INR' ?
                            '₹ '+service.feeRupees: '$ '+service.feeDollars}{} </td> */}
                        <td className= "text-center"><Link className= "btn btn-outline-primary btn-sm" to={`/schedule-appointment/${appointmentService.slug}`}>Edit</Link></td>
                        </tr>

                        
                        
                        
                        </>
                        
                        
                        
                    </tbody>
                    </table>
                </div>
                <div className= "shopping-cart-footer">
                    <div className= "column"></div>
                    <div className= "column text-lg">
                        {/* <span className= "text-gray-dark">Consulation Fee:&nbsp; </span>
                        <span className= "text-gray-dark" style={{fontWeight:"500"}}>
                            {serviceCart.currency === 'INR' ? '₹': '$'}{serviceCart.fee}
                        </span> */}
                    </div>
                </div>
                
                <div className= "d-flex justify-content-between paddin-top-1x mt-4">
                    <Link className= "btn btn-outline-secondary" to="/patient-details">
                        <i className= "icon-arrow-left"></i><span className= "hidden-xs-down">&nbsp;Back</span></Link>
                        {/* <Link className= "btn btn-primary" to="checkout-complete.html">Complete Order</Link> */}
                </div>
                </div>
                {/* <!-- Sidebar --> */}
                
                <div className= "col-xl-4 col-lg-4">
                <aside className= "sidebar">
                    <div className= "padding-top-2x hidden-lg-up"></div>
                    

                    <div style={{marginTop:'0px'}}>
                    <section style={{padding:"20px 15px 0px 15px"}} className="widget widget-order-summary card">
                    <h3 className="widget-title"><strong>Patient Details</strong></h3>
                    <ul style={{}} className= "list-unstyled">
                        <li><span className= "text-muted">Patient Name:&nbsp; </span>{patientDetails.name} </li>
                        <li><span className= "text-muted">Gender/Age:&nbsp; </span>{patientDetails.gender}/{getAge(patientDetails.dob)} years</li>
                        <li><span className= "text-muted">Address:&nbsp; </span>{patientDetails.address1}{patientDetails.address2 ? ', ' + patientDetails.address2 : null}, {patientDetails.city},{' '}
                                    {patientDetails.state}-{patientDetails.postalCode}, {patientDetails.country}</li>
                        <li><span className= "text-muted">Phone:&nbsp; </span>{'+'+patientDetails.phone}</li>
                        {patientDetails.email &&
                            <li><span className= "text-muted">Email:&nbsp; </span>{patientDetails.email}</li>
                        }
                    </ul>
                    <table className="table">
                        <tbody>
                        <tr>
                        <td>Consulation Fee:</td>
                        <td className="text-gray-dark" style={{fontWeight:"500"}}>{serviceCart.currency === 'INR' ? '₹': '$'}{serviceCart.fee}</td>
                        </tr>
                        
                        
                        </tbody>
                    </table>
                    <hr/>
                    <div>
                        {error && <Message variant="danger">{error}</Message>}
                    </div>
                    <div className= "d-flex justify-content-between">
                    
                        <button className= "btn btn-primary btn-block" type="button"
                                disabled={false}
                                onClick={placeOrder}>Confirm & Pay</button>
                    </div>
                    </section>
                    
                    </div>
                   
                    
                </aside>
                </div>
            </div>
            </div>
            </>
            
        </div>
        
    )
}

export default ConfirmAppointmentScreen
