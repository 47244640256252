import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div>
            <footer className="site-footer" style={{ backgroundimage: "url(/assets/img/footer-bg.png)"}}>
            <div className="container">
                <div className="row">
                <div className="col-lg-6">
                    
                    <section className="widget widget-links widget-light-skin">
                    <h3 className="widget-title">Etechan HealthTech</h3>
                    <div className="row">
                        <div className="col-md-6">
                        <ul>
                            <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/our-products">Our Products</Link></li>
                            <li><Link to="/our-services">Our Services</Link></li>
                            <li><Link to="/ayurveda">Ayurveda</Link></li>
                            <li><Link to="/diet-and-nutrition">Diet & Nutrition</Link></li>
                            <li><Link to="/hyperbaric">Hyperbaric</Link></li>
                            
                        </ul>
                        </div>
                        <div className="col-md-6">
                        <ul>
                            <li><Link to="/refund-cancellation-policy">Refund Policy</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                            <li><Link to="/doctors">Our Experts</Link></li>
                            <li><Link to="/services">Diseases</Link></li>
                            <li><Link to="/contact-us">Contact Us</Link></li>
                        </ul>
                        </div>
                    </div>
                    </section>
                </div>
                <div className="col-lg-3 col-md-6">
                    
                    <section className="widget widget-light-skin">
                        <h3 className="widget-title">Get In Touch</h3>
                        <p style={{marginBottom:"5px"}} className="text-white"><i className="fa fa-map-marker" ></i>{' '}Etechan Nutri Health & Wellness World,<br/>{' '} 110, Pocket I, Jasola,<br/> New Delhi - 110044</p>
                        <p style={{marginBottom:"5px"}} className="text-white">Phone: +91 (11) 4505 6219</p>
                        {/* <ul className="list-unstyled text-sm text-white">
                            <li><span className="opacity-50">Monday-Friday:&nbsp;</span>9.00 am - 8.00 pm</li>
                            <li><span className="opacity-50">Saturday:&nbsp;</span>10.00 am - 6.00 pm</li>
                        </ul> */}
                        <p style={{marginBottom:"10px"}}><Link className="navi-link-light" to="/contact-us">info@etechanhealthtech.com</Link></p><Link className="social-button shape-circle sb-facebook sb-light-skin" to="#"><i className="socicon-facebook"></i></Link><Link className="social-button shape-circle sb-twitter sb-light-skin" to="#"><i className="socicon-twitter"></i></Link><Link className="social-button shape-circle sb-instagram sb-light-skin" to="#"><i className="socicon-instagram"></i></Link><Link className="social-button shape-circle sb-google-plus sb-light-skin" to="#"><i className="socicon-googleplus"></i></Link>
                    </section>
                </div>
                <div className="col-lg-3 col-md-6">
                   
                <section className="widget widget-light-skin">
                    <h3 className="widget-title">Be Informed</h3>
                   
                        <div className="col-lg-12 col-md-12">
                        <div className="input-group input-light">
                            <input style={{width:"100%"}} className="form-control" type="email" name="EMAIL" placeholder="Your e-mail" /><span className="input-group-addon" ><i className="icon-mail"></i></span>
                        </div>
                        {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                            <input type="text"  tabIndex="-1" />
                        </div>
                        <p style={{paddingLeft:"0px"}} className="form-text text-sm text-white opacity-50 pt-2">Subscribe to our Newsletter.</p>
                        </div>
                        <div className="col-lg-12 col-md-12">
                        <button className="btn btn-primary btn-block mt-0" type="submit">Subscribe</button>
                        </div>
                    
                    {/* <div className="pt-3"><img className="d-block" style={{ width: "324px"}} alt="Cerdit Cards" src="/images/img/credit-cards-footer.png" /></div> */}
                    </section>
                </div>
                </div>
                
                {/* <!-- Copyright--> */}
                <p style={{textAlign:"center"}} className="footer-copyright">© All rights reserved. </p>
            </div>
            </footer>
        </div>
    )
}

export default Footer
