import React from 'react'
import { Link } from 'react-router-dom'

function Paginate({pages, page=1, keyword='',services=false, isAdmin=false}) {

    if (keyword){
        keyword=keyword.split('?keyword=')[1].split('&')[0]
    //    console.log('paginate kw:',keyword)
    }

    // console.log('KEYWORD:', keyword)
    return (pages > 1 && (

        services ? 
            <div>
                <nav className="pagination">
                <div className="column">
                <ul className="pages">

                    {[...Array(pages).keys()].map((x) => (

                        <li key={x+1} className={x+1 === page ? "active" : null}>
                            <Link to={!isAdmin ?
                                `?keyword=${keyword}&page=${x+1}`
                                : `/admin/servicelist/?keyword=${keyword}&page=${x+1}`
                                }>{x+1}
                            </Link>
                        </li>
                    ))}
                    
                </ul>
                </div>
                <div className="column text-right hidden-xs-down"><Link className="btn btn-outline-secondary btn-sm" 
                            to={!isAdmin ?
                                `?keyword=${keyword}&page=${page+1}`
                                : `/admin/servicelist/?keyword=${keyword}&page=${page+1}`
                                }>Next&nbsp;<i className="icon-chevron-right"></i></Link></div>
            </nav>
                
            </div> :
                <div>
                <nav className="pagination">
                <div className="column">
                <ul className="pages">

                    {[...Array(pages).keys()].map((x) => (

                        <li key={x+1} className={x+1 === page ? "active" : null}>
                            <Link to={!isAdmin ?
                                `/?keyword=${keyword}&page=${x+1}`
                                : `/admin/productlist/?keyword=${keyword}&page=${x+1}`
                                }>{x+1}
                            </Link>
                        </li>
                    ))}
                    
                </ul>
                </div>
                <div className="column text-right hidden-xs-down"><Link className="btn btn-outline-secondary btn-sm" 
                            to={!isAdmin ?
                                `/?keyword=${keyword}&page=${page+1}`
                                : `/admin/productlist/?keyword=${keyword}&page=${page+1}`
                                }>Next&nbsp;<i className="icon-chevron-right"></i></Link></div>
            </nav>
                
            </div>
        
        



        )
    )
    
}

export default Paginate
