import React, { useEffect,useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Paginate from '../components/Paginate'
import { listProducts, deleteProduct, createProduct } from '../actions/productActions'
import { PRODUCT_CREATE_RESET } from '../constants/productConstants'
function ProductListScreen({history,match}) {

    const dispatch = useDispatch()
    const productList = useSelector(state => state.productList)
    const { loading , error, products, pages, page } = productList

    const productDelete = useSelector(state => state.productDelete)
    const { loading:loadingDelete , error:errorDelete } = productDelete

    const productCreate = useSelector(state => state.productCreate)
    const { loading:loadingCreate , error:errorCreate, success:successCreate, product:createdProduct } = productCreate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const [productName, setProductName] = useState('')
    const [openModal, setOpenModal] = useState(false)

    let keyword = history.location.search


    useEffect(() => {
        

        if(!userInfo.isAdmin){
            history.push('/login')
        }

        if(successCreate){
            history.push(`/admin/${createdProduct.slug}/edit`)
            dispatch({ type: PRODUCT_CREATE_RESET })
        }else{
            dispatch(listProducts(keyword))
        }
        
    },[dispatch,history,userInfo,successCreate,createdProduct,keyword])

    const deleteHandler = (id)=> {
        if (window.confirm('Are you sure you want to delete this Product?')) {
            dispatch(deleteProduct(id))
            dispatch(listProducts())
        }
        
    }

    const createProductHandler = (productName) =>{
        setOpenModal(false)
        dispatch(createProduct(productName))
    } 

    return (
        <div>
            {loading ? <Loader/>
                : error ? <Message variant='danger'>{error}</Message>
                : (
                    <>
                    {/* <!-- Page Title--> */}
                    <div className="page-title">
                    <div className="container">
                        <div className="column">
                        <h1>Product List</h1>
                        </div>
                        <div className="column">
                        <ul className="breadcrumbs">
                            <li><Link to="/">Home</Link> 
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li><Link to="">Account</Link> 
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>Product List</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    {/* <!-- Page Content--> */}
                    <div className="container padding-bottom-3x mb-2">
                    <div className="row">

                    <div className="col-12">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div >
                            <h5>Product List</h5>
                        </div>
                        {/* <button className="btn btn-secondary margin-right-none" 
                                type="button"
                                onClick={createProductHandler}
                                >
                                <i className="fas fa-plus"></i> Add Product
                        </button> */}
                        <button className="btn btn-secondary margin-right-none" 
                                type="button" 
                                data-toggle="modal" 
                                data-target="#modalCentered"
                                onClick={()=> setOpenModal(true)}>
                                    <i className="fas fa-plus"></i> Add Product
                        </button>

                    </div>
                    
                    {/* <!-- Vertically Centered Modal--> */}
                    <div className={openModal ? "modal fade show" : "modal fade"} 
                         style={{display: openModal ? "block" : "none", paddingRight: "17px" }}
                         id="modalCentered" 
                         tabIndex="-1" 
                         role="dialog"
                         >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Create Product</h4>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <p>Enter the name of the product.</p>
                            <input className="form-control" 
                                    type="text" 
                                    id="text-input" 
                                    value={productName}
                                    onChange={(e)=>setProductName(e.target.value)}
                                    />
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-secondary btn-sm" 
                                    type="button" 
                                    data-dismiss="modal"
                                    onClick={()=> setOpenModal(false)}
                                    >Close</button>
                            <button className="btn btn-primary btn-sm" type="button" onClick={()=>createProductHandler(productName)}>Create Product</button>
                            
                        </div>
                        </div>
                    </div>
                    </div>
                    </div>
                                {/* <div className="col-lg-3"></div> */}
                                <div className="col-lg-12">
                                    <center>{loadingDelete && <Loader/>}</center>
                                    {errorDelete && <Message variant="danger">{errorDelete}</Message>}

                                    <center>{loadingCreate && <Loader/>}</center>
                                    {errorCreate && <Message variant="danger">{errorCreate}</Message>}
                                <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                                <div className="table-responsive">
                                <table className="table table-striped">
                                <thead>
                                    <tr>
                                    <th>ID</th>
                                    <th>Name</th>                            
                                    <th>Price {'&'} Stock1 </th>
                                    <th>Price {'&'} Stock2 </th>
                                    <th>Price {'&'} Stock3 </th>
                                    <th>Price {'&'} Stock4 </th>
                                    
                                    <th></th>
                                    <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map(product => (
                                        <tr key={product._id}>
                                        <th scope="row">{product.refId}</th>
                                        <td>{product.name} <small>({product.category})</small></td>
                                        <td><strong><span style={{color:'green'}}>{product.weight_1}</span>- {product.rupee_price_1 ? '₹'+product.rupee_price_1 : '-'}</strong> / <strong>{product.dollar_price_1 ? '$'+product.dollar_price_1 : '-'}</strong> - [<strong style={{color:'blue'}}>{product.countInStock_1}</strong>]</td>
                                        <td><strong><span style={{color:'green'}}>{product.weight_2}</span>-{product.rupee_price_2 ? '₹'+product.rupee_price_2 : '-'}</strong> / <strong>{product.dollar_price_2 ? '$'+product.dollar_price_2 : '-'}</strong> - [<strong style={{color:'blue'}}>{product.countInStock_2}</strong>]</td>
                                        <td><strong><span style={{color:'green'}}>{product.weight_3}</span>-{product.rupee_price_3 ? '₹'+product.rupee_price_3 : '-'}</strong> / <strong>{product.dollar_price_3 ? '$'+product.dollar_price_3 : '-'}</strong> - [<strong style={{color:'blue'}}>{product.countInStock_3}</strong>]</td>
                                        <td><strong><span style={{color:'green'}}>{product.weight_4}</span>-{product.rupee_price_4 ? '₹'+product.rupee_price_4 : '-'}</strong> / <strong>{product.dollar_price_4 ? '$'+product.dollar_price_4 : '-'}</strong> - [<strong style={{color:'blue'}}>{product.countInStock_4}</strong>]</td>
                                        
                                        <td><Link to ={`/admin/${product.slug}/edit`}>
                                            <i className='fas fa-edit'></i>
                                            </Link>
                                        </td>
                                        <td><button style={{padding: "0", border: "none", background: "none"}} onClick={()=> deleteHandler(product.slug)}>
                                            <i style={{color:'red'}} className='fas fa-trash-alt'></i>
                                            </button>
                                        </td>
                                        </tr>
                                    ))}
                                    
                                    
                                </tbody>
                                </table>
                            </div>
                                <hr/>
                                </div>
                            </div>
                            <Paginate pages={pages} page={page} isAdmin={true} />

                            </div>
                    
                    </>
                    )}
            
        </div>
    )
}

export default ProductListScreen
