import { CART_ADD_ITEM, 
         CART_REMOVE_ITEM, 
         CART_REMOVE_ALL_ITEMS,
         CART_SAVE_SHIPPING_ADDRESS,

         CART_SAVE_PAYMENT_METHOD,
         CART_CLEAR_ITEMS,
        } from '../constants/cartConstants'


export const cartReducer = (state={cartItems:[], shippingAddress:{} }, action) => {
    switch(action.type) {
        case CART_ADD_ITEM:
            const item = action.payload
            // const existItem = state.cartItems.find(x => x.product === item.product)
            const existItem = state.cartItems.find(x => x.product === item.product && x.weightId === item.weightId)
            if (existItem) {
                // const index = state.cartItems.indexOf(existItem);
                // console.log('existitem:',index)
                // console.log('indices:',indices)
                // state.cartItems.splice(index, 1);
                return {
                    ...state,
                    // cartItems: state.cartItems.map(x =>
                    //     x.product === existItem.product ? item : x)
                    cartItems: state.cartItems.map(x =>
                        x.product === existItem.product && x.weightId === existItem.weightId ? item : x)
                }

            } else {
                return {
                    ...state,
                    cartItems: [...state.cartItems, item]
                    
                }
                
            }
        case CART_REMOVE_ITEM:

            return{
                ...state,
                cartItems: state.cartItems.filter(x => String(x.product)+String(x.weightId) !== String(action.payload['id'])+String(action.payload['weightId']) )
            }

        case CART_REMOVE_ALL_ITEMS:
            return{                
                cartItems: []
            }
        case CART_SAVE_SHIPPING_ADDRESS:
            return{
                ...state,
                shippingAddress: action.payload
            }

        case CART_SAVE_PAYMENT_METHOD:
            return{
                ...state,
                paymentMethod: action.payload
            }

        case CART_CLEAR_ITEMS:
            return{
                ...state,
                cartItems: []
            }

        default:
            return state
        
        }
        
}