import React, { useEffect,useState,useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { createOrder,getFreightCharges,sendEmailOrder } from '../actions/orderActions'
import { ORDER_CREATE_RESET, ORDER_EMAIL_SEND_RESET } from '../constants/orderConstants'

function PlaceOrderScreen({history}) {

    const orderCreate = useSelector(state => state.orderCreate)
    const {order, error,success} = orderCreate

    const freightCharges = useSelector(state => state.freightCharges)
    const { loading:loadingFreightCharges , errorFreightCharges, charges } = freightCharges

    const currencyValue = useSelector(state => state.currency)
    const {currency} = currencyValue

    const dispatch = useDispatch()

    const cart = useSelector(state => state.cart)
    cart.itemsPrice = cart.cartItems.reduce((acc,item) => acc + item.price * item.qty, 0).toFixed(2)

    const totalWeightInGm = Number(cart.cartItems.reduce((acc,item)=> acc +item.weightInGm*item.qty,0))
    const [weightRange, setWeightRange] = useState('')
    

    useEffect(() => {
        if(!charges){
            dispatch(getFreightCharges())
            // console.log('state code:', charges.find(x=>x.sourceToDestination === "northEast_J&K"))
        }
        if(success){
            history.push(`/order/${order.slug}`)

            // this section needs to be removed when international order freight charges are available
            if (order.shippingAddress['countryCode'] !== "IN"){
                dispatch(sendEmailOrder(order.slug,"Initiated"))
            }
            // end of section

            dispatch({type: ORDER_CREATE_RESET})
            dispatch({type: ORDER_EMAIL_SEND_RESET})
        }
    },[success,history,order,dispatch,charges])


    const shippingCost = useMemo(()=>{

        const metroCities = ["Mumbai", "Mumbai Suburban", "Pune", "Pune Division",        
                            "Nagpur", "Nagpur Division", "Kolkata", "Howrah", "Chennai", "Coimbatore",
                            "Salem", "Bangalore Urban", "Hyderābād", "Hyderabad", "Ahmedabad", "Surat",        
                            "Vishākhapatnam", "Visakhapatnam", "Kanpur", "Patna", "Jaipur"]

        const northEast_JandK = ["AR","AS","MN","ML","MZ","NL","TR","SK","JK"]
        
        if (totalWeightInGm>0 && totalWeightInGm<=500){
            setWeightRange('zero_half_Kg')
        } else if (totalWeightInGm>500 && totalWeightInGm<=1000){
            setWeightRange('half_one_Kg')
        }
        
        else if (totalWeightInGm>1000 && totalWeightInGm<=1500){
            setWeightRange('one_oneAndHalf_Kg')
        }else if (totalWeightInGm>1500 && totalWeightInGm<=2000){
            setWeightRange('oneAndHalf_two_Kg')
        }
        
        else if (totalWeightInGm>2000 && totalWeightInGm<=2500){
            setWeightRange('two_twoAndHalf_Kg')
        }else if (totalWeightInGm>2500 && totalWeightInGm<=3000){
            setWeightRange('twoAndHalf_three_Kg')
        }
        
        else if (totalWeightInGm>3000 && totalWeightInGm<=3500){
            setWeightRange('three_threeAndHalf_Kg')
        }else if (totalWeightInGm>3500 && totalWeightInGm<=4000){
            setWeightRange('threeAndHalf_four_Kg')
        }
        
        else if (totalWeightInGm>4000 && totalWeightInGm<=4500){
            setWeightRange('four_fourAndHalf_Kg')
        }else if (totalWeightInGm>4500 && totalWeightInGm<=5000){
            setWeightRange('fourAndHalf_five_Kg')
        }
        
        else if (totalWeightInGm>5000 && totalWeightInGm<=5500){
            setWeightRange('five_fiveAndHalf_Kg')
        }else if (totalWeightInGm>5500 && totalWeightInGm<=6000){
            setWeightRange('fiveAndHalf_six_Kg')
        }
        
        else if (totalWeightInGm>6000 && totalWeightInGm<=6500){
            setWeightRange('six_sixAndHalf_Kg')
        }else if (totalWeightInGm>6500 && totalWeightInGm<=7000){
            setWeightRange('sixAndHalf_seven_Kg')
        }else{
            setWeightRange('sixAndHalf_seven_Kg')
        }
        

        if(charges && charges.length > 0){
            let destination = ''
            if(cart.shippingAddress['countryCode']==='IN' && cart.shippingAddress['stateCode']==='DL'){
                destination = charges.find(x=>x.sourceToDestination === 'withinState')
            }else if (cart.shippingAddress['countryCode']==='IN' && metroCities.includes(cart.shippingAddress['city'])){
                destination = charges.find(x=>x.sourceToDestination === 'metroToMetro')
            }else if (cart.shippingAddress['countryCode']==='IN' && northEast_JandK.includes(cart.shippingAddress['stateCode'])){
                destination = charges.find(x=>x.sourceToDestination === "northEast_J&K")
            }else if (cart.shippingAddress['countryCode']==='IN'){
                destination = charges.find(x=>x.sourceToDestination === "restOfIndia")
            }else {
                destination = charges.find(x=>x.sourceToDestination === "international")
            }
            if (destination[weightRange] && Number(destination[weightRange]) !== 0){
                return destination[weightRange]
            } else if (destination['maxPrice']){
                return destination['maxPrice']
            }else{
                return 0
            }
        } else{
            return 0
        }      
    },[cart.shippingAddress,totalWeightInGm,charges,weightRange])
    // console.log('total weight:',totalWeightInGm)

    cart.shippingPrice = Number(shippingCost)
    cart.totalPrice = (Number(cart.itemsPrice) + cart.shippingPrice).toFixed(2)
    cart.currency = currency


    // change below two lines for including payment options (Razorpay & Paypal) in the screen
    
    // const [paymentMethod,setPaymentMethod] = useState("Razorpay")
    // cart.paymentMethod = paymentMethod
    cart.paymentMethod = "Razorpay"
    // console.log('payMethod:',paymentMethod)
    if (!cart.cartItems || (cart.cartItems && cart.cartItems.length === 0)){
        history.push('/cart')
    }


    const placeOrder = ()=>{
        dispatch(createOrder({
            orderItems: cart.cartItems,
            shippingAddress: cart.shippingAddress,
            paymentMethod: cart.paymentMethod,
            itemsPrice: cart.itemsPrice,
            shippingPrice: cart.shippingPrice,
            totalPrice: cart.totalPrice,
            currency: cart.currency,
            status: "Initiated",
            totalWeightInGm: totalWeightInGm,         
        }))

      
        
    }
    return (
        <div>
             {loadingFreightCharges ? <center> <Loader variant="grow"/> </center>
                : errorFreightCharges ? <Message variant='danger'>{errorFreightCharges}</Message>
                : (
                    <>
            <div className= "page-title">
            <div className= "container">
                <div className= "column">
                <h1>Checkout</h1>
                </div>
                <div className= "column">
                <ul className= "breadcrumbs">
                    <li><Link to="index.html">Home</Link>
                    </li>
                    <li className= "separator">&nbsp;</li>
                    <li>Checkout</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className= "container padding-bottom-3x mb-2">
            <div className= "row">
                {/* <!-- Checkout Adress--> */}
                <div className= "col-xl-8 col-lg-8">
                <div className= "steps flex-sm-nowrap mb-5">
                    <Link className= "step" to= "/shipping">
                        <h4 className= "step-title"><i className= "icon-check-circle"></i>1. Address</h4>
                    </Link> 
                    
                    {/* <Link className= "step" to= "/payment">
                        <h4 className= "step-title"><i className= "icon-check-circle"></i>2. Payment Method</h4>
                    </Link>  */}
                    
                    <Link className= "step active" to= "#">
                        <h4 className= "step-title">2. Place Order</h4>
                    </Link> 
                    
                    <Link className= "step" to= "#">
                        <h4 className= "step-title">3. Pay</h4>
                    </Link> 
                </div>
                <h4 className= "padding-bottom-1x">Review Your Order</h4>
                <div className= "table-responsive shopping-cart">
                    <table className= "table">
                    <thead>
                        <tr>
                        <th>Product Name</th>
                        <th className= "text-center">Subtotal</th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {cart.cartItems.length === 0 ? <Message variant='info'>Your Cart is empty</Message> :
                        <>
                        {cart.cartItems.map((item,index)=>(

                                <tr key={index}>
                                <td>
                                    <div className= "product-item"><Link className= "product-thumb" to={`/products/${item.product}`}><img src={item.image} alt={item.name} /></Link>
                                    <div className= "product-info">
                                        <h4 className= "product-title"><Link to={`/products/${item.product}`}>{item.name}<small>x {item.qty}</small></Link></h4>
                                        <span><em>Category:</em> {item.category}</span><span><em>{item.weight_name}:</em> {item.weight.split('-')[0]}</span>
                                    </div>
                                    </div>
                                </td>
                                <td className= "text-center text-lg">{item.currency === 'INR' ?
                                                                                    '₹': '$'}{item.price} x {item.qty} = {item.currency === 'INR' ?
                                                                                    '₹': '$'}{(item.price*item.qty).toFixed(2)}</td>
                                <td className= "text-center"><Link className= "btn btn-outline-primary btn-sm" to="/cart">Edit</Link></td>
                                </tr>

                        ))}
                        
                        
                        </>
                        }
                        
                        
                    </tbody>
                    </table>
                </div>
                <div className= "shopping-cart-footer">
                    <div className= "column"></div>
                    <div className= "column text-lg"><span className= "text-muted">Subtotal:&nbsp; </span><span className= "text-gray-dark">{cart.cartItems.length>0 && cart.currency === 'INR' ? '₹': '$'}{cart.totalPrice}</span></div>
                </div>
                
                <div className= "d-flex justify-content-between paddin-top-1x mt-4">
                    <Link className= "btn btn-outline-secondary" to="/shipping">
                        <i className= "icon-arrow-left"></i><span className= "hidden-xs-down">&nbsp;Back</span></Link>
                        {/* <Link className= "btn btn-primary" to="checkout-complete.html">Complete Order</Link> */}
                </div>
                </div>
                {/* <!-- Sidebar --> */}
                
                <div className= "col-xl-4 col-lg-4">
                <aside className= "sidebar">
                    <div className= "padding-top-2x hidden-lg-up"></div>
                    <div >
                    <section style={{padding:"20px 15px 0px 15px"}} className="widget widget-order-summary card">
                    <div>
                    <h3 className="widget-title"><strong>Shipping to:</strong></h3>
                    <ul style={{}} className= "list-unstyled">
                        <li><span className= "text-muted">Name:&nbsp; </span>{cart.shippingAddress.firstName} {cart.shippingAddress.lastName ? cart.shippingAddress.lastName : null}</li>
                        <li><span className= "text-muted">Address:&nbsp; </span>{cart.shippingAddress.address1}{cart.shippingAddress.address2 ? ', ' + cart.shippingAddress.address2 : null}, {cart.shippingAddress.city},{' '}
                                    {cart.shippingAddress.state}-{cart.shippingAddress.postalCode}, {cart.shippingAddress.country}</li>
                        <li><span className= "text-muted">Phone:&nbsp; </span>{cart.shippingAddress.phone}</li>
                    </ul>
                    </div>
                    {/* <div >
                    <h3 style={{marginBottom:'5px'}} className="widget-title">Payment Method:</h3>
                    <div className= "custom-control custom-radio">
                                <input className="custom-control-input" type="radio" 
                                        value="Razorpay"
                                        onChange={(e)=> setPaymentMethod(e.target.value)}
                                        id="rzpPayment" name='radioCard'  checked={paymentMethod !== 'Razorpay' ? false : true}/>
                                <label className="custom-control-label" htmlFor="rzpPayment">Card / UPI / Net Banking / Wallet </label>

                    </div>
                    <div className= "custom-control custom-radio">
                                <input className="custom-control-input" type="radio" 
                                        value="PayPal"
                                        onChange={(e)=> setPaymentMethod(e.target.value)}
                                        id="paypalPayment" name='radioCard'  checked={paymentMethod !== 'PayPal' ? false : true}/>
                                <label className="custom-control-label" htmlFor="paypalPayment">PayPal </label>

                    </div>
                   
                    </div>  */}
                    
                    </section>
                    
                    </div>

                    <div style={{marginTop:'0px'}}>
                    <section style={{padding:"20px 15px 0px 15px"}} className="widget widget-order-summary card">
                    <h3 className="widget-title"><strong>Order Summary</strong></h3>
                    <table className="table">
                        <tbody>
                        <tr>
                        <td>Cart Subtotal:</td>
                        <td className="text-gray-dark">{cart.currency === 'INR' ? '₹': '$'}{cart.itemsPrice}</td>
                        </tr>
                        <tr>
                        <td>Shipping:</td>
                        <td className="text-gray-dark">{cart.currency === 'INR' ? '₹': '$'}{cart.shippingPrice}</td>
                        </tr>
                        
                        <tr>
                        <td><strong>Total</strong></td>
                        <td className="text-lg text-gray-dark">{cart.currency === 'INR' ? '₹': '$'}{cart.totalPrice}</td>
                        </tr>
                        </tbody>
                    </table>
                    <hr/>
                    <div>
                        {error && <Message variant="danger">{error}</Message>}
                    </div>
                    <div className= "d-flex justify-content-between">
                    
                        <button className= "btn btn-primary btn-block" type="button"
                                disabled={cart.cartItems === 0}
                                onClick={placeOrder}>Place Order</button>
                    </div>
                    </section>
                    
                    </div>
                   
                    
                </aside>
                </div>
            </div>
            </div>
            </>)}
        </div>
        
    )
}

export default PlaceOrderScreen
