// import axios from 'axios'
import { CART_SAVE_SERVICE,
         CART_SAVE_APPOINTMENT_SCHEDULE,
         CART_SAVE_PATIENT_DETAILS,
         CART_SAVE_SERVICE_PAYMENT_METHOD,
} from '../constants/serviceCartConstants'


export const saveService = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_SERVICE,
        payload: data,
    })

    localStorage.setItem('appointmentService',JSON.stringify(data))

}

export const saveAppointmentSchedule = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_APPOINTMENT_SCHEDULE,
        payload: data,
    })

    localStorage.setItem('appointmentSchedule',JSON.stringify(data))

}

export const savePatientDetails = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_PATIENT_DETAILS,
        payload: data,
    })

    localStorage.setItem('patientDetails',JSON.stringify(data))

}

export const savePaymentMethod = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_SERVICE_PAYMENT_METHOD,
        payload: data,
    })

    localStorage.setItem('servicePaymentMethod',JSON.stringify(data))

}