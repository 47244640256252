import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { listAppointments,sendEmailAppointment, attainAppointment} from '../../actions/appointmentActions'


function AppointmentListScreen({history}) {

    const dispatch = useDispatch()

    const appointmentList = useSelector(state => state.appointmentList)
    const { loading , error, appointments } = appointmentList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin


    const statusHandler = (appointment,status) => {
        if(status==="Attained"){
            dispatch(attainAppointment(appointment))
            if (window.confirm('Send Email for Appointment Attained?')) {
                dispatch(sendEmailAppointment(appointment.slug,"Attained"))
            }
            dispatch(listAppointments())
        }        
    }

    useEffect(() => {
        if(userInfo && userInfo.isAdmin){
            dispatch(listAppointments())
        }else{
            history.push('/login')
        }
        
    },[dispatch,history,userInfo])

    
    return (
        <div>
            {loading ? <Loader/>
                : error ? <Message variant='danger'>{error}</Message>
                : (
                    <>
                    {/* <!-- Page Title--> */}
                    <div className="page-title">
                    <div className="container">
                        <div className="column">
                        <h1>Appointment</h1>
                        </div>
                        <div className="column">
                        <ul className="breadcrumbs">
                            <li><Link to="index.html">Home</Link> 
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>Appointment List</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    {/* <!-- Page Content--> */}
                    <div className="container padding-bottom-3x mb-2">
                    <div className="row" style={{height:"100vh",overflowY:"scroll"}}>
                       
                        <div className="col-lg-12">
                        <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                        <div className="table-responsive">
                        <table className="table table-striped">
                        <thead>
                            <tr>
                            <th>ID</th>
                            <th>USER</th>
                            <th>DATE</th>
                            <th>PATIENT</th>
                            <th>TIMESLOT</th>
                            <th>FEE</th>
                            <th>STATUS</th>                            
                            <th>DELIVERED</th>
                            <th>UPDATE</th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {appointments.map(appointment => (
                                <tr key={appointment._id}>
                                <th>{appointment.refId}</th>
                                <td>{appointment.user && appointment.user.name}</td>
                                <td>{appointment.createdAt.substring(0,10)}</td>
                                <td>{appointment.patientDetails && appointment.patientDetails.name}</td>
                                <td>{appointment.timeslot && appointment.timeslot.startTime+' - '+appointment.timeslot.endTime}</td>
                                
                                <td>{appointment.currency==="INR"?'₹' : '$'}{appointment.fee}</td>
                                <td  style={{ color: 'green',fontWeight:'bold' }}>{appointment.isPaid ? 
                                appointment.paidAt.substring(0,10) :
                                <i className='fas fa-check' style={{ color: 'red' }}></i>
                                }</td>
                                <td>{appointment.isAttained ? 
                                appointment.attainedAt.substring(0,10) :
                                <i className='fas fa-check' style={{ color: 'red' }}></i>
                                }</td>
                                <td>

                                        <select className="form-control" 
                                            as="select"
                                            value = {appointment.status}
                                            onChange = {(e) => {
                                                statusHandler(appointment,e.target.value)                                            }
                                            }
                                                id="product-mfg">
                                            
                                                <option value="select">
                                                    Select Status
                                                </option> 
                                                <option value="Attained">
                                                    Attained
                                                </option> 
                                                                                     

                                            </select>
                                        
                                    
                                </td>

                                <td><Link to ={`/appointment/${appointment.slug}`}>
                                    Details
                                    </Link>
                                </td>
                               
                                </tr>
                            ))}
                            
                            
                        </tbody>
                        </table>
                    </div>
                        <hr/>
                        </div>
                    </div>
                    </div>
                    
                </>
                )}
            
        </div>
    )
}

export default AppointmentListScreen
