import React from 'react'

function Loader({variant="grow"}) {
    return (
        <div style={{flex: 1, justifyContent:"center"}}>
        <center >
        {variant==="spinner-border" &&
        <div className="spinner-border spinner-border-sm text-gray-dark m-2"
                style={{width: "3rem", height: "3rem"}} role="status">
                    
                    <span className="sr-only">Loading...</span>
        </div>}
        {variant==="grow" &&
        <div className="spinner-grow spinner-grow-sm text-gray-dark m-2"
            style={{width: "3rem", height: "3rem"}} role="status">
            <span className="sr-only">Loading...</span>
        </div>}

        </center>
        
        </div>
    )
}

export default Loader
