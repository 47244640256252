import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Rating from "../components/Rating";
import Message from "../components/Message";
import Loader from "../components/Loader";
import axios from "axios";
// import RelatedProducts  from '../components/RelatedProducts'

import {
  listProductDetails,
  createProductReview,
} from "../actions/productActions";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";

import ProductCarousel from "../components/ProductCarousel";

import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

//   import {
//     FacebookIcon,LinkedinIcon,
//     PinterestIcon,TelegramIcon,
//     TwitterIcon,WhatsappIcon,
//   } from "react-share";

function ProductScreen({ match, history }) {
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const [weightId, setWeightId] = useState("1");

  const productDetails = useSelector((state) => state.productDetails);
  let { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    loading: loadingProductReview,
    error: errorProductReview,
    success: successProductReview,
  } = productReviewCreate;

  const currencyValue = useSelector((state) => state.currency);
  const { currency } = currencyValue;

  const dispatch = useDispatch();

  const [reviewOpen, SetReviewOpen] = useState(false);

  const productId = match.params.id;

  const [reviewMessage, setReviewMessage] = useState(false);

  useEffect(() => {
    // if(!product && error){
    //   // dispatch({type: PRODUCT_DETAILS_RESET})
    //   history.push('/')
    //   console.log('eror new:',error)
    // }
    if (successProductReview) {
      setRating(0);
      setComment("");
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
      setReviewMessage(true);
    }
    dispatch(listProductDetails(productId));
  }, [dispatch, match, successProductReview, productId,error]);

  const addToCartHandler = () => {
    history.push(
      `/cart/${productId}?weight=${weightId}?qty=${qty}?currency=${currency}`
    );
  };

  let imageList = [];
  if (product) {
    if (
      product.image_1 &&
      product.image_1.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':product.image_1});
    }
    if (
      product.image_2 &&
      product.image_2.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':product.image_2});
    }
    if (
      product.image_3 &&
      product.image_3.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':product.image_3});
    }
    if (
      product.image_4 &&
      product.image_4.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':product.image_4});
    }
    if (
      product.image_5 &&
      product.image_5.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':product.image_5});
    }
  }

  const [reviewImage1, setReviewImage1] = useState(null);
  const [reviewImage2, setReviewImage2] = useState(null);
  const [reviewImage3, setReviewImage3] = useState(null);
  const [reviewImage4, setReviewImage4] = useState(null);
  const [reviewImage5, setReviewImage5] = useState(null);

  const fileSelectedHandler1 = (e) => {
    if(e.target.files.length > 5){
        alert('You can upload a maximum of 5 images!')
        return
    }
    setReviewImage1(null);
    setReviewImage2(null);
    setReviewImage3(null);
    setReviewImage4(null);
    setReviewImage5(null);

    setReviewImage1(e.target.files[0]);
    setReviewImage2(e.target.files[1]);
    setReviewImage3(e.target.files[2]);
    setReviewImage4(e.target.files[3]);
    setReviewImage5(e.target.files[4]);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      createProductReview(productId, {
        rating,
        comment,
      })
    );
    SetReviewOpen(false);
  };

  

  useEffect(() => {
    if (successProductReview) {
      // if (reviewImage1 || reviewImage2 || reviewImage3 || reviewImage4 || reviewImage5) {
        const uploadReviewImages = async () => {

          const config = {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${userInfo ? userInfo.token : ''}`,
            },
          };
      
          const formData = new FormData();
          formData.append("image_1", reviewImage1);
          if(reviewImage2){
              formData.append("image_2", reviewImage2);
          }
          if(reviewImage3){
              formData.append("image_3", reviewImage3);
          }
          if(reviewImage4){
              formData.append("image_4", reviewImage4);
          }
          if(reviewImage5){
              formData.append("image_5", reviewImage5);
          }
          formData.append("product_slug", productId);
          
          try {
             await axios.post(
              "/api/products/uploadReviewImage/",
              formData,
              config
            );
            setReviewImage1(null);
            setReviewImage2(null);
            setReviewImage3(null);
            setReviewImage4(null);
            setReviewImage5(null);
          } catch (error) {
            console.log("uploading error!" + error);
          }
        };
      
        uploadReviewImages();
      // }

    }
  }, [
    successProductReview,
    reviewImage1,
    reviewImage2,
    reviewImage3,
    reviewImage4,
    reviewImage5,
    productId,
    userInfo,
  ]);

  const [lastReview, setLastReview] = useState(3);

  return (
    <>
       {loading ? (
        <>
          <Loader />
        </>
      ) : //
      error ? (
        <Message variant="danger">{error}</Message>
      ) : (<>
      <div className="page-title">
        <div className="container">
          <div className="column">
            <h1>{product.name}</h1>
          </div>
          <div className="column">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="separator">&nbsp;</li>
              <li>
                Shop
              </li>
              <li className="separator">&nbsp;</li>
              <li>{product.name}</li>
            </ul>
          </div>
        </div>
      </div>

   
        <div id="landingPage">
          {/* <!-- Page Content--> */}
          <div className="container padding-bottom-3x">
            <div className="row">
              {/* <!-- Poduct Gallery--> */}
              <div className="col-md-6">
                <div className="product-gallery">
                  <div className="gallery-wrapper"></div>
                  <div style={{ whiteSpace: "nowrap", overflowX: "auto" }}>
                    <div
                      style={{ marginTop: "20px" }}
                      className="product-badge bg-secondary border-default text-body"
                    >
                      {product.countInStock_1 > 0 ||
                      product.countInStock_2 > 0 ||
                      product.countInStock_3 > 0 ||
                      product.countInStock_4 > 0 ? (
                        <div style={{ color: "green", fontWeight: "bold" }}>
                          In stock
                        </div>
                      ) : (
                        <div style={{ color: "red", fontWeight: "bold" }}>
                          Out of Stock
                        </div>
                      )}
                    </div>
                    <div style={{ textAlign: "right", marginBottom: "10px" }}>
                      <Rating
                        value={product.rating}
                        text={`${product.numReviews} reviews`}
                        color={"#ffa000"}
                      />
                    </div>
                  </div>

                  <div>
                    <ProductCarousel
                      // infiniteLoop={false}
                      images={imageList}
                      showThumbs={false}
                      clickable={true}
                    />
                    <center>
                    <img width="400" src="/images/image_footer.png" alt="Product" />
                    </center>
                  </div>
                </div>
              </div>
              {/* <!-- Product Info--> */}
              <div className="col-md-6">
                <div className="padding-top-2x mt-2 hidden-md-up"></div>
                <div className="sp-categories pb-3">
                  <i className="icon-tag"></i>
                  <Link to="#">{product.category}</Link>
                </div>
                <h2 className="mb-3">{product.name}</h2>
                {weightId === "1" && currency === "INR" ? (
                  <span className="h3 d-block">
                    {" "}
                    ₹{product.rupee_price_1} ({product.weight_1})
                  </span>
                ) : weightId === "1" && currency === "USD" ? (
                  <span className="h3 d-block">
                    {" "}
                    ${product.dollar_price_1} ({product.weight_1})
                  </span>
                ) : weightId === "2" && currency === "INR" ? (
                  <span className="h3 d-block">
                    {" "}
                    ₹{product.rupee_price_2} ({product.weight_2})
                  </span>
                ) : weightId === "2" && currency === "USD" ? (
                  <span className="h3 d-block">
                    {" "}
                    ${product.dollar_price_2} ({product.weight_2})
                  </span>
                ) : weightId === "3" && currency === "INR" ? (
                  <span className="h3 d-block">
                    {" "}
                    ₹{product.rupee_price_3} ({product.weight_3})
                  </span>
                ) : weightId === "3" && currency === "USD" ? (
                  <span className="h3 d-block">
                    {" "}
                    ${product.dollar_price_3} ({product.weight_3})
                  </span>
                ) : weightId === "4" && currency === "INR" ? (
                  <span className="h3 d-block">
                    {" "}
                    ₹{product.rupee_price_4} ({product.weight_4})
                  </span>
                ) : weightId === "4" && currency === "USD" ? (
                  <span className="h3 d-block">
                    {" "}
                    ${product.dollar_price_4} ({product.weight_4})
                  </span>
                ) : null}
                {product.weight_1 && product.weight_2 ? (
                  <div style={{ marginLeft: "-15px" }} className="col-md-8">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{product.weight_name}</th>
                            <th>Price</th>
                            <th>Stock</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {product.weight_1}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {currency === "INR"
                                ? "₹" + product.rupee_price_1
                                : "$" + product.dollar_price_1}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {" "}
                              {product.countInStock_1 > 0 ? (
                                <div style={{ color: "green" }}>In stock</div>
                              ) : (
                                <div style={{ color: "red" }}>Out of Stock</div>
                              )}
                            </td>
                          </tr>
                          {product.weight_2 ? (
                            <tr>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {product.weight_2}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {currency === "INR"
                                  ? "₹" + product.rupee_price_2
                                  : "$" + product.dollar_price_2}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {" "}
                                {product.countInStock_2 > 0 ? (
                                  <div style={{ color: "green" }}>In stock</div>
                                ) : (
                                  <div style={{ color: "red" }}>
                                    Out of Stock
                                  </div>
                                )}
                              </td>
                            </tr>
                          ) : null}
                          {product.weight_3 ? (
                            <tr>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {product.weight_3}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {currency === "INR"
                                  ? "₹" + product.rupee_price_3
                                  : "$" + product.dollar_price_3}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {" "}
                                {product.countInStock_3 > 0 ? (
                                  <div style={{ color: "green" }}>In stock</div>
                                ) : (
                                  <div style={{ color: "red" }}>
                                    Out of Stock
                                  </div>
                                )}
                              </td>
                            </tr>
                          ) : null}

                          {product.weight_4 ? (
                            <tr>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {product.weight_4}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {currency === "INR"
                                  ? "₹" + product.rupee_price_4
                                  : "$" + product.dollar_price_4}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {" "}
                                {product.countInStock_4 > 0 ? (
                                  <div style={{ color: "green" }}>In stock</div>
                                ) : (
                                  <div style={{ color: "red" }}>
                                    Out of Stock
                                  </div>
                                )}
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}
                <div className="row margin-top-1x">
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label style={{ paddingLeft: "0px" }} htmlFor="size">
                        Choose {product.weight_name}{" "}
                      </label>
                      <select
                        className="form-control"
                        as="select"
                        value={weightId}
                        onChange={(e) => {
                          setWeightId(e.target.value);
                        }}
                        id="weightId"
                      >
                        {product.weight_1 ? (
                          <option value="1">{product.weight_1}</option>
                        ) : null}

                        {product.weight_2 ? (
                          <option value="2">{product.weight_2}</option>
                        ) : null}

                        {product.weight_3 ? (
                          <option value="3">{product.weight_3}</option>
                        ) : null}

                        {product.weight_4 ? (
                          <option value="4">{product.weight_4}</option>
                        ) : null}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="form-group mb-0">
                      <label style={{ paddingLeft: "0px" }} htmlFor="quantity">
                        Quantity
                      </label>

                      <select
                        className="form-control"
                        as="select"
                        value={qty}
                        onChange={(e) => setQty(e.target.value)}
                        id="quantity"
                      >
                        {weightId === "1" && product.countInStock_1 > 0
                          ? [...Array(product.countInStock_1).keys()].map(
                              (x) =>
                                x <= 9 && (
                                  <option key={x + 1} value={x + 1}>
                                    {x + 1}
                                  </option>
                                )
                            )
                          : // : weightId === "1" && product.countInStock_1===0 && product.countInStock_2>0 ?

                          // [...Array(product.countInStock_2).keys()].map((x) => (

                          //     <option key={x + 1} value={x + 1}>
                          //         {x + 1}
                          //     </option>

                          // ))
                          weightId === "2" && product.countInStock_2 > 0
                          ? [...Array(product.countInStock_2).keys()].map(
                              (x) =>
                                x <= 9 && (
                                  <option key={x + 1} value={x + 1}>
                                    {x + 1}
                                  </option>
                                )
                            )
                          : weightId === "3" && product.countInStock_3 > 0
                          ? [...Array(product.countInStock_3).keys()].map(
                              (x) =>
                                x <= 9 && (
                                  <option key={x + 1} value={x + 1}>
                                    {x + 1}
                                  </option>
                                )
                            )
                          : weightId === "4" && product.countInStock_4 > 0
                          ? [...Array(product.countInStock_4).keys()].map(
                              (x) =>
                                x <= 9 && (
                                  <option key={x + 1} value={x + 1}>
                                    {x + 1}
                                  </option>
                                )
                            )
                          : null}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row align-items-end pb-4">
                  <div className="col-sm-8">
                    <div className="pt-4 hidden-sm-up"></div>
                    <button
                      className="btn btn-primary btn-block m-0"
                      onClick={addToCartHandler}
                      data-toast
                      data-toast-type="success"
                      data-toast-position="topRight"
                      data-toast-icon="icon-check-circle"
                      data-toast-title="Product"
                      disabled={
                        (weightId === "1" && product.countInStock_1 === 0) ||
                        (weightId === "2" && product.countInStock_2 === 0) ||
                        (weightId === "3" && product.countInStock_3 === 0) ||
                        (weightId === "4" && product.countInStock_4 === 0)
                      }
                      data-toast-message="successfuly added to cart!"
                    >
                      <i className="icon-bag"></i> Add to Cart
                    </button>
                  </div>
                </div>
                {/* <div className="pt-1 mb-4"><span className="text-medium">SKU:</span> #21457832</div> */}
                <hr className="mb-2" />

                <div className="d-flex flex-wrap justify-content-between">
                  <div className="mt-2 mb-2">
                    {/* <button className="btn btn-outline-secondary btn-sm btn-wishlist"><i className="icon-heart"></i>&nbsp;To Wishlist</button> */}
                  </div>
                  <div className="mt-2 mb-2">
                    <span className="text-muted">Share:&nbsp;&nbsp;</span>
                    <div className="d-inline-block">
                      <WhatsappShareButton
                        url={window.location.href}
                        title={`Check this out: ${product.name} ${product.weight_1}`}
                      >
                        <Link
                          className="social-button shape-rounded sb-instagram"
                          to="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Instagram"
                        >
                          <i className="socicon-whatsapp"></i>
                        </Link>
                      </WhatsappShareButton>

                      <FacebookShareButton
                        url={window.location.href}
                        quote={"Check this out: " + product.name}
                      >
                        <Link
                          className="social-button shape-rounded sb-facebook"
                          to="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Facebook"
                        >
                          <i className="socicon-facebook"></i>
                        </Link>
                      </FacebookShareButton>

                      <TwitterShareButton
                        url={window.location.href}
                        title={`Check this out: ${product.name}`}
                      >
                        <Link
                          className="social-button shape-rounded sb-twitter"
                          to="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Twitter"
                        >
                          <i className="socicon-twitter"></i>
                        </Link>
                      </TwitterShareButton>

                      <PinterestShareButton
                        media={product.image}
                        description={"Check this out: " + product.name}
                      >
                        <Link
                          className="social-button shape-rounded sb-google-plus"
                          to="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Google +"
                        >
                          <i className="socicon-pinterest"></i>
                        </Link>
                      </PinterestShareButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Product Details--> */}
          <div
            className="bg-secondary padding-top-3x padding-bottom-2x mb-3"
            id="details"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="h4">Description</h3>
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      whiteSpace: "pre-wrap",
                    }}
                    className="mb-4"
                  >
                    {product.description}
                  </p>
                  {/* <h3 className="h4">Features</h3>
                            <ul className="list-icon mb-4">
                            <li><i className="icon-check text-success"></i>Capture 4K30 Video and 12MP Photos</li>
                            <li><i className="icon-check text-success"></i>Game-Style Controller with Touchscreen</li>
                            <li><i className="icon-check text-success"></i>View Live Camera Feed</li>
                            <li><i className="icon-check text-success"></i>Full Control of HERO6 Black</li>
                            <li><i className="icon-check text-success"></i>Use App for Dedicated Camera Operation</li>
                            </ul> */}
                </div>
                {/* <div className="col-md-6">
                            <h3 className="h4">Variants</h3>
                            <ul className="list-unstyled mb-4">
                            <li><strong>Weight:</strong> 35.5oz (1006g)</li>
                            <li><strong>Maximum Speed:</strong> 	35 mph (15 m/s)</li>
                            <li><strong>Maximum Distance:</strong> 	Up to 9,840ft (3,000m)</li>
                            <li><strong>Operating Frequency:</strong> 2.4GHz</li>
                            <li><strong>Manufacturer:</strong> GoPro, USA</li>
                            </ul>
                            <h3 className="h4">Shipping Options:</h3>
                            <ul className="list-unstyled mb-4">
                            <li><strong>Courier:</strong> 2 - 4 days, $22.50</li>
                            <li><strong>Local Shipping:</strong> up to one week, $10.00</li>
                            <li><strong>UPS Ground Shipping:</strong> 4 - 6 days, $18.00</li>
                            <li><strong>Unishop Global Export:</strong> 3 - 4 days, $25.00</li>
                            </ul>
                        </div> */}
              </div>
            </div>
          </div>
          {/* <!-- Reviews--> */}
          <div className="container padding-top-2x">
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="card border-default">
                  <div className="card-body">
                    <div className="text-center">
                      <div className="d-inline align-baseline display-3 mr-1">
                        {product.rating}
                      </div>
                      <div className="d-inline align-baseline text-sm text-warning mr-1">
                        <div className="rating-stars">
                          <Rating value={product.rating} color={"#ffa000"} />{" "}
                          <span style={{ color: "gray" }}>
                            {product.numReviews === 1
                              ? product.numReviews + " Review"
                              : product.numReviews + " Reviews"}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="pt-3">
                      <label className="text-medium text-sm">
                        5 stars{" "}
                        <span className="text-muted">
                          -{" "}
                          {product.reviews.reduce(
                            (acc, review) =>
                              review.rating === 5 ? acc + 1 : acc + 0,
                            0
                          )}
                        </span>
                      </label>
                      <div className="progress margin-bottom-1x">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width:
                              (product.reviews.reduce(
                                (acc, review) =>
                                  review.rating === 5 ? acc + 1 : acc + 0,
                                0
                              ) /
                                product.numReviews) *
                                100 +
                              "%",
                            height: "2px",
                          }}
                          aria-valuenow={
                            (product.reviews.reduce(
                              (acc, review) =>
                                review.rating === 5 ? acc + 1 : acc + 0,
                              0
                            ) /
                              product.numReviews) *
                            100
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <label className="text-medium text-sm">
                        4 stars{" "}
                        <span className="text-muted">
                          -{" "}
                          {product.reviews.reduce(
                            (acc, review) =>
                              review.rating === 4 ? acc + 1 : acc + 0,
                            0
                          )}
                        </span>
                      </label>
                      <div className="progress margin-bottom-1x">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width:
                              (product.reviews.reduce(
                                (acc, review) =>
                                  review.rating === 4 ? acc + 1 : acc + 0,
                                0
                              ) /
                                product.numReviews) *
                                100 +
                              "%",
                            height: "2px",
                          }}
                          aria-valuenow={
                            (product.reviews.reduce(
                              (acc, review) =>
                                review.rating === 4 ? acc + 1 : acc + 0,
                              0
                            ) /
                              product.numReviews) *
                            100
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <label className="text-medium text-sm">
                        3 stars{" "}
                        <span className="text-muted">
                          -{" "}
                          {product.reviews.reduce(
                            (acc, review) =>
                              review.rating === 3 ? acc + 1 : acc + 0,
                            0
                          )}
                        </span>
                      </label>
                      <div className="progress margin-bottom-1x">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width:
                              (product.reviews.reduce(
                                (acc, review) =>
                                  review.rating === 3 ? acc + 1 : acc + 0,
                                0
                              ) /
                                product.numReviews) *
                                100 +
                              "%",
                            height: "2px",
                          }}
                          aria-valuenow={
                            (product.reviews.reduce(
                              (acc, review) =>
                                review.rating === 3 ? acc + 1 : acc + 0,
                              0
                            ) /
                              product.numReviews) *
                            100
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <label className="text-medium text-sm">
                        2 stars{" "}
                        <span className="text-muted">
                          -{" "}
                          {product.reviews.reduce(
                            (acc, review) =>
                              review.rating === 2 ? acc + 1 : acc + 0,
                            0
                          )}
                        </span>
                      </label>
                      <div className="progress margin-bottom-1x">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width:
                              (product.reviews.reduce(
                                (acc, review) =>
                                  review.rating === 2 ? acc + 1 : acc + 0,
                                0
                              ) /
                                product.numReviews) *
                                100 +
                              "%",
                            height: "2px",
                          }}
                          aria-valuenow={
                            (product.reviews.reduce(
                              (acc, review) =>
                                review.rating === 2 ? acc + 1 : acc + 0,
                              0
                            ) /
                              product.numReviews) *
                            100
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <label className="text-medium text-sm">
                        1 star{" "}
                        <span className="text-muted">
                          -{" "}
                          {product.reviews.reduce(
                            (acc, review) =>
                              review.rating === 1 ? acc + 1 : acc + 0,
                            0
                          )}
                        </span>
                      </label>
                      <div className="progress margin-bottom-1x">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width:
                              (product.reviews.reduce(
                                (acc, review) =>
                                  review.rating === 1 ? acc + 1 : acc + 0,
                                0
                              ) /
                                product.numReviews) *
                                100 +
                              "%",
                            height: "2px",
                          }}
                          aria-valuenow={
                            (product.reviews.reduce(
                              (acc, review) =>
                                review.rating === 1 ? acc + 1 : acc + 0,
                              0
                            ) /
                              product.numReviews) *
                            100
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    {/* {loadingProductReview && <Loader/>} */}
                    {successProductReview && (
                      <Message variant="success">Review Submitted.</Message>
                    )}
                    {errorProductReview && (
                      <Message variant="danger">{errorProductReview}</Message>
                    )}

                    {userInfo ? (
                      <div className="pt-2">
                        {reviewOpen && (
                          <form
                            className="input-group form-group"
                            method="post"
                            id="leaveReview"
                            onSubmit={submitHandler}
                          >
                            <center>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <label
                                      style={{ paddingLeft: "0" }}
                                      htmlFor="review-rating"
                                    >
                                      Rating
                                    </label>
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        setRating(e.target.value)
                                      }
                                      id="review-rating"
                                      required
                                    >
                                      <option value="">Choose Rating</option>
                                      <option value="5">5 Stars</option>
                                      <option value="4">4 Stars</option>
                                      <option value="3">3 Stars</option>
                                      <option value="2">2 Stars</option>
                                      <option value="1">1 Star</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  style={{
                                    paddingLeft: "0",
                                    textAlign: "left",
                                  }}
                                  htmlFor="review-message"
                                >
                                  Review
                                </label>
                                <textarea
                                  className="form-control"
                                  value={comment}
                                  onChange={(e) => setComment(e.target.value)}
                                  id="review-message"
                                  placeholder="Write your review."
                                  rows="4"
                                  required
                                ></textarea>
                              </div>

                              <div className="form-group">
                                <label
                                  style={{
                                    paddingLeft: "0",
                                    textAlign: "left",
                                  }}
                                  htmlFor="review-image"
                                >
                                  Images (Optional)
                                </label>
                                <div className="custom-file">
                                  <input
                                    className="custom-file-input"
                                    type="file"
                                    id="review-image1"
                                    onChange={fileSelectedHandler1}
                                    multiple
                                  />
                                  <label
                                    className="custom-file-label"
                                    style={{ textAlign: "left" }}
                                    htmlFor="review-image1"
                                  >
                                    {reviewImage1
                                      ? reviewImage1.name
                                      : "Choose file..."}
                                  </label>
                                </div>
                              </div>

                              <button
                                className="btn btn-info btn-block"
                                disabled={loadingProductReview}
                                // data-dismiss={closeModal==="modal" || errorProductReview ? "modal" : ''}
                                type="submit"
                              >
                                Submit Review
                              </button>
                            </center>
                          </form>
                        )}
                        {reviewMessage && (
                          <Message variant="success">
                            Review submitted successfully!
                          </Message>
                        )}
                        {!reviewOpen && (
                          <button
                            className="btn btn-warning btn-block"
                            onClick={() => SetReviewOpen(true)}
                            data-toggle="modal"
                            // data-target="#leaveReview"
                          >
                            {product.reviews.reduce(
                              (acc, review) =>
                                acc + Number(review.user === userInfo._id),
                              0
                            ) > 0
                              ? "Edit Review"
                              : "Leave a Review"}
                          </button>
                        )}
                      </div>
                    ) : (
                      <Message variant="info">
                        Please{" "}
                        <Link to={`/login?redirect=/${product.slug}`}>
                          Login
                        </Link>{" "}
                        to Leave a Review
                      </Message>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <h3>Latest Reviews</h3>

                {/* <!-- Review--> */}

                {product.reviews.slice(0, lastReview).map((review) => (
                  <div
                    style={{ paddingLeft: "10px" }}
                    className="comment"
                    key={review._id}
                  >
                    {/* <div className="comment-author-ava"><img src="/images/img/reviews/02.jpg" alt="Comment author" /></div> */}
                    <div className="comment-body">
                      <div className="comment-header d-flex flex-wrap justify-content-between">
                        <h4 style={{ float: "left" }} className="comment-title">
                          <strong>{review.name}</strong>{" "}
                          <span style={{ fontSize: "10px" }}>
                            {" "}
                            {review.createdAt.substring(0, 10)}
                          </span>
                        </h4>

                        <div className="mb-2">
                          <Rating
                            value={review.rating}
                            color={"#ffa000"}
                            size="sm"
                          />
                          {/* <div className="rating-stars"><i className="icon-star filled"></i><i className="icon-star filled"></i><i className="icon-star filled"></i><i className="icon-star filled"></i><i className="icon-star"></i>
                                    </div> */}
                        </div>
                      </div>
                      <p className="comment-text">{review.comment}</p>

                      <Gallery>
                        {review.image_1 &&
                          <Item
                            original={review.image_1}
                            thumbnail={review.image_1}
                            width="1024"
                            height="768"
                          >
                            {({ ref, open }) => (
                              <img
                                ref={ref}
                                style={{cursor: "pointer"}}
                                onClick={open}
                                width="120px"
                                src={review.image_1}
                                alt={review.image_1}
                              />
                            )}
                          </Item>
                        }
                        {review.image_2 &&
                          <Item
                            original={review.image_2}
                            thumbnail={review.image_2}
                            width="1024"
                            height="768"
                          >
                            {({ ref, open }) => (
                              <img
                                ref={ref}
                                onClick={open}
                                style={{cursor: "pointer"}}
                                width="120px"
                                src={review.image_2}
                                alt={review.image_2}
                              />
                            )}
                          </Item>
                        }

                        {review.image_3 &&
                          <Item
                            original={review.image_3}
                            thumbnail={review.image_3}
                            width="1024"
                            height="768"
                          >
                            {({ ref, open }) => (
                              <img
                                ref={ref}
                                onClick={open}
                                style={{cursor: "pointer"}}
                                width="120px"
                                src={review.image_3}
                                alt={review.image_3}
                              />
                            )}
                          </Item>
                        }

                        {review.image_4 &&
                          <Item
                            original={review.image_4}
                            thumbnail={review.image_4}
                            width="1024"
                            height="768"
                          >
                            {({ ref, open }) => (
                              <img
                                ref={ref}
                                onClick={open}
                                style={{cursor: "pointer"}}
                                width="120px"
                                src={review.image_4}
                                alt={review.image_4}
                              />
                            )}
                          </Item>
                        }

                        {review.image_5 &&
                          <Item
                            original={review.image_5}
                            thumbnail={review.image_5}
                            width="1024"
                            height="768"
                          >
                            {({ ref, open }) => (
                              <img
                                ref={ref}
                                onClick={open}
                                style={{cursor: "pointer"}}
                                width="120px"
                                src={review.image_5}
                                alt={review.image_5}
                              />
                            )}
                          </Item>
                        }
                      </Gallery>

                      {/* {review.image_1 && <Link to={review.image_1}><img src={review.image_1} width="150px" alt="review_image1" /></Link>} */}
                    </div>
                  </div>
                ))}

                {/* <!-- View All Button--> */}
                {product.reviews.length > 3 &&
                  (lastReview === 3 ? (
                    <button
                      className="btn btn-secondary btn-block"
                      onClick={() => setLastReview(product.reviews.length)}
                      type="button"
                    >
                      View All Reviews
                    </button>
                  ) : (
                    <button
                      className="btn btn-secondary btn-block"
                      onClick={() => setLastReview(3)}
                      type="button"
                    >
                      View Less
                    </button>
                  ))}
              </div>
            </div>
          </div>
        
        
        </div>
      </>)}

      <div className="container padding-bottom-3x mb-1">
        {/* <!-- Related Products Carousel--> */}
        {/* <RelatedProducts /> */}
      </div>
    </>
  );
}

export default ProductScreen;
