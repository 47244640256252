import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { FREIGHT_CHARGE_CREATE_RESET,FREIGHT_CHARGE_UPDATE_RESET } from '../constants/orderConstants'
import { getFreightCharges,createFreightCharge,updateFreightCharge,deleteFreightCharge } from '../actions/orderActions'


function FreightChargesScreen({history}) {

    const dispatch = useDispatch()

    const freightCharges = useSelector(state => state.freightCharges)
    const { loading , error, charges } = freightCharges

    const freightChargeCreate = useSelector(state => state.freightChargeCreate)
    const {error:errorCreate, loading:loadingCreate, success:successCreate, charge:createdCharge } = freightChargeCreate

    const freightChargeUpdate = useSelector(state => state.freightChargeUpdate)
    const {error:errorUpdate, loading:loadingUpdate, success:successUpdate } = freightChargeUpdate

    const freightChargeDelete = useSelector(state => state.freightChargeDelete)
    const {error:errorDelete, loading:loadingDelete } = freightChargeDelete

    const [sourceToDestination, setSourceToDestination] = useState('')

    const [zero_half_Kg, setZero_half_Kg] = useState(0)
    const [half_one_Kg, setHalf_one_Kg] = useState(0)

    const [one_oneAndHalf_Kg, setOne_oneAndHalf_Kg] = useState(0)
    const [oneAndHalf_two_Kg, setOneAndHalf_two_Kg] = useState(0)

    const [two_twoAndHalf_Kg, setTwo_twoAndHalf_Kg] = useState(0)
    const [twoAndHalf_three_Kg, setTwoAndHalf_three_Kg] = useState(0)

    const [three_threeAndHalf_Kg, setThree_threeAndHalf_Kg] = useState(0)
    const [threeAndHalf_four_Kg, setThreeAndHalf_four_Kg] = useState(0)

    const [four_fourAndHalf_Kg, setFour_fourAndHalf_Kg] = useState(0)
    const [fourAndHalf_five_Kg, setFourAndHalf_five_Kg] = useState(0)

    const [five_fiveAndHalf_Kg, setFive_fiveAndHalf_Kg] = useState(0)
    const [fiveAndHalf_six_Kg, setFiveAndHalf_six_Kg] = useState(0)

    const [six_sixAndHalf_Kg, setSix_sixAndHalf_Kg] = useState(0)
    const [sixAndHalf_seven_Kg, setSixAndHalf_seven_Kg] = useState(0)

    const [chargeId,SetChargeId] = useState('')
    const [newCreate, SetNewCreate] = useState(false)
    // const [cancelCreate, SetCancelCreate] = useState(false)


    useEffect(() => {  
         
        if(successCreate){
            SetChargeId(createdCharge._id)
            setSourceToDestination(createdCharge.sourceToDestination)

            dispatch({ type: FREIGHT_CHARGE_CREATE_RESET })
        }
        
        else if(successUpdate){
            dispatch({type:FREIGHT_CHARGE_UPDATE_RESET})            
            SetNewCreate(false)
            // SetChargeId('')
            dispatch(getFreightCharges())
       
        } else {

            if(!charges ){
                dispatch(getFreightCharges())
            }

        }
    },[history,dispatch,charges,createdCharge,successCreate,successUpdate])

    const createFreightChargeHandler = () =>{
        SetNewCreate(true)
        dispatch(createFreightCharge())
    } 

    const updateClickHandler = (id,sourceToDestination,zero_half_Kg,half_one_Kg,one_oneAndHalf_Kg,
        oneAndHalf_two_Kg,two_twoAndHalf_Kg,twoAndHalf_three_Kg,three_threeAndHalf_Kg,
        threeAndHalf_four_Kg,four_fourAndHalf_Kg,fourAndHalf_five_Kg,five_fiveAndHalf_Kg,
        fiveAndHalf_six_Kg,six_sixAndHalf_Kg,sixAndHalf_seven_Kg) =>{
        SetNewCreate(true)
        SetChargeId(id)  
        
        setSourceToDestination(sourceToDestination)

        setZero_half_Kg(Number(zero_half_Kg))
        setHalf_one_Kg( Number(half_one_Kg))

        setOne_oneAndHalf_Kg( Number(one_oneAndHalf_Kg))
        setOneAndHalf_two_Kg( Number(oneAndHalf_two_Kg))

        setTwo_twoAndHalf_Kg( Number(two_twoAndHalf_Kg))
        setTwoAndHalf_three_Kg( Number(twoAndHalf_three_Kg))

        setThree_threeAndHalf_Kg( Number(three_threeAndHalf_Kg))
        setThreeAndHalf_four_Kg( Number(threeAndHalf_four_Kg))

        setFour_fourAndHalf_Kg( Number(four_fourAndHalf_Kg))
        setFourAndHalf_five_Kg( Number(fourAndHalf_five_Kg))

        setFive_fiveAndHalf_Kg( Number(five_fiveAndHalf_Kg))
        setFiveAndHalf_six_Kg( Number(fiveAndHalf_six_Kg))

        setSix_sixAndHalf_Kg( Number(six_sixAndHalf_Kg))
        setSixAndHalf_seven_Kg( Number(sixAndHalf_seven_Kg))
    } 

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateFreightCharge({
            _id:chargeId,sourceToDestination,zero_half_Kg,half_one_Kg,one_oneAndHalf_Kg,
            oneAndHalf_two_Kg,two_twoAndHalf_Kg,twoAndHalf_three_Kg,three_threeAndHalf_Kg,
            threeAndHalf_four_Kg,four_fourAndHalf_Kg,fourAndHalf_five_Kg,five_fiveAndHalf_Kg,
            fiveAndHalf_six_Kg,six_sixAndHalf_Kg,sixAndHalf_seven_Kg

        }))    
        dispatch({type:FREIGHT_CHARGE_UPDATE_RESET})
    }

    const deleteHandler = (id)=> {
        if (window.confirm('Are you sure you want to delete this freight charge entry?')) {
            dispatch(deleteFreightCharge(id))
            // window.location.reload();
            dispatch(getFreightCharges())
        }
        
    }

    const CancelHandler = ()=>{         
        SetNewCreate(false)
        // SetCancelCreate(false)
        dispatch(getFreightCharges())        
        dispatch({ type: FREIGHT_CHARGE_CREATE_RESET })
        dispatch({type:FREIGHT_CHARGE_UPDATE_RESET})
    }

    return (
        <div>
            {loading ? <Loader/>
                : error ? <Message variant='danger'>{error}</Message>
                : (
                    <>
                    {/* <!-- Page Title--> */}
                    <div className="page-title">
                    <div className="container">
                        <div className="column">
                        <h1>Freight Charges</h1>
                        </div>
                        <div className="column">
                        <ul className="breadcrumbs">
                            <li><Link to="index.html">Home</Link> 
                            </li>                           
                            <li className="separator">&nbsp;</li>
                            <li>Freight Charges</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    {/* <!-- Page Content--> */}
                    <div className="container padding-bottom-3x mb-2">

                    {/* <div><Link to='/admin/productlist'><h6 className="margin-bottom-1x">Go Back</h6></Link></div> */}

                    <div className="row" >
                    <center>{loadingDelete && <Loader/>}</center>
                    {errorDelete && <Message variant="danger">{errorDelete}</Message>}

                    <center>{loadingCreate && <Loader/>}</center>
                    {errorCreate && <Message variant="danger">{errorCreate}</Message>}
                    <div className="col-12">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div >
                            <h5>Freight Charges</h5>
                            </div>
                            <button className="btn btn-secondary margin-right-none" 
                                    type="button"
                                    onClick={createFreightChargeHandler}
                                    >
                                    <i className="fas fa-plus"></i> Add New
                            </button>
                        </div>
                        </div>
                        
                        {newCreate && 
                        <div className="col-md-12">
                            <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                            
                            <form className="card" onSubmit={submitHandler}>
                                
                                <div className="col-md-12">
                                    <br />
                                    <h4 className="margin-bottom-1x">Edit Charges</h4>
                                    {loadingUpdate ? <Loader/> : errorUpdate ? <center><Message variant='danger'>{errorUpdate}</Message></center> : 
                                    <>
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="source-to-dest">Source/Destination</label>
                                            <input className="form-control" 
                                                    type="text" 
                                                    id="source-to-dest" 
                                                    value={sourceToDestination || ""} 
                                                    onChange={(e)=>setSourceToDestination(e.target.value)}
                                                    />
                                        </div>

                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="zero_half_Kg">0-500gm</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="zero_half_Kg" 
                                                    value={zero_half_Kg} 
                                                    onChange={(e)=>setZero_half_Kg(Number(e.target.value))}
                                                    />
                                        </div>
                                        
                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="half_one_Kg">500gm-1Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="half_one_Kg" 
                                                    value={half_one_Kg} 
                                                    onChange={(e)=>setHalf_one_Kg(Number(e.target.value))}
                                                    />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="one_oneAndHalf_Kg">1Kg-1.5Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="one_oneAndHalf_Kg" 
                                                    value={one_oneAndHalf_Kg} 
                                                    onChange={(e)=>setOne_oneAndHalf_Kg( Number(e.target.value))}
                                                    />
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="oneAndHalf_two_Kg">1.5Kg-2Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="oneAndHalf_two_Kg" 
                                                    value={oneAndHalf_two_Kg} 
                                                    onChange={(e)=>setOneAndHalf_two_Kg( Number(e.target.value))}
                                                    />
                                        </div>
                                    </div>
                                    <div className="row">
                                        
                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="two_twoAndHalf_Kg">2Kg-2.5Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="two_twoAndHalf_Kg" 
                                                    value={two_twoAndHalf_Kg} 
                                                    onChange={(e)=>setTwo_twoAndHalf_Kg( Number(e.target.value))}
                                                    />
                                        </div>
                                        
                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="twoAndHalf_three_Kg">2.5Kg-3Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="twoAndHalf_three_Kg" 
                                                    value={twoAndHalf_three_Kg} 
                                                    onChange={(e)=>setTwoAndHalf_three_Kg( Number(e.target.value))}
                                                    />
                                        </div>

                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="three_threeAndHalf_Kg">3Kg-3.5Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="three_threeAndHalf_Kg" 
                                                    value={three_threeAndHalf_Kg} 
                                                    onChange={(e)=>setThree_threeAndHalf_Kg(Number(e.target.value))}
                                                    />
                                        </div>

                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="threeAndHalf_four_Kg">3.5Kg-4Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="threeAndHalf_four_Kg" 
                                                    value={threeAndHalf_four_Kg} 
                                                    onChange={(e)=>setThreeAndHalf_four_Kg(Number(e.target.value))}
                                                    />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="four_fourAndHalf_Kg">4Kg-4.5Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="four_fourAndHalf_Kg" 
                                                    value={four_fourAndHalf_Kg} 
                                                    onChange={(e)=>setFour_fourAndHalf_Kg(Number(e.target.value))}
                                                    />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="fourAndHalf_five_Kg">4.5Kg-5Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="fourAndHalf_five_Kg" 
                                                    value={fourAndHalf_five_Kg} 
                                                    onChange={(e)=>setFourAndHalf_five_Kg(Number(e.target.value))}
                                                    />
                                        </div>
                                        
                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="five_fiveAndHalf_Kg">5Kg-5.5Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="five_fiveAndHalf_Kg" 
                                                    value={five_fiveAndHalf_Kg} 
                                                    onChange={(e)=>setFive_fiveAndHalf_Kg(Number(e.target.value))}
                                                    />
                                        </div>

                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="fiveAndHalf_six_Kg">5.5Kg-6Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="fiveAndHalf_six_Kg" 
                                                    value={fiveAndHalf_six_Kg} 
                                                    onChange={(e)=>setFiveAndHalf_six_Kg(Number(e.target.value))}
                                                    />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="six_sixAndHalf_Kg">6Kg-6.5Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="six_sixAndHalf_Kg" 
                                                    value={six_sixAndHalf_Kg} 
                                                    onChange={(e)=>setSix_sixAndHalf_Kg(Number(e.target.value))}
                                                    />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label style={{paddingLeft:'0px'}} htmlFor="sixAndHalf_seven_Kg">6.5Kg-7Kg</label>
                                            <input className="form-control" 
                                                    type="number" 
                                                    id="sixAndHalf_seven_Kg" 
                                                    value={sixAndHalf_seven_Kg} 
                                                    onChange={(e)=>setSixAndHalf_seven_Kg(Number(e.target.value))}
                                                    />
                                        </div>
                                        
                                        <div className="form-group col-md-4">

                                            <button style={{marginTop:"28px"}} className="btn btn-primary margin-right-none" 
                                                    type="submit" 
                                                   >
                                                        Update 
                                            </button>
                                            <button style={{marginTop:"28px"}} className="btn btn-primary btn-danger margin-right-none" 
                                                    type="button" 
                                                onClick={CancelHandler}
                                                >
                                                        Cancel 
                                            </button>
                                        </div>
                                        
                                        

                                        
                                    </div>
                                   
                                    
                                    </>
                                    }
                                    <br />
                                </div>
                            </form>
                            
                        
                        </div>}


                        
                    </div>



                    <div className="row" >
                       
                        <div className="col-lg-12">
                        <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                        <div className="table-responsive">
                        <table className="table table-striped">
                        <thead>
                            <tr>
                            {/* <th>ID</th> */}
                            <th>Source/Destination</th>
                            <th>0-500gm</th>
                            <th>500gm-1Kg</th>
                            <th>1Kg-1.5Kg</th>
                            <th>1.5Kg-2Kg</th>
                            <th>2Kg-2.5Kg</th>
                            <th>2.5Kg-3Kg</th>
                            <th>3Kg-3.5Kg</th>                            
                            <th>3.5Kg-4Kg</th>                            
                            <th>4Kg-4.5Kg</th>                            
                            <th>4.5Kg-5Kg</th>                           
                            <th>5Kg-5.5Kg</th>                            
                            <th>5.5Kg-6Kg</th>                            
                            <th>6Kg-6.5Kg</th>                            
                            <th>6.5Kg-7Kg</th>                           
                            <th></th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {charges.map((charge) => (

                                <tr key={charge._id}>

                                <th>{charge.sourceToDestination}</th>

                                <td>{charge.zero_half_Kg}</td>
                                <td >{charge.half_one_Kg }</td>

                                <td>{charge.one_oneAndHalf_Kg}</td>
                                <td >{charge.oneAndHalf_two_Kg}</td>

                                <td >{charge.two_twoAndHalf_Kg}</td>
                                <td>{charge.twoAndHalf_three_Kg}</td>

                                <td>{charge.three_threeAndHalf_Kg}</td>
                                <td>{charge.threeAndHalf_four_Kg}</td>

                                <td>{charge.four_fourAndHalf_Kg}</td>
                                <td>{charge.fourAndHalf_five_Kg}</td> 

                                <td>{charge.five_fiveAndHalf_Kg}</td>
                                <td>{charge.fiveAndHalf_six_Kg}</td>

                                <td>{charge.six_sixAndHalf_Kg}</td>
                                <td>{charge.sixAndHalf_seven_Kg}</td>                    

                                <td><Link to="#" onClick={() => updateClickHandler(charge._id,
                                        charge.sourceToDestination,
                                        charge.zero_half_Kg,
                                        charge.half_one_Kg,

                                        charge.one_oneAndHalf_Kg,
                                        charge.oneAndHalf_two_Kg,

                                        charge.two_twoAndHalf_Kg,
                                        charge.twoAndHalf_three_Kg,

                                        charge.three_threeAndHalf_Kg,
                                        charge.threeAndHalf_four_Kg,

                                        charge.four_fourAndHalf_Kg,
                                        charge.fourAndHalf_five_Kg,

                                        charge.five_fiveAndHalf_Kg,
                                        charge.fiveAndHalf_six_Kg,

                                        charge.six_sixAndHalf_Kg,
                                        charge.sixAndHalf_seven_Kg
                                        )}>
                                    <i className='fas fa-edit'></i>
                                    </Link>
                                </td>
                                <td><button style={{padding: "0", border: "none", background: "none"}} onClick={()=> deleteHandler(charge._id)}>
                                    <i style={{color:'red'}} className='fas fa-trash-alt'></i>
                                    </button>
                                </td>
                                
                               
                                </tr>
                            ))}
                            
                            
                        </tbody>
                        </table>
                    </div>
                        <hr/>
                        </div>
                    </div>
                    </div>
                    
                </>
                )}
            
        </div>
    )
}

export default FreightChargesScreen
