import React from "react";
import { Link } from "react-router-dom";

// import { useDispatch, useSelector } from "react-redux";
// import Loader from "../../components/Loader";
// import Message from "../../components/Message";
// import {
//   loadPageContents,
//   loadPageContent,
//   createPageContent,
//   updatePageContent,
// } from "../../actions/pageActions";
// import {
//   PAGE_CONTENT_CREATE_RESET,
//   PAGE_CONTENT_UPDATE_RESET,
// } from "../../constants/pageConstants";

// import { EditorState,ContentState, convertFromRaw,convertFromHTML, convertToRaw } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
// import { convertToHTML, } from "draft-convert";
// import DOMPurify from "dompurify";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import "../../index.css";

function AboutUsScreen() {
  // const dispatch = useDispatch();

  // const pageContentsAll = useSelector((state) => state.pageContents);
  // const { loading, error, pageContentsList } = pageContentsAll;
  // // console.log('pageContentsAll:',pageContentsAll)
  // const pageContentCreate = useSelector((state) => state.pageContentCreate);
  // const {
  //   loading: loadingCreate,
  //   error: errorCreate,
  //   success: successCreate,
  //   pageContent: createdPageContent,
  // } = pageContentCreate;

  // const pageContentUpdate = useSelector((state) => state.pageContentUpdate);
  // const {
  //   loading: loadingUpdate,
  //   error: errorUpdate,
  //   success: successUpdate,
  //   pageContent: UpdatedPageContent,
  // } = pageContentUpdate;

  // const [contentId, setContentId] = useState("");
  // const [editContent, setEditContent] = useState(false);
  // const [pageContentExists, setPageContentExists] = useState(false);
  // const [content, setContent] = useState("");

  // useEffect(()=>{
  //   console.log('list:',pageContentsList)
  //   // if (pageContentsList) {
  //     var i;
  //     for (i = 0; i < pageContentsList.length; i++) {
  //       if (pageContentsList[i].name === "aboutUs") {
  //         setContentId(pageContentsList[i]._id);
  //         setContent(pageContentsList[i].content);
  //         // setConvertedContent(convertToHTML(pageContentsList[i].content));
  //         setPageContentExists(true);
  //       }
  //     }
  //   // }
  // },[pageContentsList])

  // useEffect(() => {

  //   if (successCreate) {
  //     setContentId(createdPageContent._id);
  //     setEditContent(true);
  //     dispatch({ type: PAGE_CONTENT_CREATE_RESET });
  //   } else if (successUpdate) {
  //     dispatch({ type: PAGE_CONTENT_UPDATE_RESET });
  //     setEditContent(false);
  //     dispatch(loadPageContents());
  //   } else {
  //     dispatch(loadPageContents());
  //   }
  //   // dispatch(loadPageContents());
  // }, [
  //   dispatch,
  //   // pageContentsList,
  //   successCreate,
  //   successUpdate,
  //   createdPageContent,
  // ]);

  // const addContentHandler = () => {
  //   dispatch(createPageContent("aboutUs"));
  // };

  // console.log('content1:',convertFromHTML(content))
  // const blocksFromHTML  = convertFromHTML(content);
  // const contentFromDb = ContentState.createFromBlockArray(
  //   blocksFromHTML.contentBlocks,
  //   blocksFromHTML.entityMap
  // );
  // const [editorState, setEditorState] = useState(() =>
  
  //   // content
  //   //   ? 
  //     EditorState.createWithContent(contentFromDb)
  //     // : EditorState.createEmpty()
  // );
  

  // const [convertedContent, setConvertedContent] = useState(
  //   convertToHTML(editorState.getCurrentContent())
  // );
  // // if(convertedContent==='<p></p>'){
  // //   setEditorState(contentFromDb)
  // // }

  // console.log('convertedContent:',convertToHTML(editorState.getCurrentContent()));
  // console.log('editorState:',editorState);
  // // console.log('content:',content);

  // const convertContentToHTML = () => {
  //   let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
  //   setConvertedContent(currentContentAsHTML);
  // };

  // const saveContent = (content) => {
  //   window.localStorage.setItem(
  //     "aboutUsContent",
  //     convertedContent
  //   );
  // };

  // const handleEditorChange = (state) => {
  //   setEditorState(state);
  //   convertContentToHTML();
  //   const contentState = editorState.getCurrentContent();
  //   // console.log('content state', convertToRaw(contentState));
  //   saveContent(contentState);
  // };
  // const createMarkup = (html) => {
  //   return {
  //     __html: DOMPurify.sanitize(html),
  //   };
  // };

  // const contentOnPage = createMarkup(content)
  // console.log('content:',createMarkup(content));
  //   // console.log('editor state:',editorState.getCurrentContent())

  // const saveContentToDbHandler = () => {
  //   const rawContent = window.localStorage.getItem("aboutUsContent")
  //   dispatch(updatePageContent(rawContent, contentId));
  //   setEditContent(false)
  // };

  return (
    <div>
      {/* <!-- Page Title--> */}
      <div className="page-title">
        <div className="container">
          <div className="column">
            <h1>About Us</h1>
          </div>
          <div className="column">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="separator">&nbsp;</li>
              <li>About Us</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- Page Content--> */}
      <div className="container padding-bottom-2x mb-2" style={{minHeight:"70vh"}}>
        <div className="row justify-content-center">
          {/* <!-- Content--> */}
          <div
            className="col-xl-10 col-lg-10 order-lg-2"
            style={{ fontSize: "16px", color: "black" }}
          >
            {/* <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div>
                
              </div>
              {pageContentExists ? (
                <button
                  className="btn btn-secondary margin-right-none"
                  type="button"
                  onClick={() => {
                    setEditContent(true);
                  }}
                >
                  <i className="fas fa-plus"></i> Edit Content
                </button>
              ) : (
                <button
                  className="btn btn-secondary margin-right-none"
                  type="button"
                  onClick={()=>addContentHandler()}
                >
                  <i className="fas fa-plus"></i> Add Content
                </button>
              )}
            </div>

            {editContent && (
                <>
              <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
              <div>
              <button
                  className="btn btn-secondary margin-right-none"
                  type="button"
                  onClick={() =>saveContentToDbHandler()}
                > Save Changes
                </button>
              </div>

            </>

            )}

            <div
              className="preview"
              dangerouslySetInnerHTML={convertedContent === '<p></p>' ? contentOnPage : createMarkup(convertedContent) }
            ></div> */}

            <h2 className="pt-4">About Us</h2>
            <p>
            ETECHAN HEALTH CARE is a platform that bridges the gap between you and your wellness worldwide where doctors and patients, nutritionists, and individuals deliver online / IoT-based services and products. It produces NUTRITION, FOOD SUPPLEMENTS, and runs Nutri Health & Wellness Centres.
            </p>
            <p>
            With an immense passion to serve the world in a better, easier, and natural way ETECHAN HEALTH CARE comes to cultivate and instill healthy living habits based on ancient ayurvedic treatises and the modern style of diagnosis and treatment.
            </p>
            <p>
            Although with the increasing population, the health problems are mounting heavily, the technology has made our lives easier, faster, better, and active than ever before, Etechan brings the best nutritional products and services to maintain its client’s health and personal care with the real-time diagnosis and recommendations founded with AI and machine-learning facilities.
            </p>
            <p>
            ETECHAN HEALTH CARE has been successful in bringing together a wide range of health experts from the modern style of treatment to India’s indigenous system of medicine and treatment, which includes prominent researchers, scientists, eminent doctors, and seasoned and experienced nutritionists and diet planners.
            </p>
            <p>
            In order to treat the lifestyle diseases Etechan Health Care comes with the ultimate combination of organic plants, roots, leaves, flowers, and fruits based nutritional products, pure organic food supplements, Ayurveda & Unani medicines, health, hygiene, personal care, skincare, dental care, and beauty products in wellness space along with the D2C platform (Ecommerce website) for delivering the products and wellness services such as online/IoT-based doctors, nutritionists, and Dieticians with AI-assisted physiotherapies, Yoga, and physical fitness consultation services.
            </p>
            <p>
            ETECHAN HEALTH CARE brings remarkable Healthtech under computational biology for real-time diagnosis such as AI-based CIRCADIAN RHYTHM MONITORING and RECOMMENDATIONS for the people whose health has been affected by chronic diseases like liver diseases, acidity, constipation, IBS, ulcerative colitis, piles, sinusitis, bronchitis, asthma, psoriasis, heart disease, blood disease, migraine, joint pain, sleep disorder, diabetes, depression, bipolar disorder, seasonal affective disorder, Parkinson disease,  Alzheimer and dementia due to sleep and wake disturbance caused by their hectic life schedule.
                        </p>
            
          </div>
        </div>

        
      </div>
    </div>
  );
}

export default AboutUsScreen;
