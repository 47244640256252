import { 
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,

    ORDER_CREATE_RESET,

    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,

    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAIL, 
    ORDER_PAY_RESET, 

    ORDER_EMAIL_SEND_REQUEST,
    ORDER_EMAIL_SEND_SUCCESS,
    ORDER_EMAIL_SEND_FAIL, 
    ORDER_EMAIL_SEND_RESET, 

    ORDER_LIST_MY_REQUEST,
    ORDER_LIST_MY_SUCCESS,
    ORDER_LIST_MY_FAIL, 
    ORDER_LIST_MY_RESET,
    
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL, 

    ORDER_DELIVER_REQUEST,
    ORDER_DELIVER_SUCCESS,
    ORDER_DELIVER_FAIL, 
    ORDER_DELIVER_RESET,

    ORDER_SHIP_REQUEST,
    ORDER_SHIP_SUCCESS,
    ORDER_SHIP_FAIL,

    ORDER_SHIP_RESET,

    ORDER_RZP_CHECKOUT_REQUEST,
    ORDER_RZP_CHECKOUT_SUCCESS,
    ORDER_RZP_CHECKOUT_FAIL,
    ORDER_RZP_CHECKOUT_RESET,

    FREIGHT_CHARGES_REQUEST,
    FREIGHT_CHARGES_SUCCESS,
    FREIGHT_CHARGES_FAIL,

    FREIGHT_CHARGE_CREATE_REQUEST,
    FREIGHT_CHARGE_CREATE_SUCCESS,
    FREIGHT_CHARGE_CREATE_FAIL,
    FREIGHT_CHARGE_CREATE_RESET,

    FREIGHT_CHARGE_UPDATE_REQUEST,
    FREIGHT_CHARGE_UPDATE_SUCCESS,
    FREIGHT_CHARGE_UPDATE_FAIL,
    FREIGHT_CHARGE_UPDATE_RESET,

    FREIGHT_CHARGE_DELETE_REQUEST,
    FREIGHT_CHARGE_DELETE_SUCCESS,
    FREIGHT_CHARGE_DELETE_FAIL,
} from '../constants/orderConstants'

export const orderCreateReducer = (state ={}, action)=>{
    switch(action.type) {
        case ORDER_CREATE_REQUEST:
            return {
                loading: true
            }

        case ORDER_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                order: action.payload
            }

        case ORDER_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case ORDER_CREATE_RESET:
            return {}
    
        default:
            return state
    }
}



export const orderDetailsReducer = (state ={loading:true, orderItems:[], shippingAddress:{}, billingAddress:{}}, action)=>{
    switch(action.type) {
        case ORDER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case ORDER_DETAILS_SUCCESS:
            return {
                loading: false,
                order: action.payload
            }

        case ORDER_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}

export const orderPayReducer = (state ={}, action)=>{
    switch(action.type) {
        case ORDER_PAY_REQUEST:
            return {
                loading: true
            }

        case ORDER_PAY_SUCCESS:
            return {
                loading: false,
                success: true
            }

        case ORDER_PAY_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case ORDER_PAY_RESET:
        return {}

        default:
            return state
    }
}

export const orderEmailSendReducer = (state ={}, action)=>{
    switch(action.type) {
        case ORDER_EMAIL_SEND_REQUEST:
            return {
                loading: true
            }

        case ORDER_EMAIL_SEND_SUCCESS:
            return {
                loading: false,
                success: true,
                // resp: action.payload
            }


        case ORDER_EMAIL_SEND_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case ORDER_EMAIL_SEND_RESET:
        return {}

        default:
            return state
    }
}   

export const orderRazorpayCheckoutReducer = (state ={}, action)=>{
    switch(action.type) {
        case ORDER_RZP_CHECKOUT_REQUEST:
            return {
                loading: true
            }

        case ORDER_RZP_CHECKOUT_SUCCESS:
            return {
                loading: false,
                success: true,
                razorpayData : action.payload
            }

        case ORDER_RZP_CHECKOUT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case ORDER_RZP_CHECKOUT_RESET:
            return {}

        default:
            return state
    }
}

export const orderListMyReducer = (state ={orders:[]}, action)=>{
    switch(action.type) {
        case ORDER_LIST_MY_REQUEST:
            return {
                loading: true
            }

        case ORDER_LIST_MY_SUCCESS:
            return {
                loading: false,
                orders: action.payload
            }

        case ORDER_LIST_MY_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case ORDER_LIST_MY_RESET:
        return {
            orders: []
        }

        default:
            return state
    }
}

export const orderListReducer = (state ={orders:[]}, action)=>{
    switch(action.type) {
        case ORDER_LIST_REQUEST:
            return {
                loading: true
            }

        case ORDER_LIST_SUCCESS:
            return {
                loading: false,
                orders: action.payload
            }

        case ORDER_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}

export const orderDeliverReducer = (state ={}, action)=>{
    switch(action.type) {
        case ORDER_DELIVER_REQUEST:
            return {
                loading: true
            }

        case ORDER_DELIVER_SUCCESS:
            return {
                loading: false,
                success: true
            }

        case ORDER_DELIVER_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case ORDER_DELIVER_RESET:
        return {}

        default:
            return state
    }
}

export const orderShipReducer = (state ={}, action)=>{
    switch(action.type) {
        case ORDER_SHIP_REQUEST:
            return {
                loading: true
            }

        case ORDER_SHIP_SUCCESS:
            return {
                loading: false,
                success: true
            }

        case ORDER_SHIP_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case ORDER_SHIP_RESET:
        return {}

        default:
            return state
    }
}

export const freightChargesReducer = (state ={loading:true, freightCharges:[]}, action)=>{
    switch(action.type) {
        case FREIGHT_CHARGES_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FREIGHT_CHARGES_SUCCESS:
            return {
                loading: false,
                charges: action.payload
            }

        case FREIGHT_CHARGES_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}

export const freightChargeCreateReducer = (state = {}, action) => {

    switch (action.type) {
        case FREIGHT_CHARGE_CREATE_REQUEST:
            return { loading: true }

        case FREIGHT_CHARGE_CREATE_SUCCESS:
            return { loading: false, success: true, charge: action.payload }

        case FREIGHT_CHARGE_CREATE_FAIL:
            return { loading: false, error: action.payload}

        case FREIGHT_CHARGE_CREATE_RESET:
            return {}

        default:
            return state

    }
}

export const freightChargeUpdateReducer = (state = { charge: {}}, action) => {

    switch (action.type) {
        case FREIGHT_CHARGE_UPDATE_REQUEST:
            return { loading: true }

        case FREIGHT_CHARGE_UPDATE_SUCCESS:
            return { loading: false, success: true, charge: action.payload }

        case FREIGHT_CHARGE_UPDATE_FAIL:
            return { loading: false, error: action.payload}

        case FREIGHT_CHARGE_UPDATE_RESET:
            return { charge: {}}

        default:
            return state

    }
}

export const freightChargeDeleteReducer = (state = {}, action) => {

    switch (action.type) {
        case FREIGHT_CHARGE_DELETE_REQUEST:
            return { loading: true }

        case FREIGHT_CHARGE_DELETE_SUCCESS:
            return { loading: false, success: true }

        case FREIGHT_CHARGE_DELETE_FAIL:
            return { loading: false, error: action.payload}

        default:
            return state

    }
}

