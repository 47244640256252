import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { listServiceDetails, updateService } from '../../actions/serviceActions'
import { SERVICE_UPDATE_RESET } from '../../constants/serviceConstants'
import { listDoctors } from '../../actions/doctorActions'

import axios from 'axios'

function ServiceEditScreen({match,history}) {

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const serviceId = match.params.id

    const [name, setName] = useState('')
    const [disease, setDisease] = useState('')
    const [serviceType, setServiceType] = useState('')
    const [organs, setOrgans] = useState('')

    const [description, setDescription] = useState('')
    const [short_description, setShort_description] = useState('')

    const [feeRupees, setFeeRupees] = useState(0)
    const [feeDollars, setFeeDollars] = useState(0)

    const [SGST, setSGST] = useState(0)
    const [CGST, setCGST] = useState(0)
    const [IGST, setIGST] = useState(0)

    const [doctorSlug1, setDoctorSlug1] = useState('')
    const [doctorSlug2, setDoctorSlug2] = useState('')
    const [doctorSlug3, setDoctorSlug3] = useState('')
    const [doctorSlug4, setDoctorSlug4] = useState('')

    const [image_1, setImage_1] = useState('')
    const [image_2, setImage_2] = useState('')
    const [image_3, setImage_3] = useState('')
    const [image_4, setImage_4] = useState('')
    const [image_5, setImage_5] = useState('')

    const [uploading1, setUploading1] = useState(false)
    const [uploading2, setUploading2] = useState(false)
    const [uploading3, setUploading3] = useState(false)
    const [uploading4, setUploading4] = useState(false)
    const [uploading5, setUploading5] = useState(false)

    const dispatch = useDispatch()

    const serviceDetails = useSelector(state => state.serviceDetails)
    const {error, loading, service } = serviceDetails

    const serviceUpdate = useSelector(state => state.serviceUpdate)
    const {error:errorUpdate, loading:loadingUpdate, success:successUpdate } = serviceUpdate

    const doctorList = useSelector(state => state.doctorList)
    const { loading:loadingDoctor , error:errorDoctor, doctors} = doctorList

    useEffect(() => {    

        if(successUpdate){
            dispatch({type:SERVICE_UPDATE_RESET})
            history.push('/admin/servicelist')
        } else {

            if(!service.name || service.slug !== serviceId){
                dispatch(listServiceDetails(serviceId))
            }else{
                setName(service.name)
                setDisease(service.disease)
                setServiceType(service.serviceType)
                setOrgans(service.organs)
                setDescription(service.description)

                setShort_description(service.short_description)
                setFeeRupees(service.feeRupees)    
                setFeeDollars(service.feeDollars)
                
                setSGST(service.SGST)
                setCGST(service.CGST)
                setIGST(service.IGST)

                setDoctorSlug1(service.doctors && service.doctors.length > 0 ? service.doctors[0].slug : '')
                setDoctorSlug2(service.doctors && service.doctors.length > 1 ? service.doctors[1].slug : '')
                setDoctorSlug3(service.doctors && service.doctors.length > 2 ? service.doctors[2].slug : '')
                setDoctorSlug4(service.doctors && service.doctors.length > 3 ? service.doctors[3].slug : '')

                setImage_1(service.image_1)
                setImage_2(service.image_2)
                setImage_3(service.image_3)
                setImage_4(service.image_4)
                setImage_5(service.image_5)
                
            }

        }
    },[service,serviceId,dispatch,history,successUpdate])

    useEffect(() => {
        dispatch(listDoctors())       
    }, [dispatch])

    function hasDuplicates(array) {
        var valuesSoFar = Object.create(null);
        for (var i = 0; i < array.length; ++i) {
            var value = array[i];
            if (value in valuesSoFar) {
                return true;
            }
            valuesSoFar[value] = true;
        }
        return false;
    }

    var doctorSlugs = [];
    const submitHandler = (e) => {
        e.preventDefault()

        if(doctorSlug1 && doctorSlug1 !== 'selectDoctor'){
            doctorSlugs.push(doctorSlug1);
        }
        
        if(doctorSlug2 && doctorSlug2 !== 'selectDoctor'){
            doctorSlugs.push(doctorSlug2);
        }
        
        if(doctorSlug3 && doctorSlug3 !== 'selectDoctor'){
            doctorSlugs.push(doctorSlug3);
        }
        
        if(doctorSlug4 && doctorSlug4 !== 'selectDoctor'){
            doctorSlugs.push(doctorSlug4);
        }

        if(hasDuplicates(doctorSlugs)){
            alert('Two or more doctors selected are same! Please select different doctors.')
        }else{
            
            dispatch(updateService({
                slug:serviceId,name,disease,serviceType,organs,doctorSlugs,
                description,short_description,feeRupees,feeDollars,SGST,CGST,
                IGST,image_1,image_2,image_3,image_4,image_5          

            }))  

        }

        
          
    }

    const config = {
        headers: {
            'content-type':'multipart/form-data',
            Authorization: `Bearer ${userInfo ? userInfo.token : ''}`
        }
    }


    const uploadFileHandler1 = async (e) => {
        const file1 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_1',file1)
        formData.append('service_slug',serviceId)

        setUploading1(true)

        try{

            const {data} = await axios.post('/api/services/upload/', formData,config)
            
            setImage_1(data)
            setUploading1(false)
        }catch(error){
            setUploading1(false)
        }

    }

    const uploadFileHandler2 = async (e) => {
        const file2 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_2',file2)
        formData.append('service_slug',serviceId)

        setUploading2(true)

        try{
            const {data} = await axios.post('/api/services/upload/', formData,config)
            
            setImage_2(data)
            setUploading2(false)
        }catch(error){
            setUploading2(false)
        }

    }

    const uploadFileHandler3 = async (e) => {
        const file3 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_3',file3)
        formData.append('service_slug',serviceId)

        setUploading3(true)

        try{
           const {data} = await axios.post('/api/services/upload/', formData,config)
            
            setImage_3(data)
            setUploading3(false)
        }catch(error){
            setUploading3(false)
        }

    }

    const uploadFileHandler4 = async (e) => {
        const file4 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_4',file4)
        formData.append('service_slug',serviceId)

        setUploading4(true)

        try{
            const {data} = await axios.post('/api/services/upload/', formData,config)
            
            setImage_4(data)
            setUploading4(false)
        }catch(error){
            setUploading4(false)
        }

    }

    const uploadFileHandler5 = async (e) => {
        const file5 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_5',file5)
        formData.append('service_slug',serviceId)

        setUploading5(true)

        try{
            const {data} = await axios.post('/api/services/upload/', formData,config)
            
            setImage_5(data)
            setUploading5(false)
        }catch(error){
            setUploading5(false)
        }

    }


    return (
        <div>
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>Edit Service</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Home</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li><Link to="/admin/servicelist">service List</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>Edit Service</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2">
            <div><Link to='/admin/servicelist'><h6 className="margin-bottom-1x">Go Back</h6></Link></div>

            <div className="row" >
                <div className="col-md-12">
                     <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                    
                    <form className="card" onSubmit={submitHandler}>
                        
                        <div className="col-md-12">
                            <br />
                            <h4 className="margin-bottom-1x">Service Info</h4>
                            
                            {loading ? <Loader/> : error ? <center><Message variant='danger'>{error}</Message></center> : 
                            <>
                            <div className="row">
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-name">Name</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="service-name" 
                                            value={name} 
                                            onChange={(e)=>setName(e.target.value)}
                                            />
                                </div>
                                
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-brand">Disease</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="service-disease" 
                                            value={disease} 
                                            onChange={(e)=>setDisease(e.target.value)}
                                            />
                                </div>
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-category">Service Type</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="service-serviceType" 
                                            value={serviceType} 
                                            onChange={(e)=>setServiceType(e.target.value)}
                                            />
                                </div>

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-category">Organs</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="service-organs" 
                                            value={organs} 
                                            onChange={(e)=>setOrgans(e.target.value)}
                                            />
                                </div>
                                
                                
                            </div>

                            <div className="row">
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="feeRupees">Rupee(₹) Price</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="feeRupees" 
                                            value={feeRupees} 
                                            onChange={(e)=>setFeeRupees(e.target.value  || 0)}
                                            />
                                </div>
                                
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="feeRupees">Dollar($) Price</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="feeRupees" 
                                            value={feeDollars} 
                                            onChange={(e)=>setFeeDollars(e.target.value  || 0)}
                                            />
                                </div>
                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-sgst">SGST</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="service-sgst" 
                                            value={SGST} 
                                            onChange={(e)=>setSGST(e.target.value)}
                                            />
                                </div>
                                
                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-cgst">CGST</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="service-cgst" 
                                            value={CGST} 
                                            onChange={(e)=>setCGST(e.target.value)}
                                            />
                                </div>
                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-igst">IGST</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="service-igst" 
                                            value={IGST} 
                                            onChange={(e)=>setIGST(e.target.value)}
                                            />
                                </div>

                            </div>

                            
                            <div className="row">
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="expert">Associtated Doctor 1</label>
                                            <select
                                                className="form-control"
                                                as="select"
                                                value={doctorSlug1 || ''}
                                                onChange={(e)=>setDoctorSlug1(e.target.value)}
                                                id="expert"
                                            >
                                                <option key={'select_doctor'} value="selectDoctor">
                                                            Select 
                                                </option>
                                                {doctors && doctors.map(
                                                    (doctor) =>                                                
                                                        <option key={doctor.slug} value={doctor.slug}>
                                                            {doctor.name}
                                                        </option>                                                
                                                    )
                                                }
                                            </select> 
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="expert">Associtated Doctor 2</label>
                                            <select
                                                className="form-control"
                                                as="select"
                                                value={doctorSlug2 || ''}
                                                onChange={(e)=>setDoctorSlug2(e.target.value)}
                                                id="expert"
                                            >
                                                <option key={'select_doctor2'} value="selectDoctor">
                                                            Select  
                                                </option>
                                                {doctors && doctors.map(
                                                    (doctor) =>                                                
                                                        <option key={doctor.slug+"2"} value={doctor.slug}>
                                                            {doctor.name}
                                                        </option>                                                
                                                    )
                                                }
                                            </select> 
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="expert">Associtated Doctor 3</label>
                                            <select
                                                className="form-control"
                                                as="select"
                                                value={doctorSlug3 || ''}
                                                onChange={(e)=>setDoctorSlug3(e.target.value)}
                                                id="expert"
                                            >
                                                <option key={'select_doctor3'} value="selectDoctor">
                                                            Select  
                                                </option>
                                                {doctors && doctors.map(
                                                    (doctor) =>                                                
                                                        <option key={doctor.slug+"3"} value={doctor.slug}>
                                                            {doctor.name}
                                                        </option>                                                
                                                    )
                                                }
                                            </select> 
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="expert">Associtated Doctor 4</label>
                                            <select
                                                className="form-control"
                                                as="select"
                                                value={doctorSlug4 || ''}
                                                onChange={(e)=>setDoctorSlug4(e.target.value)}
                                                id="expert"
                                            >
                                                <option key={'select_doctor4'} value="selectDoctor">
                                                            Select 
                                                </option>
                                                {doctors && doctors.map(
                                                    (doctor) =>                                                
                                                        <option key={doctor.slug+"4"} value={doctor.slug}>
                                                            {doctor.name}
                                                        </option>                                                
                                                    )
                                                }
                                            </select> 
                                        </div>

                                        

                            
                            
                            </div>

                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-description">Description</label>
                                    <textarea className="form-control"
                                            name="paragraph_text" 
                                            id="service-description" 
                                            value={description} 
                                            onChange={(e)=>setDescription(e.target.value)}
                                            rows="10"></textarea>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label style={{paddingLeft:'0px'}} htmlFor="Short_Description">Short Description</label>
                                    <textarea className="form-control"
                                            name="paragraph_text" 
                                            id="Short_Description" 
                                            value={short_description} 
                                            onChange={(e)=>setShort_description(e.target.value)}
                                            rows="5"></textarea>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-image-1">Image 1</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="service-image-1" 
                                            value={image_1} 
                                            onChange={(e)=>setImage_1(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image1-file'
                                        custom
                                        onChange={uploadFileHandler1}
                                        />
                                    {uploading1 && <Loader/>}
                                </div>
                                
                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-image-2">Image 2</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="service-image-2" 
                                            value={image_2} 
                                            onChange={(e)=>setImage_2(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image2-file'
                                        custom
                                        onChange={uploadFileHandler2}
                                        />
                                    {uploading2 && <Loader/>}
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-image-3">Image 3</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="service-image-3" 
                                            value={image_3} 
                                            onChange={(e)=>setImage_3(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image3-file'
                                        custom
                                        onChange={uploadFileHandler3}
                                        />
                                    {uploading3 && <Loader/>}
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-image-4">Image 4</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="service-image-4" 
                                            value={image_4} 
                                            onChange={(e)=>setImage_4(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image4-file'
                                        custom
                                        onChange={uploadFileHandler4}
                                        />
                                    {uploading4 && <Loader/>}
                                </div>

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-image-5">Image 5</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="service-image-5" 
                                            value={image_5} 
                                            onChange={(e)=>setImage_5(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image5-file'
                                        custom
                                        onChange={uploadFileHandler5}
                                        />
                                    {uploading5 && <Loader/>}
                                </div>
                                
                                
                            </div>

                            <center>{loadingUpdate && <Loader/>}</center>
                            <center>{loadingDoctor && <Loader/>}</center>
                            {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
                            {errorDoctor && <Message variant="danger">{errorDoctor}</Message>}

                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                                
                                <button className="btn btn-primary margin-right-none" 
                                        type="submit" 
                                        data-toast data-toast-position="topRight" 
                                        data-toast-type="success" 
                                        data-toast-icon="icon-check-circle" 
                                        data-toast-title="Success!" 
                                        data-toast-message="Your profile updated successfuly.">
                                            Update Service
                                </button>
                            </div>
                            </>
                            }
                            <br />
                        </div>
                    </form>
                    
                {/* <aside className="user-info-wrapper">
                    <div className="user-cover" style={{backgroundImage: "url(/assets/img/account/user-cover-img.jpg)"}}>
                    <div className="info-label" data-toggle="tooltip" title="You currently have 290 Reward Points to spend"><i className="icon-award"></i>290 points</div>
                    </div>
                    <div className="user-info">
                    <div className="user-avatar"><Link className="edit-avatar" to="#"></Link><img src="/images/img/account/user-ava.jpg" alt="User" /></div>
                    <div className="user-data">
                        <h4 className="h5">Daniel Adams</h4><span>Joined February 06, 2017</span>
                    </div>
                    </div>
                </aside>
                <nav className="list-group"><Link className="list-group-item with-badge" to="account-orders.html"><i className="icon-shopping-bag"></i>Orders<span className="badge badge-default badge-pill">6</span></Link><Link className="list-group-item active" to="account-profile.html"><i className="icon-user"></i>Profile</Link><Link className="list-group-item" to="account-address.html"><i className="icon-map-pin"></i>Addresses</Link><Link className="list-group-item with-badge" to="account-wishlist.html"><i className="icon-heart"></i>Wishlist<span className="badge badge-default badge-pill">3</span></Link><Link className="list-group-item with-badge" to="account-tickets.html"><i className="icon-tag"></i>My Tickets<span className="badge badge-default badge-pill">4</span></Link></nav> */}
                </div>


                
            </div>

            </div>
            
        </div>
    )
}

export default ServiceEditScreen
