import React from 'react'
import { Link } from 'react-router-dom'

function DietNutritionScreen() {
    return (
        <div>

            {/* <!-- Page Title--> */}
                <div className="page-title mb-0">
                <div className="container">
                    <div className="column">
                    <h1>Diet & Nutrition</h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li><Link to="/">Home</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>Treatments</li>
                        <li className="separator">&nbsp;</li>
                        <li>Diet & Nutrition</li>
                    </ul>
                    </div>
                </div>
                </div>

                <section >
                    <img src="/images/healthandnutrition_banner.jpg" alt="dietnutrition-banner"/>                    
                </section>
                <br/>
                {/* <!-- Page Content--> */}
                <div className="container padding-bottom-2x mb-2">
                
                    <div className="row justify-content-center">
                        {/* <!-- Content--> */}
                        <div className="col-xl-12 col-lg-12 order-lg-2" style={{fontSize:"16px", color:"black"}}>
                            
                        
                            <h2 className="pt-4">Ailment Prevention with Proper Diet and Nutrition</h2><hr className="margin-bottom-1x"/>
                            <p>A balanced diet provides our body with essential nutrients to help in its proper functioning. 
                                The number of calories that one consumes in his diet must be in accordance with the number of calories
                                 the body uses while performing several activities like walking, thinking, breathing, and other vital 
                                 functions. For such proper functioning of the body and to stay fit, one must focus on the importance 
                                 of good nutrition and diet plan in life.
                            </p>
                            <p>As they say, “Prevention is better than cure”. Usually, people come to know about the importance of staying fit 
                                after their health starts deteriorating or when they come across some life-threatening disease. However, if one 
                                takes care of his health and the diet, the possibilities of coming across any severe health issues or deadly 
                                ailments can be reduced to a large extent.
                            </p>
                            <p>The rising levels of fatal ailments like cancer, heart diseases, kidney failures, etc. are more 
                                or less due to the inappropriate nutrition/ balanced diet and lack of a healthy lifestyle. 
                                At Etechan Health Group’s Wellness Centers, we render our special services to people going 
                                through a variety of health issues. We help them by offering customized nutrition & diet 
                                plans which are prepared by our expert nutritionists and dieticians from all around the 
                                globe after proper analysis and research on your health and lifestyle.
                            </p>
                            <div className="card text-center mt-4 mb-4">
                               
                                <div className="card-body">
                                
                                <div className="row" style={{fontSize:"16px",color:"black",borderRadius:"0"}}>

                                    <div className= "col-xl-6 col-lg-6">
                                    <h5 style={{textAlign:"left"}} className="card-title"><span>1. Healthy Food Intake </span></h5><hr className="margin-bottom-1x"/>

                                    <p style={{textAlign:"justify"}} className="card-text">At Etechan Health Tech, our main priority is to help our patients in 
                                        maintaining their healthy well-being. We do this by constantly tracking their food intake. Our nutritionists help you 
                                        with the best diet plans that are customized as per your lifestyle and preferences. Our dieticians and nutritionists 
                                        ensure that your food intake comprises of adequate amount of the needed nutrients that you require.
                                        </p>
                                    </div>
                                    

                                    <div className= "col-xl-6 col-lg-6">
                                    <img  style={{borderRadius:"4px"}} src="/images/healthy_food.jpg" alt="hww-step1" />

                                    </div>
                                    
                                </div>
                                <br/><br/>
                                <div className="row" style={{fontSize:"16px",color:"black",borderRadius:"0"}}>

                                    
                                    <div className= "col-xl-6 col-lg-6">
                                    <h5 style={{textAlign:"left"}} className="card-title">2. Importance of a Balanced Diet</h5><hr className="margin-bottom-1x"/>

                                    <p style={{textAlign:"justify"}} className="card-text">A balanced diet must comprise six main nutrients, which include fats, fiber, 
                                    vitamins, protein, carbohydrates, and minerals. We all know the value of balanced diets in our lives. However, in our 
                                    hectic lives, we all fail to eat balanced diets. Our expert nutritionists conduct intensive research and analyze your
                                     body’s response to various food products. Accordingly, they come up with a balanced diet program such that you get 
                                     wholesome nutrition from the planned diet.
                                    </p>
                                    </div>
                                    <div className= "col-xl-6 col-lg-6">
                                    <img  style={{borderRadius:"4px"}} src="/images/nutri2.jpg" alt="hww-step1" />

                                    </div>
                                </div>

                                <br/><br/>
                                <div className="row" style={{fontSize:"16px",color:"black",borderRadius:"0"}}>

                                    
                                    <div className= "col-xl-6 col-lg-6">
                                    <h5 style={{textAlign:"left"}} className="card-title">3. Timely Intake of a Healthy Diet</h5><hr className="margin-bottom-1x"/>

                                    <p style={{textAlign:"justify"}} className="card-text">Food intake sounds like a very simple activity but it's mere simple slip-ups we make that make a huge 
                                        modification in our daily lives. It is imperative to recognize that when it comes to eating, it is not just a proper 
                                        well-balanced diet which matters, but also the right timing of meals. At EHT, we help you with a proper diet plan with 
                                        exact timings so that you see the results for yourself!
                                    </p>
                                    </div>
                                    <div className= "col-xl-6 col-lg-6">
                                    <img style={{borderRadius:"4px"}}  src="/images/nutri3.jpg" alt="hww-step1" />

                                    </div>
                                </div>

                                <br/><br/>
                                <div className="row" style={{fontSize:"16px",color:"black",borderRadius:"0"}}>

                                    
                                    <div className= "col-xl-6 col-lg-6">
                                    <h5 style={{textAlign:"left"}} className="card-title">4. Maintaining Weight as per BMI</h5><hr className="margin-bottom-1x"/>

                                    <p style={{textAlign:"justify"}} className="card-text">We all know that a well-balanced diet aids in maintaining weight easily. 
                                        Calories constitute a significant element of a well-balanced diet which depends on the age, activity level and weight 
                                        goals of a person. A proper diet intake under our nutritionists’ and experts’ supervision helps our patients to gain 
                                        or lose weight as per their Body Mass Index. So, if you are overweight or underweight, you do not need to worry. 
                                        Etechan Health Tech experts will help you achieve your perfect weight goal using natural methodologies.
                                    </p>
                                    </div>
                                    <div className= "col-xl-6 col-lg-6">
                                    <img  style={{borderRadius:"4px"}} src="/images/weight_loss.jpg" alt="wtloss" />

                                    </div>
                                </div>

                                <br/><br/>
                                <div className="row" style={{fontSize:"16px",color:"black",borderRadius:"0"}}>

                                    
                                    <div className= "col-xl-6 col-lg-6">
                                    <h5 style={{textAlign:"left"}} className="card-title">5. Fitness and Staying Healthy</h5><hr className="margin-bottom-1x"/>

                                    <p style={{textAlign:"justify"}} className="card-text">It goes without saying that a well-planned and timely managed 
                                    balanced diet has astounding benefits in our lives. We not only feel energized throughout the day but also increase
                                     our fitness level. All our health issues and weight loss gain worries fade away merely by changing the way we intake
                                      our food. Hence, for a fit and healthy lifestyle, please feel free to contact our team and we will do our best to 
                                      bring a change in your life.
                                    </p>
                                    </div>
                                    <div className= "col-xl-6 col-lg-6">
                                    <img style={{borderRadius:"4px", width:"534px"}}  src="/images/yoga.jpg" alt="nutriyoga" />

                                    </div>
                                </div>
                                
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    
                
                    
                    
                    
                </div>
    
            </div>
    )
}

export default DietNutritionScreen
