import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { verifyEmail } from '../actions/userActions'

// import axios from 'axios'

function VerifyEmailScreen({location,history}) {

    const [email, setEmail] = useState('')
    
    const userVerifyEmail = useSelector(state => state.userVerifyEmail)
    const {error:errorVerifyEmail, loading:loadingVerifyEmail, otpInfo:otpInfoEmail } = userVerifyEmail


    const dispatch = useDispatch()

    useEffect(() => {
    
        if( (otpInfoEmail && otpInfoEmail.email)){
            history.push('/verify-email-otp')
        }

    },[history,otpInfoEmail])

    const submitHandler = (e) => {
        e.preventDefault()
        
        if(!email ){
            alert('Please Enter your Email Id.')
        }else{
            dispatch(verifyEmail(email))
        }
        
    }
    return (
        <div>
            
            
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2 mt-5" style={{minHeight: "80vh"}}>
            <div className="row" style={{justifyContent: "center"}}>
                
                <div className="col-lg-5 col-md-7 col-sm-10">
                                
                <div className="padding-top-3x hidden-md-up"></div>
               <form className="card" method="post"  onSubmit={submitHandler}>
               <div className="card-body">
                    <h4 className="margin-bottom-1x">Email Verification</h4>

                        <div className="form-group input-group" >
                        
                        <label style={{paddingLeft:'0px'}} >Email </label>

                        <input style={{width:'100%',borderRadius:"5px"}} className="form-control" 
                                type="email" 
                                placeholder="Enter Email" 
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                        />
                                
                        <span style={{marginTop: "16px"}} className="input-group-addon">
                            <i className="icon-mail"></i>
                        </span>
                    </div>

                    </div>
                    <div style={{marginTop: '-30px'}} className="col-12 text-center text-sm-center">

                    {errorVerifyEmail && <Message variant='danger'>{errorVerifyEmail}</Message>}                    
                    {loadingVerifyEmail && <Loader />}

                    <button className="btn btn-primary btn-block mb-4" type="submit">Verify</button>
                    </div>
                    <div className="col-12 text-center text-sm-center mb-3">
                    Back to &nbsp;
                    <span><Link to="/profile"
                    >Profile</Link></span></div>
                    <br />
                    
                </form>
                </div>
            </div>
            </div>
        </div>
    )
}

export default VerifyEmailScreen
