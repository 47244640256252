import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listProductDetails, updateProduct } from '../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants'
import axios from 'axios'

function ProductEditScreen({match,history}) {

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const productId = match.params.id

    const [name, setName] = useState('')
    const [brand, setBrand] = useState('')
    const [category, setCategory] = useState('')
    const [description, setDescription] = useState('')
    const [weight_name, setWeigh_name] = useState('')

    const [SGST, setSGST] = useState(0)
    const [CGST, setCGST] = useState(0)
    const [IGST, setIGST] = useState(0)
    
    const [mfgStateCode, setMfgStateCode] = useState('DL')
    const [hsnCode, setHsnCode] = useState('')

    const [weight_1, setWeight_1] = useState('')
    const [rupee_price_1, setRupee_price_1] = useState(0)
    const [dollar_price_1, setDollar_price_1] = useState(0)
    const [countInStock_1, setCountInStock_1] = useState(0)
    const [weightInGm_1, setWeightInGm_1] = useState(0)

    const [weight_2, setWeight_2] = useState('')
    const [rupee_price_2, setRupee_price_2] = useState(0)
    const [dollar_price_2, setDollar_price_2] = useState(0)
    const [countInStock_2, setCountInStock_2] = useState(0)
    const [weightInGm_2, setWeightInGm_2] = useState(0)

    const [weight_3, setWeight_3] = useState('')
    const [rupee_price_3, setRupee_price_3] = useState(0)
    const [dollar_price_3, setDollar_price_3] = useState(0)
    const [countInStock_3, setCountInStock_3] = useState(0)
    const [weightInGm_3, setWeightInGm_3] = useState(0)

    const [weight_4, setWeight_4] = useState('')
    const [rupee_price_4, setRupee_price_4] = useState(0)
    const [dollar_price_4, setDollar_price_4] = useState(0)
    const [countInStock_4, setCountInStock_4] = useState(0)
    const [weightInGm_4, setWeightInGm_4] = useState(0)

    const [image_1, setImage_1] = useState('')
    const [image_2, setImage_2] = useState('')
    const [image_3, setImage_3] = useState('')
    const [image_4, setImage_4] = useState('')
    const [image_5, setImage_5] = useState('')

    const [uploading1, setUploading1] = useState(false)
    const [uploading2, setUploading2] = useState(false)
    const [uploading3, setUploading3] = useState(false)
    const [uploading4, setUploading4] = useState(false)
    const [uploading5, setUploading5] = useState(false)

    const dispatch = useDispatch()

    const productDetails = useSelector(state => state.productDetails)
    const {error, loading, product } = productDetails

    const productUpdate = useSelector(state => state.productUpdate)
    const {error:errorUpdate, loading:loadingUpdate, success:successUpdate } = productUpdate

    useEffect(() => {    

        if(successUpdate){
            dispatch({type:PRODUCT_UPDATE_RESET})
            history.push('/admin/productlist')
        } else {

            if(!product.name || product.slug !== productId){
                dispatch(listProductDetails(productId))
            }else{
                setName(product.name)
                setBrand(product.brand)
                setCategory(product.category)
                setDescription(product.description)
                setWeigh_name(product.weight_name)

                setSGST(product.SGST)
                setCGST(product.CGST)
                setIGST(product.IGST)
                setMfgStateCode(product.manufacturingStateCode)
                setHsnCode(product.hsnCode)
    
                setWeight_1(product.weight_1)
                setRupee_price_1(product.rupee_price_1)
                setDollar_price_1(product.dollar_price_1)
                setCountInStock_1(product.countInStock_1)
                setWeightInGm_1(product.weightInGm_1)
    
                setWeight_2(product.weight_2)
                setRupee_price_2(product.rupee_price_2)
                setDollar_price_2(product.dollar_price_2)
                setCountInStock_2(product.countInStock_2)
                setWeightInGm_2(product.weightInGm_2)

                setWeight_3(product.weight_3 )
                setRupee_price_3(product.rupee_price_3)
                setDollar_price_3(product.dollar_price_3 )
                setCountInStock_3(product.countInStock_3)
                setWeightInGm_3(product.weightInGm_3)

                setWeight_4(product.weight_4)
                setRupee_price_4(product.rupee_price_4)
                setDollar_price_4(product.dollar_price_4)
                setCountInStock_4(product.countInStock_4)
                setWeightInGm_4(product.weightInGm_4)

                setImage_1(product.image_1)
                setImage_2(product.image_2)
                setImage_3(product.image_3)
                setImage_4(product.image_4)
                setImage_5(product.image_5)
                
            }

        }
    },[product,productId,dispatch,history,successUpdate])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateProduct({
            slug:productId,name,brand,category,description,weight_name,
            SGST,CGST,IGST,mfgStateCode,hsnCode,
            weight_1,rupee_price_1,dollar_price_1,countInStock_1,weightInGm_1,
            weight_2,rupee_price_2,dollar_price_2,countInStock_2,weightInGm_2,
            weight_3,rupee_price_3,dollar_price_3,countInStock_3,weightInGm_3,
            weight_4,rupee_price_4,dollar_price_4,countInStock_4,weightInGm_4,
            image_1,image_2,image_3,image_4,image_5          

        }))    
    }

    const config = {
        headers: {
            'content-type':'multipart/form-data',
            Authorization: `Bearer ${userInfo ? userInfo.token : ''}`
        }
    }


    const uploadFileHandler1 = async (e) => {
        const file1 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_1',file1)
        formData.append('product_slug',productId)

        setUploading1(true)

        try{

            const {data} = await axios.post('/api/products/upload/', formData,config)
            
            setImage_1(data)
            setUploading1(false)
        }catch(error){
            setUploading1(false)
        }

    }

    const uploadFileHandler2 = async (e) => {
        const file2 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_2',file2)
        formData.append('product_slug',productId)

        setUploading2(true)

        try{
            const {data} = await axios.post('/api/products/upload/', formData,config)
            
            setImage_2(data)
            setUploading2(false)
        }catch(error){
            setUploading2(false)
        }

    }

    const uploadFileHandler3 = async (e) => {
        const file3 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_3',file3)
        formData.append('product_slug',productId)

        setUploading3(true)

        try{
           const {data} = await axios.post('/api/products/upload/', formData,config)
            
            setImage_3(data)
            setUploading3(false)
        }catch(error){
            setUploading3(false)
        }

    }

    const uploadFileHandler4 = async (e) => {
        const file4 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_4',file4)
        formData.append('product_slug',productId)

        setUploading4(true)

        try{
            const {data} = await axios.post('/api/products/upload/', formData,config)
            
            setImage_4(data)
            setUploading4(false)
        }catch(error){
            setUploading4(false)
        }

    }

    const uploadFileHandler5 = async (e) => {
        const file5 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_5',file5)
        formData.append('product_slug',productId)

        setUploading5(true)

        try{
            const {data} = await axios.post('/api/products/upload/', formData,config)
            
            setImage_5(data)
            setUploading5(false)
        }catch(error){
            setUploading5(false)
        }

    }


    return (
        <div>
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>Edit Product</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Home</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li><Link to="/admin/productlist">Product List</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>Edit Product</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2">
            <div><Link to='/admin/productlist'><h6 className="margin-bottom-1x">Go Back</h6></Link></div>

            <div className="row" >
                <div className="col-md-12">
                     <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                    
                    <form className="card" onSubmit={submitHandler}>
                        
                        <div className="col-md-12">
                            <br />
                            <h4 className="margin-bottom-1x">Product Info</h4>
                            <center>{loadingUpdate && <Loader/>}</center>
                            {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
                            {loading ? <Loader/> : error ? <center><Message variant='danger'>{error}</Message></center> : 
                            <>
                            <div className="row">
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-name">Name</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-name" 
                                            value={name} 
                                            onChange={(e)=>setName(e.target.value)}
                                            />
                                </div>
                                
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-brand">Brand</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-brand" 
                                            value={brand} 
                                            onChange={(e)=>setBrand(e.target.value)}
                                            />
                                </div>
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-category">Category</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-category" 
                                            value={category} 
                                            onChange={(e)=>setCategory(e.target.value)}
                                            />
                                </div>

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-category">Weight Name</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-weight-name" 
                                            value={weight_name} 
                                            onChange={(e)=>setWeigh_name(e.target.value)}
                                            />
                                </div>
                                
                                
                            </div>

                            <div className="row">
                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-sgst">SGST</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="product-sgst" 
                                            value={SGST} 
                                            onChange={(e)=>setSGST(e.target.value)}
                                            />
                                </div>
                                
                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-cgst">CGST</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="product-cgst" 
                                            value={CGST} 
                                            onChange={(e)=>setCGST(e.target.value)}
                                            />
                                </div>
                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-igst">IGST</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="product-igst" 
                                            value={IGST} 
                                            onChange={(e)=>setIGST(e.target.value)}
                                            />
                                </div>

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-mfg">Manufacturing State</label>
                                    <select className="form-control" 
                                        as="select"
                                        value = {mfgStateCode}
                                        onChange = {(e) => {
                                            setMfgStateCode(e.target.value)   
                                        }
                                        }
                                            id="product-mfg">
                                        
                                            <option value="DL">
                                                Delhi
                                            </option> 
                                            <option value="BH">
                                                Bihar
                                            </option>
                                       

                                        </select>
                                    
                                </div>

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-hsn">HSN Code</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-hsn" 
                                            value={hsnCode} 
                                            onChange={(e)=>setHsnCode(e.target.value)}
                                            />
                                </div>
                                
                                
                            </div>

                            <div className="row">
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-weight-1">{weight_name ? weight_name : 'Weight'} 1</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-weight-1" 
                                            value={weight_1 || ''} 
                                            onChange={(e)=>setWeight_1(e.target.value)}
                                            />
                                </div>
                                
                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="rupee-price-1">Rupee(₹) Price 1</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="rupee-price-1" 
                                            value={rupee_price_1} 
                                            onChange={(e)=>setRupee_price_1(e.target.value  || 0)}
                                            />
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="dollar-price-1">Dollar($) Price 1</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="dollar-price-1" 
                                            value={dollar_price_1} 
                                            onChange={(e)=>setDollar_price_1(e.target.value  || 0)}
                                            />
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="countinstock-1">Count in Stock 1</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="countinstock-1" 
                                            value={countInStock_1} 
                                            onChange={(e)=>setCountInStock_1(e.target.value  || 0)}
                                            />
                                </div>

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="weightingm-1">Weight in gm 1</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="weightingm-1" 
                                            value={weightInGm_1} 
                                            onChange={(e)=>setWeightInGm_1(e.target.value  || 0)}
                                            />
                                </div>
                                
                                
                                
                            </div>

                            <div className="row">
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-weight-2">{weight_name ? weight_name : 'Weight'} 2</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-weight-2" 
                                            value={weight_2 || ''} 
                                            onChange={(e)=>setWeight_2(e.target.value)}
                                            />
                                </div>
                                
                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="rupee-price-2">Rupee(₹) Price 2</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="rupee-price-2" 
                                            value={rupee_price_2} 
                                            onChange={(e)=>setRupee_price_2(e.target.value || 0)}
                                            />
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="dollar-price-2">Dollar($) Price 2</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="dollar-price-2" 
                                            value={dollar_price_2} 
                                            onChange={(e)=>setDollar_price_2(e.target.value || 0)}
                                            />
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="countinstock-2">Count in Stock 2</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="countinstock-2" 
                                            value={countInStock_2} 
                                            onChange={(e)=>setCountInStock_2(e.target.value || 0)}
                                            />
                                </div>

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="weightingm-2">Weight in gm 2</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="weightingm-2" 
                                            value={weightInGm_2} 
                                            onChange={(e)=>setWeightInGm_2(e.target.value  || 0)}
                                            />
                                </div>
                                
                                
                            </div>

                            <div className="row">
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-weight-3">{weight_name ? weight_name : 'Weight'} 3</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-weight-3" 
                                            value={weight_3 || ''} 
                                            onChange={(e)=>setWeight_3(e.target.value)}
                                            />
                                </div>
                                
                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="rupee-price-3">Rupee(₹) Price 3</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="rupee-price-3" 
                                            value={rupee_price_3} 
                                            onChange={(e)=>setRupee_price_3(e.target.value  || 0)}
                                            />
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="dollar-price-3">Dollar($) Price 3</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="dollar-price-3" 
                                            value={dollar_price_3} 
                                            onChange={(e)=>setDollar_price_3(e.target.value  || 0)}
                                            />
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="countinstock-3">Count in Stock 3</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="countinstock-3" 
                                            value={countInStock_3} 
                                            onChange={(e)=>setCountInStock_3(e.target.value  || 0)}
                                            />
                                </div>

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="weightingm-3">Weight in gm 3</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="weightingm-3" 
                                            value={weightInGm_3} 
                                            onChange={(e)=>setWeightInGm_3(e.target.value  || 0)}
                                            />
                                </div>
                                
                                
                            </div>

                            <div className="row">
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-weight-4">{weight_name ? weight_name : 'Weight'} 4</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-weight-4" 
                                            value={weight_4 || ''} 
                                            onChange={(e)=>setWeight_4(e.target.value)}
                                            />
                                </div>
                                
                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="rupee-price-4">Rupee(₹) Price 4</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="rupee-price-4" 
                                            value={rupee_price_4} 
                                            onChange={(e)=>setRupee_price_4(e.target.value  || 0)}
                                            />
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="dollar-price-4">Dollar($) Price 4</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="dollar-price-4" 
                                            value={dollar_price_4} 
                                            onChange={(e)=>setDollar_price_4(e.target.value  || 0)}
                                            />
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="countinstock-4">Count in Stock 4</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="countinstock-4" 
                                            value={countInStock_4} 
                                            onChange={(e)=>setCountInStock_4(e.target.value  || 0)}
                                            />
                                </div>

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="weightingm-4">Weight in gm 4</label>
                                    <input className="form-control" 
                                            type="number" 
                                            id="weightingm-4" 
                                            value={weightInGm_4} 
                                            onChange={(e)=>setWeightInGm_4(e.target.value  || 0)}
                                            />
                                </div>
                                
                                
                            </div>

                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-description">Description</label>
                                    <textarea className="form-control"
                                            name="paragraph_text" 
                                            id="product-description" 
                                            value={description} 
                                            onChange={(e)=>setDescription(e.target.value)}
                                            rows="10"></textarea>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-image-1">Image 1</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-image-1" 
                                            value={image_1} 
                                            onChange={(e)=>setImage_1(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image1-file'
                                        custom
                                        onChange={uploadFileHandler1}
                                        />
                                    {uploading1 && <Loader/>}
                                </div>
                                
                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-image-2">Image 2</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-image-2" 
                                            value={image_2} 
                                            onChange={(e)=>setImage_2(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image2-file'
                                        custom
                                        onChange={uploadFileHandler2}
                                        />
                                    {uploading2 && <Loader/>}
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-image-3">Image 3</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-image-3" 
                                            value={image_3} 
                                            onChange={(e)=>setImage_3(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image3-file'
                                        custom
                                        onChange={uploadFileHandler3}
                                        />
                                    {uploading3 && <Loader/>}
                                </div>

                                <div className="form-group col-md-2">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-image-4">Image 4</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-image-4" 
                                            value={image_4} 
                                            onChange={(e)=>setImage_4(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image4-file'
                                        custom
                                        onChange={uploadFileHandler4}
                                        />
                                    {uploading4 && <Loader/>}
                                </div>

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="product-image-5">Image 5</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="product-image-5" 
                                            value={image_5} 
                                            onChange={(e)=>setImage_5(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image5-file'
                                        custom
                                        onChange={uploadFileHandler5}
                                        />
                                    {uploading5 && <Loader/>}
                                </div>
                                
                                
                            </div>

                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                                
                                <button className="btn btn-primary margin-right-none" 
                                        type="submit" 
                                        data-toast data-toast-position="topRight" 
                                        data-toast-type="success" 
                                        data-toast-icon="icon-check-circle" 
                                        data-toast-title="Success!" 
                                        data-toast-message="Your profile updated successfuly.">
                                            Update 
                                </button>
                            </div>
                            </>
                            }
                            <br />
                        </div>
                    </form>
                    
                {/* <aside className="user-info-wrapper">
                    <div className="user-cover" style={{backgroundImage: "url(/assets/img/account/user-cover-img.jpg)"}}>
                    <div className="info-label" data-toggle="tooltip" title="You currently have 290 Reward Points to spend"><i className="icon-award"></i>290 points</div>
                    </div>
                    <div className="user-info">
                    <div className="user-avatar"><Link className="edit-avatar" to="#"></Link><img src="/images/img/account/user-ava.jpg" alt="User" /></div>
                    <div className="user-data">
                        <h4 className="h5">Daniel Adams</h4><span>Joined February 06, 2017</span>
                    </div>
                    </div>
                </aside>
                <nav className="list-group"><Link className="list-group-item with-badge" to="account-orders.html"><i className="icon-shopping-bag"></i>Orders<span className="badge badge-default badge-pill">6</span></Link><Link className="list-group-item active" to="account-profile.html"><i className="icon-user"></i>Profile</Link><Link className="list-group-item" to="account-address.html"><i className="icon-map-pin"></i>Addresses</Link><Link className="list-group-item with-badge" to="account-wishlist.html"><i className="icon-heart"></i>Wishlist<span className="badge badge-default badge-pill">3</span></Link><Link className="list-group-item with-badge" to="account-tickets.html"><i className="icon-tag"></i>My Tickets<span className="badge badge-default badge-pill">4</span></Link></nav> */}
                </div>


                
            </div>

            </div>
            
        </div>
    )
}

export default ProductEditScreen
