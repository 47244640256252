import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";
import Message from "../../components/Message";
import Loader from "../../components/Loader";

import {
  getAppointmentDetails,
  payAppointment,
  attainAppointment,
  cancelAppointment,
  updateDoctorAppointment,
  razorpayCheckoutAppointment,
  sendEmailAppointment,
} from "../../actions/appointmentActions";
import { listDoctors } from '../../actions/doctorActions'

import {
  APPOINTMENT_PAY_RESET,
  APPOINTMENT_ATTAIN_RESET,
  APPOINTMENT_CANCEL_RESET,
  APPOINTMENT_UPDATE_DOCTOR_RESET,
  APPOINTMENT_RZP_CHECKOUT_RESET,
  APPOINTMENT_EMAIL_SEND_RESET,
} from "../../constants/appointmentConstants";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;

    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}


function AppointmentScreen({ match, history, location }) {
  const appointmentRazorpayCheckout = useSelector((state) => state.appointmentRazorpayCheckout);
  const {error: errorRazorpayCheckout, success: successRazorpayCheckout, razorpayData } = appointmentRazorpayCheckout;

  const appointmentId = match.params.id;

  const appointmentDetails = useSelector((state) => state.appointmentDetails);
  const { appointment, error, loading } = appointmentDetails;

  const appointmentPay = useSelector((state) => state.appointmentPay);
  const { loading: loadingPay, success: successPay } = appointmentPay;

  const appointmentAttain = useSelector((state) => state.appointmentAttain);
  const { loading: loadingAttain, success: successAttain, error:errorAttain } = appointmentAttain;

  const appointmentCancel = useSelector((state) => state.appointmentCancel);
  const { loading: loadingCancel, success: successCancel, error:errorCancel } = appointmentCancel;
  
  const appointmentUpdateDoctor = useSelector((state) => state.appointmentUpdateDoctor);
  const { loading: loadingUpdateDoctor, success: successUpdateDoctor, error:errorUpdateDoctor } = appointmentUpdateDoctor;
  
  const doctorList = useSelector(state => state.doctorList)
  const { loading:loadingDoctor , error:errorDoctor, doctors} = doctorList
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const [sdkReady, setSdkReady] = useState(false);
  const [showResponse, setShowResponse] = useState(false);

  const [processing, setProcessing] = useState(false);

  const [doctorSlug, setDoctorSlug] = useState('')
  const [doctorName, setDoctorName] = useState('')
  const [updateDoctor, setUpdateDoctor] = useState(false)

  async function addPayPalScript() {
    const res = await loadScript(
      "https://www.paypal.com/sdk/js?client-id=AUKeFcRNOHAZVyeJwUPwBFWRgEE0jNBbFFTRESKdBf3xS3Uov6qIoO6Hpfry9fDaAK4hMGLPoRceVPO7"
    );

    if (!res) {
      alert("Razorpay sdk fail to load");
      return;
    } else {
      setSdkReady(true);
    }
  }

  async function displayRazorPay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay sdk fail to load");
      return;
    }
    // console.log('backend rzp id:',razorpayData['id'])
    var rzp_key = "rzp_live_nW1gGq5gOOHZlg"
    if(window.location.hostname==='localhost' || window.location.hostname==='127.0.0.1'){
      rzp_key = "rzp_test_SGLAfrxKFdEhc1"
    }
    const options = {
      key: rzp_key,
      amount: razorpayData["amount"],
      currency: razorpayData["currency"],
      name: "Etechan Nutri Health",
      description: "Etechan Nutri Health & wellness World",
      image: "/images/logo.png",
      order_id: razorpayData["id"], //Replace with the order_id generated by you in the backend.
      handler: function (response) {
        // console.log('razorpay response',response)
        // alert(response.razorpay_payment_id);
        dispatch(payAppointment(appointmentId,response))
        setProcessing(true);
        setTimeout(() => {
          dispatch(sendEmailAppointment(appointment.slug, "Booked"));
          dispatch(getAppointmentDetails(appointmentId));
          setProcessing(false);
        }, 1000);

        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },

      prefill: {
        name:
          appointment.patientDetails.name +
          " " +
          appointment.patientDetails.lastName,
        email: appointment.patientDetails.email,
        contact: appointment.patientDetails.phone,
      },

      theme: {
        color: "#528FF0",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  // const [rzp,setRzp] = useState(false)
  const [successNotification, setSuccessNotification] = useState(false)
  
  useEffect(() => {
    if(successPay){
      setSuccessNotification(true)
    }
    if (!userInfo) {
      history.push(`/login?redirect=${location.pathname}`)
    }
    if (
      !appointment ||
      successPay ||
      appointment.slug !== appointmentId ||
      successAttain ||
      successCancel ||
      successUpdateDoctor
    ) {
      dispatch({ type: APPOINTMENT_PAY_RESET });
      dispatch({ type: APPOINTMENT_ATTAIN_RESET });
      dispatch({ type: APPOINTMENT_CANCEL_RESET });
      dispatch({ type: APPOINTMENT_UPDATE_DOCTOR_RESET });
      dispatch({ type: APPOINTMENT_EMAIL_SEND_RESET });
      dispatch({ type: APPOINTMENT_RZP_CHECKOUT_RESET });
      dispatch(getAppointmentDetails(appointmentId));
      

    } else if (!appointment.isPaid) {
        if (appointment.paymentMethod === "PayPal" && !window.paypal) {
          addPayPalScript();
        } else {
          setSdkReady(true);
        }

        if (!successRazorpayCheckout && appointment.paymentMethod === "Razorpay") {
          dispatch(razorpayCheckoutAppointment(appointmentId));
        }
    }
    dispatch(listDoctors());
  }, [
    appointment,
    appointmentId,
    dispatch,
    successPay,
    successAttain,
    successCancel,
    successUpdateDoctor,
    history,
    userInfo,
    successRazorpayCheckout,
    razorpayData,
    location.pathname
  ]);

  const successPaymentHandler = (paymentResult) => {
    if (paymentResult["status"] === "COMPLETED") {
      dispatch(payAppointment(appointmentId, paymentResult));
      dispatch(sendEmailAppointment(appointment.slug, "Booked"));
    }
  };

  const attainedHandler = () => {
    if (window.confirm('Update Appointment as Attained?')) {
      dispatch(attainAppointment(appointment));
      dispatch(sendEmailAppointment(appointment.slug, "Attained"));
    }
  };

  const doctorHandler = (slug)=>{         
    setDoctorSlug(slug)
    var result = doctors.filter(doctor => {
        return doctor.slug === slug
      })
      setDoctorName(result.name)
  }



  const submitUpdateDoctor = (e) => {
    e.preventDefault()
    if (window.confirm(`Update Expert to - ${doctorSlug} ?`)) {
      dispatch(updateDoctorAppointment(appointment,doctorSlug));
    }
    setUpdateDoctor(false)
  }

  const cancelUpdateDoctorHandler = (e)=>{ 
    e.preventDefault()   
    setUpdateDoctor(false)             
    setDoctorSlug('')
    setDoctorName('')     
    dispatch({ type: APPOINTMENT_UPDATE_DOCTOR_RESET })
}

  
  const cancelHandler = () => {
    if (window.confirm('Cancel Appointment?')) {
      dispatch(cancelAppointment(appointment));
      dispatch(sendEmailAppointment(appointment.slug, "Cancelled"));
    }
  };


  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div>
      {/* <!-- Page Title--> */}
      <div className="page-title">
        <div className="container">
          <div className="column">
            <h1>{appointment.isPaid ? "My Appointment" : "Checkout"}</h1>
          </div>
          <div className="column">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="separator">&nbsp;</li>
              <li>{appointment.isPaid ? "My Appointment" : "Checkout"}</li>
            </ul>
          </div>
        </div>
      </div>

      {/* <!-- Page Content--> */}
      <div className="container padding-bottom-3x mb-2">
        <div className="row">
          {/* <!-- Checkout Adress--> */}
          <div className="col-xl-8 col-lg-8">
         
          {!appointment.isPaid && (
              <div className= "steps flex-sm-nowrap mb-5">
              <Link className= "step " to= "#">
                  <h4 className= "step-title"> <i className= "icon-check-circle" style={{color:"#007bff"}}></i> 1. Schedule</h4>
              </Link> 

              
              <Link className= "step " to= "#">
                  <h4 className= "step-title"><i className= "icon-check-circle" style={{color:"#007bff"}}></i> 2. Patient Details</h4>
              </Link> 
              <Link className= "step " to= "#">
                  <h4 className= "step-title"><i className= "icon-check-circle" style={{color:"#007bff"}}></i> 3. Confirm Appointment</h4>
              </Link> 
              
              <Link className= "step active" to= "#">
                  <h4 className= "step-title">3. Pay</h4>
              </Link> 
          </div>
            
          )}
            {successNotification &&
            <div className="alert alert-success alert-dismissible fade show text-center margin-bottom-1x">
              <span className="alert-close" data-dismiss="alert"></span>
              <i className="icon-check-circle"></i>&nbsp;&nbsp;<span className="text-medium">Success:</span>
               Your Appointment is booked successfully.
            </div>
            
            }
            
            <h5>Appointment Details</h5>
            <div className="table-responsive shopping-cart">
              <table className="table">
                <thead>
                <tr>
                    <th>Service</th>
                    <th>Time Slot</th>
                    <th>Expert</th>
                    {!appointment.isPaid ?
                      <th>Consulation Fee</th> :                   
                      <th>Status</th>  
                    }                  
                    </tr>
                </thead>
                <tbody>
                  
                    <>
                      
                        <tr >

                        <td>
                            <div className= "product-item " style={{maxWidth:"10%"}}>
                                <Link to={`/services/${appointment.service.slug}`} 
                                      style={{textDecoration:"none", color:"#343a40"}} 
                                      className= "text-center" >
                                          <div className="text-left">
                                          <img src={appointment.service.image_1} 
                                                width="100px"
                                                
                                                alt={appointment.service.name} />
                                          </div>
                                          
                                      <div className="text-left text-lg">{appointment.service.name}</div>
                                </Link>
                            
                            </div>
                        </td>
                          
                          <td className= "text-center text-md">
                              <div style={{textAlign:"left"}}>
                              {appointment.appointmentSchedule.startTime + " - " + appointment.appointmentSchedule.endTime}<br/>
                              {appointment.appointmentSchedule.date}
                              </div>
                          </td>

                          <td className= "text-center text-md">
                            <div style={{textAlign:"left"}}>
                            {appointment.appointmentSchedule.doctor ? appointment.appointmentSchedule.doctor.name : '-'}
                            </div>
                        </td>

                        <td className= "text-center text-md">
                            <div style={{textAlign:"left"}}>
                            {!appointment.isPaid ?
                              <>{appointment.currency === 'INR' ? '₹': '$'}{appointment.fee}</> :                   
                              appointment.status === "Attained" ||  appointment.status === "Booked" ? 
                              <span style={{color:"green"}}>{appointment.status}</span> : appointment.status === "Cancelled" ?
                              <span style={{color:"red"}}>{appointment.status}</span> :
                              appointment.status
                            } 
                            
                            </div>
                        </td>
                        </tr>
                      
                    </>
                
                </tbody>
              </table>
            </div>
            <div className="shopping-cart-footer">
              <div className="column"></div>
             
            </div>

            {appointment.rzpResponse && userInfo.isAdmin && (
              <>
                <hr />
                <div className="col-xl-12 col-lg-12 mt-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      onChange={(e) => setShowResponse(!showResponse)}
                      id="appointment_response"
                      checked={showResponse === true}
                    />

                    <label
                      className="custom-control-label"
                      htmlFor="appointment_response"
                    >
                      Razorpay Response
                    </label>
                  </div>
                  {showResponse && <p>{appointment.rzpResponse}</p>}
                </div>
              </>
            )}
          </div>
          {/* <!-- Sidebar --> */}

          <div className="col-xl-4 col-lg-4">
            <aside className="sidebar">
              <div className="padding-top-2x hidden-lg-up"></div>
              <div style={{ marginTop: "0px" }}>
                <section
                  style={{ padding: "20px 15px 0px 15px" }}
                  className="widget widget-order-summary card"
                >
                  <h3 className="widget-title" style={{ marginBottom: "5px" }}>
                    Patient Details
                  </h3>
                  <div>
                    
                    {appointment.patientDetails &&
                    <ul  className="list-unstyled">
                    <li>
                      <span className="text-muted">
                        <strong>Name:&nbsp; </strong>
                      </span>
                      {appointment.patientDetails.name}{" "}
                      
                    </li>
                    <li>
                      <strong className="text-muted">Address:&nbsp; </strong>
                      {appointment.patientDetails.address1}
                      {appointment.patientDetails.address2
                        ? ", " + appointment.patientDetails.address2
                        : null}
                      , {appointment.patientDetails.city},{" "}
                      {appointment.patientDetails.state}-
                      {appointment.patientDetails.postalCode},{" "}
                      {appointment.patientDetails.country}
                    </li>
                    <li>
                      <strong className="text-muted">Email:&nbsp; </strong>
                      
                        {appointment.patientDetails.email}
                      
                    </li>
                    <li>
                      <strong className="text-muted">Phone:&nbsp; </strong>
                      {appointment.patientDetails.phone}
                    </li>
                  </ul>

                    }
                  </div>                  
                  <h3
                          style={{ marginBottom: "5px" }}
                          className="widget-title"
                        >
                          Payment Details:
                        </h3>
                        <table width="100%">
                            <tbody style={{lineHeight:"27px"}}>
                            <tr >
                                <td style={{width:"50%",textAlign:"left"}}>
                                <strong className="text-muted">{!appointment.isPaid ? "Total Payable" : "Fee Paid"}</strong>
                                </td>
                                <td style={{width:"50%",textAlign:"right"}}>
                                {appointment.currency === "INR" ? "₹" : "$"}
                                {appointment.fee}
                                </td>
                            </tr>
                            {appointment.CGST > 0 && !appointment.isPaid &&
                            <tr>
                                <td style={{width:"50%",textAlign:"left"}}>
                                <strong className="text-muted">CGST (Included)</strong>
                                </td>
                                <td style={{width:"50%",textAlign:"right"}}>
                                {appointment.currency === "INR" ? "₹" : "$"}
                                {appointment.CGST}
                                </td>
                            </tr>
                            }
                            
                            {appointment.SGST > 0 &&  !appointment.isPaid &&
                            <tr>
                                <td style={{width:"50%",textAlign:"left"}}>
                                <strong className="text-muted">SGST (Included)</strong>
                                </td>
                                <td style={{width:"50%",textAlign:"right"}}>
                                {appointment.currency === "INR" ? "₹" : "$"}
                                {appointment.SGST}
                                </td>
                            </tr>
                            }
                            
                            {appointment.IGST > 0 && !appointment.isPaid && 
                            <tr>
                                <td style={{width:"50%",textAlign:"left"}}>
                                <strong className="text-muted">IGST (Included)</strong>
                                </td>
                                <td style={{width:"50%",textAlign:"right"}}>
                                {appointment.currency === "INR" ? "₹" : "$"}
                                {appointment.IGST}
                                </td>
                            </tr>
                            }
                            
                            {appointment.isPaid && 
                            <tr>
                                <td style={{width:"50%",textAlign:"left"}}>
                                <strong className="text-muted">Payment Method</strong>
                                </td>
                                <td style={{width:"50%",textAlign:"right"}}>
                                {appointment.paymentMethod}
                                </td>
                            </tr>
                            }
                            
                            {appointment.isPaid && 
                            <tr>
                                <td style={{width:"50%",textAlign:"left"}}>
                                <strong className="text-muted">Booked on</strong>
                                </td>
                                <td style={{width:"50%",textAlign:"right"}}>
                                {appointment.paidAt.substring(0, 10)}
                                </td>
                            </tr>
                            }
                            </tbody>
                        </table>
                    
                  
                  {!appointment.isPaid &&
                    appointment.paymentMethod === "PayPal" &&
                    appointment.status === "Initiated" && (
                      <div>
                        {loadingPay && <Loader />}
                        {/* <PayPalButton 
                                amount={appointment.totalPrice}
                                onSuccess = {successPaymentHandler}
                            /> */}

                        {!sdkReady ? (
                          <Loader />
                        ) : (
                          <PayPalButton
                            amount={appointment.totalPrice}
                            onSuccess={successPaymentHandler}
                          />
                        )}
                      </div>
                    )}

                  {errorRazorpayCheckout && (
                    <Message variant="danger">{errorRazorpayCheckout}</Message>
                  )}
                  {!appointment.isPaid &&
                    appointment.paymentMethod === "Razorpay" &&
                    successRazorpayCheckout && 
                    (
                      <div>
                        <button
                          type="button"
                          onClick={displayRazorPay}
                          className="btn btn-primary btn-block"
                         
                        >
                          {processing ? (
                            <>
                              <span
                                className="spinner-grow spinner-grow-sm text-white mr-2"
                                role="status"
                              ></span>
                              Processing..
                            </>
                          ) : (
                            "Pay Now"
                          )}
                        </button>
                        
                      </div>
                    )}
                        
                  
                  {appointment.isPaid && (
                    <>
                      
                      <div>
                        {/* {userInfo &&
                          appointment.isPaid &&
                          userInfo.isAdmin &&                          
                          (
                            <div>
                              <Link
                                className="btn btn-primary btn-block"
                                to={`/invoice/${appointment.slug}/`}
                              >
                                Invoice
                              </Link>
                            </div>
                          )} */}

                    {loadingAttain && <Loader />}
                    {loadingDoctor && <Loader />}
                    {errorAttain && <Message variant="danger">{errorAttain}</Message>}
                    {errorDoctor && <Message variant="danger">{errorDoctor}</Message>}
                    {userInfo &&
                      userInfo.isAdmin &&
                      appointment.isPaid &&
                      !appointment.isAttained && (
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-block"
                            onClick={attainedHandler}
                          >
                            Mark as Attained
                          </button>
                        </div>
                      )}

                    {loadingCancel && <Loader />}
                    {errorCancel && <Message variant="danger">{errorCancel}</Message>}
                    {userInfo &&
                      userInfo.isAdmin &&
                      appointment.isPaid &&
                      !appointment.isAttained &&
                      !appointment.isCancelled && (
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-block"
                            onClick={cancelHandler}
                          >
                            Mark as Cancelled
                          </button>
                        </div>
                      )}

                      <div>
                        {loadingUpdateDoctor && <Loader />}
                        {errorUpdateDoctor && <Message variant="danger">{errorUpdateDoctor}</Message>}
                        {userInfo &&
                          userInfo.isAdmin &&
                          appointment.isPaid &&
                          <>
                            <div className="">
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-block"
                                onClick={()=>setUpdateDoctor(!updateDoctor)}
                              >
                                Change Appointment Doctor
                              </button>
                            </div>

                              {updateDoctor &&  

                                <form className="card" onSubmit={submitUpdateDoctor}>
                                        
                                        <div className="col-md-12">
                                          <br/>
                                            <select
                                                className="form-control"
                                                as="select"
                                                value={doctorSlug}
                                                onChange={(e)=>doctorHandler(e.target.value)}
                                                id="expert"
                                            >
                                                <option key={'select_doctor'} value="selectDoctor">
                                                            Select Doctor 
                                                </option>
                                                {doctors && doctors.map(
                                                    (doctor) =>                                                
                                                        <option key={doctor.slug} value={doctor.slug}>
                                                            {doctor.name}
                                                        </option>                                                
                                                    )
                                                }
                                            </select> 

                                            <div className="row">
                                            <div className="form-group col-md-12">
                                                <button style={{marginTop:"28px"}} className="btn btn-primary margin-right-none" 
                                                        type="submit" 
                                                    >
                                                            Update Doctor 
                                                </button>
                                                <button style={{marginTop:"28px"}} className="btn btn-primary btn-danger margin-right-none" 
                                                        type="button" 
                                                    onClick={cancelUpdateDoctorHandler}
                                                    >
                                                            Cancel 
                                                </button>
                                                </div>
                                                </div>
                                           
                                            
                                            <br />
                                        </div>
                                    </form>
                                    
                              }
                               
                          </>      
                          }
                      </div>
                      </div>
                    </>
                  )}
                </section>
              </div>
              
            </aside>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default AppointmentScreen;
