import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { verifyPhone } from '../actions/userActions'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

// import axios from 'axios'

function VerifyPhoneScreen({location,history}) {

    
    const countryCode = 'IN'
    const [phoneCode,setPhoneCode] = useState('+91')
    const [phone, setPhone] = useState(phoneCode)

    const userVerifyPhone = useSelector(state => state.userVerifyPhone)
    const {error:errorVerifyPhone, loading:loadingVerifyPhone, otpInfo:otpInfoPhone } = userVerifyPhone


    const dispatch = useDispatch()

    useEffect(() => {
    
        if( (otpInfoPhone && otpInfoPhone.phone)){
            history.push('/verify-phone-otp')
        }

    },[history,otpInfoPhone])

    const  handleOnChange =(value, data)=> {
        setPhone(value.replace(/[-+()\s]/g, ''))
        setPhoneCode(data['dialCode'])
      }

    const submitHandler = (e) => {
        e.preventDefault()
        const phoneWithoutCode = phone.replace(phoneCode, "")
        if(!phoneWithoutCode ){
            alert('Please Enter your Phone number.')
        }else{
            dispatch(verifyPhone(phone))
        }
        
        
    }
    return (
        <div>
            
            
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2 mt-5" style={{minHeight: "80vh"}}>
            <div className="row" style={{justifyContent: "center"}}>
                
                <div className="col-lg-5 col-md-7 col-sm-10">
                                
                <div className="padding-top-3x hidden-md-up"></div>
               <form className="card" method="post"  onSubmit={submitHandler}>
               <div className="card-body">
                    <h4 className="margin-bottom-1x">Phone Verification</h4>

                        <div className="form-group input-group" >
                        
                        <label style={{paddingLeft:'0px'}} >Phone </label>

                        <PhoneInput 
                            containerClass="form-control"
                            inputStyle={{width:"105%", height:"100%",paddingLeft:"50px"}}
                            containerStyle={{paddingLeft:"0",width:"100%",border:"none"}}
                            buttonStyle={{paddingRight:"0px"}}
                            countryCodeEditable ={false}
                            enableSearch={true}
                            
                            country={countryCode}
                            value={phone}
                            onChange={handleOnChange}
                        />
                                
                       
                    </div>

                    </div>
                    <div style={{marginTop: '-30px'}} className="col-12 text-center text-sm-center">

                    {errorVerifyPhone && <Message variant='danger'>{errorVerifyPhone}</Message>}                    
                    {loadingVerifyPhone && <Loader />}

                    <button className="btn btn-primary btn-block mb-4" type="submit">Verify</button>
                    </div>
                    <div className="col-12 text-center text-sm-center mb-3">
                    Back to &nbsp;
                    <span><Link to="/profile"
                    >Profile</Link></span></div>
                    <br />
                    
                </form>
                </div>
            </div>
            </div>
        </div>
    )
}

export default VerifyPhoneScreen
