import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  useEffect(() => {
    // console.log('history1:',history.location.pathname.slice(0,8) )
    // history.location.pathname === '/' || history.location.pathname === '/contact-us' || history.location.pathname === '/our-products' ?

    const unlisten = history.listen(() => {
       history.location.pathname.slice(0,8) === '/product' ?
      window.scrollTo(0,220) :
      // history.location.pathname.slice(0,13) === '/our-services' && history.location.pathname.slice(14,22) === 'diseases' ?
      // window.scrollTo(0,1320) :
      // history.location.pathname.slice(0,13) === '/our-services' && history.location.pathname.slice(14,25) === 'how-we-work' ?
      // window.scrollTo(0,800) :
      // history.location.pathname.slice(0,13) === '/our-services' && history.location.pathname.slice(14,30) === 'meet-our-doctors' ?
      // window.scrollTo(0,6000) :
      window.scrollTo(0,0)
    });
    return () => {
      unlisten();
    }
  }, [history]);

  return (null);
}

export default withRouter(ScrollToTop);