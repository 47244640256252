import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Product from '../components/Product'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Paginate from '../components/Paginate'
import ProductCarousel from '../components/ProductCarousel'

import { listProducts } from '../actions/productActions'

import "../index.css"

function HomeScreen(history) {

    const dispatch = useDispatch()
    const productList = useSelector(state => state.productList)
    const {error, loading, products, page, pages} = productList

    const currencyValue = useSelector(state => state.currency)
    const {currency} = currencyValue
    
    let keyword = history.location.search
    
    useEffect(()=> {
        dispatch(listProducts(keyword))
    }, [dispatch,keyword])
    
    return (
        <div >
            
            <section  id="banner-section">
            {!keyword && <ProductCarousel images={[{"image":"/images/slider1.jpg"},
                                                   {"image":"/images/slider2.jpg"},
                                                   {"image": "/images/slider3.jpg"},
                                                   {"image": "/images/slider4.jpg"},
                                                   {"image": "/images/slider5.jpg"},
                                                //    {"image": "/images/slider6.jpg"},
                                                   {"image": "/images/slider7.jpg",link:"https://aicrmr.etechanhealthtech.com/"},                                                 
                                                   {"image": "/images/slider8.jpg"},
                                                ]} 
                                                   hasLink ={true}
                                                   clickable={true}
                                                   
                                                   />}            
            </section>
            <br />
            
            <section className="container padding-bottom-2x mb-2" >
            <h2 className="h3 pb-3 text-center">{keyword && keyword.split("=")[1].split("&")[0] !== "" ? "Search Result" : "Featured Products"}</h2>
            { loading ? <> <Loader /></>
            //
                : error ? <Message variant='danger'>{error}</Message>
                : products.length === 0 ?
                <center>
                <Message variant="warning">No Product found with this keyword!</Message>
                </center>
                :
            <>
            <div className="row">

                {products.map(product => (

                        <div className="col-lg-3 col-md-4 col-sm-6" key={product._id}>
                            
                            <Product product={product} currency={currency}/>
                            
                        </div>

                ))}

            </div>
            <Paginate page={page} pages={pages} keyword={keyword} />

            </>
            }
            <div className="text-center"><Link className="btn btn-outline-secondary" to="/our-products">View All Products</Link></div>
            </section>
            
            
            
           
            {/* <section className="container padding-top-3x padding-bottom-2x">
            <div className="row">
                <div className="col-md-3 col-sm-6 text-center mb-30"><img className="d-block w-90 img-thumbnail rounded mx-auto mb-4" src="/img/services/01.png" alt="Shipping" />
                <h6 className="mb-2">Free Worldwide Shipping</h6>
                <p className="text-sm text-muted mb-0">Free shipping for all orders over $100</p>
                </div>
                <div className="col-md-3 col-sm-6 text-center mb-30"><img className="d-block w-90 img-thumbnail rounded mx-auto mb-4" src="/img/services/02.png" alt="Money Back" />
                <h6 className="mb-2">Money Back Guarantee</h6>
                <p className="text-sm text-muted mb-0">We return money within 30 days</p>
                </div>
                <div className="col-md-3 col-sm-6 text-center mb-30"><img className="d-block w-90 img-thumbnail rounded mx-auto mb-4" src="/img/services/03.png" alt="Support" />
                <h6 className="mb-2">24/7 Customer Support</h6>
                <p className="text-sm text-muted mb-0">Friendly 24/7 customer support</p>
                </div>
                <div className="col-md-3 col-sm-6 text-center mb-30"><img className="d-block w-90 img-thumbnail rounded mx-auto mb-4" src="/img/services/04.png" alt="Payment" />
                <h6 className="mb-2">Secure Online Payment</h6>
                <p className="text-sm text-muted mb-0">We posess SSL / Secure Certificate</p>
                </div>
            </div>
            </section> */}
            
        </div>
        
    )
}

export default HomeScreen
