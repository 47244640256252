import axios from 'axios'
import { CART_ADD_ITEM, 
         CART_REMOVE_ITEM, 
         CART_REMOVE_ALL_ITEMS,
         CART_SAVE_SHIPPING_ADDRESS,

         CART_SAVE_PAYMENT_METHOD
        } from '../constants/cartConstants'


export const addToCart = (id,weightId,qty,currency,add=true) => async (dispatch, getState) => {
    const {data} = await axios.get(`/api/products/${id}`)
    if (add){

        dispatch({
            type: CART_ADD_ITEM,
            payload: {
                product: data.slug,
                name: data.name,
                image: data.image_1,
                category: data.category,
                price: weightId === "1" && currency === 'INR' ? Number(data.rupee_price_1)
                        : weightId === "2" && currency === 'INR' ? Number(data.rupee_price_2)
                        : weightId === "3" && currency === 'INR' ? Number(data.rupee_price_3)
                        : weightId === "4" && currency === 'INR' ? Number(data.rupee_price_4)
                        
                        : weightId === "1" && currency === 'USD' ? Number(data.dollar_price_1)
                        : weightId === "2" && currency === 'USD' ? Number(data.dollar_price_2)
                        : weightId === "3" && currency === 'USD' ? Number(data.dollar_price_3)
                        : weightId === "4" && currency === 'USD' ? Number(data.dollar_price_4)
                        : null,
                weight_name: data.weight_name,
                weightId: weightId,
                weight: weightId === "1"  ? data.weight_1
                            : weightId === "2"  ? data.weight_2
                            : weightId === "3"  ? data.weight_3
                            : weightId === "4"  ? data.weight_4
                            : null,
                
                weightInGm: weightId === "1"  ? data.weightInGm_1
                : weightId === "2"  ? data.weightInGm_2
                : weightId === "3"  ? data.weightInGm_3
                : weightId === "4"  ? data.weightInGm_4
                : null,
    
                countInStock: weightId === "1"  ? data.countInStock_1
                : weightId === "2"  ? data.countInStock_2
                : weightId === "3"  ? data.countInStock_3
                : weightId === "4"  ? data.countInStock_4
                : null,
    
                currency: currency,

                // The below two set of info are additional in case needed in the processing/assessment
                weight_1: data.weight_1,
                weight_2: data.weight_2,
                weight_3: data.weight_3,
                weight_4: data.weight_4,
    
                countInStock_1: data.countInStock_1,
                countInStock_2: data.countInStock_2,
                countInStock_3: data.countInStock_3,
                countInStock_4: data.countInStock_4,
    
                qty
    
            }
        })
    
        localStorage.setItem('cartItems',JSON.stringify(getState().cart.cartItems))

    }
    
    
}

export const removeFromCart = (id,weightId) => (dispatch, getState) => {
    dispatch({
        type: CART_REMOVE_ITEM,
        payload: {'id':id,'weightId':weightId},
    })

    localStorage.setItem('cartItems',JSON.stringify(getState().cart.cartItems))

}

export const removeAllFromCart = () => (dispatch, getState) => {
    dispatch({
        type: CART_REMOVE_ALL_ITEMS,
    })

    localStorage.setItem('cartItems',JSON.stringify(getState().cart.cartItems))

}

export const saveShippingAddress = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_SHIPPING_ADDRESS,
        payload: data,
    })

    localStorage.setItem('shippingAddress',JSON.stringify(data))

}

export const savePaymentMethod = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_PAYMENT_METHOD,
        payload: data,
    })

    localStorage.setItem('paymentMethod',JSON.stringify(data))

}