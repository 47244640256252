import React, {useState,useEffect} from 'react'
import { Link } from 'react-router-dom'

import csc from 'country-state-city'
import { useDispatch, useSelector } from 'react-redux'
import { saveShippingAddress } from '../actions/cartActions'
import { setCurrencyinAction } from '../actions/currencyActions'
import { addToCart } from '../actions/cartActions'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

function ShippingScreen({history}) {

    const cart = useSelector(state => state.cart)
    const { shippingAddress,cartItems } = cart
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const dispatch = useDispatch()

    const userInfoFromStorage = localStorage.getItem('userInfo') ?
        JSON.parse(localStorage.getItem('userInfo')) : null

    // Shipping Address data
    const [firstName, setFirstName] = useState(shippingAddress ? shippingAddress.firstName : userInfoFromStorage ? userInfoFromStorage.name : userInfo ? userInfo.name : "")
    const [lastName, setLastName] = useState(shippingAddress ? shippingAddress.lastName : '')

    const [address1, setAddress1] = useState(shippingAddress.address1)
    const [address2, setAddress2] = useState(shippingAddress.address2)
    const [city, setCity] = useState(shippingAddress.city)
    const [state, setState] = useState(shippingAddress.state)
    const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
    const [country, setCountry] = useState(shippingAddress.country)
    const [email, setEmail] = useState(shippingAddress.email ? shippingAddress.email : userInfo ? userInfo.email : "")
    const [phoneCode,setPhoneCode] = useState(shippingAddress.phoneCode ? shippingAddress.phoneCode : '91')
    const [phone, setPhone] = useState(shippingAddress.phone)
    const optionsCountry = csc.getAllCountries()

    const [countryCode, setCountryCode] = useState(shippingAddress.countryCode)
    const [stateCode, setStateCode] = useState(shippingAddress.stateCode)

    const [optionsState, setOptionsState] = useState(csc.getStatesOfCountry(countryCode))
    const [optionsCity, setOptionsCity] = useState(csc.getCitiesOfState(countryCode, stateCode))

    // Billing Address data    

    const [firstNameBilling, setFirstNameBilling] = useState(shippingAddress.firstNameBilling ? shippingAddress.firstNameBilling : userInfoFromStorage ? userInfoFromStorage.name.split(' ')[0] : '')
    const [lastNameBilling, setLastNameBilling] = useState(shippingAddress.lastNameBilling ? shippingAddress.lastNameBilling :userInfoFromStorage ? userInfoFromStorage.name.split(' ')[1] : '')

    const [address1Billing, setAddress1Billing] = useState(shippingAddress.address1Billing)
    const [address2Billing, setAddress2Billing] = useState(shippingAddress.address2Billing)
    const [cityBilling, setCityBilling] = useState(shippingAddress.cityBilling)
    const [stateBilling, setStateBilling] = useState(shippingAddress.stateBilling)
    const [postalCodeBilling, setPostalCodeBilling] = useState(shippingAddress.postalCodeBilling)
    const [countryBilling, setCountryBilling] = useState(shippingAddress.countryBilling)
    const [emailBilling, setEmailBilling] = useState(shippingAddress.emailBilling ? shippingAddress.emailBilling : userInfo ? userInfo.email : '')
    const [phoneCodeBilling,setPhoneCodeBilling] = useState(shippingAddress.phoneCodeBilling ? shippingAddress.phoneCodeBilling : '91')
    const [phoneBilling, setPhoneBilling] = useState(shippingAddress.phoneBilling)
    
    const [countryCodeBilling, setCountryCodeBilling] = useState(shippingAddress.countryCodeBilling)
    const [stateCodeBilling, setStateCodeBilling] = useState(shippingAddress.stateCodeBilling)

    const [optionsStateBilling, setOptionsStateBilling] = useState(csc.getStatesOfCountry(countryCode))
    const [optionsCityBilling, setOptionsCityBilling] = useState(csc.getCitiesOfState(countryCodeBilling, stateCodeBilling))

    const [sameAsShipping, setSameAsShipping] = useState(true)

    
    const submitHandler = (e) => {
        e.preventDefault()
        var phoneWithoutCode = ''
        if(phone){
            phoneWithoutCode = phone.replace(phoneCode, "")
        }

        var phoneBillingWithoutCode = ''
        if(phoneBilling){
            phoneBillingWithoutCode = phoneBilling.replace(phoneCodeBilling, "")
        }

        if(!Array(csc.getCountryByCode(countryCode))[0].name){
            alert('Please Select Shipping Country.')
        }else if(Array(csc.getCountryByCode(countryCode))[0].name && csc.getStatesOfCountry(countryCode).length>0 && (!state || state === '' || state === 'Choose State...')) {
            alert('Please Select Shipping State.')
        }else if(Array(csc.getCountryByCode(countryCode))[0].name && csc.getStatesOfCountry(countryCode).length>0 
                && csc.getCitiesOfState(countryCode,stateCode).length>0 && (!city || city === '' || city === 'Choose City...')) {
            alert('Please Select Shipping City.')
        }else if(!sameAsShipping && !Array(csc.getCountryByCode(countryCodeBilling))[0].name){
            alert('Please Select Billing Country.')
        }else if(!sameAsShipping && Array(csc.getCountryByCode(countryCodeBilling))[0].name && csc.getStatesOfCountry(countryCodeBilling).length>0 && (!stateBilling || stateBilling === '' || stateBilling === 'Choose State')) {
            alert('Please Select Billing State.')
        }else if(!sameAsShipping && Array(csc.getCountryByCode(countryCodeBilling))[0].name && csc.getStatesOfCountry(countryCodeBilling).length>0 
                && csc.getCitiesOfState(countryCodeBilling,stateCodeBilling).length>0 && (!cityBilling || cityBilling === '' || cityBilling === 'Choose City...')) {
            alert('Please Select Billing City.')
        }else if(!phoneWithoutCode){
            alert('Please Enter Phone No.')
        }else if(!sameAsShipping && !phoneBillingWithoutCode){
            alert('Please Enter Billing Phone No.')
        }
        // else if(!sameAsShipping && countryCodeBilling==="IN" && phoneBilling.slice(phoneCodeBilling.length).length<10){
        //     alert('Please Enter a valid phone Number')
        // }
        else{
            !lastName && setLastName('') 
            !lastNameBilling && setLastNameBilling('')
    
            dispatch(saveShippingAddress({
                firstName,lastName,address1,address2,city,state,postalCode,country,email,phone,phoneCode,countryCode,stateCode,
                firstNameBilling,lastNameBilling, address1Billing,address2Billing,cityBilling,stateBilling,countryCodeBilling,
                stateCodeBilling,postalCodeBilling,countryBilling,emailBilling,phoneBilling,phoneCodeBilling,sameAsShipping
            }))
            if(countryCode === "IN"){
                dispatch(setCurrencyinAction('INR'))
                
                cartItems.map(item=>(
                    dispatch(addToCart(item.product,item.weightId,item.qty,'INR'))
                ))
            }else{
                dispatch(setCurrencyinAction('USD'))
                
                cartItems.map(item=>(
                    dispatch(addToCart(item.product,item.weightId,item.qty,'USD'))
                ))
            }
            history.push('/placeorder')
        }
       
    }

    useEffect(()=>{
        if (sameAsShipping){
            setFirstNameBilling(firstName)
            setLastNameBilling(lastName)
            setAddress1Billing(address1)
            setAddress2Billing(address2)
            setCityBilling(city)
            setStateBilling(state)
            setPostalCodeBilling(postalCode)
            setCountryBilling(country)
            setEmailBilling(email)
            setPhoneBilling(phone)
            setCountryCodeBilling(countryCode)
            setStateCodeBilling(stateCode)
        }
    },[sameAsShipping,firstName,lastName,address1,address2,city,
        state,postalCode,country,email,phone,countryCode,stateCode])
    // console.log('phone:',phone, phone.startsWith(phoneCode),phone.slice(phoneCode.length).length,phoneCode)
    const  handlePhone =(value, data)=> {
        setPhone(value)
        setPhoneCode(data['dialCode'])
    }
    
    const  handlePhoneBilling =(value, data)=> {
        setPhoneBilling(value)
        setPhoneCodeBilling(data['dialCode'])
    }
    return (
        <div>
            {/* <!-- Page Title--> */}
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>Checkout</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="index.html">Home</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>Checkout</li>
                </ul>
                </div>
            </div>
            </div>
            
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2">
            <div className="row" style={{justifyContent:"center"}}>
                {/* <!-- Checkout Adress--> */}
                <div className="col-xl-8 col-lg-8" >
                <div className= "steps flex-sm-nowrap mb-5">
                    <Link className= "step active" to= "#">
                        <h4 className= "step-title">1. Address</h4>
                    </Link> 
                    
                    {/* <Link className= "step " to= "#">
                        <h4 className= "step-title">2. Payment Method</h4>
                    </Link>  */}
                    
                    <Link className= "step " to= "#">
                        <h4 className= "step-title">2. Place Order</h4>
                    </Link> 
                    
                    <Link className= "step" to= "#">
                        <h4 className= "step-title">3. Pay</h4>
                    </Link> 
                </div>
                
                <h4>Shipping Address</h4>
                
                <hr className="padding-bottom-1x" />
                <form  method="post"
                        onSubmit={submitHandler}
                        >
                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">First Name</label>
                        <input className="form-control" type="text" 
                                placeholder='Enter First Name'
                                value={firstName ? firstName : ''}
                                onChange={(e)=>setFirstName(e.target.value)}
                                id="checkout-fn" required/>
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Last Name</label>
                        <input className="form-control" type="text" 
                                placeholder='Enter Last Name'
                                value={lastName ? lastName : ''}
                                onChange={(e)=>setLastName(e.target.value)}
                                id="checkout-ln" />
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Address Line 1</label>
                        <input className="form-control" type="text" 
                                placeholder='Address Line 1 (Required)'
                                value={address1 ? address1 : ''}
                                onChange={(e)=>setAddress1(e.target.value)}
                                id="checkout-add1" required/>
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Address Line 2</label>
                        <input className="form-control" type="text" 
                                placeholder='Address Line 2'
                                value={address2 ? address2 : ''}
                                onChange={(e)=>setAddress2(e.target.value)}
                                id="checkout-add2" />
                    </div>
                    </div>
                </div>

                <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                    
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Country</label>
                    <select className="form-control" 
                            onChange={(e)=>{
                                setCountry(e.target.value)
                                if (Number(e.target.selectedIndex) !== 0){
                                    setCountryCode(Array(optionsCountry)[0][Number(e.target.selectedIndex)-1].isoCode)
                                    setOptionsState(csc.getStatesOfCountry(Array(optionsCountry)[0][Number(e.target.selectedIndex)-1].isoCode))    
                                }
                                }}
                            value={country ? country : ""}
                            id="select-country" required>
                        <option key="choose-country">Choose Country...</option>
                        {
                        [...Array(optionsCountry)[0]].map((x) => (
                                            
                                            <option key={x.name} value={x.name} >
                                                {x.name}
                                            </option>
                                        ))
                        }
                        
                    </select>
                    
                    </div>
                    </div>
                    
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">State</label>
                    <select className="form-control" 
                            onChange={(e)=>{
                                setState(e.target.value)
                                
                                if(Number(e.target.selectedIndex) !== 0){
                                    setOptionsCity(csc.getCitiesOfState(countryCode, (Array(optionsState)[0][Number(e.target.selectedIndex)-1].isoCode)))
                                    setStateCode(Array(optionsState)[0][Number(e.target.selectedIndex)-1].isoCode)
                                }
                                
                                
                            }}
                            value={state ? state : ""}
                            id="select-state" required>
                        <option key="choose-state">Choose State...</option>
                        {
                        [...Array(optionsState)[0]].map((x) => (
                                            
                                            <option key={x.name} value={x.name}>
                                                {x.name}
                                            </option>
                                        ))
                        }
                        
                    </select>
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">City</label>
                        <select className="form-control" 
                                onChange={(e)=>{
                                    setCity(e.target.value)
                                    // setCityCode(Array(optionsCity)[0][Number(e.target.selectedIndex)-1].name)
                                }}
                                value={city ? city : ""}
                                id="select-city" required>
                            <option key="choose-city">Choose City...</option>
                            {
                            [...Array(optionsCity)[0]].map((x,index) => (
                                                
                                                <option key={index+'shippingcity'} value={x.name}>
                                                    {x.name}
                                                </option>
                                            ))
                            }
                            
                        </select>
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Postal Code</label>
                        <input className="form-control" type="text" 
                                placeholder='Enter Postal Code (Required)'
                                value={postalCode ? postalCode : ''}
                                onChange={(e)=>setPostalCode(e.target.value)}
                                id="checkout-postal" required/>
                    </div>
                    </div>
                    
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Email</label>
                        <input className="form-control" type="email" 
                                placeholder='Enter Email id'
                                value={email ? email : ''}
                                onChange={(e)=>setEmail(e.target.value)}
                                id="checkout-email" />
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                   
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Phone Number</label>
                    
                    <PhoneInput 
                        containerClass="form-control"
                        inputStyle={{width:"106%", height:"100%",paddingLeft:"50px"}}
                        containerStyle={{paddingLeft:"0",width:"100%",border:"none"}}
                        countryCodeEditable ={false}
                        // buttonStyle={{width:"14%"}}
                        // inputClass="form-control"
                        // searchClass="form-control"
                        
                        country={countryCode && countryCode.toLowerCase()}
                        value={phone}
                        onChange={handlePhone}
                    />

                    </div>
                    </div>
                </div>

                <h4>Billing Address</h4>
                <hr className="padding-bottom-1x" />
                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                    
                        <input className="custom-control-input" type="checkbox" 
                            onChange={(e)=>setSameAsShipping(!sameAsShipping)}
                            id="same_address" checked={sameAsShipping === true } />
                    
                    <label className="custom-control-label" htmlFor="same_address">Same as shipping address</label>
                    </div>
                </div>

                {sameAsShipping === false ?
                    <><div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">First Name</label>
                        <input className="form-control" type="text" 
                                placeholder='Enter First Name'
                                value={firstNameBilling ? firstNameBilling : ''}
                                onChange={(e)=>setFirstNameBilling(e.target.value)}
                                id="checkout-fnBilling" />
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Last Name</label>
                        <input className="form-control" type="text" 
                                placeholder='Enter Last Name'
                                value={lastNameBilling ? lastNameBilling : ''}
                                onChange={(e)=>setLastNameBilling(e.target.value)}
                                id="checkout-lnBilling" />
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Address Line 1</label>
                        <input className="form-control" type="text" 
                                placeholder='Address Line 1 (Required)'
                                value={address1Billing ? address1Billing : ''}
                                onChange={(e)=>setAddress1Billing(e.target.value)}
                                id="checkout-add1Billing" required/>
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Address Line 2</label>
                        <input className="form-control" type="text" 
                                placeholder='Address Line 2'
                                value={address2Billing ? address2Billing : ''}
                                onChange={(e)=>setAddress2Billing(e.target.value)}
                                id="checkout-add2Billing" />
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Country</label>
                    <select className="form-control" 
                            onChange={(e)=>{
                                setCountryBilling(e.target.value)
                                
                                if (Number(e.target.selectedIndex) !== 0){
                                setCountryCodeBilling(Array(optionsCountry)[0][Number(e.target.selectedIndex)-1].isoCode)
                                setOptionsStateBilling(csc.getStatesOfCountry(Array(optionsCountry)[0][Number(e.target.selectedIndex)-1].isoCode))
                                }
                            }}
                            value={Array(csc.getCountryByCode(countryCodeBilling))[0].name}
                            id="select-countryBilling" required>
                        <option key="choose-countryBilling">Choose Country...</option>
                        {
                        [...Array(optionsCountry)[0]].map((x) => (
                                            
                                            <option key={x.name+'billing'} value={x.name} >
                                                {x.name}
                                            </option>
                                        ))
                        }
                        
                    </select>
                    </div>
                    </div>
                    
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">State</label>
                    <select className="form-control" 
                            onChange={(e)=>{
                                setStateBilling(e.target.value)

                                if (Number(e.target.selectedIndex) !== 0){
                                setOptionsCityBilling(csc.getCitiesOfState(countryCodeBilling, (Array(optionsStateBilling)[0][Number(e.target.selectedIndex)-1].isoCode)))
                                setStateCodeBilling(Array(optionsStateBilling)[0][Number(e.target.selectedIndex)-1].isoCode)
                                }
                            }}
                            value={stateBilling}
                            id="select-stateBilling" required>
                        <option key="choose-stateBilling">Choose State...</option>
                        {
                        [...Array(optionsStateBilling)[0]].map((x) => (
                                            
                                            <option key={x.name+'billing'} value={x.name}>
                                                {x.name}
                                            </option>
                                        ))
                        }
                        
                    </select>
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">City</label>
                        <select className="form-control" 
                                onChange={(e)=>{
                                    setCityBilling(e.target.value)
                                    // setCityCodeBilling(Array(optionsCityBilling)[0][Number(e.target.selectedIndex)-1].name)
                                }}
                                value={cityBilling}
                                id="select-cityBilling" required>
                            <option key="choose-cityBilling">Choose City...</option>
                            {
                            [...Array(optionsCityBilling)[0]].map((x,index) => (
                                                
                                                <option key={index+'billingcity'} value={x.name}>
                                                    {x.name}
                                                </option>
                                            ))
                            }
                            
                        </select>
                    </div>
                    </div>

                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Postal Code</label>
                        <input className="form-control" type="text" 
                                placeholder='Enter Postal Code (Required)'
                                value={postalCodeBilling ? postalCodeBilling : ''}
                                onChange={(e)=>setPostalCodeBilling(e.target.value)}
                                id="checkout-postalBilling" required />
                    </div>
                    </div>
                    
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Email</label>
                        <input className="form-control" type="email" 
                                placeholder='Enter Email id'
                                value={emailBilling ? emailBilling : ''}
                                onChange={(e)=>setEmailBilling(e.target.value)}
                                id="checkout-emailBilling" />
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Phone Number</label>
                    <PhoneInput 
                       containerClass="form-control"
                       inputStyle={{width:"106%", height:"100%",paddingLeft:"50px"}}
                       containerStyle={{paddingLeft:"0",width:"100%",border:"none"}}
                       countryCodeEditable ={false}
                    //    buttonStyle={{maxWidth:"14%"}}
                        
                        country={countryCodeBilling && countryCodeBilling.toLowerCase()}
                        value={phoneBilling}
                        onChange={handlePhoneBilling}
                    />
                    </div>
                    </div>
                </div></> : null
                
                }
                

                <div className="d-flex justify-content-between paddin-top-1x mt-4">
                    <Link className="btn btn-outline-secondary" to="/cart">
                        <i className="icon-arrow-left"></i>
                        <span className="hidden-xs-down">&nbsp;Back To Cart</span>
                    </Link>
                    
                    <button className="btn btn-primary" 
                            type='submit'
                            >
                        <span className="hidden-xs-down">Continue&nbsp;</span>
                        <i className="icon-arrow-right"></i>
                    </button>
                </div>
                </form>
                </div>
  
               
            </div>
            </div>
        </div>
    )
}

export default ShippingScreen
