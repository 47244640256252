import React, { useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { listMyAppointments } from '../../actions/appointmentActions'



function MyAppointmentsScreen({history}) {

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const appointmentListMy = useSelector(state => state.appointmentListMy)
    const { loading: loadingAppointments, error:errorAppointments, appointments } = appointmentListMy

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {            
            dispatch(listMyAppointments())           
        }
    },[dispatch, userInfo,history])

    
    return (
        <div>
            
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>My Appointments</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Home</Link>
                    </li>
                    
                    <li className="separator">&nbsp;</li>
                    <li>My Appointments</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2" style={{ minHeight:"90vh"}}>
            
            <div className="row">
               
                <div className="col-lg-12">
                <h4 className="margin-bottom-1x">My Appointments</h4>
                <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                {loadingAppointments ? (
                        <Loader />
                        ): errorAppointments ? (
                            <Message variant='danger'>{errorAppointments}</Message>
                        ) :

                        appointments.length === 0 ? (
                            <div  style={{marginBottom: "30px"}}>
                                <Message  variant='info'>
                                    No Appointment to show. <Link to='/services'>Visit our Services</Link>                                    
                                </Message>
                            </div>
                            ) :
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                <thead>
                                    <tr>
                                    <th>Appointment #</th>
                                    <th>Appointment Date</th>
                                    <th>Timing</th>
                                    <th>Expert</th>
                                    <th>Fee</th>
                                    <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {appointments.map(appointment => (
                                        appointment.status !== "Initiated" &&
                                        <tr key={appointment._id}>
                                        <td><Link className="navi-link" to="#" data-toggle="modal" data-target="#orderDetails">{appointment.refId}</Link></td>
                                        <td>{appointment.appointmentSchedule.date}</td>
                                        <td>{appointment.appointmentSchedule.startTime} - {appointment.appointmentSchedule.endTime}</td>
                                        <td>{appointment.appointmentSchedule.doctor && appointment.appointmentSchedule.doctor.name} </td>
                                        <td><span>{appointment.currency === "INR" ? '₹' : appointment.currency === "USD" ? '$' : null}{appointment.fee}</span><br/>
                                        {appointment.isPaid && <span className="text-success">Paid on: {appointment.paidAt.substring(0,10)}</span>}
                                        </td>
                                        
                                        {/* <td><Link to={`/order/${appointment.slug}`}>{appointment.isDelivered ? <span className="text-success">Delivered</span> : (<span className="text-danger">{appointment.isPaid ? <>Not Delivered</> : <span style={{color:'green',fontWeight:'bold'}}>Continue Shopping</span>}</span>)}</Link></td> */}
                                        <td><Link to={`/appointment/${appointment.slug}`}>
                                            {
                                            appointment.status==="Booked" ? 
                                                <span className="text-success">Booked</span> :
                                            appointment.status==="Attained" ? 
                                                <span className="text-success">Attained</span> :                                
                                            appointment.status==="Cancelled" ? 
                                                <span className="text-danger">Cancelled</span> :
                                             null
                                            }
                                            </Link></td>
                                        </tr>

                                    ))}
                                    
                                    
                                </tbody>
                                </table>
                            </div>
                        }
                <hr/>
                </div>
            </div>
            </div>
            
        </div>
    )
}

export default MyAppointmentsScreen
