import {
    SET_CURRENCY,
} from '../constants/currencyConstants'

export const currencyReducer = (state = {}, action) => {

    switch (action.type) {
        case SET_CURRENCY:
            return { currency: action.payload }

        default:
            return state

    }
}