import { CART_SAVE_SERVICE,
         CART_SAVE_APPOINTMENT_SCHEDULE,
         CART_SAVE_PATIENT_DETAILS,
         CART_SAVE_SERVICE_PAYMENT_METHOD,
         CART_CLEAR_SERVICE,
   } from '../constants/serviceCartConstants'


export const serviceCartReducer = (state={appointmentService:{}, appointmentSchedule:{} , patientDetails:{}}, action) => {
switch(action.type) {
   
   case CART_SAVE_SERVICE:
       return{
           ...state,
           appointmentService: action.payload
       }

   case CART_SAVE_APPOINTMENT_SCHEDULE:
       return{
           ...state,
           appointmentSchedule: action.payload
       }
   
    case CART_SAVE_PATIENT_DETAILS:
       return{
           ...state,
           patientDetails: action.payload
       }

   case CART_SAVE_SERVICE_PAYMENT_METHOD:
       return{
           ...state,
           servicePaymentMethod: action.payload
       }

    case CART_CLEAR_SERVICE:
    return{
        ...state,
        appointmentService: {},
        appointmentSchedule:{},
        patientDetails:{}
    }

   default:
       return state
   
   }
   
}