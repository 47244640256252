import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Product from '../components/Product'
import Loader from '../components/Loader'
import Message from '../components/Message'

import { listProducts } from '../actions/productActions'

function AllProductsScreen(history) {

    const dispatch = useDispatch()
    const productList = useSelector(state => state.productList)
    const {error, loading, products} = productList

    const currencyValue = useSelector(state => state.currency)
    const {currency} = currencyValue

    useEffect(()=> {   
        dispatch(listProducts('?keyword=all'))
    }, [dispatch])

    
    return (
        <div>
            
            <section className="container padding-bottom-2x mt-4 mb-1" >
            <h2 className="h3 pb-3 text-center">Our Products</h2>
            { loading ? <Loader />
                : error ? <Message variant='danger'>{error}</Message>
                :
            <>
            <div className="row">

                {products.map(product => (

                        <div className="col-lg-3 col-md-4 col-sm-6" key={product._id}>
                            
                            <Product product={product} currency={currency}/>
                            
                        </div>

                ))}

            </div>
            

            </>
            }
            <div className="text-center"><Link className="btn btn-outline-secondary" to="/">Back to Home</Link></div>
            </section>
            
        </div>
        
    )
}

export default AllProductsScreen
