import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'

function ProfileScreen({history}) {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails)
    const {error, loading, user } = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const { success } = userUpdateProfile
    // console.log('user name ',userInfo)
    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            if(!user || !user.name || success || userInfo._id !== user._id ){
                dispatch({ type: USER_UPDATE_PROFILE_RESET})
                dispatch(getUserDetails('profile'))
                
            }else{
                setName(user.name)
                setEmail(user.email)
                if(user && !user.username.includes('@')){
                    setPhone(user.username)
                }
                
            }
        }
    },[dispatch, history, userInfo, user, success])

    const submitHandler = (e) => {
        e.preventDefault()

        if (password !== confirmPassword){
            setMessage('Passwords do not match')
        } else {
            dispatch(updateUserProfile({
                'id': user._id,
                'name':name,
                // 'email':email,
                'password': password,
            }))
            setMessage('Profile Updated')
        }
        
    }
    console.log('email:',email)
    return (
        <div>
            
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>My Profile</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Home</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li><Link to="account-orders.html">Account</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>My Profile</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2">
            
           
                <center>
                    <div className="">
                    <div className=" card col-lg-5 col-md-7 col-sm-10">

                        
                        <div className="padding-top-2x mt-2 hidden-lg-up"></div>

                        <form className="" autoComplete="new-password" onSubmit={submitHandler}>
                            
                            <div className="col-md-12" style={{textAlign:"left"}}>
                                <br />
                                <h4 className="margin-bottom-1x">My Profile</h4>
                                <div className="form-group">
                                    <label style={{paddingLeft:'0px'}} htmlFor="account-fn">Name</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="account-fn" 
                                            value={name} 
                                            autoComplete="off"
                                            onChange={(e)=>setName(e.target.value)}
                                            required />
                                </div>
                            
                                <div className="form-group">
                                    <label style={{paddingLeft:'0px'}} htmlFor="account-email">Phone Number</label>
                                    <input className="form-control" 
                                    type="text" 
                                    id="account-email" 
                                    value={phone && '+'+phone} 
                                    onChange={(e)=>setPhone(e.target.value)}
                                    disabled />
                                    <div style={{textAlign:"right"}}><Link style={{textDecoration:"none"}} to='/verify-phone'>{phone ? "Change Phone" : "Update Phone"}</Link></div>
                                </div>

                                <div className="form-group">
                                    <label style={{paddingLeft:'0px'}} htmlFor="account-email">E-mail Id</label>
                                    <input className="form-control" 
                                    type="email" 
                                    id="account-email" 
                                    value={email} 
                                    onChange={(e)=>setEmail(e.target.value)}
                                    disabled />
                                    <div style={{textAlign:"right"}}><Link style={{textDecoration:"none"}} to='/verify-email'>{email ? "Change Email" : "Update Email"}</Link></div>
                                </div>
                                
                            
                            {/* <div className="col-md-6">
                            <div className="form-group">
                                <label for="account-phone">Phone Number</label>
                                <input className="form-control" type="text" id="account-phone" value="+7 (805) 348 95 72" required /> 
                            </div>
                            </div> */}
                            
                            <div className="form-group">
                                <label style={{paddingLeft:'0px'}} htmlFor="account-pass">New Password</label>
                                <input className="form-control" 
                                        type="password" 
                                        onChange={(e)=>setPassword(e.target.value)}
                                        id="" />
                            </div>
                            
                            <div className="form-group">
                                <label style={{paddingLeft:'0px'}} htmlFor="account-confirm-pass">Confirm Password</label>
                                <input className="form-control" 
                                        type="password" 
                                        onChange={(e)=>setConfirmPassword(e.target.value)}
                                        id="account-confirm-pass" />
                            </div>
                            
                            
                            
                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                            <div style={{width: "100%"}}>
                                {message && message === 'Profile Updated' ?  
                                <Message variant='success'>{message}</Message> : 
                                message && <Message variant='danger'>{message}</Message>}
                                {error && <Message variant='danger'>{error}</Message>}
                            </div>
                            
                            {loading && <Loader />}
                                <button className="btn btn-primary btn-block margin-right-none" 
                                        type="submit" 
                                        data-toast data-toast-position="topRight" 
                                        data-toast-type="success" 
                                        data-toast-icon="icon-check-circle" 
                                        data-toast-title="Success!" 
                                        data-toast-message="Your profile updated successfuly.">
                                            Update Profile
                                </button>
                            </div>
                            <br />
                            </div>
                        </form>

                        {/* <aside className="user-info-wrapper">
                        <div className="user-cover" style={{backgroundImage: "url(/assets/img/account/user-cover-img.jpg)"}}>
                        <div className="info-label" data-toggle="tooltip" title="You currently have 290 Reward Points to spend"><i className="icon-award"></i>290 points</div>
                        </div>
                        <div className="user-info">
                        <div className="user-avatar"><Link className="edit-avatar" to="#"></Link><img src="/images/img/account/user-ava.jpg" alt="User" /></div>
                        <div className="user-data">
                            <h4 className="h5">Daniel Adams</h4><span>Joined February 06, 2017</span>
                        </div>
                        </div>
                        </aside>
                        <nav className="list-group"><Link className="list-group-item with-badge" to="account-orders.html"><i className="icon-shopping-bag"></i>Orders<span className="badge badge-default badge-pill">6</span></Link><Link className="list-group-item active" to="account-profile.html"><i className="icon-user"></i>Profile</Link><Link className="list-group-item" to="account-address.html"><i className="icon-map-pin"></i>Addresses</Link><Link className="list-group-item with-badge" to="account-wishlist.html"><i className="icon-heart"></i>Wishlist<span className="badge badge-default badge-pill">3</span></Link><Link className="list-group-item with-badge" to="account-tickets.html"><i className="icon-tag"></i>My Tickets<span className="badge badge-default badge-pill">4</span></Link></nav> */}
                        </div>

                    </div>
                
                </center>
                

            
            </div>
            
        </div>
    )
}

export default ProfileScreen
