import axios from 'axios'

import {

    DOCTOR_LIST_REQUEST,
    DOCTOR_LIST_SUCCESS,
    DOCTOR_LIST_FAIL,

    DOCTOR_DETAILS_REQUEST,
    DOCTOR_DETAILS_SUCCESS,
    DOCTOR_DETAILS_FAIL,

    DOCTOR_CREATE_REQUEST,
    DOCTOR_CREATE_SUCCESS,
    DOCTOR_CREATE_FAIL,
    
    DOCTOR_UPDATE_REQUEST,
    DOCTOR_UPDATE_SUCCESS,
    DOCTOR_UPDATE_FAIL,

    DOCTOR_DELETE_REQUEST,
    DOCTOR_DELETE_SUCCESS,
    DOCTOR_DELETE_FAIL,

} from '../constants/doctorConstants'

export const listDoctors = () => async (dispatch) => {
    try {
        dispatch({
            type: DOCTOR_LIST_REQUEST
        })
       

        const { data } = await axios.get(
            `/api/doctors/`           
        )

        dispatch({
            type: DOCTOR_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: DOCTOR_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listDoctorDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: DOCTOR_DETAILS_REQUEST })

        const { data } = await axios.get(
            `/api/doctors/${id}`,
            )

        dispatch({
            type: DOCTOR_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DOCTOR_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createDoctor = (doctorName) => async (dispatch,getState) => {
    try {
        dispatch({
            type: DOCTOR_CREATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/doctors/create/`,   
            {'doctorName':doctorName},         
            config
        )

        dispatch({
            type: DOCTOR_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: DOCTOR_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateDoctor = (doctor) => async (dispatch,getState) => {
    try {
        dispatch({
            type: DOCTOR_UPDATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/doctors/update/${doctor.slug}/`,   
            doctor,         
            config
        )

        dispatch({
            type: DOCTOR_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch({ 
            type: DOCTOR_DETAILS_SUCCESS,  
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: DOCTOR_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteDoctor = (id) => async (dispatch,getState) => {
    try {
        dispatch({
            type: DOCTOR_DELETE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/doctors/delete/${id}/`,            
            config
        )

        dispatch({
            type: DOCTOR_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: DOCTOR_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
