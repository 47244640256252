import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Rating from '../../components/Rating'
// import axios from "axios";
import '../../index.css'
// import RelatedProducts  from '../components/RelatedProducts'

import {
  listServiceDetails,
  createServiceReview,
} from "../../actions/serviceActions";
import { SERVICE_CREATE_REVIEW_RESET } from "../../constants/serviceConstants";

import ProductCarousel from "../../components/ProductCarousel";

import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";


function ServiceScreen({ match, history }) {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const serviceDetails = useSelector((state) => state.serviceDetails);
  const { loading, error, service } = serviceDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const serviceReviewCreate = useSelector((state) => state.serviceReviewCreate);
  const {
    loading: loadingServiceReview,
    error: errorServiceReview,
    success: successServiceReview,
  } = serviceReviewCreate;

  const currencyValue = useSelector((state) => state.currency);
  const { currency } = currencyValue;

  const dispatch = useDispatch();

  const [reviewOpen, SetReviewOpen] = useState(false);

  const serviceId = match.params.id;
  const [reviewMessage, setReviewMessage] = useState(false);

      useEffect(() => {


    if (successServiceReview) {
      setRating(0);
      setComment("");
      dispatch({ type: SERVICE_CREATE_REVIEW_RESET });
      setReviewMessage(true);
    }

      dispatch(listServiceDetails(serviceId));
  
  }, [dispatch, match,  serviceId, successServiceReview]);

  const checkoutHandler = () => {
    history.push(`/login?redirect=schedule-appointment/${serviceId}`)
}

  let imageList = [];
  if (service) {
    if (
      service.image_1 &&
      service.image_1.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':service.image_1});
    }
    if (
      service.image_2 &&
      service.image_2.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':service.image_2});
    }
    if (
      service.image_3 &&
      service.image_3.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':service.image_3});
    }
    if (
      service.image_4 &&
      service.image_4.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':service.image_4});
    }
    if (
      service.image_5 &&
      service.image_5.search("/images/placeholder") !== 0
    ) {
      imageList.push({'image':service.image_5});
    }
  }


  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      createServiceReview(serviceId, {
        rating,
        comment,
      })
    );
    SetReviewOpen(false);
  };

  const [lastReview, setLastReview] = useState(3);

  return (
    <>
       {loading ? (
        <>
          <Loader />
        </>
      ) : //
      error ? (
        <Message variant="danger">{error}</Message>
      ) : (<>
      <div className="page-title">
        <div className="container">
          <div className="column">
            <h1>{service.name}</h1>
          </div>
          <div className="column">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="separator">&nbsp;</li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li className="separator">&nbsp;</li>
              <li>{service.name}</li>
            </ul>
          </div>
        </div>
      </div>

   
        <div id="landingPage">
          {/* <!-- Page Content--> */}
          
          <div className="container padding-bottom-3x">
            <div className="row">
              {/* <!-- Poduct Gallery--> */}
              <div className="col-md-6">
                <div className="product-gallery" style={{paddingTop:"15px"}}>
                  <div className="gallery-wrapper"></div>
                  <div style={{ whiteSpace: "nowrap", overflowX: "auto" }}>
                    <div
                      style={{ marginTop: "20px",display:"none" }}
                      className="product-badge bg-secondary border-default text-body"
                    >
                      {service.available ? (
                        <div style={{ color: "green" }}>
                          Available
                        </div>
                      ) : (
                        <div style={{ color: "red" }}>
                          Not Available
                        </div>
                      )}
                    </div>
                    <div style={{ textAlign: "right", marginBottom: "10px" }}>
                      <Rating
                        value={service.rating}
                        text={`${service.numReviews} reviews`}
                        color={"#ffa000"}
                      />
                    </div>
                  </div>

                  <div>
                    <ProductCarousel
                      infiniteLoop={true}
                      images={imageList}
                      showThumbs={false}
                      clickable={true}
                    />
                    
                  </div>
                </div>
              </div>
              {/* <!-- Product Info--> */}
              <div className="col-md-6">
                <div className="padding-top-2x mt-2 hidden-md-up"></div>
                
                <h2 className="mb-3">{service.name}</h2>
                
                <div id="des-box-service" style={{
                      marginBottom: "20px",
                     
                    }}>
                  
                  <p id="des-text-service" style={{
                     
                      color: "black",
                      whiteSpace: "pre-wrap",
                    }}>

                  {service.short_description}
                  
                  </p>

                  
                  
                
                </div>
                <h4>Fee: {currency === "INR" ? '₹'+service.feeRupees : '$'+service.feeDollars}</h4>
                <div className="row align-items-end pb-3">
                  <div className="col-sm-8">
                    <div className="pt-2 hidden-sm-up"></div>
                    <button
                      className="btn btn-primary btn-block m-0"
                      onClick={checkoutHandler}
                      data-toast
                      data-toast-type="success"
                      data-toast-position="topRight"
                      data-toast-icon="icon-check-circle"
                      data-toast-title="Product"
                      disabled={
                       false
                      }
                      data-toast-message="successfuly added to cart!"
                    >
                      <i className="icon-bag"></i> Book Appointment
                    </button>
                  </div>
                </div>
                {/* <div className="pt-1 mb-4"><span className="text-medium">SKU:</span> #21457832</div> */}
                <hr className="mb-0" />

                <div className="d-flex flex-wrap justify-content-between">
                  <div className="mt-2 mb-2">
                    {/* <button className="btn btn-outline-secondary btn-sm btn-wishlist"><i className="icon-heart"></i>&nbsp;To Wishlist</button> */}
                  </div>
                  <div className="mt-2 mb-2">
                    <span className="text-muted">Share:&nbsp;&nbsp;</span>
                    <div className="d-inline-block">
                      <WhatsappShareButton
                        url={window.location.href}
                        title={`Check this out: ${service.name} ${service.weight_1}`}
                      >
                        <Link
                          className="social-button shape-rounded sb-instagram"
                          to="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Instagram"
                        >
                          <i className="socicon-whatsapp"></i>
                        </Link>
                      </WhatsappShareButton>

                      <FacebookShareButton
                        url={window.location.href}
                        quote={"Check this out: " + service.name}
                      >
                        <Link
                          className="social-button shape-rounded sb-facebook"
                          to="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Facebook"
                        >
                          <i className="socicon-facebook"></i>
                        </Link>
                      </FacebookShareButton>

                      <TwitterShareButton
                        url={window.location.href}
                        title={`Check this out: ${service.name}`}
                      >
                        <Link
                          className="social-button shape-rounded sb-twitter"
                          to="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Twitter"
                        >
                          <i className="socicon-twitter"></i>
                        </Link>
                      </TwitterShareButton>

                      <PinterestShareButton
                        media={service.image}
                        description={"Check this out: " + service.name}
                      >
                        <Link
                          className="social-button shape-rounded sb-google-plus"
                          to="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Google +"
                        >
                          <i className="socicon-pinterest"></i>
                        </Link>
                      </PinterestShareButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* <!-- Product Details--> */}
          <div
            className="bg-secondary padding-top-3x padding-bottom-2x mb-3"
            id="details"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="h4">Description</h3>
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      whiteSpace: "pre-wrap",
                    }}
                    className="mb-4"
                  >
                    {service.description}
                  </p>
                 
                </div>
              
              </div>
            </div>
          
          </div>
          {/* <!-- Reviews--> */}
          <div className="container padding-top-2x">
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="card border-default">
                  <div className="card-body">
                    <div className="text-center">
                      <div className="d-inline align-baseline display-3 mr-1">
                        {service.rating}
                      </div>
                      <div className="d-inline align-baseline text-sm text-warning mr-1">
                        <div className="rating-stars">
                          <Rating value={service.rating} color={"#ffa000"} />{" "}
                          <span style={{ color: "gray" }}>
                            {service.numReviews === 1
                              ? service.numReviews + " Review"
                              : service.numReviews + " Reviews"}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="pt-3">
                      <label className="text-medium text-sm">
                        5 stars{" "}
                        <span className="text-muted">
                          -{" "}
                          {service.reviews.reduce(
                            (acc, review) =>
                              review.rating === 5 ? acc + 1 : acc + 0,
                            0
                          )}
                        </span>
                      </label>
                      <div className="progress margin-bottom-1x">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width:
                              (service.reviews.reduce(
                                (acc, review) =>
                                  review.rating === 5 ? acc + 1 : acc + 0,
                                0
                              ) /
                                service.numReviews) *
                                100 +
                              "%",
                            height: "2px",
                          }}
                          aria-valuenow={
                            (service.reviews.reduce(
                              (acc, review) =>
                                review.rating === 5 ? acc + 1 : acc + 0,
                              0
                            ) /
                              service.numReviews) *
                            100
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <label className="text-medium text-sm">
                        4 stars{" "}
                        <span className="text-muted">
                          -{" "}
                          {service.reviews.reduce(
                            (acc, review) =>
                              review.rating === 4 ? acc + 1 : acc + 0,
                            0
                          )}
                        </span>
                      </label>
                      <div className="progress margin-bottom-1x">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width:
                              (service.reviews.reduce(
                                (acc, review) =>
                                  review.rating === 4 ? acc + 1 : acc + 0,
                                0
                              ) /
                                service.numReviews) *
                                100 +
                              "%",
                            height: "2px",
                          }}
                          aria-valuenow={
                            (service.reviews.reduce(
                              (acc, review) =>
                                review.rating === 4 ? acc + 1 : acc + 0,
                              0
                            ) /
                              service.numReviews) *
                            100
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <label className="text-medium text-sm">
                        3 stars{" "}
                        <span className="text-muted">
                          -{" "}
                          {service.reviews.reduce(
                            (acc, review) =>
                              review.rating === 3 ? acc + 1 : acc + 0,
                            0
                          )}
                        </span>
                      </label>
                      <div className="progress margin-bottom-1x">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width:
                              (service.reviews.reduce(
                                (acc, review) =>
                                  review.rating === 3 ? acc + 1 : acc + 0,
                                0
                              ) /
                                service.numReviews) *
                                100 +
                              "%",
                            height: "2px",
                          }}
                          aria-valuenow={
                            (service.reviews.reduce(
                              (acc, review) =>
                                review.rating === 3 ? acc + 1 : acc + 0,
                              0
                            ) /
                              service.numReviews) *
                            100
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <label className="text-medium text-sm">
                        2 stars{" "}
                        <span className="text-muted">
                          -{" "}
                          {service.reviews.reduce(
                            (acc, review) =>
                              review.rating === 2 ? acc + 1 : acc + 0,
                            0
                          )}
                        </span>
                      </label>
                      <div className="progress margin-bottom-1x">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width:
                              (service.reviews.reduce(
                                (acc, review) =>
                                  review.rating === 2 ? acc + 1 : acc + 0,
                                0
                              ) /
                                service.numReviews) *
                                100 +
                              "%",
                            height: "2px",
                          }}
                          aria-valuenow={
                            (service.reviews.reduce(
                              (acc, review) =>
                                review.rating === 2 ? acc + 1 : acc + 0,
                              0
                            ) /
                              service.numReviews) *
                            100
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <label className="text-medium text-sm">
                        1 star{" "}
                        <span className="text-muted">
                          -{" "}
                          {service.reviews.reduce(
                            (acc, review) =>
                              review.rating === 1 ? acc + 1 : acc + 0,
                            0
                          )}
                        </span>
                      </label>
                      <div className="progress margin-bottom-1x">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width:
                              (service.reviews.reduce(
                                (acc, review) =>
                                  review.rating === 1 ? acc + 1 : acc + 0,
                                0
                              ) /
                                service.numReviews) *
                                100 +
                              "%",
                            height: "2px",
                          }}
                          aria-valuenow={
                            (service.reviews.reduce(
                              (acc, review) =>
                                review.rating === 1 ? acc + 1 : acc + 0,
                              0
                            ) /
                              service.numReviews) *
                            100
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    {/* {loadingProductReview && <Loader/>} */}
                    {successServiceReview && (
                      <Message variant="success">Review Submitted.</Message>
                    )}
                    {errorServiceReview && (
                      <Message variant="danger">{errorServiceReview}</Message>
                    )}

                    {userInfo ? (
                      <div className="pt-2">
                        {reviewOpen && (
                          <form
                            className="input-group form-group"
                            method="post"
                            id="leaveReview"
                            onSubmit={submitHandler}
                          >
                            <center>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <label
                                      style={{ paddingLeft: "0" }}
                                      htmlFor="review-rating"
                                    >
                                      Rating
                                    </label>
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        setRating(e.target.value)
                                      }
                                      id="review-rating"
                                      required
                                    >
                                      <option value="">Choose Rating</option>
                                      <option value="5">5 Stars</option>
                                      <option value="4">4 Stars</option>
                                      <option value="3">3 Stars</option>
                                      <option value="2">2 Stars</option>
                                      <option value="1">1 Star</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  style={{
                                    paddingLeft: "0",
                                    textAlign: "left",
                                  }}
                                  htmlFor="review-message"
                                >
                                  Review
                                </label>
                                <textarea
                                  className="form-control"
                                  value={comment}
                                  onChange={(e) => setComment(e.target.value)}
                                  id="review-message"
                                  placeholder="Write your review."
                                  rows="4"
                                  required
                                ></textarea>
                              </div>

                              <button
                                className="btn btn-info btn-block"
                                disabled={loadingServiceReview}
                                // data-dismiss={closeModal==="modal" || errorProductReview ? "modal" : ''}
                                type="submit"
                              >
                                Submit Review
                              </button>
                            </center>
                          </form>
                        )}
                        {reviewMessage && (
                          <Message variant="success">
                            Review submitted successfully!
                          </Message>
                        )}
                        {!reviewOpen && (
                          <button
                            className="btn btn-warning btn-block"
                            onClick={() => SetReviewOpen(true)}
                            data-toggle="modal"
                            // data-target="#leaveReview"
                          >
                            {service.reviews.reduce(
                              (acc, review) =>
                                acc + Number(review.user === userInfo._id),
                              0
                            ) > 0
                              ? "Edit Review"
                              : "Leave a Review"}
                          </button>
                        )}
                      </div>
                    ) : (
                      <Message variant="info">
                        Please{" "}
                        <Link to={`/login?redirect=/${service.slug}`}>
                          Login
                        </Link>{" "}
                        to Leave a Review
                      </Message>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <h3>Latest Reviews</h3>

                {/* <!-- Review--> */}

                {service.reviews.slice(0, lastReview).map((review) => (
                  <div
                    style={{ paddingLeft: "10px" }}
                    className="comment"
                    key={review._id}
                  >
                    {/* <div className="comment-author-ava"><img src="/images/img/reviews/02.jpg" alt="Comment author" /></div> */}
                    <div className="comment-body">
                      <div className="comment-header d-flex flex-wrap justify-content-between">
                        <h4 style={{ float: "left" }} className="comment-title">
                          <strong>{review.name}</strong>{" "}
                          <span style={{ fontSize: "10px" }}>
                            {" "}
                            {review.createdAt.substring(0, 10)}
                          </span>
                        </h4>

                        <div className="mb-2">
                          <Rating
                            value={review.rating}
                            color={"#ffa000"}
                            size="sm"
                          />
                          {/* <div className="rating-stars"><i className="icon-star filled"></i><i className="icon-star filled"></i><i className="icon-star filled"></i><i className="icon-star filled"></i><i className="icon-star"></i>
                                    </div> */}
                        </div>
                      </div>
                      <p className="comment-text">{review.comment}</p>

                      
                      {/* {review.image_1 && <Link to={review.image_1}><img src={review.image_1} width="150px" alt="review_image1" /></Link>} */}
                    </div>
                  </div>
                ))}

                {/* <!-- View All Button--> */}
                {service.reviews.length > 3 &&
                  (lastReview === 3 ? (
                    <button
                      className="btn btn-secondary btn-block"
                      onClick={() => setLastReview(service.reviews.length)}
                      type="button"
                    >
                      View All Reviews
                    </button>
                  ) : (
                    <button
                      className="btn btn-secondary btn-block"
                      onClick={() => setLastReview(3)}
                      type="button"
                    >
                      View Less
                    </button>
                  ))}
              </div>
            </div>
          </div>
        




        </div>
      </>)}

      <div className="container padding-bottom-3x mb-1">
        {/* <!-- Related Products Carousel--> */}
        {/* <RelatedProducts /> */}
      </div>
    </>
  );
}

export default ServiceScreen;
