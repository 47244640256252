import React, {useState,useEffect} from 'react'
import { Link } from 'react-router-dom'

import csc from 'country-state-city'
import { useDispatch, useSelector } from 'react-redux'
import { savePatientDetails } from '../../actions/serviceCartActions'
import { setCurrencyinAction } from '../../actions/currencyActions'
// import { addToCart } from '../actions/cartActions'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import "../../index.css"

function PatientDetailsScreen({history}) {

    const serviceCart = useSelector(state => state.serviceCart)
    const { appointmentService, appointmentSchedule, patientDetails} = serviceCart

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin
    const optionsCountry = csc.getAllCountries()

    const dispatch = useDispatch()

    const userInfoFromStorage = localStorage.getItem('userInfo') ?
        JSON.parse(localStorage.getItem('userInfo')) : null
    
    // const patientDetailsFromStorage = localStorage.getItem('patientDetails') ?
    //     JSON.parse(localStorage.getItem('patientDetails')) : null
    

    // console.log('serviceFromStorage:',serviceFromStorage)
    // console.log('patientDetailsFromStorage:',patientDetailsFromStorage)
    // Shipping Address data
    const [name, setName] = useState(patientDetails ? patientDetails.name : userInfoFromStorage ? userInfoFromStorage.name : userInfo ? userInfo.name : "")

    const [gender, setGender] = useState(patientDetails ? patientDetails.gender : '')
    const [calendarDate,setCalendarDate] = useState('')
    const [dob, setDob] = useState(patientDetails && patientDetails.dob ? patientDetails.dob : calendarDate ? moment(calendarDate).format("YYYY-MM-DD") : '')
    const [symptoms, setSymptoms] = useState(patientDetails ? patientDetails.symptoms : '')
    // const [profession, setProfession] = useState(patientDetails ? patientDetails.profession : '')
    
    const [address1, setAddress1] = useState(patientDetails ? patientDetails.address1 : '')
    const [address2, setAddress2] = useState(patientDetails ? patientDetails.address2 : '')
    const [city, setCity] = useState(patientDetails ? patientDetails.city : '')
    const [state, setState] = useState(patientDetails ? patientDetails.state : '')
    const [postalCode, setPostalCode] = useState(patientDetails ? patientDetails.postalCode : '')
    const [country, setCountry] = useState(patientDetails ? patientDetails.country : '')
    const [email, setEmail] = useState(patientDetails && patientDetails.email ? patientDetails.email : userInfo ? userInfo.email : "")
    const [phoneCode,setPhoneCode] = useState(patientDetails && patientDetails.phoneCode ? patientDetails.phoneCode : '91')
    const [phone, setPhone] = useState(userInfo.username.search('@') === -1 ? userInfo.username : patientDetails ? patientDetails.phone : '')

    const [countryCode, setCountryCode] = useState(patientDetails ? patientDetails.countryCode : '')
    const [stateCode, setStateCode] = useState(patientDetails ? patientDetails.stateCode : '')

    const [optionsState, setOptionsState] = useState(csc.getStatesOfCountry(countryCode))
    const [optionsCity, setOptionsCity] = useState(csc.getCitiesOfState(countryCode, stateCode))

    // Billing Address data    

    const [billingName, setBillingName] = useState(patientDetails && patientDetails.billingName ? patientDetails.billingName : userInfoFromStorage ? userInfoFromStorage.name : '')

    const [billingAddress1, setBillingAddress1] = useState(patientDetails ? patientDetails.billingAddress1 : '')
    const [billingAddress2, setBillingAddress2] = useState(patientDetails ? patientDetails.billingAddress2 : '')
    
    const [billingCity, setBillingCity] = useState(patientDetails ? patientDetails.billingCity : '')
    const [billingState, setBillingState] = useState(patientDetails ? patientDetails.billingState : '')
    const [billingPostalCode, setBillingPostalCode] = useState(patientDetails ? patientDetails.billingPostalCode : '')
    const [billingCountry, setBillingCountry] = useState(patientDetails ? patientDetails.billingCountry : '')
    const [billingEmail, setBillingEmail] = useState(patientDetails && patientDetails.billingEmail ? patientDetails.billingEmail : userInfo ? userInfo.email : '')
    const [billingPhone, setBillingPhone] = useState(userInfo.username.search('@') === -1 ? userInfo.username : patientDetails ? patientDetails.billingPhone : '')
    const [phoneCodeBilling, setPhoneCodeBilling] = useState(patientDetails && patientDetails.phoneCodeBilling ? patientDetails.phoneCodeBilling : '91')

    const [billingCountryCode, setBillingCountryCode] = useState(patientDetails ? patientDetails.billingCountryCode : '')
    const [billingStateCode, setBillingStateCode] = useState(patientDetails ? patientDetails.billingStateCode : '')

    const [optionsStateBilling, setOptionsStateBilling] = useState(csc.getStatesOfCountry(countryCode))
    const [optionsCityBilling, setOptionsCityBilling] = useState(csc.getCitiesOfState(billingCountryCode, billingStateCode))

    const [sameAsShipping, setSameAsShipping] = useState(true)

    // const prescriptionFromStorage = localStorage.getItem('prescription') ?
    //     localStorage.getItem('prescription') : null
    
    // const reportFromStorage = localStorage.getItem('report') ?
    //     localStorage.getItem('report') : null

    // const [prescription, setPrescription] = useState(prescriptionFromStorage);
    // const [report, setReport] = useState(reportFromStorage);

    // const prescriptionHandler = (e) => {   
    //     setPrescription(null)     
    //     setPrescription(e.target.files[0]);
    //     localStorage.setItem('prescription',prescription)
    // };
    
    // const reportHandler = (e) => {     
    //     setReport(null)   
    //     setReport(e.target.files[0]);
    //     localStorage.setItem('report',report)
    // };

    // console.log('prescriptionFromStorage',typeof prescriptionFromStorage)
    // console.log('report',report)
    // console.log('dob',dob)
    const submitHandler = (e) => {
        e.preventDefault()

        const phoneWithoutCode = phone.replace(phoneCode, "")
        const phoneBillingWithoutCode = billingPhone.replace(phoneCodeBilling, "")

        if(!Array(csc.getCountryByCode(countryCode))[0].name){
            alert('Please Select Shipping Country.')
        }else if(Array(csc.getCountryByCode(countryCode))[0].name && csc.getStatesOfCountry(countryCode).length>0 && (!state || state === '' || state === 'Choose State...')) {
            alert('Please Select Shipping State.')
        }else if(Array(csc.getCountryByCode(countryCode))[0].name && csc.getStatesOfCountry(countryCode).length>0 
                && csc.getCitiesOfState(countryCode,stateCode).length>0 && (!city || city === '' || city === 'Choose City...')) {
            alert('Please Select Shipping City.')
        }else if(!sameAsShipping && !Array(csc.getCountryByCode(billingCountryCode))[0].name){
            alert('Please Select Billing Country.')
        }else if(!sameAsShipping && Array(csc.getCountryByCode(billingCountryCode))[0].name && csc.getStatesOfCountry(billingCountryCode).length>0 && (!billingState || billingState === '' || billingState === 'Choose State')) {
            alert('Please Select Billing State.')
        }else if(!sameAsShipping && Array(csc.getCountryByCode(billingCountryCode))[0].name && csc.getStatesOfCountry(billingCountryCode).length>0 
                && csc.getCitiesOfState(billingCountryCode,billingStateCode).length>0 && (!billingCity || billingCity === '' || billingCity === 'Choose City...')) {
            alert('Please Select Billing City.')
        }else if(!phoneWithoutCode){
            alert('Please Enter Phone No.')
        }
        else if(!sameAsShipping && !phoneBillingWithoutCode){
            alert('Please Enter Billing Phone No.')
        }
        else{
    
            dispatch(savePatientDetails({
                name,gender,dob,calendarDate,
                symptoms:symptoms ? symptoms : "",
                profession: "",
                // profession:profession ? profession : "",
                address1,
                address2:address2 ? address2 : "",
                city,state,
                postalCode,country,
                email:email ? email : ""
                ,phone,countryCode,stateCode,
                billingName, billingAddress1, 
                billingAddress2: billingAddress2 ? billingAddress2 : "",
                billingCity, billingState, 
                billingStateCode, billingCountryCode,               
                billingPostalCode, billingCountry,
                billingEmail:billingEmail ? billingEmail : "", 
                billingPhone:billingPhone ? billingPhone : "", 
                phoneCodeBilling, sameAsShipping,
                prescription: '',
                report: '' ,
            }))
            // localStorage.setItem('prescription',prescription) 
            // localStorage.setItem('report',report) 
            if(countryCode === "IN"){
                dispatch(setCurrencyinAction('INR'))
                
                // cartItems.map(item=>(
                //     dispatch(addToCart(item.product,item.weightId,item.qty,'INR'))
                // ))
            }else{
                dispatch(setCurrencyinAction('USD'))
                
                // cartItems.map(item=>(
                //     dispatch(addToCart(item.product,item.weightId,item.qty,'USD'))
                // ))
            }
            history.push('/confirm-appointment')
        }
       
    }

    useEffect(()=>{
        if(!appointmentService){
            history.push('/services')
        }
    },[appointmentService,history])


    useEffect(()=>{
        if (sameAsShipping){
            setBillingName(name)
            setBillingAddress1(address1)
            setBillingAddress2(address2)
            setBillingCity(city)
            setBillingState(state)
            setBillingPostalCode(postalCode)
            setBillingCountry(country)
            setBillingEmail(email)
            setBillingPhone(phone)
            setBillingCountryCode(countryCode)
            setBillingStateCode(stateCode)
        }
    },[sameAsShipping,name,address1,address2,city,
        state,postalCode,country,email,phone,countryCode,stateCode])
    // console.log('phone:',phone, phone.startsWith(phoneCode),phone.slice(phoneCode.length).length,phoneCode)
        // console.log('dob',dob)
        // console.log('phone',phone)
        // console.log('userinfo',userInfo.username)
        
    const dobHandler = (date) => {
        setCalendarDate(date)
        setDob(moment(date).format("YYYY-MM-DD"))  
    }

    const  handlePhone =(value, data)=> {
        setPhone(value)
        setPhoneCode(data['dialCode'])
    }
    
    const  handlePhoneBilling =(value, data)=> {
        setBillingPhone(value)
        setPhoneCodeBilling(data['dialCode'])
    }
    
    return (
        <div>
            {/* <!-- Page Title--> */}
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>Patient Details</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="index.html">Home</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>Patient Details</li>
                </ul>
                </div>
            </div>
            </div>
            
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2">
            <div className="row" style={{justifyContent:"center"}}>
                {/* <!-- Checkout Adress--> */}
                <div className="col-xl-10 col-lg-10" >
                <div className= "steps flex-sm-nowrap mb-5">
                    <Link className= "step " to= {appointmentService ? `/schedule-appointment/${appointmentService.slug}` : '/services'}>
                        <h4 className= "step-title">{appointmentSchedule && appointmentSchedule.date && <i className= "icon-check-circle" style={{color:"#007bff"}}></i>} 1. Schedule</h4>
                    </Link> 

                    
                    <Link className= "step active" to= "#">
                        <h4 className= "step-title">{patientDetails && patientDetails.name && <i className= "icon-check-circle" style={{color:"#007bff"}}></i>} 2. Patient Details</h4>
                    </Link> 
                    <Link className= "step " to= {appointmentSchedule && patientDetails && appointmentSchedule.date && patientDetails.name ? "/confirm-appointment" : "#"}>
                        <h4 className= "step-title">{appointmentSchedule && patientDetails && appointmentSchedule.date && patientDetails.name && <i className= "icon-check-circle" style={{color:"#007bff"}}></i>}3. Confirm Appointment</h4>
                    </Link> 
                    
                    <Link className= "step" to= "#">
                        <h4 className= "step-title">3. Pay</h4>
                    </Link> 
                </div>
            
                
                <h4>Patient Details</h4>
                
                <hr className="padding-bottom-1x" />
                <form  method="post"
                        onSubmit={submitHandler}
                        >
                <div className="row">
                    <div className="col-sm-4">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-name">Full Name</label>
                        <input className="form-control" type="text" 
                                placeholder='Enter Full Name'
                                value={name ? name : ''}
                                onChange={(e)=>setName(e.target.value)}
                                id="checkout-name" required/>
                    </div>
                    </div>
                    <div className="col-sm-4">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-gender">Gender</label>
                    <select className="form-control" 
                        as="select"
                        value = {gender}
                        onChange = {(e) => {setGender(e.target.value)}}
                        required
                        id="gender-select">
                        
                            <option value="">
                                Select Gender
                            </option> 
                            <option value="Male">
                                Male
                            </option> 
                            <option value="Female">
                                Female
                            </option> 
                            <option value="Other">
                                Other
                            </option> 
                        </select>
                    </div>
                    </div>

                    <div className="col-sm-4">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Date of Birth</label>
                    <br/>
                    <div className="customDatePickerWidth">
                        <DatePicker 
                            selected={calendarDate ? calendarDate : null} 
                            className="form-control"      
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Click to select DOB"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            maxDate={addDays(new Date(), 0)}
                            onChange={(date) => dobHandler(date)} 
                            required />
                    </div>
                    
                
                    </div>
                    </div>
               
                    
                   
                </div>
                {/* <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Profession</label>
                        <input className="form-control" type="text" 
                                placeholder='Enter Profession (Optional)'
                                value={profession ? profession : ''}
                                onChange={(e)=>setProfession(e.target.value)}
                                id="checkout-add1" />
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Date of Birth</label>
                    <br/>
                    <div className="customDatePickerWidth">
                        <DatePicker 
                            selected={calendarDate ? calendarDate : null} 
                            className="form-control"      
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Click to select DOB"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            maxDate={addDays(new Date(), 0)}
                            onChange={(date) => dobHandler(date)} 
                            required />
                    </div>
                    
                
                    </div>
                    </div>
               
                </div> */}

                {/* <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group" >
                                <label
                                  style={{
                                    paddingLeft: "0",
                                    textAlign: "left",
                                  }}
                                  htmlFor="prescription-image">
                                  Prescription (Optional)
                                </label>
                                <div className="custom-file">
                                  <input
                                    className="custom-file-input"
                                    type="file"
                                    id="prescription-image"
                                    onChange={prescriptionHandler}
                                    
                                  />
                                  <label
                                    className="custom-file-label"
                                    style={{ textAlign: "left" }}
                                    htmlFor="prescription-image"
                                  >
                                    {prescription
                                      ? prescription.name
                                      : "Choose file..."}
                                  </label>
                                </div>
                              </div>

                    </div>
                
                    <div className="col-sm-6">
                    <div className="form-group">
                                <label
                                  style={{
                                    paddingLeft: "0",
                                    textAlign: "left",
                                  }}
                                  htmlFor="report-image"
                                >
                                  Report (Optional)
                                </label>
                                <div className="custom-file">
                                  <input
                                    className="custom-file-input"
                                    type="file"
                                    id="report-image"
                                    onChange={reportHandler}
                                    
                                  />
                                  <label
                                    className="custom-file-label"
                                    style={{ textAlign: "left" }}
                                    htmlFor="report-image"
                                  >
                                    {report
                                      ? report.name
                                      : "Choose file..."}
                                  </label>
                                </div>
                              </div>

                    </div>
                
                
                </div>
                 */}
                <div className="row">
                    <div className="col-sm-12">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Symptoms</label>
                        <textarea
                            className="form-control"
                            value={symptoms}
                            onChange={(e) => setSymptoms(e.target.value)}
                            id="symptoms-text"
                            placeholder="Write Symptoms (Optional)"
                            rows="4"
                            
                        ></textarea>
                    </div>
                    </div>
                    
                </div>



                <h4>Correspondence Address</h4>                
                <hr className="padding-bottom-1x" />

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Address Line 1</label>
                        <input className="form-control" type="text" 
                                placeholder='Address Line 1 (Required)'
                                value={address1 ? address1 : ''}
                                onChange={(e)=>setAddress1(e.target.value)}
                                id="checkout-add1" required/>
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Address Line 2</label>
                        <input className="form-control" type="text" 
                                placeholder='Address Line 2'
                                value={address2 ? address2 : ''}
                                onChange={(e)=>setAddress2(e.target.value)}
                                id="checkout-add2" />
                    </div>
                    </div>
                </div>

                <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                    
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Country</label>
                    <select className="form-control" 
                            onChange={(e)=>{
                                setCountry(e.target.value)
                                if (Number(e.target.selectedIndex) !== 0){
                                    setCountryCode(Array(optionsCountry)[0][Number(e.target.selectedIndex)-1].isoCode)
                                    setOptionsState(csc.getStatesOfCountry(Array(optionsCountry)[0][Number(e.target.selectedIndex)-1].isoCode))    
                                }
                                }}
                            value={country ? country : ""}
                            id="select-country" required>
                        <option key="choose-country">Choose Country...</option>
                        {
                        [...Array(optionsCountry)[0]].map((x) => (
                                            
                                            <option key={x.name} value={x.name} >
                                                {x.name}
                                            </option>
                                        ))
                        }
                        
                    </select>
                    
                    </div>
                    </div>
                    
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">State</label>
                    <select className="form-control" 
                            onChange={(e)=>{
                                setState(e.target.value)
                                
                                if(Number(e.target.selectedIndex) !== 0){
                                    setOptionsCity(csc.getCitiesOfState(countryCode, (Array(optionsState)[0][Number(e.target.selectedIndex)-1].isoCode)))
                                    setStateCode(Array(optionsState)[0][Number(e.target.selectedIndex)-1].isoCode)
                                }
                                
                                
                            }}
                            value={state ? state : ""}
                            id="select-state" required>
                        <option key="choose-state">Choose State...</option>
                        {
                        [...Array(optionsState)[0]].map((x) => (
                                            
                                            <option key={x.name} value={x.name}>
                                                {x.name}
                                            </option>
                                        ))
                        }
                        
                    </select>
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">City</label>
                        <select className="form-control" 
                                onChange={(e)=>{
                                    setCity(e.target.value)
                                    // setCityCode(Array(optionsCity)[0][Number(e.target.selectedIndex)-1].name)
                                }}
                                value={city ? city : ""}
                                id="select-city" required>
                            <option key="choose-city">Choose City...</option>
                            {
                            [...Array(optionsCity)[0]].map((x,index) => (
                                                
                                                <option key={index+'shippingcity'} value={x.name}>
                                                    {x.name}
                                                </option>
                                            ))
                            }
                            
                        </select>
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Postal Code</label>
                        <input className="form-control" type="text" 
                                placeholder='Enter Postal Code (Required)'
                                value={postalCode ? postalCode : ''}
                                onChange={(e)=>setPostalCode(e.target.value)}
                                id="checkout-postal" required/>
                    </div>
                    </div>
                    
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Email</label>
                        <input className="form-control" type="email" 
                                placeholder='Enter Email id'
                                value={email ? email : ''}
                                onChange={(e)=>setEmail(e.target.value)}
                                id="checkout-email"
                                required />
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                   
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Phone Number</label>
                    
                    <PhoneInput 
                        containerClass="form-control"
                        inputStyle={{width:"106%", height:"100%",paddingLeft:"50px"}}
                        containerStyle={{paddingLeft:"0",width:"100%",border:"none"}}
                        countryCodeEditable ={false}
                        // buttonStyle={{width:"14%"}}
                        // inputClass="form-control"
                        // searchClass="form-control"
                        
                        country={countryCode && countryCode.toLowerCase()}
                        value={phone}
                        onChange={handlePhone}
                    />

                    </div>
                    </div>
                </div>

                <h4>Billing Address</h4>                
                <hr className="padding-bottom-1x" />

                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                    
                        <input className="custom-control-input" type="checkbox" 
                            onChange={(e)=>setSameAsShipping(!sameAsShipping)}
                            id="same_address" checked={sameAsShipping === true } />
                    
                    <label className="custom-control-label" htmlFor="same_address">Same as Correspondence address</label>
                    </div>
                </div>

                {sameAsShipping === false ?
                    <><div className="row">
                    <div className="col-sm-12">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Full Name</label>
                        <input className="form-control" type="text" 
                                placeholder='Enter Full Name'
                                value={billingName ? billingName : ''}
                                onChange={(e)=>setBillingName(e.target.value)}
                                id="checkout-fnBilling" />
                    </div>
                    </div>
                   
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Address Line 1</label>
                        <input className="form-control" type="text" 
                                placeholder='Address Line 1 (Required)'
                                value={billingAddress1 ? billingAddress1 : ''}
                                onChange={(e)=>setBillingAddress1(e.target.value)}
                                id="checkout-add1Billing" required/>
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Address Line 2</label>
                        <input className="form-control" type="text" 
                                placeholder='Address Line 2'
                                value={billingAddress2 ? billingAddress2 : ''}
                                onChange={(e)=>setBillingAddress2(e.target.value)}
                                id="checkout-add2Billing" />
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Country</label>
                    <select className="form-control" 
                            onChange={(e)=>{
                                setBillingCountry(e.target.value)
                                
                                if (Number(e.target.selectedIndex) !== 0){
                                    setBillingCountryCode(Array(optionsCountry)[0][Number(e.target.selectedIndex)-1].isoCode)
                                setOptionsStateBilling(csc.getStatesOfCountry(Array(optionsCountry)[0][Number(e.target.selectedIndex)-1].isoCode))
                                }
                            }}
                            value={billingCountry ? billingCountry : ""}
                            id="select-countryBilling" required>
                        <option key="choose-countryBilling">Choose Country...</option>
                        {
                        [...Array(optionsCountry)[0]].map((x) => (
                                            
                                            <option key={x.name+'billing'} value={x.name} >
                                                {x.name}
                                            </option>
                                        ))
                        }
                        
                    </select>
                    </div>
                    </div>
                    
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">State</label>
                    <select className="form-control" 
                            onChange={(e)=>{
                                setBillingState(e.target.value)

                                if (Number(e.target.selectedIndex) !== 0){
                                setOptionsCityBilling(csc.getCitiesOfState(billingCountryCode, (Array(optionsStateBilling)[0][Number(e.target.selectedIndex)-1].isoCode)))
                                setBillingStateCode(Array(optionsStateBilling)[0][Number(e.target.selectedIndex)-1].isoCode)
                                }
                            }}
                            value={billingState}
                            id="select-stateBilling" required>
                        <option key="choose-stateBilling">Choose State...</option>
                        {
                        [...Array(optionsStateBilling)[0]].map((x) => (
                                            
                                            <option key={x.name+'billing'} value={x.name}>
                                                {x.name}
                                            </option>
                                        ))
                        }
                        
                    </select>
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">City</label>
                        <select className="form-control" 
                                onChange={(e)=>{
                                    setBillingCity(e.target.value)
                                    // setCityCodeBilling(Array(optionsCityBilling)[0][Number(e.target.selectedIndex)-1].name)
                                }}
                                value={billingCity}
                                id="select-cityBilling" required>
                            <option key="choose-cityBilling">Choose City...</option>
                            {
                            [...Array(optionsCityBilling)[0]].map((x,index) => (
                                                
                                                <option key={index+'billingcity'} value={x.name}>
                                                    {x.name}
                                                </option>
                                            ))
                            }
                            
                        </select>
                    </div>
                    </div>

                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Postal Code</label>
                        <input className="form-control" type="text" 
                                placeholder='Enter Postal Code (Required)'
                                value={billingPostalCode ? billingPostalCode : ''}
                                onChange={(e)=>setBillingPostalCode(e.target.value)}
                                id="checkout-postalBilling" required />
                    </div>
                    </div>
                    
                </div>

                <div className="row">
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Email</label>
                        <input className="form-control" type="email" 
                                placeholder='Enter Email id'
                                value={billingEmail ? billingEmail : ''}
                                onChange={(e)=>setBillingEmail(e.target.value)}
                                id="checkout-emailBilling" />
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                    <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Phone Number</label>
                    <PhoneInput 
                       containerClass="form-control"
                       inputStyle={{width:"106%", height:"100%",paddingLeft:"50px"}}
                       containerStyle={{paddingLeft:"0",width:"100%",border:"none"}}
                       countryCodeEditable ={false}
                    //    buttonStyle={{maxWidth:"14%"}}
                        
                        country={billingCountryCode && billingCountryCode.toLowerCase()}
                        value={billingPhone}
                        onChange={handlePhoneBilling}
                    />
                    </div>
                    </div>
                </div></> : null
                
                }
                

                <div className="d-flex justify-content-between paddin-top-1x mt-4">
                    <Link className="btn btn-outline-secondary" to={appointmentService ? `/schedule-appointment/${appointmentService.slug}` : '/services'}>
                        <i className="icon-arrow-left"></i>
                        <span className="hidden-xs-down">&nbsp;Back To Schedule</span>
                    </Link>
                    
                    <button className="btn btn-primary" 
                            type='submit'
                            >
                        <span className="hidden-xs-down">Submit & Continue&nbsp;</span>
                        <i className="icon-arrow-right"></i>
                    </button>
                </div>
                </form>
                </div>
  
               
            </div>
            </div>
        </div>
    )
}

export default PatientDetailsScreen
