import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { validatePhone, validateEmail } from '../actions/userActions'
import {USER_VALIDATE_PHONE_RESET,USER_VALIDATE_EMAIL_RESET} from '../constants/userConstants'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
// import axios from 'axios'

function RegisterScreen({location,history}) {

    // const [countryCode,setCountryCode] = useState('IN')
    const countryCode = 'IN'
    const [phoneCode,setPhoneCode] = useState('+91')

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({ type: USER_VALIDATE_PHONE_RESET})       
        dispatch({ type: USER_VALIDATE_EMAIL_RESET})       
    }, [])

    // const [reset,SetReset] = useState(false)
    // if(!reset){
    //     dispatch(logout)
    //     SetReset(true)
    // }

    // useEffect(() => {
    //     const getGeoInfo = () => {
            
    //             axios.get('https://ipapi.co/json/').then((response) => {
    //                 let data = response.data;
    //                 // console.log('ip data:',data)
    //                 setCountryCode(data.country)
    //                 setPhoneCode(data.country_calling_code.replace('+',""))
    //             }).catch((error) => {
    //                 console.log(error);
    //             });
            
            
    //     };
    //     getGeoInfo()
    // },[countryCode])

    const [name, setName] = useState('')
    const [phone, setPhone] = useState(phoneCode)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const [registerUsingPhone, setRegisterUsingPhone] = useState(true)
    const [registerUsingEmail, setRegisterUsingEmail] = useState(false)

    const userValidatePhone = useSelector(state => state.userValidatePhone)
    const {error:errorValidatePhoneRegister, loading:loadingValidatePhone, otpInfo } = userValidatePhone
    
    const userValidateEmail = useSelector(state => state.userValidateEmail)
    const {error:errorValidateEmailRegister, loading:loadingValidateEmail, otpInfo:otpInfoEmail } = userValidateEmail

    const registerSelectHandler = (method) =>{
        // preventDefault()
        if (method==="phone"){
            setRegisterUsingEmail(false)
            setRegisterUsingPhone(true)
            setEmail('')
        } else if (method==="email"){
            setRegisterUsingEmail(true)
            setRegisterUsingPhone(false)
            setPhone('')
        }
    }

    

    const redirect = location.search ? location.search.split('=')[1] : '/'
    // const userRegister = useSelector(state => state.userRegister)
    // const {error, loading, userInfo } = userRegister

    useEffect(() => {

        if ((otpInfo && otpInfo.phone) ) {
            history.push(redirect ? `/validate-phone-otp?redirect=${redirect}` : '/validate-phone-otp')
        }else if( (otpInfoEmail && otpInfoEmail.email)){
            history.push(redirect ? `/validate-email-otp?redirect=${redirect}` : '/validate-phone-otp')
        }

    },[history,otpInfo,otpInfoEmail,redirect])

    const  handleOnChange =(value, data)=> {
        setPhone(value.replace(/[-+()\s]/g, ''))
        setPhoneCode(data['dialCode'])
      }

    const submitHandler = (e) => {
        e.preventDefault()
        if(registerUsingPhone){
            setEmail('')
            
            const phoneWithoutCode = phone.replace(phoneCode, "")
            if(!phoneWithoutCode ){
                alert('Please Enter Phone Number.')
            }else if (password !== confirmPassword){
                setMessage('Passwords do not match')
            } else {
                dispatch(validatePhone(name,phone,email,password))
                // dispatch(register(name,phone,email,password))
            }
        }else{
            setPhone('')
            if(!email ){
                alert('Please Enter your Email Id.')
            }else if (password !== confirmPassword){
                setMessage('Passwords do not match')
            } else {
                dispatch(validateEmail(name,phone,email,password))
            }
        }
        

        
        
    }
    return (
        <div>
            
            
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2 mt-5">
            <div className="row" style={{justifyContent: "center"}}>
                
                <div className="col-lg-5 col-md-7 col-sm-10">
                                
                <div className="padding-top-3x hidden-md-up"></div>
               <form className="card" method="post"  onSubmit={submitHandler}>
               <div className="card-body">
                    <h4 className="margin-bottom-1x">User Registration</h4>
                    <ul className="nav nav-tabs nav-justified" role="tablist">
                        
                        <li className="nav-item">
                            <button 
                                style={{width:"100%", background:"none",margin:"0px",color:registerUsingPhone ? "#0055FF" : null}}
                                className={registerUsingPhone ? "nav-link active" : "nav-link"} 
                                type="button" onClick={()=>registerSelectHandler('phone')} 
                                data-toggle="tab" role="tab">Phone
                            </button>
                        </li>
                        <li className="nav-item" style={{color:"blue"}}>
                            <button 
                                style={{width:"100%", background:"none",margin:"0px",color:registerUsingEmail ? "#0055FF" : null}}
                                className={registerUsingEmail ? "nav-link active" : "nav-link"}
                                type="button" onClick={()=>registerSelectHandler('email')} 
                                data-toggle="tab" role="tab">Email                                
                            </button>
                        </li>
                    </ul><br/>

                    <div className="form-group" controlid="name">
                        <label style={{paddingLeft:'0px'}} htmlFor="reg-fn">Full Name</label>
                        <input className="form-control" 
                                placeholder="Enter Full Name"  
                                type="text" 
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                                id="reg-fn" required />
                    </div>

                    {registerUsingPhone &&
                        <div className="form-group input-group">
                        <label style={{paddingLeft:'0px'}} >Phone Number</label>

                        <PhoneInput 
                            containerClass="form-control"
                            inputStyle={{width:"105%", height:"100%",paddingLeft:"50px"}}
                            containerStyle={{paddingLeft:"0",width:"100%",border:"none"}}
                            buttonStyle={{paddingRight:"0px"}}
                            countryCodeEditable ={false}
                            enableSearch={true}
                            
                            country={countryCode}
                            value={phone}
                            onChange={handleOnChange}
                        />
                    </div>
                    }

                    {registerUsingEmail &&
                        <div className="form-group input-group" >
                        
                        <label style={{paddingLeft:'0px'}} >Email </label>

                        <input style={{width:'100%',borderRadius:"5px"}} className="form-control" 
                                type="email" 
                                placeholder="Enter Email" 
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                        />
                                
                        <span style={{marginTop: "16px"}} className="input-group-addon">
                            <i className="icon-mail"></i>
                        </span>
                    </div>
                    }

                    {/* <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="reg-phone">Phone Number</label>
                        <input className="form-control" type="text" id="reg-phone" required />
                    </div>
                    </div> */}
                    
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="reg-pass">Password</label>
                        <input className="form-control" 
                                type="password" 
                                placeholder="Enter Password"  
                                value={password}
                                onChange={(e)=>setPassword(e.target.value)}
                                id="reg-pass" required />
                    </div>
                    
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="reg-pass-confirm">Confirm Password</label>
                        <input className="form-control" 
                                type="password" 
                                placeholder="Confirm Password"  
                                value={confirmPassword}
                                onChange={(e)=>setConfirmPassword(e.target.value)}
                                id="reg-pass-confirm" required />
                    </div>
                    </div>
                    <div style={{marginTop: '-30px'}} className="col-12 text-center text-sm-center">

                    {message && <Message variant='danger'>{message}</Message>}
                    {errorValidatePhoneRegister && <Message variant='danger'>{errorValidatePhoneRegister}</Message>}                    
                    {loadingValidatePhone && <Loader />}
                    {errorValidateEmailRegister && <Message variant='danger'>{errorValidateEmailRegister}</Message>}                    
                    {loadingValidateEmail && <Loader />}

                    <button className="btn btn-primary btn-block mb-4" type="submit">Register</button>
                    </div>
                    <div className="col-12 text-center text-sm-center mb-3">
                    Already have an Account? {' '}
                    <span><Link style={{textDecoration:"none"}} to={redirect ? `/login?redirect=${redirect}` : '/login'}
                    >Sign In</Link></span></div>
                    <br />
                    
                </form>
                </div>
            </div>
            </div>
        </div>
    )
}

export default RegisterScreen
