import React from 'react'
import { Link } from 'react-router-dom'

function HyperbaricScreen() {
    return (
        <div>

            {/* <!-- Page Title--> */}
                <div className="page-title mb-0">
                <div className="container">
                    <div className="column">
                    <h1>Hyperbaric</h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li><Link to="/">Home</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>Treatments</li>
                        <li className="separator">&nbsp;</li>
                        <li>Hyperbaric</li>
                    </ul>
                    </div>
                </div>
                </div>

                <section >
                    <img  src="/images/hyperbaric_banner.jpg" alt="dietnutrition-banner"/>                    
                </section>
                <br/>
                {/* <!-- Page Content--> */}
                <div className="container padding-bottom-2x mb-2">
                
                    <div className="row justify-content-center">
                        {/* <!-- Content--> */}
                        <div className="col-xl-12 col-lg-12 order-lg-2" style={{fontSize:"16px", color:"black"}}>
                            
                        
                            <h2 className="pt-4">Hyperbaric Oxygen Therapy</h2><hr className="margin-bottom-1x"/>
                            <p>Hyperbaric oxygen therapy involves breathing pure oxygen in a pressurized room or tube. It is a 
                                well-established treatment for decompression sickness, a hazard of scuba diving. Other conditions 
                                treated with hyperbaric oxygen therapy include serious infections, bubbles of air in your blood 
                                vessels, and wounds that won't heal as a result of diabetes or radiation injury.

                            </p>
                            <p>In a hyperbaric oxygen therapy chamber, the air pressure is increased to three times higher 
                                than normal air pressure. Under these conditions, your lungs can gather more oxygen than
                                 would be possible breathing pure oxygen at normal air pressure.
                            </p>

                            <p>Your blood carries this oxygen throughout your body. This helps fight bacteria and stimulate
                                 the release of substances called growth factors and stem cells, which promote healing.
                            </p>
                            <img  class="d-block mx-auto " src="/images/hyperbaric2.jpg" alt="hyperbaric2" /><br/><br/>
                            <h3>Why Hyperbaric Oxygen Therapy?</h3>
                            <p>
                            Your body's tissues need an adequate supply of oxygen to function. When tissue is injured, 
                            it requires even more oxygen to survive. Hyperbaric oxygen therapy increases the amount of oxygen 
                            your blood can carry. An increase in blood oxygen temporarily restores normal levels of blood 
                            gases and tissue function to promote healing and fight infection.
                            </p>
                            <p>
                            Hyperbaric oxygen therapy is used to treat several medical conditions. And medical institutions 
                            use it in different ways. Your doctor may suggest hyperbaric oxygen therapy in one of the 
                            following conditions:

                            </p>
                            <ul>
                                <li>
                                Anemia, severe
                                </li>
                                <li>
                                Brain abscess
                                </li>
                                <li>
                                Bubbles of air in your blood vessels (arterial gas embolism)
                                </li>
                                <li>
                                Burn
                                </li>
                                <li>
                                Decompression sickness
                                </li>
                                <li>
                                Carbon monoxide poisoning
                                </li>
                                <li>
                                Crushing injury
                                </li>
                                <li>
                                Deafness, sudden
                                </li>
                                <li>                                
                                    Gangrene
                                </li>
                                <li>                                
                                Infection of skin or bone that causes tissue death
                                </li>
                                <li>                                
                                Nonhealing wounds, such as a diabetic foot ulcer
                                </li>
                                <li>                                
                                Radiation injury
                                </li>
                                <li>                                
                                Skin graft or skin flap at risk of tissue death

                                </li>
                                <li>                                
                                Vision loss, sudden and painless
                                </li>
                            </ul>

                            <p>Hyperbaric oxygen therapy can effectively treat the following conditions:</p>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                <ul>
                                    <li>
                                    AIDS/HIV
                                    </li>
                                    <li>
                                    Allergies
                                    </li>
                                    <li>
                                    Alzheimer's disease
                                    </li>
                                    <li>
                                    Arthritis
                                    </li>
                                    <li>
                                    Asthma
                                    </li>
                                    <li>
                                    Autism
                                    </li>
                                    <li>
                                    Bell's palsy
                                    </li>
                                    <li>
                                    Brain injury
                                    </li>
                                    <li>                                
                                    Cancer
                                    </li>
                                    <li>                                
                                    Cerebral palsy
                                    </li>
                                    <li>                                
                                    Chronic fatigue syndrome
                                    </li>
                                    <li>                                
                                    Cirrhosis
                                    </li>
                                    
                                </ul>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                <ul>
                                    <li>
                                    Depression
                                    </li>
                                    <li>
                                    Fibromyalgia
                                    </li>
                                    <li>
                                    Gastrointestinal ulcers
                                    </li>
                                    <li>
                                    Heart disease
                                    </li>
                                    <li>
                                    Heatstroke
                                    </li>
                                    <li>
                                    Hepatitis
                                    </li>
                                    <li>
                                    Migraine
                                    </li>
                                    <li>
                                    Multiple sclerosis
                                    </li>
                                    <li>                                
                                    Parkinson's disease
                                    </li>
                                    <li>                                
                                    Spinal cord injury
                                    </li>
                                    <li>                                
                                    Nonhealing wounds, such as a diabetic foot ulcer
                                    </li>
                                    <li>                                
                                    Sports injury
                                    </li>
                                    <li>                                
                                    Stroke
                                    </li>
                                    
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                
                    
                    
                    
                </div>
    
            </div>
    )
}

export default HyperbaricScreen
