export const SERVICE_LIST_REQUEST = 'SERVICE_LIST_REQUEST'
export const SERVICE_LIST_SUCCESS = 'SERVICE_LIST_SUCCESS'
export const SERVICE_LIST_FAIL = 'SERVICE_LIST_FAIL'

export const SERVICE_DETAILS_REQUEST = 'SERVICE_DETAILS_REQUEST'
export const SERVICE_DETAILS_SUCCESS = 'SERVICE_DETAILS_SUCCESS'
export const SERVICE_DETAILS_FAIL = 'SERVICE_DETAILS_FAIL'
export const SERVICE_DETAILS_RESET = 'SERVICE_DETAILS_RESET'

export const SERVICE_DELETE_REQUEST = 'SERVICE_DELETE_REQUEST'
export const SERVICE_DELETE_SUCCESS = 'SERVICE_DELETE_SUCCESS'
export const SERVICE_DELETE_FAIL = 'SERVICE_DELETE_FAIL'

export const SERVICE_CREATE_REQUEST = 'SERVICE_CREATE_REQUEST'
export const SERVICE_CREATE_SUCCESS = 'SERVICE_CREATE_SUCCESS'
export const SERVICE_CREATE_FAIL = 'SERVICE_CREATE_FAIL'
export const SERVICE_CREATE_RESET = 'SERVICE_CREATE_RESET'

export const SERVICE_UPDATE_REQUEST = 'SERVICE_UPDATE_REQUEST'
export const SERVICE_UPDATE_SUCCESS = 'SERVICE_UPDATE_SUCCESS'
export const SERVICE_UPDATE_FAIL = 'SERVICE_UPDATE_FAIL'
export const SERVICE_UPDATE_RESET = 'SERVICE_UPDATE_RESET'

export const SERVICE_CREATE_REVIEW_REQUEST = 'SERVICE_CREATE_REVIEW_REQUEST'
export const SERVICE_CREATE_REVIEW_SUCCESS = 'SERVICE_CREATE_REVIEW_SUCCESS'
export const SERVICE_CREATE_REVIEW_FAIL = 'SERVICE_CREATE_REVIEW_FAIL'
export const SERVICE_CREATE_REVIEW_RESET = 'SERVICE_CREATE_REVIEW_RESET'

export const SERVICE_TIMESLOTS_REQUEST = 'SERVICE_TIMESLOTS_REQUEST'
export const SERVICE_TIMESLOTS_SUCCESS = 'SERVICE_TIMESLOTS_SUCCESS'
export const SERVICE_TIMESLOTS_FAIL = 'SERVICE_TIMESLOTS_FAIL'

export const SERVICE_TIMESLOT_CREATE_REQUEST = 'SERVICE_TIMESLOT_CREATE_REQUEST'
export const SERVICE_TIMESLOT_CREATE_SUCCESS = 'SERVICE_TIMESLOT_CREATE_SUCCESS'
export const SERVICE_TIMESLOT_CREATE_FAIL = 'SERVICE_TIMESLOT_CREATE_FAIL'
export const SERVICE_TIMESLOT_CREATE_RESET = 'SERVICE_TIMESLOT_CREATE_RESET'

export const SERVICE_TIMESLOT_UPDATE_REQUEST = 'SERVICE_TIMESLOT_UPDATE_REQUEST'
export const SERVICE_TIMESLOT_UPDATE_SUCCESS = 'SERVICE_TIMESLOT_UPDATE_SUCCESS'
export const SERVICE_TIMESLOT_UPDATE_FAIL = 'SERVICE_TIMESLOT_UPDATE_FAIL'
export const SERVICE_TIMESLOT_UPDATE_RESET = 'SERVICE_TIMESLOT_UPDATE_RESET'

export const SERVICE_TIMESLOT_DELETE_REQUEST = 'SERVICE_TIMESLOT_DELETE_REQUEST'
export const SERVICE_TIMESLOT_DELETE_SUCCESS = 'SERVICE_TIMESLOT_DELETE_SUCCESS'
export const SERVICE_TIMESLOT_DELETE_FAIL = 'SERVICE_TIMESLOT_DELETE_FAIL'
