import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { resetPassword } from '../actions/userActions'

function PasswordResetScreen({location,history}) {

    
    const [email, setEmail] = useState('')
    // const [requestSent, setRequestSent] =useState(false)

    const dispatch = useDispatch()

    // const redirect = location.search ? location.search.split('=')[1] : '/'
    const userPasswordReset = useSelector(state => state.userPasswordReset)
    const {error, loading, success } = userPasswordReset
    // useEffect(() => {
    //     if (userInfo) {
    //         history.push(redirect)
    //     }
    // },[history,userInfo,redirect])

    const submitHandler = (e) => {
        e.preventDefault()

        dispatch(resetPassword(email))
       

    }

    // if (requestSent){
    //     history.push('/login')
    // }

    return (
        <div>
             {/* <!-- Page Title--> */}
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>Password Recovery</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Home</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li><Link to="#">Account</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>Password Recovery</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10">
                <h2>Forgot your password?</h2>
                <p>Change your password in three easy steps. This helps to keep your new password secure.</p>
                <ol className="list-unstyled">
                    <li><span className="text-primary text-medium">1. </span>Fill in your email address below.</li>
                    <li><span className="text-primary text-medium">2. </span>We'll email you a password recovery link.</li>
                    <li><span className="text-primary text-medium">3. </span>Follow the link and set the new password.</li>
                </ol>
                {error && <Message variant='danger'>{error}</Message>}
                {success && <Message variant='success'>Password Recovery Link sent to your email.</Message>}
                {loading && <Loader />}
                <form className="card mt-4" method="post"  onSubmit={submitHandler}>
                    <div className="card-body">
                    <div className="form-group">
                        <label for="email-for-pass">Enter your email address</label>
                        <input className="form-control" type="text" id="email-for-pass" 
                               value={email}
                               onChange={(e)=>setEmail(e.target.value)}
                               required/>
                               <small className="form-text text-muted">Type in the email address you used when you registered with Etichan Health Care. We'll email a password recovery link to this address.</small>
                    </div>
                    </div>
                    <div className="card-footer">
                    <button className="btn btn-primary" type="submit">Get New Password</button>
                    <Link className="btn btn-secondary" to="/login">Back to Login</Link>
                    
                    </div>
                    {/* <Link to="/login">Back to Login</Link> */}
                </form>
                </div>
            </div>
            </div>

            
        </div>
    )
}

export default PasswordResetScreen
