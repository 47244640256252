import React from 'react'
import Rating from './Rating'
import { Link } from 'react-router-dom'

import "../index.css"

function Product({ product,currency }) {
    
    // function truncate(str, n){
    //     if (str){
    //         return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    //     }
        
    // };


    return (     
        <div className="product-card mb-30" style={{color:"black"}}>
            {/* <div className="product-badge bg-danger">Sale</div> */}
            <div >
           
            <Rating value={product.rating} text={`${product.numReviews} reviews`} color={'#ffa000'} />
            </div>
            <Link className="product-thumb" to={`/${product.slug}`}>
                <img src={`${product.image_1}`} alt="Product" />
                <img  src="/images/image_footer.png" alt="Product" />
            </Link>
           
            
            <div className="product-card-body"  style={{padding:"5px"}}>
            {/* <div className="product-category"><Link to="#">{product.category}</Link></div> */}
            <h3 className="product-title" style={{marginBottom:"0px",marginTop:"8px"}}><Link to={`/${product.slug}/`}><strong id = "prod-title" >{ product.name }</strong></Link></h3>
            <h4 className="product-price" id = "prod-price" style={{marginTop:"5px", paddingLeft:"4px",paddingRight:"4px"}}>
                {/* <del>₹{ product.rupee_price_1 }</del>*/}
                { currency === 'INR' ? <strong>₹{product.rupee_price_1}</strong> : <strong>${product.dollar_price_1}</strong>} <span style={{fontSize:"small"}}>({product.weight_1})</span>
            </h4>
            <div id={"des-box"}>
            <Link style={{color:"black",textDecoration: "none"}} to={`/${product.slug}/`}>
            <p id="des-text">
            <strong>Description: </strong>

            {product.description}
            
            </p>
            
            </Link>
            </div>
            
            </div>
            <div className="product-button-group" style={{maxHeight:"20px"}}>
                <Link className="product-button btn-wishlist" to="#">
                    <i className="icon-heart"></i><span>Wishlist</span>
                </Link>
                <Link className="product-button btn-compare" to={`/${product.slug}`}>
                    <i className="icon-info"></i><span>Info</span>
                </Link>
                <Link className="product-button" to={`/${product.slug}`} 
                    data-toast data-toast-type="success" 
                    data-toast-position="topRight" 
                    data-toast-icon="icon-check-circle" 
                    data-toast-title="Product" 
                    data-toast-message="successfuly added to cart!">
                    <i className="icon-shopping-cart"></i><span>To Cart</span>
                </Link>
            </div>
        </div>
    )
}

export default Product
