import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { listServiceDetails } from "../../actions/serviceActions";
import {
  saveAppointmentSchedule,
  saveService,
} from "../../actions/serviceCartActions";
import { listDoctors } from "../../actions/doctorActions";
// import styled, { css } from "styled-components";

import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import "../../index.css";

function ScheduleAppointmentScreen({ match, history }) {
  const serviceDetails = useSelector((state) => state.serviceDetails);
  const { loading, error, service } = serviceDetails;

  const serviceCart = useSelector((state) => state.serviceCart);
  const { appointmentSchedule, patientDetails } = serviceCart;

  const [timeslotObject, setTimeslotObject] = useState(
    appointmentSchedule && appointmentSchedule._id ? appointmentSchedule : ""
  );

  const [timeslotSelected, setTimeslotSelected] = useState(
    appointmentSchedule && appointmentSchedule._id
      ? appointmentSchedule._id
      : ""
  );

  const dispatch = useDispatch();

  const serviceId = match.params.id;
  // console.log('timeslotSelected:',timeslotSelected)

  const currentDateHandler = (date) => {
    setTimeslotSelected("");
    setCalendarDate(date);
    setCurrentDate(moment(date).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (!service || (service && !service.timeslots)) {
      dispatch(listServiceDetails(serviceId));
    }
  }, [dispatch, serviceId, service]);

  useEffect(() => {
    dispatch(listDoctors());
  }, [dispatch]);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [disabledDates, setDisabledDates] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [currentTimeslots, setCurrentTimeslots] = useState("");

  const [showModal, setShowModal] = useState(false);

  function getDatesRange(startDate, endDate) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  // const [excludedDates, setExcludedDates] = useEffect('')

  useEffect(() => {
    var dates = [];
    var availableDates = [];
    var i;
    if (service && service.timeslots) {
      for (i = 0; i <= service.timeslots.length; i++) {
        if (service.timeslots[i]) {
          dates.push(moment(service.timeslots[i]["date"]).format("YYYY-MM-DD"));
        }
      }
      availableDates = dates.filter(onlyUnique);
      setFirstDate(availableDates[0]);
      setLastDate(availableDates.slice(-1)[0]);

      const dateRange = getDatesRange(new Date(firstDate), new Date(lastDate));

      var result = dateRange.filter(
        (b) => availableDates.findIndex((a) => a === b) === -1
      );
      setDisabledDates(result.map((date) => new Date(date)));

      // setExcludedDates(result.map((date)=>new Date(date)))
    }
    var tempslots = [];
    if (currentDate && availableDates) {
      tempslots = service.timeslots.filter(
        (timeslot) => moment(timeslot.date).format("YYYY-MM-DD") === currentDate
      );
      setCurrentTimeslots(tempslots);
    } else if (firstDate && availableDates) {
      tempslots = service.timeslots.filter(
        (timeslot) => moment(timeslot.date).format("YYYY-MM-DD") === firstDate
      );
      setCurrentTimeslots(tempslots);
    }
    // return () => {
    //   cleanup
    // }
  }, [service, firstDate, lastDate, currentDate]);

  // const availableDates = dates.filter(onlyUnique)

  // console.log('availableDates:',availableDates)

  const [calendarDate, setCalendarDate] = useState("");

  const timeslotSelectHandler = (id) => {
    setTimeslotSelected(id);
    let index = service.timeslots.findIndex(
      (timeslot) => timeslot._id === Number(id)
    );
    setTimeslotObject(service.timeslots[index]);
  };

  const checkoutHandler = () => {
    if (!timeslotSelected) {
      alert("Please select a time slot.");
    } else {
      dispatch(saveAppointmentSchedule(timeslotObject));
      dispatch(saveService(service));
      setTimeslotSelected("");
      history.push("/patient-details");
    }
  };

  const gobackHandler = () => {
    history.push(`/services`);
  };

  const [doctor, setDoctor] = useState({});

  const getExperience = (birthDate) =>
    Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

  // function useOutsideAlerter(ref) {
  //     useEffect(() => {
  //         /**
  //          * Alert if clicked on outside of element
  //          */
  //         function handleClickOutside(event) {
  //             if (ref.current && !ref.current.contains(event.target)) {
  //                 alert("You clicked outside of me!");
  //             }
  //         }

  //         // Bind the event listener
  //         if(showModal){
  //           document.addEventListener("mousedown", handleClickOutside);
  //         }
  //         return () => {
  //             // Unbind the event listener on clean up
  //             document.removeEventListener("mousedown", handleClickOutside);
  //         };
  //     }, [ref]);
  // }
  const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef);

  const doctorModalHandler = (doctor) => {
    setDoctor(doctor);
    setShowModal(true);

    // document.body.className = 'hasScrollbar modal-open'
    document.getElementById("schedule-page-root").style.filter = "blur(5px)";
    document.getElementById("header-root").style.filter = "blur(5px)";
    // document.getElementById('modal-root').style.filter = 'blur(0px)'
  };

  const closeModal = () => {
    setShowModal(false);
    // document.body.className = 'hasScrollbar'
    document.getElementById("schedule-page-root").style.filter = "blur(0px)";
    // document.getElementById('header-root').style.filter = null
    document.getElementById("header-root").removeAttribute("style");
    // document.getElementById('header-root').style.zindex = '-1'
  };

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : //
      error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div id="schedule-page-root">
            <div className="page-title">
              <div className="container">
                <div className="column">
                  <h1>Schedule Appointment - {service && service.name}</h1>
                </div>
                <div className="column">
                  <ul className="breadcrumbs">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>Schedule Appointment - {service.name}</li>
                  </ul>
                </div>
              </div>
            </div>

            <div id="landingPage">
              {/* <!-- Page Content--> */}
              <div className="container padding-bottom-3x">
                <center>
                  <div className="col-md-10">
                    <div className="steps flex-sm-nowrap mb-5">
                      <Link className="step active" to="#">
                        <h4 className="step-title">
                          {appointmentSchedule && appointmentSchedule.date && (
                            <i
                              className="icon-check-circle"
                              style={{ color: "#007bff" }}
                            ></i>
                          )}{" "}
                          1. Schedule
                        </h4>
                      </Link>

                      <Link
                        className="step "
                        to={
                          appointmentSchedule && appointmentSchedule.date
                            ? "/patient-details"
                            : "#"
                        }
                      >
                        <h4 className="step-title">
                          {patientDetails && patientDetails.name && (
                            <i
                              className="icon-check-circle"
                              style={{ color: "#007bff" }}
                            ></i>
                          )}{" "}
                          2. Patient Details
                        </h4>
                      </Link>
                      <Link
                        className="step "
                        to={
                          appointmentSchedule &&
                          patientDetails &&
                          appointmentSchedule.date &&
                          patientDetails.name
                            ? "/confirm-appointment"
                            : "#"
                        }
                      >
                        <h4 className="step-title">
                          {appointmentSchedule &&
                            patientDetails &&
                            appointmentSchedule.date &&
                            patientDetails.name && (
                              <i
                                className="icon-check-circle"
                                style={{ color: "#007bff" }}
                              ></i>
                            )}
                          3. Confirm Appointment
                        </h4>
                      </Link>

                      <Link className="step" to="#">
                        <h4 className="step-title">3. Pay</h4>
                      </Link>
                    </div>

                    {/* <h4 className="mb-3 mt-3" style={{textAlign:"left"}}>Schedule Appointment - {service.name}</h4> */}
                    <center>
                      <div>
                        <div className=" text-center mb-3">
                          <div className="card-body">
                            <h5 style={{ display: "inline-block" }}>
                              Select a Date
                            </h5>

                            <div
                              className="col-sm-4"
                              style={{
                                textAlign: "center",
                                display: "inline-block",
                              }}
                            >
                              <div className="form-group">
                                {/* <label style={{paddingLeft:'0px'}} htmlFor="checkout-fn">Choose a Date</label> */}

                                <div className="customDatePickerWidth">
                                  <DatePicker
                                    selected={
                                      calendarDate ? calendarDate : null
                                    }
                                    className="form-control"
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Select Date"
                                    peekNextMonth
                                    // showMonthDropdown
                                    // showYearDropdown
                                    inputStyle={{ textAlign: "center" }}
                                    dropdownMode="select"
                                    excludeDates={disabledDates}
                                    minDate={addDays(new Date(firstDate), 0)}
                                    maxDate={addDays(new Date(lastDate), 0)}
                                    onChange={(date) =>
                                      currentDateHandler(date)
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </center>

                    
                      {currentTimeslots.length === 0 ? (
                        <div >
                          <Message variant="info">
                            Currently NO Timeslots are available{" "}
                            {service && "for " + service.name}.{" "}
                          </Message>
                        </div>
                      ) : (
                        <div className="table-responsive">
                        <Table className="table">
                          <Thead>
                            <Tr>
                              <Th>Select</Th>
                              <Th>Date</Th>
                              <Th>Time Slot</Th>
                              <Th>Duration</Th>
                              <Th>Expert</Th>
                            </Tr>
                          </Thead>

                          <Tbody>
                            {currentTimeslots &&
                              currentTimeslots.map((item) => (
                                <Tr
                                  key={
                                    item.date + item.startTime + item.endTime
                                  }
                                >
                                  <Td>
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id={item._id}
                                        value={item._id}
                                        onChange={(e) =>
                                          timeslotSelectHandler(e.target.value)
                                        }
                                        checked={
                                          Number(timeslotSelected) === item._id
                                            ? true
                                            : false
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={item._id}
                                      >
                                        {"  "}
                                      </label>
                                    </div>
                                  </Td>
                                  <Td>{item.date}</Td>
                                  <Td>
                                    {item.startTime} - {item.endTime} (IST)
                                  </Td>
                                  <Td>{item.duration} minutes</Td>
                                  <Td>
                                    <button
                                      className="btn"
                                      style={{
                                        padding: "0px",
                                        border: "0px",
                                        background: "none",
                                        margin: "-10px",
                                      }}
                                      onClick={() =>
                                        doctorModalHandler(item.doctor)
                                      }
                                    >
                                      {item.doctor && item.doctor.name}
                                    </button>
                                  </Td>
                                </Tr>
                              ))}
                          </Tbody>
                        </Table>
                        
                        </div>
                      )}
                    </div>
                  

                  <div className="shopping-cart-footer">
                    <div className="column" style={{ textAlign: "left" }}>
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={gobackHandler}
                      >
                        <i className="icon-arrow-left"></i>
                        <span className="hidden-xs-down">&nbsp;Back</span>
                      </button>
                    </div>
                    <div className="column">
                      <button
                        className="btn btn-primary"
                        type="button"
                        disabled={!timeslotSelected}
                        onClick={checkoutHandler}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </>
      )}

      <div
        id="modal-root"
        // style={{position: "relative", zIndex: "999"}}
        ref={wrapperRef}
        className={showModal ? "modal fade show" : "modal fade"}
        style={{ display: showModal ? "block" : "none", paddingRight: "17px" }}
        // id="modalCentered"
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{doctor.name}</h4>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <div
                  className="product-card product-list"
                  style={{ verticalAlign: "top" }}
                >
                  <div className="product-thumb" style={{ padding: "0px" }}>
                    <img src={doctor.image_1} alt={doctor.name} />
                  </div>
                  <div className="product-card-inner">
                    <div className="product-card-body pt-0">
                      <div className="mt-2">
                        <h5 className="mb-0">{doctor && doctor.name}</h5>
                        <div className="text-muted text-lg">
                          {doctor && doctor.professionTitle}{" "}
                          {doctor.degree && doctor.professionTitle
                            ? "- " + doctor.degree
                            : doctor.degree && !doctor.professionTitle
                            ? doctor.degree
                            : ""}
                        </div>
                      </div>

                      <hr className="mb-2 mt-2" />

                      {doctor.specialty_1 ||
                      doctor.specialty_2 ||
                      doctor.specialty_3 ||
                      doctor.specialty_4 ? (
                        <>
                          <strong className="text-muted">
                            Specialization:
                          </strong>
                          <ul className="mb-1" style={{ marginLeft: "10px" }}>
                            {doctor.specialty_1 && (
                              <li>{doctor.specialty_1}</li>
                            )}
                            {doctor.specialty_2 && (
                              <li>{doctor.specialty_2}</li>
                            )}
                            {doctor.specialty_3 && (
                              <li>{doctor.specialty_3}</li>
                            )}
                            {doctor.specialty_4 && (
                              <li>{doctor.specialty_4}</li>
                            )}
                          </ul>
                        </>
                      ) : null}

                      {(doctor.association_1 ||
                        doctor.association_2 ||
                        doctor.association_3 ||
                        doctor.association_4) && (
                        <div>
                          <strong className="text-muted">Associations:</strong>

                          <ul className="mb-1" style={{ marginLeft: "10px" }}>
                            {doctor.association_1 && (
                              <li>{doctor.association_1}</li>
                            )}
                            {doctor.association_2 && (
                              <li>{doctor.association_2}</li>
                            )}
                            {doctor.association_3 && (
                              <li>{doctor.association_3}</li>
                            )}
                            {doctor.association_4 && (
                              <li>{doctor.association_4}</li>
                            )}
                          </ul>
                        </div>
                      )}

                      {doctor.experienceFrom &&
                        getExperience(doctor.experienceFrom) > 0 && (
                          <div>
                            <strong className="text-muted">Experience:</strong>{" "}
                            {getExperience(doctor.experienceFrom)} years
                          </div>
                        )}

                      {doctor.services && doctor.services.length > 0 && (
                        <div>
                          <strong className="text-muted">
                            Services at Etechan:
                          </strong>
                          <ul className="mb-1" style={{ marginLeft: "10px" }}>
                            {doctor.services.map((service) => (
                              <li key={service.slug}>{service.name}</li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {doctor.websiteLink && (
                        <>
                          <strong className="text-muted">Website:</strong>{" "}
                          <Link to={doctor.websiteLink}>
                            {doctor.websiteLink}
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {doctor.description && (
                  <div
                    className="bg-secondary padding-top-1x padding-bottom-2x mt-2 mb-3"
                    id="details"
                  >
                    <div className="">
                      <div className="">
                        <p
                          style={{
                            fontSize: "15px",
                            color: "black",
                            padding: "10px",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {doctor.description}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-outline-secondary btn-sm"
                type="button"
                data-dismiss="modal"
                onClick={closeModal}
              >
                Close
              </button>
              <button className="btn btn-primary btn-sm" type="button">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScheduleAppointmentScreen;
