import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'
import { setCurrencyinAction } from '../actions/currencyActions'
import { addToCart } from '../actions/cartActions'
import SearchBox from './SearchBox'
import { useHistory } from "react-router-dom"

import "../index.css"

function Header() {

    const [navbar,setNavbar] = useState(false)

    const cart = useSelector(state => state.cart)
    const { cartItems } = cart
    const dispatch = useDispatch()
    let history = useHistory();
    // console.log('history',history.location.pathname)
    // const [menuToggle,setMenuToggle] = useState(false)

    // useMemo(() => {
    //   fetch('https://extreme-ip-lookup.com/json/')
    //   .then( res => res.json())
    //   .then(response => {
    //     console.log('resp:',response)
    //     if(response.countryCode==="IN" && cartItems.length===0){
    //       dispatch(setCurrencyinAction("INR"))

    //     }else if(response.countryCode!=="IN" && cartItems.length===0){
    //       dispatch(setCurrencyinAction("USD"))
    //     }
    //     localStorage.setItem('countryCodeFromIP',response.countryCode)
    //   //  console.log("Country is : ", response)
    //  })
    //  .catch((data, status) => {
    //    console.log('Request failed:', data)
    //  });
    //   },[dispatch,cartItems.length])
    // console.log('country from ip:',countryUsingIp)
    
    const [open,setOpen] = useState(false)

    const currencyFromState = useSelector(state => state.currency)
    const { currency } = currencyFromState

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const setCurrencyHandler = (currency) => {
      dispatch(setCurrencyinAction(currency))
      cart.currency = currency
      cartItems.map(item=>(
        dispatch(addToCart(item.product,item.weightId,item.qty,currency))
      ))
    }
    const logoutHandler = () => {
      dispatch(logout) 
      setOpen(false)     
      history.push('/')
      
    }

    // const [treatmentToggle, setTreatmentToggle] = useState(false)
    // const treatmentToggleHandler = (e)=>{
    //   e.preventDefault()
    //   setTreatmentToggle(!treatmentToggle)
    // }
    
    const [compBiologyToggle, setCompBiologyToggle] = useState(false)
    const compBiologyHandler = (e)=>{
      e.preventDefault()
      setCompBiologyToggle(!compBiologyToggle)
    }

    const stickNavbar = () => {
      if (window.scrollY >= 68){
        setNavbar(true)
        document.body.style.paddingTop = "68px";
        document.getElementById("site-branding-img").style.display ="none"
        document.getElementById("site-branding-search").style.display ="block"
        document.getElementById("main-navbar").style.padding ="8px"
        // document.getElementById("search-mobile-menu-icon").style.display ="block"
        // document.getElementById("mobile-menu-icon").style.display ="none"
      }else{
        setNavbar(false)
        document.body.style.paddingTop = "0";
        document.getElementById("site-branding-img").style.display ="block"
        document.getElementById("site-branding-search").style.display ="none"
        document.getElementById("main-navbar").style.padding ="0"
        // document.getElementById("search-mobile-menu-icon").style.display ="none"
        // document.getElementById("mobile-menu-icon").style.display ="block"
      }
    }
    // console.log(navbar)
    window.addEventListener('scroll',stickNavbar)

    return (
        <div id='header-root'>
      <header className={navbar ? "site-header navbar-sticky navbar-stuck " : "site-header navbar-sticky "}>
      {/* <!-- Topbar--> */}
      <div className="topbar d-flex justify-content-between topbar-stuck">
        {/* <!-- Logo--> */}
        {/* <div className="toolbar d-flex">
         
          <div id="search-mobile-menu-icon" style={{display:"none"}} className={ open ? "toolbar-item visible-on-mobile mobile-menu-toggle active" : "toolbar-item visible-on-mobile mobile-menu-toggle"}>
            <Link to="#" style={{paddingLeft:"0", borderRight:"1px solid #e5e5e5"}}>

              <div><i className={open ? "icon-menu icon-x" : "icon-menu"}></i>
                  <span className="text-label">Menu</span>
              </div>
            </Link>
          </div>
        </div> */}
        <div id="site-branding-search" style={{marginTop:"8px",paddingLeft:"15px",marginRight:"-10px",display:"none"}}>
          <SearchBox style={{paddingLeft:"0"}} showCategoryBtn={false} mobileView={true} toolbarView={true} id={'search1'}/>
          </div>
        
        <div  id="site-branding" className="site-branding d-flex" style={{maxWidth: "54%"}} >
          <Link id="site-branding-img"  className="site-logo align-self-center" to="/">
            <img  src="/images/logo.png" alt="Etichan" />
          </Link>
          
        
        
        </div>
        {/* <!-- Search / Categories--> */}
        <div  className="search-box-wrap d-flex">
        <div className="search-box-inner ">
        <SearchBox id={'search2'} />
        </div>
        </div>
        {/* <!-- Toolbar--> */}
        <div className="toolbar d-flex" >
         
          <div id="mobile-menu-icon"  className={ open ? "toolbar-item visible-on-mobile mobile-menu-toggle active" : "toolbar-item visible-on-mobile mobile-menu-toggle"}>
            <Link to="/#" onClick={()=>{setOpen(!open)}}>

              <div><i className={open ? "icon-menu icon-x" : "icon-menu"}></i>
                  <span className="text-label">Menu</span>
              </div>
            </Link>
          </div>

            
            <div className="toolbar-item hidden-on-mobile"><Link to="/#">
              <div>
                { currency === 'INR' ? 
                    <> <i className="flag-icon"><img src="/images/inr.png" alt="inr" /></i><span className="text-label">INR (₹)</span> </>
                    : currency === 'USD' ? <><i className="flag-icon"><img src="/images/EN.png" alt="usd" /></i><span className="text-label">USD ($)</span></>
                    : <> <i className="flag-icon"><img src="/images/inr.png" alt="inr" /></i><span className="text-label">INR (₹)</span> </>
                }
                
              </div></Link>
            <ul className="toolbar-dropdown lang-dropdown">
              <li className="px-3 pt-1 pb-2">
                <select className="form-control form-control-sm" 
                                as="select"
                                value = {currency}
                                onChange = {(e) => {
                                    setCurrencyHandler(e.target.value)   
                                }
                            }
                                id="headerCurrency">
                            {currency === 'USD' ?
                              <><option value='INR'>
                              INR (₹)
                          </option>

                          <option value='USD'>
                              USD ($)
                          </option></> :
                          
                          <>
                          <option value='USD'>
                              USD ($)
                          </option>
                          <option value='INR'>
                              INR (₹)
                          </option>

                          </>                          
                          }
                            
                  </select>
              </li>
              {/* {currency === 'INR' ?
                  <><li><Link to="/#"><i className="flag-icon"><img src="/images/EN.png" alt="usd" /></i>USD ($)</Link></li></>
                  : <><li><Link to="/#"><i className="flag-icon"><img src="/images/inr.png" alt="inr" /></i>INR (₹)</Link></li></>
  
              } */}
              
              
            </ul>
          </div>
            
          <div className="toolbar-item hidden-on-mobile">
            <Link to="/login">
              <div><i className="icon-user"></i>
                {userInfo ? <span className="text-label">{userInfo.name}</span>
                          : <span className="text-label">Sign In / Up</span>
                }
                
              </div>
            </Link>

              {userInfo ? <>
                <ul  className="toolbar-dropdown lang-dropdown">
                <li ><Link style={{fontSize:"14px"}} to="/profile" id='username'>Profile</Link></li>
                <li><Link style={{fontSize:"14px"}} 
                        onClick={logoutHandler} to="/#">Logout</Link></li>
                </ul>
                </>


                : <>
                <div className="toolbar-dropdown text-center px-2">
                {/* <p className="text-xs mb-3 pt-2">Sign in to your account or register new one to have full control over your orders.</p> */}
                <Link className="btn btn-primary btn-sm btn-block" to="/login">Sign In</Link>
                {/* <p className="text-xs text-muted mb-2">New customer?&nbsp;<Link to="/register">Register</Link></p></div> */}
                <Link className="btn btn-outline-primary btn-sm btn-block" to="/register">Register</Link></div>
              </>
                }

          </div>
          {userInfo && userInfo.isAdmin && (
            <>
            
              <div className="toolbar-item hidden-on-mobile">
              <Link to="/login">
                <div><i className="fas fa-user-cog"></i>
                  <span className="text-label">Admin</span>
                  
                  
                </div>
              </Link>
              <ul  className="toolbar-dropdown lang-dropdown">
                <li ><Link style={{fontSize:"14px"}} to="/admin/userlist" id='admin-users'>Users</Link></li>
                <li><Link style={{fontSize:"14px"}} to="/admin/productlist" id='admin-products'>Products</Link></li>
                <li><Link style={{fontSize:"14px"}} to="/admin/orderlist" id='admin-orders'>Orders</Link></li>
                {/* <li><Link style={{fontSize:"14px"}} to="/admin/upload-static-files" id='admin-files'>Static Files</Link></li> */}
                <li><Link style={{fontSize:"14px"}} to="/admin/freight-charges" id='freight-charges'>Freight Charges</Link></li>
                <li><Link style={{fontSize:"14px"}} to="/admin/servicelist" id='admin-services'>Services</Link></li>
                <li><Link style={{fontSize:"14px"}} to="/admin/appointmentlist" id='admin-appointments'>Appointments</Link></li>
                <li><Link style={{fontSize:"14px"}} to="/admin/doctorlist" id='admin-doctors'>Doctors</Link></li>
                </ul>
              
            </div>
            </>
          )}


          
          <div className="toolbar-item">
            <Link to="/cart">
              <div>
                <span className="cart-icon">
                <i className="icon-shopping-cart"></i><span className="count-label">{cartItems.length} </span>
                </span>

                <span className="text-label">Cart</span></div>
            </Link>
            {/* <div className="toolbar-dropdown cart-dropdown widget-cart hidden-on-mobile">
              
              <div className="entry">
                <div className="entry-thumb"><Link to="shop-single.html"><img src="/images/img/shop/widget/04.jpg" alt="Product" /></Link> </div>
                <div className="entry-content">
                  <h4 className="entry-title"><Link to="shop-single.html">Canon EOS M50 Mirrorless Camera</Link> </h4><span className="entry-meta">1 x $910.00</span>
                </div>
                <div className="entry-delete"><i className="icon-x"></i></div>
              </div>
              
              <div className="entry">
                <div className="entry-thumb"><Link to="shop-single.html"><img src="/images/img/shop/widget/05.jpg" alt="Product" /></Link> </div>
                <div className="entry-content">
                  <h4 className="entry-title"><Link to="shop-single.html">Apple iPhone X 256 GB Space Gray</Link> </h4><span className="entry-meta">1 x $1,450.00</span>
                </div>
                <div className="entry-delete"><i className="icon-x"></i></div>
              </div>
              
              <div className="entry">
                <div className="entry-thumb"><Link to="shop-single.html"><img src="/images/img/shop/widget/06.jpg" alt="Product" /></Link> </div>
                <div className="entry-content">
                  <h4 className="entry-title"><Link to="shop-single.html">HP LaserJet Pro Laser Printer</Link> </h4><span className="entry-meta">1 x $188.50</span>
                </div>
                <div className="entry-delete"><i className="icon-x"></i></div>
              </div>
              <div className="text-right">
                <p className="text-gray-dark py-2 mb-0"><span className='text-muted'>Subtotal:</span> &nbsp;$2,548.50</p>
              </div>
              <div className="d-flex">
                <div className="pr-2 w-50"><Link className="btn btn-secondary btn-sm btn-block mb-0" to="/cart">Expand Cart</Link> </div>
                <div className="pl-2 w-50"><Link className="btn btn-primary btn-sm btn-block mb-0" to="checkout.html">Checkout</Link> </div>
              </div>
            </div> */}
          </div>

          
        </div>

        
        {/* <!-- Mobile Menu--> */}

        <div className= {open ? "mobile-menu open" : "mobile-menu"} style={{width:"100%"}}>
          {/* <!-- Search Box--> */}
          {/* <div className="mobile-search">
            <SearchBox showCategoryBtn={false} mobileView={true} name={'search3'}/>
            
          </div> */}
          {/* <!-- Toolbar--> */}
          
          <div className="toolbar" >
            <div className="toolbar-item"><Link to="/#">
              <div>
                { currency === 'INR' ? 
                    <> <i className="flag-icon"><img src="/images/inr.png" alt="inr" /></i><span className="text-label">INR (₹)</span> </>
                    : <><i className="flag-icon"><img src="/images/EN.png" alt="usd" /></i><span className="text-label">USD ($)</span></>
                }</div></Link>
              <ul className="toolbar-dropdown lang-dropdown w-100">
                <li className="px-3 pt-1 pb-2">
                <select className="form-control form-control-sm" 
                                as="select"
                                value = {currency}
                                onChange = {(e) => {
                                    setCurrencyHandler(e.target.value)
                                    setOpen(false)  
                                }
                            }
                                id="headerCurrency">
                            
                            {currency === 'USD' ?
                              <><option value='INR'>
                                  INR (₹)
                              </option>

                              <option value='USD'>
                                  USD ($)
                              </option></> :
                              
                              <>
                              <option value='USD'>
                                  USD ($)
                              </option>
                              <option value='INR'>
                                  INR (₹)
                              </option>

                              </>                          
                              }
                  </select>
                </li>
              </ul>
            </div>
            <div className="toolbar-item">
            <Link to="/login" onClick={()=>setOpen(false)}>
              <div><i className="icon-user"></i>
                {userInfo ? <span className="text-label">{userInfo.name}</span>
                          : <span className="text-label">Sign In / Up</span>
                }
                
              </div>
            </Link>
           
              {userInfo 
              ? 
              <>
                <ul  className="toolbar-dropdown lang-dropdown">
                <li ><Link style={{fontSize:"14px"}} to="/profile" id='username' onClick={()=>setOpen(false)}>Profile</Link></li>
                <li><Link style={{fontSize:"14px"}} 
                        onClick={logoutHandler}>Logout</Link></li>
                </ul>
                </>

                : <>
                <div className="toolbar-dropdown text-center px-2">
                {/* <p className="text-xs mb-3 pt-2">Sign in to your account or register new one to have full control over your orders.</p> */}
                <Link className="btn btn-primary btn-sm btn-block" to="/login" onClick={()=>setOpen(false)}>Sign In</Link>
                <Link className="btn btn-outline-primary btn-sm btn-block" to="/register" onClick={()=>setOpen(false)}>Register</Link></div>
              </>
                }
            
            </div>
            {userInfo && userInfo.isAdmin && (
                <>
                  <div className="toolbar-item">
                  <Link to="/login">
                    <div><i className="fas fa-user-cog"></i>
                      <span className="text-label">Admin</span>
                      
                      
                    </div>
                  </Link>
                  <ul  className="toolbar-dropdown lang-dropdown">
                    <li ><Link style={{fontSize:"14px"}} to="/admin/userlist" id='userList' onClick={()=>setOpen(false)}>Users</Link></li>
                    <li><Link style={{fontSize:"14px"}} to="/admin/productlist" id='productList' onClick={()=>setOpen(false)}>Products</Link></li>
                    <li><Link style={{fontSize:"14px"}} to="/admin/orderlist" id='orderList' onClick={()=>setOpen(false)}>Orders</Link></li>
                    {/* <li><Link style={{fontSize:"14px"}} to="/admin/upload-static-files" id='frieghtCharges' onClick={()=>setOpen(false)}>Static Files</Link></li> */}
                    <li><Link style={{fontSize:"14px"}} to="/admin/freight-charges" id='freightList' onClick={()=>setOpen(false)}>Freight Charges</Link></li>
                    <li><Link style={{fontSize:"14px"}} to="/admin/servicelist" id='serviceList' onClick={()=>setOpen(false)}>Services</Link></li>
                    <li><Link style={{fontSize:"14px"}} to="/admin/doctorlist" id='doctorList' onClick={()=>setOpen(false)}>Doctors</Link></li>

                  </ul>
                  
                </div>
                </>
              )}
            
          </div>
          
          {/* <!-- Slideable (Mobile) Menu--> */}
          <nav className="slideable-menu" >
            <ul className="menu" data-initial-height="385">
              <li className={history.location.pathname === '/' ? "has-children active" : "has-children"}><span><Link to="/" onClick={()=>setOpen(false)}>Home</Link> </span>
               
              </li>

              <li className={history.location.pathname === '/our-products' ? "has-children active" : "has-children"}>
                <span><Link to="/our-products" onClick={()=>setOpen(false)}>Our Products</Link> </span>
               
              </li>

              {/* <li className={history.location.pathname === '/our-services' ? "has-children active" : "has-children"}>
                <span><Link to="/our-services" onClick={()=>setOpen(false)}>Our Services</Link> </span>
               
              </li> */}

              {/* <li className={history.location.pathname === '/services' ? "has-children active" : "has-children"}>
                <span><Link to="/services" style={{color:"blue"}} onClick={()=>setOpen(false)}>Doctors on Call</Link> </span>
                
              </li> */}

              
              {/* <li className="has-children"><span><Link onClick={treatmentToggleHandler } to="/#">{' '}Treatment {' '}<i style={{color:"black"}} className={treatmentToggle ? "fa fa-minus-square" : "fa fa-plus-square"}></i><span style={{paddingTop:"10px"}}>{' '}</span></Link></span>
              
              </li> */}
              {/* {treatmentToggle &&
              <>
              <ul>
              <li><Link to="/ayurveda" onClick={()=>setOpen(false)}><span style={{color:"white"}}>aa</span>Ayurveda</Link> </li>
              <li><Link to="/diet-and-nutrition" onClick={()=>setOpen(false)}><span style={{color:"white"}}>aa</span>Diet & Nutrition</Link> </li>
              <li><Link to="/hyperbaric" onClick={()=>setOpen(false)}><span style={{color:"white"}}>aa</span>Hyperbaric</Link> </li>
              </ul>
              
              </>} */}
              
              <li className="has-children"><span><Link onClick={compBiologyHandler } style={{color:"#505050"}} to="/#">{' '}Computational Biology {' '}<i style={{color:"black"}} className={compBiologyToggle ? "fa fa-minus-square" : "fa fa-plus-square"}></i><span style={{paddingTop:"10px"}}>{' '}</span></Link></span>
              
              </li>
              {compBiologyToggle &&
              <>
              <ul>
                <li><Link rel="noopener noreferrer" to="/#" onClick={() => { window.open('https://aicrmr.etechanhealthtech.com/','_blank')}} >{' '}Circadian Rhythms</Link> </li>
                <li><Link rel="noopener noreferrer" to="/#" onClick={() => { window.open('https://alzheimers.etechanhealthtech.com/','_blank')}} >{' '}Alzheimer's disease</Link> </li>
              </ul>
              
              </>}
             


              {userInfo &&
              <li className={history.location.pathname === '/profile' ? "has-children active" : "has-children"}>
                <span><Link to="/profile" onClick={()=>setOpen(false)}>Profile</Link> </span>
                
              </li>}

              {userInfo &&
              <>
              <li className={history.location.pathname === '/orders' ? "has-children active" : "has-children"}>
                <span><Link to="/orders" onClick={()=>setOpen(false)}>My Orders</Link> </span>
                
              </li>
              
              <li className={history.location.pathname === '/appointments' ? "has-children active" : "has-children"}>
                <span><Link to="/appointments" onClick={()=>setOpen(false)}>My Appointments</Link> </span>
                
              </li>
              </>
              
              }

              <li className={history.location.pathname === '/cart' ? "has-children active" : "has-children"}>
                <span><Link to="/cart" onClick={()=>setOpen(false)}>My Cart</Link> </span>
                
              </li>

              {/* <li className={history.location.pathname === '/about-us' ? "has-children active" : "has-children"}>
                <span><Link to="/about-us" onClick={()=>setOpen(false)}>About Us</Link> </span>
                
              </li>               */}
              
              {/* <li className={history.location.pathname === '/contact-us' ? "has-children active" : "has-children"}>
                <span><Link to="/contact-us" onClick={()=>setOpen(false)}>Contact Us</Link> </span>
                
              </li> */}

              <li className={history.location.pathname === '/services' ? "has-children active" : "has-children"}>
                <span><Link to="/services" style={{color:"blue"}} onClick={()=>setOpen(false)}>Doctors on Call</Link> </span>
                
              </li>

              <li className={history.location.pathname === '/login' ? "has-children active" : "has-children"}>
                {!userInfo ?
                 <span> <Link to="/login" onClick={()=>setOpen(false)}>Login / Register</Link> </span>
                :
                <span> <Link  
                        onClick={
                          logoutHandler                          
                        } to="/#">Logout</Link>
                </span>
                }
              </li>
              
            </ul>
          </nav>
        </div>

      
      </div>

      {/* <div className="topbar d-flex justify-content-between ">
      <div className="toolbar-item visible-on-mobile">
      <SearchBox showCategoryBtn={false} name={'search4'}/>
      </div>
          
        
      </div> */}

      
      {/* <!-- Navbar--> */}
      <div className="navbar" id='main-navbar'
      // style={{backgroundColor:"#9eca62", height:"30%"}}
      // style={{padding:"0"}}
      >
        <div className="btn-group categories-btn">
          {/* <button className="btn btn-secondary dropdown-toggle" data-toggle="dropdown"><i className="icon-menu text-lg"></i>&nbsp;Categories</button> */}
          <div className="dropdown-menu mega-dropdown">
            <div className="row">
              
            </div>
            
          </div>
        </div>
        {/* <!-- Main Navigation--> */}
        <nav className="site-menu "  >
          <ul  >
            {/* <li className={history.location.pathname === '/' && " " }><Link to="/">Home</Link>  */}
            <li className=""><Link to="/"><span className="menu-color-custom">Home</span></Link> 

             
            </li>
            {/* <li className=""><Link to="/about-us"><span className="menu-color-custom">About Us</span></Link> 
              
            </li> */}

            <li className=""><Link to="/our-products"><span className="menu-color-custom">Our Products</span></Link> 
              
            </li>

            {/* <li className="has-submenu"><Link to="/our-services"><span className="menu-color-custom">Our Services</span></Link> 
           
            </li> */}

            {/* <li className="has-submenu " style={{margin:"2px"}}><Link to="/services" style={{color:"#91c54a"}}><span className="menu-color-custom">Doctors on Call</span></Link> 
              
            </li> */}

            {/* <li className="has-submenu"><Link to="/#"><span className="menu-color-custom">Treatment</span></Link> 
                <ul className="sub-menu">

                  <li><Link to="/ayurveda">Ayurveda</Link> </li>
                  <li><Link to="/diet-and-nutrition">Diet & Nutrition</Link> </li>
                  <li><Link to="/hyperbaric">Hyperbaric</Link> </li>
                             
              </ul>
            </li> */}
            <li className= "has-submenu" ><Link to="/#"><span className="menu-color-custom">Account</span></Link> 
              <ul className="sub-menu">
              
                  
                  {userInfo && <li><Link to="/profile">Profile</Link> </li>}
                  {userInfo && <li><Link to="/orders">My Orders</Link> </li>}
                  {userInfo && <li><Link to="/appointments">My Appointments</Link> </li>}
                  <li><Link to="/cart">My Cart</Link> </li>
                  <li>{!userInfo ?
                      <Link to="/login" onClick={()=>setOpen(false)}>Login / Register</Link> 
                      :
                      <Link  onClick={logoutHandler} to="/#">Logout</Link>
                      } 
                  </li>              
              </ul>
            </li>
            
            {/* <li className="has-submenu"><Link to="/contact-us"><span className="menu-color-custom">Contact Us</span></Link> 
              
            </li> */}

            <li className= "has-submenu" ><Link to="/#"><span className="menu-color-custom">Comp. Biology</span></Link> 
              <ul className="sub-menu">
              
                  <li><Link rel="noopener noreferrer" to="/#" onClick={() => { window.open('https://aicrmr.etechanhealthtech.com/','_blank')}} >Circadian Rhythms</Link>  </li>
                  <li><Link rel="noopener noreferrer" to="/#" onClick={() => { window.open('https://alzheimers.etechanhealthtech.com/','_blank')}} >Alzheimer's disease</Link>  </li>
                           
              </ul>
            </li>

  

            
            <li className="" style={{margin:"2px"}}><Link to="/services" style={{color:"#007bff"}}><span className="menu-color-custom">Doctors on Call</span></Link> 
              {/* color code green: #91c54a */}
            </li>
            {/* <li className="has-megamenu"><Link to="components/accordion.html">Components</Link> 
              
            </li> */}
          </ul>
        </nav>
        {/* <!-- Toolbar ( Put toolbar here only if you enable sticky navbar )--> */}
        <div className="toolbar">
          <div className="toolbar-inner">
          {userInfo && userInfo.isAdmin && (
            <>
            
              <div className="toolbar-item">
              <Link to="/login">
                <div><i className="fas fa-user-cog"></i>
                  <span className="text-label">Admin</span>
                  
                  
                </div>
              </Link>
              <ul  className="toolbar-dropdown lang-dropdown">
                <li ><Link style={{fontSize:"14px"}} to="/admin/userlist" id='admin-users'>Users</Link></li>
                <li><Link style={{fontSize:"14px"}} to="/admin/productlist" id='admin-products'>Products</Link></li>
                <li><Link style={{fontSize:"14px"}} to="/admin/orderlist" id='admin-orders'>Orders</Link></li>
                {/* <li><Link style={{fontSize:"14px"}} to="/admin/upload-static-files" id='admin-files'>Static Files</Link></li> */}
                <li><Link style={{fontSize:"14px"}} to="/admin/freight-charges" id='freight-charges'>Freight Charges</Link></li>
                <li><Link style={{fontSize:"14px"}} to="/admin/servicelist" id='admin-services'>Services</Link></li>
                <li><Link style={{fontSize:"14px"}} to="/admin/appointmentlist" id='admin-appointments'>Appointment</Link></li>
                <li><Link style={{fontSize:"14px"}} to="/admin/doctorlist" id='admin-doctors'>Doctors</Link></li>

                </ul>
              
            </div>
            </>
          )}

            
            <div className="toolbar-item"><Link id="cart-text-label-link" to="/cart">
                <div><span className="cart-icon"><i className="icon-shopping-cart"></i><span className="count-label">{cartItems.length}  </span></span><span id="cart-text-label" className="text-label">Cart</span></div></Link> 
              {/* <div className="toolbar-dropdown cart-dropdown widget-cart">
                
                <div className="entry">
                  <div className="entry-thumb"><Link to="shop-single.html"><img src="/images/img/shop/widget/04.jpg" alt="Product" /></Link> </div>
                  <div className="entry-content">
                    <h4 className="entry-title"><Link to="shop-single.html">Canon EOS M50 Mirrorless Camera</Link> </h4><span className="entry-meta">1 x $910.00</span>
                  </div>
                  <div className="entry-delete"><i className="icon-x"></i></div>
                </div>
                
                <div className="entry">
                  <div className="entry-thumb"><Link to="shop-single.html"><img src="/images/img/shop/widget/05.jpg" alt="Product" /></Link> </div>
                  <div className="entry-content">
                    <h4 className="entry-title"><Link to="shop-single.html">Apple iPhone X 256 GB Space Gray</Link> </h4><span className="entry-meta">1 x $1,450.00</span>
                  </div>
                  <div className="entry-delete"><i className="icon-x"></i></div>
                </div>
                
                <div className="entry">
                  <div className="entry-thumb"><Link to="shop-single.html"><img src="/images/img/shop/widget/06.jpg" alt="Product" /></Link> </div>
                  <div className="entry-content">
                    <h4 className="entry-title"><Link to="shop-single.html">HP LaserJet Pro Laser Printer</Link> </h4><span className="entry-meta">1 x $188.50</span>
                  </div>
                  <div className="entry-delete"><i className="icon-x"></i></div>
                </div>
                <div className="text-right">
                  <p className="text-gray-dark py-2 mb-0"><span className='text-muted'>Subtotal:</span> &nbsp;$2,548.50</p>
                </div>
                <div className="d-flex">
                  <div className="pr-2 w-50"><Link className="btn btn-secondary btn-sm btn-block mb-0" to="cart.html">Expand Cart</Link> </div>
                  <div className="pl-2 w-50"><Link className="btn btn-primary btn-sm btn-block mb-0" to="checkout.html">Checkout</Link> </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div id="mobile-searchbox" className="mobile-searchBox mob-searchbar-stuck" style={{width:"100%"}}>
        <div className="topbar d-flex">
        <SearchBox showCategoryBtn={false} mobileView={true} id={'search3'}/>
        </div>
      </div>
    </header>
  
    </div>
    )
}

export default Header
