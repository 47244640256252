import React, { useEffect,useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Paginate from '../../components/Paginate'
import { listServices, deleteService, createService } from '../../actions/serviceActions'
import { SERVICE_CREATE_RESET } from '../../constants/serviceConstants'
function ServiceListScreen({history,match}) {

    const dispatch = useDispatch()
    const serviceList = useSelector(state => state.serviceList)
    const { loading , error, services, pages, page } = serviceList

    const serviceDelete = useSelector(state => state.serviceDelete)
    const { loading:loadingDelete , error:errorDelete } = serviceDelete

    const serviceCreate = useSelector(state => state.serviceCreate)
    const { loading:loadingCreate , error:errorCreate, success:successCreate, service:createdService } = serviceCreate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const [serviceName, setServiceName] = useState('')
    const [openModal, setOpenModal] = useState(false)

    let keyword = history.location.search


    useEffect(() => {
        

        if(!userInfo.isAdmin){
            history.push('/login')
        }

        if(successCreate){
            history.push(`/admin/services/${createdService.slug}/edit`)
            dispatch({ type: SERVICE_CREATE_RESET })
        }else{
            dispatch(listServices(keyword))
        }
        
    },[dispatch,history,userInfo,successCreate,createdService,keyword])

    const deleteHandler = (id)=> {
        if (window.confirm('Are you sure you want to delete this Service?')) {
            dispatch(deleteService(id))
            dispatch(listServices())
        }
        
    }

    const createProductHandler = (serviceName) =>{
        setOpenModal(false)
        dispatch(createService(serviceName))
    } 

    return (
        <div>
            {loading ? <Loader/>
                : error ? <Message variant='danger'>{error}</Message>
                : (
                    <>
                    {/* <!-- Page Title--> */}
                    <div className="page-title">
                    <div className="container">
                        <div className="column">
                        <h1>Service List</h1>
                        </div>
                        <div className="column">
                        <ul className="breadcrumbs">
                            <li><Link to="/">Home</Link> 
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li><Link to="">Account</Link> 
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>Service List</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    {/* <!-- Page Content--> */}
                    <div className="container padding-bottom-3x mb-2">
                    <div className="row">

                    <div className="col-12">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div >
                            <h5>Service List</h5>
                        </div>
                        {/* <button className="btn btn-secondary margin-right-none" 
                                type="button"
                                onClick={createProductHandler}
                                >
                                <i className="fas fa-plus"></i> Add Product
                        </button> */}
                        <button className="btn btn-secondary margin-right-none" 
                                type="button" 
                                data-toggle="modal" 
                                data-target="#modalCentered"
                                onClick={()=> setOpenModal(true)}>
                                    <i className="fas fa-plus"></i> Add Service
                        </button>

                    </div>
                    
                    {/* <!-- Vertically Centered Modal--> */}
                    <div className={openModal ? "modal fade show" : "modal fade"} 
                         style={{display: openModal ? "block" : "none", paddingRight: "17px" }}
                         id="modalCentered" 
                         tabIndex="-1" 
                         role="dialog"
                         >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Create Service</h4>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <p>Enter the name of the Service.</p>
                            <input className="form-control" 
                                    type="text" 
                                    id="text-input" 
                                    value={serviceName}
                                    onChange={(e)=>setServiceName(e.target.value)}
                                    />
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-secondary btn-sm" 
                                    type="button" 
                                    data-dismiss="modal"
                                    onClick={()=> setOpenModal(false)}
                                    >Close</button>
                            <button className="btn btn-primary btn-sm" type="button" onClick={()=>createProductHandler(serviceName)}>Create Service</button>
                            
                        </div>
                        </div>
                    </div>
                    </div>
                    </div>
                                {/* <div className="col-lg-3"></div> */}
                                <div className="col-lg-12">
                                    <center>{loadingDelete && <Loader/>}</center>
                                    {errorDelete && <Message variant="danger">{errorDelete}</Message>}

                                    <center>{loadingCreate && <Loader/>}</center>
                                    {errorCreate && <Message variant="danger">{errorCreate}</Message>}
                                <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                                <div className="table-responsive">
                                <table className="table table-striped">
                                <thead>
                                    <tr>
                                    <th>ID</th>
                                    <th>Name</th>                            
                                    <th>Fee </th>                                  
                                    
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {services.map(service => (
                                        <tr key={service._id}>
                                        <th scope="row">{service.refId}</th>
                                        <td><Link style={{color:"black",textDecoration:"none",fontWeight:"500"}} to={`/services/${service.slug}`}>{service.name} </Link></td>
                                        <td><strong> {service.feeRupees ? '₹'+service.feeRupees : '-'}</strong> / <strong>{service.feeDollars ? '$'+service.feeDollars : '-'}</strong> </td>
                                       
                                        <td><Link to ={`/admin/service-timeslots/${service.slug}/`}>
                                            Appointment Slots
                                            </Link>
                                        </td>
                                        <td><Link to ={`/admin/services/${service.slug}/edit`}>
                                            <i className='fas fa-edit'></i>
                                            </Link>
                                        </td>
                                        <td><button style={{padding: "0", border: "none", background: "none"}} onClick={()=> deleteHandler(service.slug)}>
                                            <i style={{color:'red'}} className='fas fa-trash-alt'></i>
                                            </button>
                                        </td>
                                        </tr>
                                    ))}
                                    
                                    
                                </tbody>
                                </table>
                            </div>
                                <hr/>
                                </div>
                            </div>
                            <Paginate pages={pages} page={page} services={true} isAdmin={true} />

                            </div>
                    
                    </>
                    )}
            
        </div>
    )
}

export default ServiceListScreen
