import React from 'react'
// import Rating from './Rating'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import "../index.css"
import { SERVICE_DETAILS_RESET } from '../constants/serviceConstants'

function Service({ service,currency }) {

    const redirectLink = `/login?redirect=schedule-appointment/${service.slug}`
    const dispatch = useDispatch()

    const resetHandler = () =>{
        dispatch({type: SERVICE_DETAILS_RESET})
    }
    

    return ( 

        // <div class="card"><img class="card-img-top" src={`${service.image_1}`} alt="Card image"/>
        //         <div class="card-body">
        //           <h4 class="card-title">Card title</h4>
        //           <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p><a class="btn btn-primary btn-sm" href="#">Go somewhere</a>
        //         </div>
        //       </div>

        <div className="product-card mb-30" style={{color:"black"}}>               


            {/* <Link className="product-thumb pt-0" to={`services/${service.slug}`}> */}
            <Link className="product-thumb pt-0" onClick={resetHandler} to={redirectLink}>
                <img src={`${service.image_1}`} alt="Service" />
                {/* <img  src="/images/image_footer.png" alt="Product" /> */}
            </Link>
           
            
            <div className="product-card-body"  style={{padding:"5px"}}>
            {/* <div className="product-category"><Link to="#">{service.category}</Link></div> */}
            {/* <h3 className="product-title" style={{marginBottom:"0px",marginTop:"8px"}}><Link to={`services/${service.slug}/`}><strong id = "prod-title" >{ service.name }</strong></Link></h3> */}
            <h3 className="product-title" style={{marginBottom:"0px",marginTop:"8px"}}><Link onClick={resetHandler} to={redirectLink}><strong id = "prod-title" >{ service.name }</strong></Link></h3>
            <h4 className="product-price" id = "prod-price" style={{marginTop:"5px", paddingLeft:"4px",paddingRight:"4px"}}>
                {/* <del>₹{ service.rupee_price_1 }</del>*/}
                { currency === 'INR' ? <strong>₹ {service.feeRupees}</strong> : <strong>${service.feeDollars}</strong>}
            </h4>
            {/* <div id="des-box">
            <Link style={{color:"black",textDecoration: "none"}} to={`services/${service.slug}/`}>
            <p id="des-text">
            <strong>Description: </strong>

            {service.description}
            
            </p>
            
            </Link>
            </div> */}
            
            </div>
            <div className="product-button-group" style={{maxHeight:"20px"}}>
                {/* <Link className="product-button btn-wishlist" to="#">
                    <i className="icon-heart"></i><span>Wishlist</span>
                </Link> */}
                {/* <Link className="product-button btn-compare" to={`/${service.slug}`}>
                    <i className="icon-info"></i><span>Info</span>
                </Link> */}
                {/* <Link className="product-button service-on-hover" to={`services/${service.slug}`}  */}
                <Link className="product-button service-on-hover" to={redirectLink} onClick={resetHandler}
                    data-toast data-toast-type="success" 
                    data-toast-position="topRight" 
                    data-toast-icon="icon-check-circle" 
                    data-toast-title="Product" 
                    data-toast-message="successfuly added to cart!">
                    
                    <i className="far fa-calendar-check" aria-hidden="true"><span style={{fontFamily: "Rubik", fontSize:"14px"}}>&nbsp; Book an Appointment</span></i>
                </Link>
            </div>
        </div>
    
    
    
    )
}

export default Service
