import React from 'react'
import { Link, useHistory } from 'react-router-dom'

function PolicyScreen({match}) {

    // const policyId = match.params.id
    let history = useHistory();
    const path = history.location.pathname
    const policyId = path.substring(1,path.length)
    return (
        <div>

            {/* <!-- Page Title--> */}
                <div className="page-title">
                <div className="container">
                    <div className="column">
                    <h1>{policyId === 'refund-cancellation-policy' ? "Refund Policy" : policyId==='privacy-policy' ? "Privacy Policy" : policyId==='terms-conditions' ? "Terms & Conditions" : null}
                    
                    </h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li><Link to="/">Home</Link>
                        </li>
                        
                        <li className="separator">&nbsp;</li>
                        <li>{policyId === 'refund-cancellation-policy' ? "Refund Policy" : policyId==='privacy-policy' ? "Privacy Policy" : policyId==='terms-conditions' ? "Terms & Conditions" : null}</li>
                    </ul>
                    </div>
                </div>
                </div>
                {/* <!-- Page Content--> */}
                <div className="container padding-bottom-2x mb-2">
                
                    <div className="row justify-content-center">
                        {/* <!-- Content--> */}
                        <div className="col-xl-10 col-lg-10 order-lg-2" style={{fontSize:"14px", color:"black"}}>
                       
                        
                            <h2 className="pt-4">{policyId === 'refund-cancellation-policy' ? "Our Refund & Cancellation policy " : policyId==='privacy-policy' ? "Privacy Policy" : policyId==='terms-conditions' ? "Terms & Conditions" : null}</h2>
                            
                            {policyId === 'refund-cancellation-policy' && <>
                            <ul>
                                <li>
                                     We accept return or exchange of our delivered items only in case of good conditions, undamaged, unaltered and unbroken seal. 
                                </li>
                                
                                <li>
                                    Customers can exchange or return the items within 7-8 days from the date of purchase mentioned on the receipt of the original order. 
                                </li>
                                <li>
                                    Items must be returned with all tags remained attached with proof of purchase. 
                                </li>
                                <li>
                                    Our team of professionals will inspect the item once we received it and notify your returned item. When your return is confirmed and approved, we will initiate the refund to your original method of payment. You will receive your money within a certain amount of days, depending on your financial issuer’s policies.
                                </li>
                                <li>
                                    Shipping costs will not be refundable and You will be responsible for your own shipping costs for returning your item. 
                                </li>

                                <li>
                                    If the returned item is damaged in any way, or you have initiated the return after 7-8 days have passed, you will not be eligible for the refund. 
                                </li>
                                <li>
                                    If anything is unclear or you have more questions feel free to contact our customer support team. 
                                    You may call us or mail us at contact@etechanhealthtech.com 
                                </li>
                        
                            </ul>
                            </>}
                        
                            {policyId === 'privacy-policy' && <>
                            <p>
                                This privacy policy has been compiled to serve you better and safe, if you are concerned
                                with how your ‘Personally Identifiable Information’ is being used online. 
                                ‘Personally Identifiable Information’ is information that can be used on its own or 
                                with other information to identify, contact, or locate a single person, or to identify an 
                                individual in context. Please read our privacy policy carefully to get a clear understanding of 
                                how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance 
                                with our website. Please also note that: Our privacy policy is subject to change at any time without notice. 
                                To make sure you are aware of any changes.
                            </p>
                            <h4>Third-party disclosure</h4>
                            <p>
                                We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information. 
                            </p>

                            <h4>We collect your email address in order to:</h4>
                            <p>
                                Send information, respond to inquiries, and/or other requests or questions
                                Market to our mailing list or continue to send emails to our clients after the original transaction has occurred. 
                            </p>                           
                            
                            <h4>When do we collect information?</h4>
                            <p>
                                We collect information from you when you visit or enter information or register on our site. We may automatically 
                                track certain information about you based upon your behaviour on our site. We use this information to do internal 
                                research on our users’ demographics, interests, and behaviour to better understand, protect and serve our users. 
                                This information is compiled and analysed on an aggregated basis. This information may include the URL that you just 
                                came from (whether this URL is on our site or not), which URL you next go to (whether this URL is on our site or not), 
                                your computer browser information, and your IP address.
                            </p>                           
                            
                            <h4>Why to do registration?</h4>
                            <p>
                                In order to take advantage of some features of the site, you must register and create a member account which is free of any 
                                cost. An online registration form may require you to provide information such as your name, email address, and a password. 
                                Your password is a component of our security system. As such, it is your responsibility to protect it. Do not share your 
                                password with any third parties. If your password has been compromised for any reason, you should change it immediately.
                            </p> 

                            <h4>What personal information do we collect from the people that visit our blog and website?</h4>
                            <p>
                                When ordering or registering on our site, as appropriate, you may be asked to enter your details to help you with your experience.
                            </p>                           
                            
                            <h4>How do we use your information?</h4>
                            <p>
                                We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a 
                                survey or marketing communication, surf the website, or use certain other site features in the following ways:

                               
                                    <li>
                                        To allow us to better service you in responding to your customer service requests.
                                    </li>
                                    <li>
                                        To administer a contest, promotion, survey or other site feature.
                                    </li>
                                    <li>
                                        To ask for ratings and reviews of services or products                                    </li>
                                    <li>
                                        To follow up with you after correspondence (live chat, email or phone inquiries)
                                    </li>
                                
                            </p>  

                            <h4>How do we protect your information?</h4> 
                            <ul>
                                <li>
                                    We do not use vulnerability scanning and/or scanning to PCI standards.

                                </li>
                                
                                <li>
                                    We only provide articles and information. We never ask for credit card numbers.
                                </li>
                                <li>
                                    We use regular Malware Scanning.
                                </li>
                                <li>
                                    Your personal information is contained behind secured networks and is only accessible by a 
                                    limited number of persons who have special access rights to such systems, 
                                    and are required to keep the information confidential. In addition, all 
                                    sensitive/credit information you supply is encrypted via Secure Socket 
                                    Layer (SSL) technology.
                                </li>
                                <li>
                                    We implement a variety of security measures when a user enters, submits, or accesses their 
                                    information to maintain the safety of your personal information.
                                </li>

                                <li>
                                    All transactions are processed through a gateway provider and are not stored or processed on our servers.
                                </li>
                               
                            </ul>  

                            <h4>Do we use ‘cookies’?</h4>
                            <p>
                                Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive 
                                through your Web browser (if you allow) that enables the site’s or service provider’s systems to recognize 
                                your browser and capture and remember certain information. For instance, we use cookies to help us remember 
                                and process the items in your shopping cart. They are also used to help us understand your preferences based 
                                on previous or current site activity, which enables us to provide you with improved services. We also use cookies 
                                to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences
                                and tools in the future.<br/>
                                We use cookies to:
                                
                                    <li>
                                        Understand and save user’s preferences for future visits.
                                    </li>
                                    <li>
                                        Keep track of advertisements.
                                    </li>
                                    <li>
                                        Compile aggregate data about site traffic and site interactions 
                                        in order to offer better site experiences and tools in the future. 
                                        We may also use trusted third-party services that track this 
                                        information on our behalf.
                                    </li>
                                    
                                

                                <>
                                    You can choose to have your computer warn you each time a cookie is being sent, 
                                    or you can choose to turn off all cookies. You do this through your browser settings. 
                                    Since the browser is a little different, look at your browser’s Help Menu to learn the 
                                    correct way to modify your cookies.
                                </>
                                <>
                                    If you turn cookies off, some features will be disabled. It won’t affect the user’s experience
                                        that makes your site experience more efficient and may not function properly. However, you will still be able to place orders.
                                </>
                                
                            </p>   

                            <h4>Third-party links</h4> 
                            <p>
                                We do not include or offer third-party products or services on our website.
                            </p>  

                            <h4>
                                Contact Us
                            </h4> 

                            <p>
                            If there are any questions regarding this privacy policy, you may contact us using the information below.
                            </p>  

                            <address>
                                Etechan Health Care Private Limited<br/>

                                110, Pocket-1, Jasola, New Delhi-110025<br/>

                                Email: info@etechanhealthtech.com<br/>
                                Web: www.etechanhealthtech.com<br/>
                            </address>             
                            </>}

                            {policyId === 'terms-conditions' && <>

                            <p>
                                PLEASE READ THIS TERMS OF AGREEMENT CAREFULLY. BY USING THIS WEBSITE OR ORDERING PRODUCTS 
                                FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF 
                                THIS AGREEMENT.
                            </p>

                            <p>
                                This Terms of  Agreement (the “Agreement”) governs your use of this website, www.etechanhealthtech.com (the “Website”), 
                                Etechan Health Care Pvt Ltd (“Business Name”) offer of products & services for purchase on this Website, or your purchase
                                 of products available on this Website. This Agreement includes, and incorporates by this reference, the policies and 
                                 guidelines referenced below. Etechan Health Care Pvt Ltd reserves the right to change or revise the terms and conditions 
                                 of this Agreement at any time by posting any changes or a revised Agreement on this Website. Etechan Health Care Pvt Ltd 
                                 will alert you that changes or revisions have been made by indicating on the top of this Agreement the date it was last 
                                 revised. The changed or revised Agreement will be effective immediately after it is posted on this Website. Your use of 
                                 the Website following the posting of any such changes or of a revised Agreement will constitute your acceptance of any 
                                 such changes or revisions. Etechan Health Care Pvt Ltd encourages you to review this Agreement whenever you visit the 
                                 Website to make sure that you understand the terms and conditions governing use of the Website. This Agreement does not 
                                 alter in any way the terms or conditions of any other written agreement you may have with Etechan Health Care Pvt Ltd for 
                                 other products or services. If you do not agree to this Agreement (including any referenced policies or guidelines), 
                                 please immediately terminate your use of the Website. If you would like to print this Agreement, please click the print 
                                 button on your browser toolbar.
                            </p>

                            <h4>PRIVACY</h4>
                            <p>
                                Etechan Health Care Pvt Ltd believes strongly in protecting user privacy and providing you with notice of Etechan Health 
                                Care Pvt Ltd.’s use of data. Please refer to Etechan Health Care Pvt Ltd privacy policy, incorporated by reference herein, 
                                that is posted on the Website.
                            </p>

                            <h4>AGREEMENT TO BE BOUND</h4>
                            <p>
                                By using this Website or ordering Products, you acknowledge that you have read and agree to be bound by this Agreement 
                                and all terms and conditions on this Website.
                            </p>
                            
                            </>}

                        </div>
                    </div>
                    
                
                </div>
    
            </div>
    )
}

export default PolicyScreen
