import axios from 'axios'
import {
    SERVICE_LIST_REQUEST,
    SERVICE_LIST_SUCCESS,
    SERVICE_LIST_FAIL,

    SERVICE_DETAILS_REQUEST,
    SERVICE_DETAILS_SUCCESS,
    SERVICE_DETAILS_FAIL,

    SERVICE_DELETE_REQUEST,
    SERVICE_DELETE_SUCCESS,
    SERVICE_DELETE_FAIL,

    SERVICE_CREATE_REQUEST,
    SERVICE_CREATE_SUCCESS,
    SERVICE_CREATE_FAIL,

    SERVICE_UPDATE_REQUEST,
    SERVICE_UPDATE_SUCCESS,
    SERVICE_UPDATE_FAIL,
    
    SERVICE_CREATE_REVIEW_REQUEST,
    SERVICE_CREATE_REVIEW_SUCCESS,
    SERVICE_CREATE_REVIEW_FAIL,

    SERVICE_TIMESLOTS_REQUEST,
    SERVICE_TIMESLOTS_SUCCESS,
    SERVICE_TIMESLOTS_FAIL,

    SERVICE_TIMESLOT_CREATE_REQUEST,
    SERVICE_TIMESLOT_CREATE_SUCCESS,
    SERVICE_TIMESLOT_CREATE_FAIL,

    SERVICE_TIMESLOT_UPDATE_REQUEST,
    SERVICE_TIMESLOT_UPDATE_SUCCESS,
    SERVICE_TIMESLOT_UPDATE_FAIL,

    SERVICE_TIMESLOT_DELETE_REQUEST,
    SERVICE_TIMESLOT_DELETE_SUCCESS,
    SERVICE_TIMESLOT_DELETE_FAIL,
} from '../constants/serviceConstants'

export const listServices = (keyword = '') => async (dispatch) => {
    try {
        dispatch({ type: SERVICE_LIST_REQUEST })

        const { data } = await axios.get(`/api/services${keyword}`)

        dispatch({
            type: SERVICE_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SERVICE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listServiceDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: SERVICE_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/services/${id}`)

        dispatch({
            type: SERVICE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SERVICE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createService = (serviceName) => async (dispatch,getState) => {
    try {
        dispatch({
            type: SERVICE_CREATE_REQUEST
        })


        const {
            userLogin: {userInfo},
        } = getState()
        // console.log('userinfo:',userLogin)

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/services/create/`,   
            {'serviceName':serviceName},         
            config
        )

        dispatch({
            type: SERVICE_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: SERVICE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateService = (service) => async (dispatch,getState) => {
    try {
        dispatch({
            type: SERVICE_UPDATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/services/update/${service.slug}/`,   
            service,         
            config
        )

        dispatch({
            type: SERVICE_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch({ 
            type: SERVICE_DETAILS_SUCCESS,  
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: SERVICE_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteService = (id) => async (dispatch,getState) => {
    try {
        dispatch({
            type: SERVICE_DELETE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/services/delete/${id}/`,            
            config
        )

        dispatch({
            type: SERVICE_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: SERVICE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createServiceReview = (serviceId, review) => async (dispatch,getState) => {
    try {
        dispatch({
            type: SERVICE_CREATE_REVIEW_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/services/${serviceId}/reviews/`,   
            review,         
            config
        )

        dispatch({
            type: SERVICE_CREATE_REVIEW_SUCCESS,
            payload: data,
        })



    } catch (error) {
        dispatch({
            type: SERVICE_CREATE_REVIEW_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getServiceTimeslots = (serviceId) => async (dispatch,getState) => {
    try {
        dispatch({
            type: SERVICE_TIMESLOTS_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/services/timeslots/${serviceId}`,            
            config
        )

        dispatch({
            type: SERVICE_TIMESLOTS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: SERVICE_TIMESLOTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createServiceTimeslot = (serviceId,timeslot) => async (dispatch,getState) => {
    try {
        dispatch({
            type: SERVICE_TIMESLOT_CREATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/services/createServiceTimeslot/${serviceId}/`,   
            timeslot,         
            config
        )

        dispatch({
            type: SERVICE_TIMESLOT_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: SERVICE_TIMESLOT_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateServiceTimeslot = (timeslot) => async (dispatch,getState) => {
    try {
        dispatch({
            type: SERVICE_TIMESLOT_UPDATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/services/updateServiceTimeslot/${timeslot._id}/`,   
            timeslot,         
            config
        )

        dispatch({
            type: SERVICE_TIMESLOT_UPDATE_SUCCESS,
            payload: data,
        })

        // dispatch({ 
        //     type: SERVICE_TIMESLOTS_SUCCESS,  
        //     payload: data,
        // })


    } catch (error) {
        dispatch({
            type: SERVICE_TIMESLOT_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteServiceTimeslot = (timeslotId) => async (dispatch,getState) => {
    try {
        dispatch({
            type: SERVICE_TIMESLOT_DELETE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/services/deleteServiceTimeslot/${timeslotId}/`,            
            config
        )

        dispatch({
            type: SERVICE_TIMESLOT_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: SERVICE_TIMESLOT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
