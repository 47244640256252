import React from 'react'
import { Link } from 'react-router-dom'

function AyurvedaScreen() {
    return (
        <div>

            {/* <!-- Page Title--> */}
                <div className="page-title mb-0">
                <div className="container">
                    <div className="column">
                    <h1>Ayurveda</h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li><Link to="/">Home</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>Treatments</li>
                        <li className="separator">&nbsp;</li>
                        <li>Ayurveda</li>
                    </ul>
                    </div>
                </div>
                </div>

                <section >
                    <img src="/images/ayurveda_banner.jpg" alt="ayur-banner"/>                    
                </section>
                <br/>
                {/* <!-- Page Content--> */}
                <div className="container padding-bottom-2x mb-2">
                
                    <div className="row justify-content-center">
                        {/* <!-- Content--> */}
                        <div className="col-xl-12 col-lg-12 order-lg-2" style={{fontSize:"16px", color:"black"}}>
                            
                        
                            <h2 className="pt-4">Ayurveda: Dive into India’s Indigenous System of Medicines</h2><hr className="margin-bottom-1x"/>
                            <p>With an upsurge in lifestyle-related ailments, there is a wide-reaching resurrection of awareness in holistic 
                                systems of health care. As India has a rich heritage of indigenous medical knowledge in this global resurgence 
                                of interest in holistic therapies, Ayurveda, Unani, Siddha & Naturopathy play a critical role in this new and 
                                emerging situation.
                            </p>
                            <ul>
                                <li>
                                    <strong>Ayurveda:</strong> The philosophy of Ayurvedic system is based on the theory of Pancha bhootas (five element theory). According to this, all the substances and living bodies are composed of five basic elements. These are Prithvi (Earth), Ap (Water), Tejas (Fire), Vayu (Air) & Akash (Ether)
                                </li>
                                <li>
                                    <strong>Siddha:</strong> This system emphasizes that remedial treatment is concerned with not just the disease, but also must consider the patient, atmosphere, age, behaviors, physical condition.

                                </li>
                                <li>
                                    <strong>Unani:</strong> Unani System of medicine is based on well-known data and practices the involving elevation of constructive health and prevention of diseases. This system emphasizes the use of naturally occurring, most herbal medicines ingredients of animal and marine basis.

                                </li>
                                <li>
                                    <strong>Naturopathy:</strong> This system is based on simple laws of nature that emphasize on eating and living conducts, acceptance of purification measures, use of hydrotherapy, baths, massage, etc.

                                </li>
                            </ul>
                            <div>
                                <img class="d-block mx-auto "  src="/images/dive_into.jpg" alt="dive-into"/>
                            </div><br/>
                            <h4>Ayurveda: Five Elements & Doshas</h4>
                            <p className="mt-2">
                            As per the Indian philosophy, the basic elements of all beings are “Pancha Maha Bhootas”. These are:
                            </p>
                            <ul>
                                <li>
                                Prithvi (Earth)
                                </li>
                                <li>
                                Ap (Water)
                                </li>
                                <li>
                                Tejas (Fire)
                                </li>
                                <li>
                                Vayu (Air)
                                </li>
                                <li>
                                Akash (Ether)
                                </li>
                            </ul>
                            <p>
                            The ancient Ayurvedic experts developed this “Pancha Maha Bhootas” theory into the “Tri Dosha” theory 
                            for their easy diagnosis and treatment. The “Tri Doshas” are also made up of the “Pancha Maha Bhootas”. 
                            These three doshas let the body's organs work together harmoniously to build our strong connection with 
                            the surroundings and the cosmos. The “Tri Doshas” are:
                            </p>
                            <ul>
                                <li>
                                <strong>Vatha:</strong> The predominant “Maha Bhoota” involved in “Vatha Dosha” is Vayu (Air) and Akash (Ether). This dosha allows movement in the body.
                                </li>
                                <li>
                                <strong>Pittha:</strong> The “Maha Bhoota” predominance in “Pittha” is Teja (Fire). This dosha allows for change and handles digestive and metabolic functions.
                                </li>
                                <li>
                                <strong>Kapha:</strong> The predominant “Maha Bhootas” in “Kapha” are Prithvi (Earth) and Jal (Water). This dosha gives structure or cohesion to the body.
                                </li>                               
                                
                            </ul>
                            <div>
                                <img class="d-block mx-auto" src="/images/ayurveda3.jpg" alt="ayurveda3"/>
                            </div><br/>

                            <h4>Prevention is better than cure</h4>
                            <p>
                            At Etechan Health Tech, we believe in preventing a disease is better than curing it. We all are aware of the importance of good nutrition 
                            for leading a healthy lifestyle. Our nutritionists track your physical activity along with your diet to help you reach and 
                            uphold your weight issues, lessen your risk of long-lasting fatal diseases and encourage your overall holistic well-being
                             as per your lifestyle. Consequently, you not just build your strength and endurance but also cope with stress and anxiety 
                             easily.
                            </p>
                            
                            <h4>Why consider Ayurvedic Treatment Methodology?</h4>
                            <p>
                            Ayurveda believes that understanding a patient's doshas and his prakruti can help regulate the patient's risk
                             of developing certain ailments or health conditions. Ayurvedic medicinal treatments and supplements can
                              bring great benefits to you by improving the complete wellness of the body. This enhances the general 
                              immunity all by working within the fundamental principles of nature that are always 100% natural.<br/>

                            Here, at Etechan Health Tech, we provide you a holistic healthy recovery using ancient and proven ayurvedic methodologies.
                            </p>
                            <div>
                                <img class="d-block mx-auto" src="/images/ayurveda4.jpg" alt="ayurveda4"/>
                            </div><br/>

                            <h4>Painless Healing from Lethal Ailments</h4>
                            <p>
                            Ayurveda is known to heal any deadly disease using its effective and long proven therapies and medicines. A proper diet with a fitting
                             daily routine is one of the major sources of a healthy and fit lifestyle. One of the major features of healing lethal 
                             ailments like cancer using Ayurveda is that it is completely painless and brings long-lasting results as well. Ayurveda 
                             focuses on treating the ailments from the root cause which is the main reason for its high success rates and long-lasting
                              results. Severe lethal gastrointestinal, cardiac, respiratory, fertility, dermatological, psychological disorders, etc. 
                              can be very efficiently healed using Ayurveda and its therapies.<br/>


                            Such deadly diseases are healed by Ayurveda as it focuses on figuring out the symptoms and then working on the imbalance 
                            created by the diseases. This way, the treatment can bring about long-lasting impacts in the body – the early diagnosis 
                            of the disease, the better the recovery. Ayurveda concentrates on treating the energy imbalances in the body by working 
                            on the doshas (Pitta, Vata & Kapha) of the system, which helps the patient to bring back the balance in the body and 
                            finally recover from the ailments.


                            If you are looking for an alternative therapy for the treatment of any ailment, please feel free to reach out to us. 
                            Our health care experts will be more than happy to guide you and provide you with essential details on any ailments.
                            </p>

                        </div>
                    </div>
                    
                
                    
                    
                
                </div>
    
            </div>
    )
}

export default AyurvedaScreen
