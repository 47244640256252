import React, {useState } from 'react'
import { Link } from 'react-router-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'

import "../../index.css"

function OurServicesScreen() {

    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)
    const [step3, setStep3] = useState(false)
    const [step4, setStep4] = useState(false)

    const activeHandler = (idx) =>{
        if (idx===1){
            setStep1(true)
            setStep2(false)
            setStep3(false)
            setStep4(false)
        }else if(idx===2){
            setStep1(false)
            setStep2(true)
            setStep3(false)
            setStep4(false)        
        }
        else if(idx===3){
            setStep1(false)
            setStep2(false)
            setStep3(true)
            setStep4(false)        
        }
        else if(idx===4){
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(true)        
        }
    }

    return (
        <div>

            {/* <!-- Page Title--> */}
                <div className="page-title">
                <div className="container">
                    <div className="column">
                    <h1>Our Services</h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li><Link to="/">Home</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>Our Services</li>
                    </ul>
                    </div>
                </div>
                </div>
                {/* <!-- Page Content--> */}
                <div className="container padding-bottom-2x mb-2">
                
                    <div className="row justify-content-center">
                        {/* <!-- Content--> */}
                        <div className="col-xl-12 col-lg-10 order-lg-2" style={{fontSize:"16px", padding:"0"}}>
                            
                            <h2 className="pt-4 ">Our Services</h2>
                            <hr/>
                            {/* <!-- Gallery--> */}
                            <div style={{marginRight:"0px",display:"block"}} className="mt-4">
                                <div style={{width:"100%",display:"block"}} ><img style={{marginRight:"0px"}} src="/images/slider4.jpg" alt="Imageblog" /></div>
                            </div>
                            
                            <h3 className="pt-4 " id="how-we-work-h3">How We Work</h3>
                            <hr id="how-we-work-hr"/>
                            <div className="card text-center mt-4 mb-4">
                                <div className="card-header">
                                <ul className="nav nav-tabs card-header-tabs">
                                    <li className="nav-item navitem-hww"><button id="hww-btn" className={step1 ? "nav-link active" : "nav-link"} type="button" onClick={()=>activeHandler(1)}>Step 1</button></li>
                                    <li className="nav-item navitem-hww"><button id="hww-btn" className={step2 ? "nav-link active" : "nav-link"} type="button" onClick={()=>activeHandler(2)}>Step 2</button></li>
                                    <li className="nav-item navitem-hww"><button id="hww-btn" className={step3 ? "nav-link active" : "nav-link"} type="button" onClick={()=>activeHandler(3)}>Step 3</button></li>
                                    <li className="nav-item navitem-hww"><button id="hww-btn" className={step4 ? "nav-link active" : "nav-link"} type="button" onClick={()=>activeHandler(4)}>Step 4</button></li>
                                </ul>
                                </div>
                                <div className="card-body">
                                
                                <div className="row" style={{fontSize:"14px",color:"black"}}>
                                    {step1 && 
                                    <>
                                    <div className= "col-xl-6 col-lg-6">
                                    <img  height="400px" src="/images/step1.jpg" alt="hww-step1" />

                                    </div>

                                    <div className= "col-xl-6 col-lg-6">
                                    <h4 style={{textAlign:"left"}} className="card-title">Complete Profile Analysis</h4> <hr className="margin-bottom-1x"/>   


                                    <p style={{textAlign:"justify"}} className="card-text">Each person's body reacts differently  to 
                                    different kinds of situation, environment ,weather and his/ her food intake. EtechanHealthtech's doctors, 
                                    experts and nutritionists provide the best fit treatment and diet plan to you as per your personalized needs. 
                                    Before planning your treatment we go through your whole body profile checkup which includes needed analysis 
                                    based tests. Based upon these tests we come up with a customized treatment plan for different ailment and diseases 
                                    according to need of your disease. We prepare our own natural and fresh medicines made up of from the medicinal 
                                    plants and herbs etc. </p>
                                    </div>
                                    </>}

                                    {step2 && 
                                    <>
                                    <div className= "col-xl-6 col-lg-6">
                                    <img  height="400px" src="/images/step2.jpg" alt="hww-step1" />

                                    </div>

                                    <div className= "col-xl-6 col-lg-6">
                                    <h4 style={{textAlign:"left"}} className="card-title">Personalized Treatment</h4><hr className="margin-bottom-1x"/>

                                    <p style={{textAlign:"justify"}} className="card-text">Once we completely analyze your profile , we come up with a customized treatment plan for you. 
                                    We assign a Doctor, Therapist and personal Nutritionist to keep your ongoing treatment and its affects on right track. They help you to recover 
                                    from diseases  and achieve your health. </p>
                                    </div>
                                    </>}

                                    {step3 && 
                                    <>
                                    <div className= "col-xl-6 col-lg-6">
                                    <img  height="400px" src="/images/step3.jpg" alt="hww-step1" />

                                    </div>

                                    <div className= "col-xl-6 col-lg-6">
                                    <h4 style={{textAlign:"left"}} className="card-title">Progress Tracking via Technology</h4><hr className="margin-bottom-1x"/>

                                    <p style={{textAlign:"justify"}} className="card-text">As the technology is growing, we are coming up with newer ways to keep a 
                                    track of our patients using technical devices. This helps us to be up- to- date with their progress and demands as well. Usually, 
                                    our Doctors and Nutritionists call you 3-4 times a week or as per your convenience and discuss your progress towards your health goals. 
                                    They discuss your customized treatment and nutrition plan as well. </p>
                                    </div>
                                    </>}

                                    {step4 && 
                                    <>
                                    <div className= "col-xl-6 col-lg-6">
                                    <img  height="400px" src="/images/step4.jpg" alt="hww-step1" />

                                    </div>

                                    <div className= "col-xl-6 col-lg-6">
                                    <h4 style={{textAlign:"left"}} className="card-title">Achieving Pre-set Outcomes</h4><hr className="margin-bottom-1x"/>

                                    <p style={{textAlign:"justify"}} className="card-text">EtechanHealthtech's Doctors and Nutritionists and dieticians have brought 
                                    tremendous changes in our patient s' lives. They have helped people in achieving good health and fast recovery from their diseases 
                                    and illness. We assure you that once you trust us and join hands with us, your health results will be evident and you will surely 
                                    like to recommend us to your friends and family.</p>
                                    </div>
                                    </>}
                                </div>
                                
                                </div>
                            </div>

                            <h3 className="pt-4 " id="diseases-h3">Diseases</h3>
                            <hr id="diseases-hr" className="margin-bottom-1x"/>   

                            <div className="card-deck" style={{color:"black"}}>
                                <div className="card margin-bottom-1x"><img className="card-img-top" id="img-diseases" src="/images/liver.jpg" alt="liver"/>
                                <div className="card-body">
                                    <h4 className="card-title">Liver</h4>
                                    <div id="disease-box">
                                    <p id="disease-text" className="card-text">Being protected by the rib cage, your liver cannot be felt physically but it is the seat of your soul and emotions. 
                                    It gives signals to guide your dietary choices because everything you put in your mouth must go through your liver before
                                    it does anything useful elsewhere in your body.

                                    Your liver is your source of power, your engine and your pharmacist. Your liver likes it bloodline leaky. 
                                    In contrast to the well-sealed vessels that prevent direct contact between blood and most tissues of your body, 
                                    the arteries and veins around your liver are stippled with holes, which means they spray blood right onto the 
                                    hepatocytes. Hepatocyte cells are swimming in blood that make them so incredibly efficient at taking up substances 
                                    from the blood. Weighing in at little over one kg, your liver is a complex natural factory in your body that works 
                                    24 hours daily. It processes virtually everything you eat, you drink, you breathe in or you rub on your skin, in fact, 
                                    your liver performs more than 500 functions which are necessary to your life.</p>
                                    </div>
                                </div>
                                <div style={{ textAlign:"center"}} className="card-footer text-sm text-muted"><Link style={{textDecoration: "none", fontSize:"16px"}} to="/diseases/liver" >Read more..</Link></div>
                                </div>
                                <div className="card margin-bottom-1x"><img className="card-img-top" id="img-diseases" src="/images/digestion.jpg" alt="digestion"/>
                                <div className="card-body">
                                    <h4 className="card-title">Digestive System</h4>
                                    <div id="disease-box">
                                    <p id="disease-text" className="card-text">Despite having a huge impact on our whole-body health system Gut is most 
                                            underrated organ of our body. How many of us aware that a healthy gut makes our immune system stronger, 
                                            it improves our heart health, brain health, our mood, our sleep and digestion, and even it may help prevent 
                                            some cancers and autoimmune diseases.

                                            Each of us have about 300 to 500 different species of bacteria in our digestive tract. While some microorganisms 
                                            are harmful to our health, many are incredibly beneficial and even necessary to a healthy body.

                                             </p>
                                    </div>
                                </div>
                                <div style={{ textAlign:"center"}} className="card-footer text-sm text-muted"><Link style={{textDecoration: "none", fontSize:"16px"}}  to="/diseases/digestive-system">Read more..</Link></div>
                                </div>
                                <div className="card margin-bottom-1x"><img className="card-img-top" id="img-diseases" src="/images/cancer.jpg" alt="cancer"/>
                                <div className="card-body">
                                    <h4 className="card-title">Cancer</h4>
                                    <div id="disease-box">
                                    <p id="disease-text" className="card-text">Cancer is touted to be a deadly and complex ailment. This disease has the capability to 
                                        grow out of control and have an effect on the nearby tissues and the organs of the body. Sometimes, over a long period of time, 
                                        toxic elements accumulate at a specific organ of the body and may produce a fatal growth in its surrounding cells which becomes cancerous. 
                                        In Ayurvedic therapy, cancer treatment is focussed on recuperating the quality of life of the patient all the way through the revival of 
                                        the body’s immune system and recovering the inbuilt immunity.
                                    </p>
                                    </div>
                                </div>
                                <div style={{ textAlign:"center"}} className="card-footer text-sm text-muted"><Link style={{textDecoration: "none", fontSize:"16px"}} to="diseases/cancer" >Read more..</Link></div>
                                </div>

                                
                            </div>

                            <div className="card-deck" style={{color:"black"}}>
                                <div className="card margin-bottom-1x"><img className="card-img-top" id="img-diseases" src="/images/psoriasis.jpg" alt="psoriasis"/>
                                <div className="card-body">
                                    <h4 className="card-title">Psoriasis</h4>
                                    <div id="disease-box">
                                    <p id="disease-text" className="card-text">Psoriasis is a state that causes surplus and rapid growth of skin cells that leads to 
                                    plaque-like deposits on the skin. According to Ayurveda, psoriasis arises due to an imbalance of "Vata" and "Kapha” dosha. Ayurveda 
                                    treatments of psoriasis focus on using herbs along with proper diet and lifestyle changes. Panchakarma therapy is the main Ayurveda 
                                    treatment used to treat psoriasis. It basically involves plant-based medicines and nutritional changes that aim to detoxify and purify the body.
                                    </p>
                                    </div>
                                </div>
                                <div style={{ textAlign:"center"}} className="card-footer text-sm text-muted"><Link style={{textDecoration: "none", fontSize:"16px"}}  to="diseases/psoriasis">Read more..</Link></div>
                                </div>


                                <div className="card margin-bottom-1x" style={{display:'block'}}><img className="card-img-top" id="img-diseases" src="/images/asthama.jpg" alt="asthama"/>
                                <div className="card-body">
                                <h4 className="card-title">Asthama</h4>
                                    <div id="disease-box">
                                    <p id="disease-text" className="card-text">In Ayurveda, the complexity in the process of breathing is known as Swasa in Sanskrit (Asthma). 
                                    Mostly, it originates from the respiratory system and the secondary causes are the other systems of the body which directly and/or 
                                    indirectly affect the respiratory system. According to the Ayurvedic theory, the main cause of Swasa is the Vata and Kapha doshas combined. 
                                    According to Ayurveda, the classification of asthma is broadly done into five main categories: Mahaswasa or Dyspnoea major, Urdhawaswasa or
                                     Expiratory Dyspnoea, Chinna swasa or Chyne- stroke respiration, Kshudra Swasa or Dyspnoea minor, Tamaka Swasa or Bronchial Asthma. 
                                     The process of ayurvedic treatment of asthma involves the movement of the excess Kapha back to the stomach and then eliminating it. 
                                     The methods adopted in Ayurveda for this are: Swedana (Sudation), Vamana (Therapeutic emesis), and Virechana (Therapeutic purgation). 
                                     As per individual conditions & requirements of the patients, these procedures are customized.
                                    </p>
                                    </div>
                                </div>
                                <div style={{ textAlign:"center"}} className="card-footer text-sm text-muted"><Link style={{textDecoration: "none", fontSize:"16px"}} to="diseases/asthama" >Read more..</Link></div>
                                </div>

                                <div className="card margin-bottom-1x"><img className="card-img-top" height="200px" id="img-diseases" src="/images/neuron.jpg" alt="neuron"/>
                                <div className="card-body">
                                    
                                    <h4 className="card-title">Neuron</h4>
                                    <div id="disease-box">
                                    <p id="disease-text" className="card-text">Neuron diseases are the set of diseases that affect the motor neurons which are accountable for 
                                    monitoring the muscle movement in the brain and spinal cord of the body. The diseases concerning neurons affect the motor neurons which 
                                    consequently causes the degeneration of the muscles. This, further, causes the loss of mobility in the limbs, problems with swallowing, 
                                    proper speech and breathing. The main cause of motor neuron disease is the Vata dosha that is in control of the cognitive and neo-cognitive 
                                    functioning of the brain. The ayurvedic treatment of the plan aims to conciliate the aggravated doshas in the body while eradicating amassed 
                                    toxins from the profound layers of body tissues. This helps in bringing the balance and harmony of the bodily systems. Ayurvedic treatment to 
                                    this ailment involves the usage of herbal decoctions, oils and other medications. These solutions are administered to counterbalance the secretion 
                                    of a variety of chemical neurotransmitters and hormones for proper neuron functioning.
                                    </p>
                                    </div>
                                </div>
                                <div style={{ textAlign:"center"}} className="card-footer text-sm text-muted"><Link style={{textDecoration: "none", fontSize:"16px"}} to="diseases/neuron" >Read more..</Link></div>
                                </div>

                                
                            </div>

                            <h3 className="pt-4 " id="meet-our-doctors-h3">Meet Our Doctors</h3>
                            <hr id="meet-our-doctors-hr"/>
                            <div  className="card text-center mt-4 mb-4">
                               
                                <div id="meet-our-doctors-card" className="card-body">
                                
                                <div className="row" style={{fontSize:"16px",color:"black",borderRadius:"0"}}>

                                    <div className= "col-xl-6 col-lg-6">
                                    <h5 style={{textAlign:"left"}} className="card-title"><span>Dr. A K Tiwari <small className="text-sm">Ayurvedic Practitioner</small></span></h5><hr className="margin-bottom-1x"/>

                                    <p style={{textAlign:"justify"}} className="card-text">Dr. A K Tiwari is an integrative practitioner in Indian system of 
                                        medicines & treatment. He is a third-generation classically trained Vaidya in his family . He has been practicing 
                                        alternative medicine in India for more than 25 years. He has treated many patients suffering from deadly diseases 
                                        like Cancer, Neuron, Kidney ailment, Liver, Diabetes, Pancreas, Sugar, Heart ailment and many more.
                                        </p>
                                    </div>
                                    

                                    <div className= "col-xl-6 col-lg-6">
                                    <img   src="/images/dr1.jpg" alt="hww-step1" />

                                    </div>
                                    
                                </div>
                                <br/>
                                <hr/>
                                <br/>
                                <div className="row" style={{fontSize:"16px",color:"black",borderRadius:"0"}}>

                                    
                                    <div className= "col-xl-6 col-lg-6">
                                    <h5 style={{textAlign:"left"}} className="card-title"><span>Dr. S.N. Thakur
                                         <small className="text-sm">Ayurvedic Practitioner</small></span></h5><hr className="margin-bottom-1x"/>

                                    <p style={{textAlign:"justify"}} className="card-text">Dr. S N Thakur is a Scientist, researcher and an integrative practitioner in Indian 
                                            system of medicine and treatment. He is an AYURVED RATNA of India. He is a third generation professionally and 
                                            classically trained doctor in his family having more than 40 years of experience. He has treated many patients of 
                                            Bronchitis, liver, infertility, psoriasis, sugar, blood diseases, brain injury etc.
                                        </p>
                                    </div>
                                    <div className= "col-xl-6 col-lg-6">
                                    <img   src="/images/dr2.jpg" alt="hww-step1" />

                                    </div>
                                </div>
                                
                                </div>
                            </div>

                            <h3 id="whatourclient-h3">What our clients say</h3><hr/>
                            <div style={{color:"black"}}>
                            <Carousel infiniteLoop autoPlay={false} showThumbs={false} interval='5000' showStatus={false} showArrows={true} swipeable={true} centerSlidePercentage="80">
                            <blockquote>
                                <p style={{color:"black"}}>For more than two years, I was experiencing chest pain and jaw pain which were clear symptoms of heart disease. 
                                    I researched online about this and got to know about Etechan Health Tech. During my stay at EHT’s Wellness 
                                    Centre, I noticed a significant improvement in my health. During my stay here, I felt no weariness and I 
                                    now have better mobility. I can now do strenuous activities like running and still do not feel tired. I am very
                                     happy with the treatment. The EHT’s staff is really caring and the services are the best.</p>
                                <cite ><strong ><h6 style={{marginBottom:"0"}}>Kiara Paul</h6></strong><h6 style={{color:"blue"}}>Heart Illness</h6></cite>
                            </blockquote>
                            <blockquote>
                                <p style={{color:"black"}}>Last year, my father was diagnosed with a chronic renal failure with a GFR level {'<'} 60mL/min. 
                                    Many doctors suggested dialysis and renal transplantation. However, due to my dad’s old age, we tried to avoid it. 
                                    I am glad we made that choice and came to EHT’s Wellness Center where he was kept under observation and ayurvedic 
                                    treatment for 2 months. The results were astounding as there was a substantial improvement in his kidney functions. 
                                    I would recommend everyone to once try and avail EHT’s services.</p>
                                <cite ><strong ><h6 style={{marginBottom:"0"}}>Alok Sharma</h6></strong><h7 style={{color:"blue"}}>Kidney Patient</h7></cite>
                            </blockquote>

                            <blockquote>
                                <p style={{color:"black"}}>I was detected with Plaque psoriasis which started from my scalp and expanded to my trunk. 
                                    I had gone to several doctors, but none seem to understand and help me in treatment. Luckily, one of my friends 
                                    recommended treatment from EHT’s natural methodologies when I had almost given up. Today I am 95% recovered and 
                                    this is all due to the love and care that EHT family bestows on its patients. Thank you from the bottom of my heart!
                                    </p>
                                <cite ><strong ><h6 style={{marginBottom:"0"}}>Tameem Ahmad</h6></strong><h7 style={{color:"blue"}}>Psoriasis Patient</h7></cite>
                            </blockquote>
                            
                            <blockquote>
                                <p style={{color:"black"}}>Every single day I am very thankful that I have doctors from Etechan Health Tech in my life. 
                                    I couldn’t think of a better recovery process than this. Last month, I celebrated one year of being cancer-free! 
                                    Thanks to EHT and the amazing team who did not let my morals go down while the struggling recovery time.
                                    </p>
                                <cite ><strong ><h6 style={{marginBottom:"0"}}>Sunita Yadav</h6></strong><h7 style={{color:"blue"}}>Cancer Patient</h7></cite>
                            </blockquote>
                
                            </Carousel>
                            
                            </div>

                            
                            {/* <!-- Post Tags + Share--> */}
                            {/* <div className="d-flex flex-wrap justify-content-between align-items-center pt-3 pb-4">
                                <div className="pb-2"><Link className="text-sm text-muted navi-link" to="#">#electronics,</Link><Link className="text-sm text-muted navi-link" to="#">&nbsp;#gadgets&nbsp;</Link></div>
                                <div className="pb-2"><span className="d-inline-block align-middle text-sm text-muted">Share post:&nbsp;&nbsp;&nbsp;</span><Link className="social-button shape-rounded sb-facebook" to="#" data-toggle="tooltip" data-placement="top" title="Facebook"><i className="socicon-facebook"></i></Link><Link className="social-button shape-rounded sb-twitter" to="#" data-toggle="tooltip" data-placement="top" title="Twitter"><i className="socicon-twitter"></i></Link><Link className="social-button shape-rounded sb-instagram" to="#" data-toggle="tooltip" data-placement="top" title="Instagram"><i className="socicon-instagram"></i></Link><Link className="social-button shape-rounded sb-google-plus" to="#" data-toggle="tooltip" data-placement="top" title="Google +"><i className="socicon-googleplus"></i></Link></div>
                            </div> */}
                            {/* <!-- Post Navigation--> */}
                            {/* <div className="entry-navigation">
                                <div className="column text-left"><Link className="btn btn-outline-secondary btn-sm" to="#"><i className="icon-arrow-left"></i>&nbsp;Prev</Link></div>
                                <div className="column"><Link className="btn btn-outline-secondary view-all" to="blog-rs.html" data-toggle="tooltip" data-placement="top" title="All posts"><i className="icon-menu"></i></Link></div>
                                <div className="column text-right"><Link className="btn btn-outline-secondary btn-sm" to="#">Next&nbsp;<i className="icon-arrow-right"></i></Link></div>
                            </div> */}
                        


                        </div>
                    </div>
                    
                
                    
                    
                    {/* <div className="text-center padding-top-3x mb-30">
                        <h2 className="mb-3">Our Core Team</h2>
                        <p className="text-muted">People behind your awesome shopping experience.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-6 mb-30 text-center"><img className="d-block w-150 mx-auto img-thumbnail rounded-circle mb-2" src="/assets/img/team/01.jpg" alt="Team" />
                        <h6>Grace Wright</h6>
                        <p className="text-muted mb-2">Founder, CEO</p>
                        <div className="social-bar"><Link className="social-button shape-circle sb-facebook" to="#" data-toggle="tooltip" data-placement="top" title="Facebook"><i className="socicon-facebook"></i></Link><Link className="social-button shape-circle sb-twitter" to="#" data-toggle="tooltip" data-placement="top" title="Twitter"><i className="socicon-twitter"></i></Link><Link className="social-button shape-circle sb-google-plus" to="#" data-toggle="tooltip" data-placement="top" title="Google +"><i className="socicon-googleplus"></i></Link></div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-30 text-center"><img className="d-block w-150 mx-auto img-thumbnail rounded-circle mb-2" src="/assets/img/team/02.jpg" alt="Team" />
                        <h6>Taylor Jackson</h6>
                        <p className="text-muted mb-2">Financial Director</p>
                        <div className="social-bar"><Link className="social-button shape-circle sb-skype" to="#" data-toggle="tooltip" data-placement="top" title="Skype"><i className="socicon-skype"></i></Link><Link className="social-button shape-circle sb-facebook" to="#" data-toggle="tooltip" data-placement="top" title="Facebook"><i className="socicon-facebook"></i></Link><Link className="social-button shape-circle sb-paypal" to="#" data-toggle="tooltip" data-placement="top" title="PayPal"><i className="socicon-paypal"></i></Link></div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-30 text-center"><img className="d-block w-150 mx-auto img-thumbnail rounded-circle mb-2" src="/assets/img/team/03.jpg" alt="Team" />
                        <h6>Quinton Cross</h6>
                        <p className="text-muted mb-2">Marketing Director</p>
                        <div className="social-bar"><Link className="social-button shape-circle sb-twitter" to="#" data-toggle="tooltip" data-placement="top" title="Twitter"><i className="socicon-twitter"></i></Link><Link className="social-button shape-circle sb-google-plus" to="#" data-toggle="tooltip" data-placement="top" title="Google +"><i className="socicon-googleplus"></i></Link><Link className="social-button shape-circle sb-email" to="#" data-toggle="tooltip" data-placement="top" title="Email"><i className="socicon-mail"></i></Link></div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-30 text-center"><img className="d-block w-150 mx-auto img-thumbnail rounded-circle mb-2" src="/assets/img/team/04.jpg" alt="Team" />
                        <h6>Liana Mullen</h6>
                        <p className="text-muted mb-2">Lead Designer</p>
                        <div className="social-bar"><Link className="social-button shape-circle sb-behance" to="#" data-toggle="tooltip" data-placement="top" title="Behance"><i className="socicon-behance"></i></Link><Link className="social-button shape-circle sb-dribbble" to="#" data-toggle="tooltip" data-placement="top" title="Dribbble"><i className="socicon-dribbble"></i></Link><Link className="social-button shape-circle sb-instagram" to="#" data-toggle="tooltip" data-placement="top" title="Instagram"><i className="socicon-instagram"></i></Link></div>
                        </div>
                    </div> */}
                </div>
    
            </div>
    )
}

export default OurServicesScreen
