import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

import { validatePhone, validateEmail } from '../actions/userActions'
import { USER_VALIDATE_PHONE_RESET, USER_VALIDATE_EMAIL_RESET } from "../constants/userConstants";

import Loader from '../components/Loader'
import Message from '../components/Message'
import { login } from '../actions/userActions'

// import axios from 'axios'

function LoginScreen({location,history}) {
    
    // const [countryCode,setCountryCode] = useState('IN')
    const countryCode = 'IN'
    const [phoneCode,setPhoneCode] = useState('+91')
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: USER_VALIDATE_PHONE_RESET})       
        dispatch({ type: USER_VALIDATE_EMAIL_RESET})       
    }, [])


    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState(phoneCode)
    const [password, setPassword] = useState('') 
    
    const [loginUsingPhone, setLoginUsingPhone] = useState(true)
    const [loginUsingEmail, setLoginUsingEmail] = useState(false)

    const loginSelectHandler = (method) =>{
        // preventDefault()
        if (method==="phone"){
            setLoginUsingEmail(false)
            setLoginUsingPhone(true)
        } else if (method==="email"){
            setLoginUsingEmail(true)
            setLoginUsingPhone(false)
        }
    }



    const redirect = location.search ? location.search.split('=')[1] : '/'
    // console.log('redirect',redirect)
    const userLogin = useSelector(state => state.userLogin)
    const {error, loading, userInfo } = userLogin

    const userValidatePhone = useSelector(state => state.userValidatePhone)
    const {error:errorValidatePhone, loading:loadingValidatePhone, otpInfo } = userValidatePhone
    
    const userValidateEmail = useSelector(state => state.userValidateEmail)
    const {error:errorValidateEmail, loading:loadingValidateEmail, otpInfo:otpInfoEmail } = userValidateEmail

    useEffect(() => {
        if (userInfo) {
            history.push(redirect)
        }
        if ((otpInfo && otpInfo.phone) ) {
            history.push('/validate-phone-otp')
        }else if( (otpInfoEmail && otpInfoEmail.email)){
            history.push('/validate-email-otp')
        }
    },[history,userInfo,redirect,otpInfo,otpInfoEmail])

    const  handleOnChange =(value, data)=> {
        setPhone(value.replace(/[-+()\s]/g, ''))
        setPhoneCode(data['dialCode'])
      }

    const submitHandler = (e) => {
        e.preventDefault()
        if(loginUsingPhone){
            setEmail('')
        }else{
            setPhone('')
        }
        const phoneWithoutCode = phone.replace(phoneCode, "")
        if (!email && !phoneWithoutCode){
            alert("Please enter phone or email.")
        // }else if (email && phoneWithoutCode){
        //     alert('Please enter ONLY ONE field: "Phone" or "email"')
        }else{
            var username 
            if(loginUsingEmail){
                username = email
            }else if (loginUsingPhone){
                username = phone
            }
            // console.log('username:',username)
            dispatch(login(username,password))
        }
    }

    const otpSubmitHandler = (e) => {
        e.preventDefault()
        if(loginUsingPhone){
            setEmail('')
            
            const phoneWithoutCode = phone.replace(phoneCode, "")
            const name = ''
            if(!phoneWithoutCode ){
                alert('Please Enter Phone Number.')
            }else{
                const login = true
                dispatch(validatePhone(name,phone,email,password,login))
            }
            
            
        }else{
            setPhone('')
            if(!email ){
                alert('Please Enter your Email Id.')
            }else{
                const name = ''
                const login = true
                dispatch(validateEmail(name,phone,email,password,login))
            }
            
        }
    }

    const goToRegisterHandler = () => {
        history.push(redirect ? `/register?redirect=${redirect}` : '/register')        
    }
    return (
        <div>
            
            
            <div className="container padding-bottom-3x mb-2 mt-5">
            
            <div className="row" style={{justifyContent: "center"}}>
            <div className="col-lg-5 col-md-7 col-sm-10" >
                
            <div className="card" style={{ justifyContent: "center" }}>
            <div className="card-body">
                
                
                <form className="" 
                        method="post"
                        onSubmit={submitHandler}>
                    
                  
                    <h4 className="margin-bottom-1x">Sign-In</h4>
                    <ul className="nav nav-tabs nav-justified" role="tablist">
                        
                        <li className="nav-item">
                            <button 
                                style={{width:"100%", background:"none",margin:"0px",color:loginUsingPhone ? "#0055FF" : null}}
                                className={loginUsingPhone ? "nav-link active" : "nav-link"} 
                                type="button" onClick={()=>loginSelectHandler('phone')} 
                                data-toggle="tab" role="tab">Phone
                            </button>
                        </li>
                        <li className="nav-item" style={{color:"blue"}}>
                            <button 
                                style={{width:"100%", background:"none",margin:"0px",color:loginUsingEmail ? "#0055FF" : null}}
                                className={loginUsingEmail ? "nav-link active" : "nav-link"}
                                type="button" onClick={()=>loginSelectHandler('email')} 
                                data-toggle="tab" role="tab">Email                                
                            </button>
                        </li>
                    </ul><br/>
                    {loginUsingPhone &&
                        <div className="form-group input-group">
                        <label style={{paddingLeft:'0px'}} >Phone</label>

                        <PhoneInput 
                            containerclassName="form-control"
                            inputStyle={{width:"100%", paddingLeft:"50px"}}
                            containerStyle={{paddingLeft:"0",width:"100%",border:"none"}}
                            buttonStyle={{paddingRight:"0px"}}
                            countryCodeEditable ={false}
                            enableSearch={true}
                            // preferredCountries={['in']}
                            placeholder="Select Country Code"
                            // buttonStyle={{width:"14%"}}
                            // inputclassName="form-control"
                            // searchclassName="form-control"
                            
                            country={countryCode}
                            value={phone}
                            onChange={handleOnChange}
                        />
                    </div>
                
                    }
                    
                    {loginUsingEmail &&
                        <div className="form-group input-group">
                        
                        <label style={{paddingLeft:'0px'}} >Email</label>

                        <input style={{width:"100%", borderRadius:"5px"}} className="form-control" 
                                type="email" 
                                placeholder="Enter Email" 
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                        />
                                
                        <span style={{marginTop: "16px"}} className="input-group-addon">
                            <i className="icon-mail"></i>
                        </span>
                    </div>
                    }
                    <div className="form-group input-group">
                        <label style={{paddingLeft:'0px'}} >Password</label>
                        <input style={{width:'100%', borderRadius:"5px"}} className="form-control" 
                                type="password" 
                                placeholder="Enter Password" 
                                value={password}
                                onChange={(e)=>setPassword(e.target.value)}
                                required />
                        <span style={{marginTop: "15px"}} className="input-group-addon"><i className="icon-lock"></i></span>
                    </div>

                    {error && <Message variant='danger'>{error}</Message>}
                    {loading && <Loader />}
                    
                    <div className="text-center text-sm-center mt-4">
                        <button className="btn btn-primary btn-block" 
                            type="submit"
                            >Login
                        </button>
                        
                        
                    </div>
                </form>

                <div>
                    <form className="" method="post" onSubmit={otpSubmitHandler}>              
                        
                        <div style={{marginBottom:"-20px"}}>
                        {errorValidatePhone && <Message variant='danger'>{errorValidatePhone}</Message>}
                        {loadingValidatePhone && <Loader />}
                        {errorValidateEmail && <Message variant='danger'>{errorValidateEmail}</Message>}
                        {loadingValidateEmail && <Loader />}
                        <button
                        className="btn btn-outline-primary btn-block mb-4"
                        
                        type="submit">
                        Login using OTP
                        </button>
                        </div>
                    
                    </form>
                    
            
                </div>

                <div className="mt-2 mb-0" style={{marginBottom:"10px",marginTop:"28px", textAlign:"center"}}>
                    No Account? {' '}
                    <button className="btn btn-link-primary " 
                        style={{border: "none",background: "none", 
                                cursor: "pointer", margin: "0", padding: "0"}}
                        onClick={goToRegisterHandler}>Register
                    </button>
                    </div>
                    {loginUsingEmail && 
                    <div className="col-12 text-center text-sm-center mb-3">
                        Forgot Password? {' '}
                        <span><Link  to='/reset-password'
                        style={{textDecoration:"none"}}
                        >Reset Password</Link></span>
                    </div>
                    }
                    
            
            </div>
            </div>   
                
            
            </div>
            </div>
            </div>
        </div>
    )
}

export default LoginScreen
