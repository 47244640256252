import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listUsers, deleteUser } from '../actions/userActions'

function UserListScreen({history}) {

    const dispatch = useDispatch()
    const userList = useSelector(state => state.userList)
    const { loading , error, users } = userList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userDelete = useSelector(state => state.userDelete)
    const { success:successDelete } = userDelete

    useEffect(() => {
        if(userInfo && userInfo.isAdmin){
            dispatch(listUsers())
        }else{
            history.push('/login')
        }
        
    },[dispatch,history,successDelete,userInfo])

    const deleteHandler = (id)=> {
        if (window.confirm('Are you sure you want to delete this user?')) {
            dispatch(deleteUser(id))
        }
        
    }
    return (
        <div>
            {loading ? <Loader/>
                : error ? <Message variant='danger'>{error}</Message>
                : (
                    <>
                    {/* <!-- Page Title--> */}
                    <div className="page-title">
                    <div className="container">
                        <div className="column">
                        <h1>Users List</h1>
                        </div>
                        <div className="column">
                        <ul className="breadcrumbs">
                            <li><Link to="index.html">Home</Link> 
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>Account
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>My Orders</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    {/* <!-- Page Content--> */}
                    <div className="container padding-bottom-3x mb-2">
                    <div className="row">
                        {/* <div className="col-lg-4">
                        
                        <nav className="list-group"><Link className="list-group-item with-badge active" to="account-orders.html"><i className="icon-shopping-bag"></i>Orders<span className="badge badge-default badge-pill">6</span></Link> <Link className="list-group-item" to="account-profile.html"><i className="icon-user"></i>Profile</Link> <Link className="list-group-item" to="account-address.html"><i className="icon-map-pin"></i>Addresses</Link> <Link className="list-group-item with-badge" to="account-wishlist.html"><i className="icon-heart"></i>Wishlist<span className="badge badge-default badge-pill">3</span></Link> <Link className="list-group-item with-badge" to="account-tickets.html"><i className="icon-tag"></i>My Tickets<span className="badge badge-default badge-pill">4</span></Link> </nav>
                        </div> */}
                        <div className="col-lg-12">
                        <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                        <div className="table-responsive">
                        <table className="table table-striped">
                        <thead>
                            <tr>
                            <th>ID</th>
                            <th>Name</th>
                            {/* <th>Reg. Date</th> */}
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Admin</th>
                            <th></th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users && users.map(user => (
                                <tr key={user._id}>
                                <th scope="row">{user._id}</th>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{!user.username.includes('@') && '+'+user.username}</td>
                                <td>{user.isAdmin ? 
                                <i className='fas fa-check' style={{ color: 'green' }}></i> :
                                <i className='fas fa-check' style={{ color: 'red' }}></i>
                                }</td>
                                <td><Link to ={`/admin/user/${user._id}/edit`}>
                                    <i className='fas fa-edit'></i>
                                    </Link>
                                </td>
                                <td><button style={{padding: "0", border: "none", background: "none"}} onClick={()=> deleteHandler(user._id)}>
                                    <i style={{color:'red'}} className='fas fa-trash-alt'></i>
                                    </button>
                                </td>
                                </tr>
                            ))}
                            
                            
                        </tbody>
                        </table>
                    </div>
                        <hr/>
                        </div>
                    </div>
                    </div>
                    
                </>
                )}
            
        </div>
    )
}

export default UserListScreen
