import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'

import { getOrderDetails } from '../actions/orderActions'
import { ORDER_PAY_RESET, ORDER_DELIVER_RESET } from '../constants/orderConstants'

import ReactToPrint from "react-to-print"

import "../invoice.css"

function InvoiceScreen({match, history,location}) {
    const orderId = match.params.id
    const orderDetails = useSelector(state => state.orderDetails)
    const {order, error,loading} = orderDetails

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const dispatch = useDispatch()

    if(!loading && !error){
        order.itemsPrice = order.orderItems.reduce((acc,item) => acc + item.price * item.qty, 0).toFixed(2)
    }

    
    useEffect(() => {
        if(!userInfo){
            history.push('/login')
                }
        
    if(!order || order.slug !== orderId ){
        dispatch({ type: ORDER_PAY_RESET })
        dispatch({ type: ORDER_DELIVER_RESET })
        dispatch(getOrderDetails(orderId))
    }
   
    },[order,orderId,dispatch,history,userInfo])

    const Invoice = React.forwardRef((props, ref) => (
        <>
        
        <div className=" justify-content-center mt-50 mb-50" ref={ref}>
        <div className="row">
            
            <div className="col-md-12">
                <div className="card-inv">
                    <div className="card-header-inv bg-transparent header-elements-inline-inv">
                        <table width="100%">
                            <tbody>
                            <tr>
                                <td style={{width:"50%",textAlign:"left"}}>
                                <img width="150px" src="/images/inv_logo.png" alt="logo"></img>
                                </td>
                                <td style={{width:"50%",textAlign:"right"}}>
                                <h4 className="invoice-color mb-2 mt-md-2">INVOICE</h4>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="card-body-inv" style={{paddingBottom:"0"}}>
                    <table width="100%" >
                        <tbody>
                            <tr>
                                <td style={{width:"50%",textAlign:"left"}}>
                                <p>Order Id: {order.refId}</p>
                                </td>
                                <td style={{width:"50%",textAlign:"right"}}>
                                <p>Date: {order.createdAt.substring(0,10)}</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="row">
                       
                            <div className="col-sm-6">
                            <div className="mb-4 mb-md-2 text-left"> <span className="text-muted">Invoice To:</span>
                                <ul className="list list-unstyled mb-0">
                                   
                                    <li><span className="font-weight-bold">{order.billingAddress.firstName} {order.billingAddress.lastName}</span></li>
                                    <li>{order.billingAddress.address1},</li>
                                    {order.billingAddress.address2 && <li> {order.billingAddress.address2} </li>}
                                    <li>{order.billingAddress.city},&nbsp;{order.billingAddress.state}</li>
                                    <li>{order.billingAddress.country} - {order.billingAddress.postalCode}</li>
                                    <li><i className="far fa-envelope"></i> {order.billingAddress.email}</li>
                                    <li><i className="fas fa-mobile-alt"></i> {order.billingAddress.phone}</li>
                                </ul>
                            </div>
                            </div>
                            <div className="col-sm-6">
                            <div id="paid-to" className="mb-4 mb-md-2 text-right paid-to"> <span className="text-muted">Pay To:</span>
                                <ul className="list list-unstyled mb-0">
                                    
                                    <li><span className="font-weight-bold">Etechan Health Care Pvt. Ltd.
                                    </span></li>
                                    <li>110, Pocket I,</li>
                                    <li>Jasola, New Delhi - 110044</li>
                                    <li>GSTIN: 07AAFCE3423P1ZF</li>
                                    {/* <li><i className="far fa-envelope"></i> info@etechanhealthtech.com</li> */}
                                    <li><i className="fas fa-phone-alt"></i> +91 11 4505 6219</li>
                                </ul>
                            </div>

                            <div id="paid-to-left" style={{display:"none"}} className="mb-4 mb-md-2 text-left paid-to"> <span className="text-muted">Paid To:</span>
                                <ul className="list list-unstyled mb-0">
                                    
                                <li><span className="font-weight-bold">Etechan Health Care Pvt. Ltd.
                                    </span></li>
                                    <li>110, Pocket I,</li>
                                    <li>Jasola, New Delhi - 110044</li>
                                    <li><i className="far fa-envelope"></i> info@etechanhealthtech.com</li>
                                    <li><i className="fas fa-phone-alt"></i> +91 11 4505 6219</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                    <table style={{ margin:"0px 20px 10px 20px"}}>
                            
                        <tbody >
                            <tr style={{border: "0px", padding:"20px"}}>
                                <td style={{textAlign: "left", width: "50%", fontSize: "14px" }}><strong>Order Id:</strong> {order.refId}</td>
                                <td style={{textAlign: "right",  width: "50%", fontSize: "14px"}}><strong>Payment Method:</strong> {order.paymentMethod}</td>
                                
                            </tr>
                        </tbody>
                            
                    </table>
                    <div className="table-responsive" >
                        
                        <table className="table table-lg">
                            <thead  >
                                <tr>
                                    <th>Item</th>
                                    <th>Rate</th>
                                    <th style={{textAlign:"center"}}>Qty.</th>
                                    <th style={{textAlign:"right"}}>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {order.orderItems.map((item,index)=>(
                                <tr key={index}>
                                    <td>
                                        <p className="mb-0">{item.name} ({item.weight})</p> <span className="text-muted"></span>
                                    </td>
                                    <td>{order.currency==="INR" ? '₹' : '$'}{item.price}</td>
                                    <td style={{textAlign:"center"}}>{item.qty}</td>
                                    <td style={{textAlign:"right"}}><span className="font-weight-semibold">{order.currency==="INR" ? '₹' : '$'}{item.price*item.qty}</span></td>
                                </tr>
                            ))}
                               
                               
                            </tbody>
                        </table>
                    </div>
                    <div  className="row ">
                        <div className="col-sm-6 col-md-7 col-lg-7">

                        </div>
                        <div style={{textAlign:"right"}} className="col-sm-6 col-md-5 col-lg-5" >
                            <div  >
                                {/* <h6 className="mb-3 text-left">Total due</h6> */}
                                <div className="table-responsive">
                                    <table width="100%" className="table">
                                        <tbody>
                                            <tr>
                                                <th className="text-left">Subtotal:</th>
                                                <td className="text-right">{order.currency==="INR" ? '₹' : '$'}{order.orderItems.reduce((acc,item) => acc + item.price * item.qty, 0).toFixed(2)}</td>
                                            </tr>

                                            {Number(order.SGST) > 0 &&
                                            <tr>
                                                <th className="text-left">SGST(Included): </th>
                                                <td className="text-right">{order.currency==="INR" ? '₹' : '$'}{order.SGST}</td>
                                            </tr>}
                                            {Number(order.CGST) > 0 &&
                                            <tr>
                                                <th className="text-left">CGST(Included): </th>
                                                <td className="text-right">{order.currency==="INR" ? '₹' : '$'}{order.CGST}</td>
                                            </tr>}
                                            {Number(order.IGST) > 0 &&
                                            <tr>
                                                <th className="text-left">IGST(Included): </th>
                                                <td className="text-right">{order.currency==="INR" ? '₹' : '$'}{order.IGST}</td>
                                            </tr>}

                                            <tr>
                                                <th className="text-left">Shipping: </th>
                                                <td className="text-right">{order.currency==="INR" ? '₹' : '$'}{order.shippingPrice}</td>
                                            </tr>


                                            <tr>
                                                <th className="text-left">Total:</th>
                                                <td className="text-right text-primary">
                                                    <span style={{color:"black",fontWeight:"bold"}}>{order.currency==="INR" ? '₹' : '$'}{order.totalPrice}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* <div className="text-right mt-3"> <button type="button" className="btn btn-primary"><b><i className="fa fa-paper-plane-o mr-1"></i></b> Send invoice</button> </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="card-footer"> 
                    <div >
                            
                            <>
                                <div style={{ display: "flex",flexDirection:"column", textAlign:"center", justifyContent:"center"}}>
                                    <div>
                                        <Link to="www.etechanhealthtech.com">www.etechanhealthtech.com</Link>
                                    </div>
                                    <div>
                                        Thank you for shopping with etechanhealthtech.com
                                    </div>

                                </div>
               
                            </>
                                
                            </div>
                       
                     </div>
                </div>
            </div>
        
        
        </div>
    </div>

        
        </>
    ))

    

    const componentRef = useRef()
    return loading ? (
        <Loader />
    ) : error ? (
        <Message variant="danger">{error}</Message>
    ) : (
        <div>
            
            <div className= "container padding-bottom-3x mb-2">
            <br/>
            <div  style={{justifyContent:"center"}} className= "row">
                {/* <!-- Checkout Adress--> */}
                
                <div  className= "col-xl-8 col-lg-8">
                <div><Link to={`/order/${orderId}`}><h6 className="margin-bottom-1x">Go Back</h6></Link></div>

                    <Invoice   ref={componentRef} />
                    <div >
                    <ReactToPrint 
                    documentTitle={order.refId+'_'+order.billingAddress.firstName+'_'+order.billingAddress.lastName}
                    trigger={() => <center><Link  className="btn btn-primary" to="#">Download Invoice</Link></center>}
                    content={() => componentRef.current}
                    /></div>

                </div>
                
            </div>
            </div>
            
        </div>
    )
}

export default InvoiceScreen
