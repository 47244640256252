export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

// VALIDATE refers to the constants used in registration (first time) process
// However, they're used for LOGIN WITH OTP also.

export const USER_VALIDATE_PHONE_REQUEST = 'USER_VALIDATE_PHONE_REQUEST'
export const USER_VALIDATE_PHONE_SUCCESS = 'USER_VALIDATE_PHONE_SUCCESS'
export const USER_VALIDATE_PHONE_FAIL = 'USER_VALIDATE_PHONE_FAIL'
export const USER_VALIDATE_PHONE_RESET = 'USER_VALIDATE_PHONE_RESET'

export const USER_VALIDATE_PHONE_OTP_REQUEST = 'USER_VALIDATE_PHONE_OTP_REQUEST'
export const USER_VALIDATE_PHONE_OTP_SUCCESS = 'USER_VALIDATE_PHONE_OTP_SUCCESS'
export const USER_VALIDATE_PHONE_OTP_FAIL = 'USER_VALIDATE_PHONE_OTP_FAIL'
export const USER_VALIDATE_PHONE_OTP_RESET = 'USER_VALIDATE_PHONE_OTP_RESET'

export const USER_VALIDATE_EMAIL_REQUEST = 'USER_VALIDATE_EMAIL_REQUEST'
export const USER_VALIDATE_EMAIL_SUCCESS = 'USER_VALIDATE_EMAIL_SUCCESS'
export const USER_VALIDATE_EMAIL_FAIL = 'USER_VALIDATE_EMAIL_FAIL'
export const USER_VALIDATE_EMAIL_RESET = 'USER_VALIDATE_EMAIL_RESET'

export const USER_VALIDATE_EMAIL_OTP_REQUEST = 'USER_VALIDATE_EMAIL_OTP_REQUEST'
export const USER_VALIDATE_EMAIL_OTP_SUCCESS = 'USER_VALIDATE_EMAIL_OTP_SUCCESS'
export const USER_VALIDATE_EMAIL_OTP_FAIL = 'USER_VALIDATE_EMAIL_OTP_FAIL'
export const USER_VALIDATE_EMAIL_OTP_RESET = 'USER_VALIDATE_EMAIL_OTP_RESET'

// VERIFY refers to update/change the EMAIL/PHONE of existing user.
export const USER_VERIFY_PHONE_REQUEST = 'USER_VERIFY_PHONE_REQUEST'
export const USER_VERIFY_PHONE_SUCCESS = 'USER_VERIFY_PHONE_SUCCESS'
export const USER_VERIFY_PHONE_FAIL = 'USER_VERIFY_PHONE_FAIL'
export const USER_VERIFY_PHONE_RESET = 'USER_VERIFY_PHONE_RESET'

export const USER_VERIFY_PHONE_OTP_REQUEST = 'USER_VERIFY_PHONE_OTP_REQUEST'
export const USER_VERIFY_PHONE_OTP_SUCCESS = 'USER_VERIFY_PHONE_OTP_SUCCESS'
export const USER_VERIFY_PHONE_OTP_FAIL = 'USER_VERIFY_PHONE_OTP_FAIL'
export const USER_VERIFY_PHONE_OTP_RESET = 'USER_VERIFY_PHONE_OTP_RESET'

export const USER_VERIFY_EMAIL_REQUEST = 'USER_VERIFY_EMAIL_REQUEST'
export const USER_VERIFY_EMAIL_SUCCESS = 'USER_VERIFY_EMAIL_SUCCESS'
export const USER_VERIFY_EMAIL_FAIL = 'USER_VERIFY_EMAIL_FAIL'
export const USER_VERIFY_EMAIL_RESET = 'USER_VERIFY_EMAIL_RESET'

export const USER_VERIFY_EMAIL_OTP_REQUEST = 'USER_VERIFY_EMAIL_OTP_REQUEST'
export const USER_VERIFY_EMAIL_OTP_SUCCESS = 'USER_VERIFY_EMAIL_OTP_SUCCESS'
export const USER_VERIFY_EMAIL_OTP_FAIL = 'USER_VERIFY_EMAIL_OTP_FAIL'
export const USER_VERIFY_EMAIL_OTP_RESET = 'USER_VERIFY_EMAIL_OTP_RESET'

export const USER_PASSWORD_RESET_REQUEST = 'USER_PASSWORD_RESET_REQUEST'
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS'
export const USER_PASSWORD_RESET_FAIL = 'USER_PASSWORD_RESET_FAIL'

export const USER_PASSWORD_RESET_CONFIRM_REQUEST = 'USER_PASSWORD_RESET_CONFIRM_REQUEST'
export const USER_PASSWORD_RESET_CONFIRM_SUCCESS = 'USER_PASSWORD_RESET_CONFIRM_SUCCESS'
export const USER_PASSWORD_RESET_CONFIRM_FAIL = 'USER_PASSWORD_RESET_CONFIRM_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'