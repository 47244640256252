import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import axios from 'axios'

function UploadStaticFilesScreen({history}) {

    // const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const [response, setResponse] = useState('')

    const [uploading, setUploading] = useState(false)

    useEffect(() => {    

        if(response){
            
            history.push('/admin/upload-static-files')
            // setResponse('')
        }
    },[response,history])

    const [fileToUpload,setFileToUpload] = useState('choose file..')

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
       

        if (window.confirm(`Upload and replace the file - ${e.target.files[0].name}?`)) {
            
            setFileToUpload(e.target.files[0].name)
            const formData = new FormData()

            formData.append('file',file)

            setUploading(true)

            try{
                const config = {
                    headers: {
                        'content-type':'multipart/form-data',
                        Authorization: `Bearer ${userInfo.token}`
                    }
                }
        

                const {data} = await axios.post('/api/products/uploadStaticFile/', formData,config)
                
                setResponse(data)
                setUploading(false)
                // window.location.reload();
            }catch(error){
                setUploading(false)
            }

        }
        
    }

    // if (requestSent){
    //     history.push('/login')
    // }

    return (
        <div>
             {/* <!-- Page Title--> */}
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>Upload Static Files</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Home</Link>
                    </li>
                   
                    <li className="separator">&nbsp;</li>
                    <li>Upload Static Files</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2">
            <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                {/* <h2>Upload Static File</h2> */}
                {/* <p>Change your password in three easy steps. This helps to keep your new password secure.</p>
                <ol className="list-unstyled">
                    <li><span className="text-primary text-medium">1. </span>Fill in your email address below.</li>
                    <li><span className="text-primary text-medium">2. </span>We'll email you a password recovery link.</li>
                    <li><span className="text-primary text-medium">3. </span>Follow the link and set the new password.</li>
                </ol> */}

                {/* <form className="card mt-4" method="post"  onSubmit={submitHandler}> */}
                    <div className="card-body">
                    <div class="col-10" style={{display:"inline-block"}}>
                    {response && <Message variant="success">{response}</Message>}
                    <div className="custom-file">
                        <input className="custom-file-input" 
                               type="file" 
                               id="file-input"
                               
                               onChange={uploadFileHandler}
                                />
                        <label className="custom-file-label" htmlFor="file-input">Uploaded File - {fileToUpload}</label>
                        {uploading && <Loader/>}
                    </div>
                    {/* <button className="btn btn-primary" type="submit" onSubmit={uploadFileHandler}>Upload File</button> */}
                    </div>

                    </div>
                    {/* <Link to="/login">Back to Login</Link> */}
                {/* </form> */}
                </div>
            </div>
            </div>

            
        </div>
    )
}

export default UploadStaticFilesScreen
