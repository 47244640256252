import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { validatePhone, validatePhoneOtp } from "../actions/userActions";
import { USER_VALIDATE_PHONE_OTP_RESET,USER_VALIDATE_PHONE_RESET } from "../constants/userConstants";

function ValidatePhoneOtpScreen({ location, history }) {
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [disableResend, setDisableResend] = useState(false);
  const [enableTime] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const userValidatePhone = useSelector((state) => state.userValidatePhone);
  const { error: errorValidatePhone, loading: loadingValidatePhone, otpInfo } = userValidatePhone;

  const userValidatePhoneOtp = useSelector(
    (state) => state.userValidatePhoneOtp
  );
  const { error, loading, userInfo } = userValidatePhoneOtp;

  const dispatch = useDispatch();

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo) {
      
      if (otpInfo && !otpInfo.forgot){
        setRegistrationSuccess(true)
        setTimeout(() => {
          history.push(redirect);
          dispatch({ type: USER_VALIDATE_PHONE_OTP_RESET });
        }, 3000);
        }else{
          history.push(redirect);
          dispatch({ type: USER_VALIDATE_PHONE_OTP_RESET });
        } 
    }
    if (otpInfo) {
      setPhone(otpInfo.phone);
    }

    if ((!otpInfo && !loadingValidatePhone) || (otpInfo && !otpInfo.phone && !loadingValidatePhone) ) {
        history.push('/register')
      }

  }, [history, userInfo, dispatch, redirect, otpInfo, loading, loadingValidatePhone]);
  

  const submitHandler = (e) => {
    e.preventDefault();
    if (!otpInfo) {
      alert("No Phone number provided.");
    }else{
      dispatch(validatePhoneOtp(phone, otp));
    }
    
  };

  const resendSubmitHandler = (e) => {
    e.preventDefault();
    if (otpInfo && !otpInfo.phone) {
      alert("No Phone number provided.");
    }else{
      const name = "";
      const email = "";
      const password = "";
      dispatch(validatePhone(name, phone, email, password));
      setDisableResend(true);
      setTimeout(() => {
        setDisableResend(false);
      }, 15000);
    }
    
    
    
    // var i = 15;
    // for (i = 15; i >= 0; i--) {
    //   setTimeout(() => {
    //     setEnableTime(String(i));
    //   }, 1000);
    // }
    
  };
 
  const goBackHandler = () => {
    var redir = ''
    if(otpInfo && otpInfo.forgot){
      redir = 'login'
    }else{
      redir = 'register'
    }
    dispatch({ type: USER_VALIDATE_PHONE_RESET });
    if (redir === 'login'){
      history.push('/login')
    }else if(redir === 'register'){
      history.push('/register')
    }
  }

  return (
    <div>
      {/* <!-- Page Content--> */}
      <div className="container padding-bottom-3x mb-2 mt-5">
      <div className="row" style={{ justifyContent: "center" }}>
        <div className="col-lg-4 col-md-6 col-sm-10">
        <div className="card" style={{ justifyContent: "center" }}>
        <div className="card-body">

            

            <div className="padding-top-3x hidden-md-up"></div>
            <form className="" method="post" onSubmit={submitHandler}>
              {/* <div className="card-body"> */}
                <h4 className="margin-bottom-1x">OTP Verification</h4>

                {otpInfo && !otpInfo.forgot && registrationSuccess ?
                  <div className="iziToast iziToast-success" style={{width:"100%", fontSize:"15px", marginBottom:"20px"}}>
                  <div style={{paddingLeft:"10px",paddingTop:"8px",textAlign:"center"}}>
                  <p><i class="fas fa-check-circle "></i>&nbsp; Registration Successfull!</p>
                  </div>
                  
                </div>
                  :
                  <div style={{paddingLeft:"3px"}}>
                    Enter the OTP sent to your mobile number: <br />
                    <div style={{fontWeight:"450"}}>{otpInfo && otpInfo.phone && "+" + otpInfo.phone}.</div>
                  <br/><br/></div>
                }

                

                <div className="form-group" controlid="name">
                  {/* <label style={{ paddingLeft: "0px" }} htmlFor="otp-verify">
                    Enter OTP
                  </label> */}
                  <input
                    className="form-control"
                    placeholder="Enter OTP Here"
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    id="otp-verify"
                    required
                  />
                </div>
              {/* </div> */}
              <div style={{marginBottom:"-10px",marginTop:"30px"}}>
              {loading && <Loader />}
              {loadingValidatePhone && <Loader />}
              {error && <Message variant="danger">{error}</Message>}
              {errorValidatePhone && (
                <Message variant="danger">{errorValidatePhone}</Message>
              )}
              <button
                  className="btn btn-primary btn-block mb-4"                  
                  type="submit"
                >
                  Submit
                </button>
              </div>                
              
            </form>
            <div>
            <form className="" method="post" onSubmit={resendSubmitHandler}>              
                
                <div style={{marginBottom:"-20px"}}>
                <button
                  className="btn btn-outline-primary btn-block mb-4"
                  disabled={disableResend}
                  
                  type="submit">
                  Resend OTP {enableTime ? "in " + enableTime : null}
                </button>
                </div>
              
            </form>
          
            </div>
            
            
            <center>
              <br/>
              <p>Back to <button type="button" 
              className="btn btn-link-primary" 
              style={{border: "none",background: "none", cursor: "pointer", margin: "0", padding: "0"}}
              onClick={goBackHandler}>{otpInfo && otpInfo.forgot ? "Login" : "Register"}
            </button></p>
            </center>
            
          
          
          </div>
        </div>

        </div>
        
        </div>

        </div>

    </div>
  );
}

export default ValidatePhoneOtpScreen;
