import React, { useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listMyOrders } from '../actions/orderActions'



function MyOrdersScreen({history}) {

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const orderListMy = useSelector(state => state.orderListMy)
    const { loading: loadingOrders, error:errorOrders, orders } = orderListMy

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {            
            dispatch(listMyOrders())           
        }
    },[dispatch, userInfo,history])

    
    return (
        <div>
            
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>My Orders</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Home</Link>
                    </li>
                    
                    <li className="separator">&nbsp;</li>
                    <li>My Orders</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2" style={{ minHeight:"90vh"}}>
            
            <div className="row">
               
                <div className="col-lg-12">
                <h4 className="margin-bottom-1x">My Orders</h4>
                <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                {loadingOrders ? (
                        <Loader />
                        ): errorOrders ? (
                            <Message variant='danger'>{errorOrders}</Message>
                        ) :

                        orders.length === 0 ? (
                            <div  style={{marginBottom: "30px"}}>
                                <Message  variant='info'>
                                    No Orders to show. <Link to='/'>Back to Shopping</Link>                                    
                                </Message>
                            </div>
                            ) :
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                <thead>
                                    <tr>
                                    <th>Order #</th>
                                    <th>Date Purchased</th>
                                    <th>Total</th>
                                    <th>Paid</th>
                                    <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {orders.map(order => (
                                        order.status !== "Initiated" &&
                                        <tr key={order._id}>
                                        <td><Link className="navi-link" to="#" data-toggle="modal" data-target="#orderDetails">{order.refId}</Link></td>
                                        <td>{order.createdAt.substring(0,10)}</td>
                                        <td><span>{order.currency === "INR" ? '₹' : order.currency === "USD" ? '$' : null}{order.totalPrice}</span></td>
                                        <td>{order.isPaid ? <span className="text-success">{order.paidAt.substring(0,10)}</span> : (<span className="text-danger"><i className='fas fa-times'></i></span>)}</td>
                                        {/* <td><Link to={`/order/${order.slug}`}>{order.isDelivered ? <span className="text-success">Delivered</span> : (<span className="text-danger">{order.isPaid ? <>Not Delivered</> : <span style={{color:'green',fontWeight:'bold'}}>Continue Shopping</span>}</span>)}</Link></td> */}
                                        <td><Link to={`/order/${order.slug}`}>
                                            {order.status==="Initiated" ? 
                                                <span className="text-info">Initiated</span> : 
                                            order.status==="Confirmed" ? 
                                                <span className="text-success">Confirmed</span> :
                                            order.status==="Shipped" ? 
                                                <span className="text-success">Confirmed</span> :
                                            order.status==="Delivered" ? 
                                                <span className="text-success">Delivered</span> :
                                            order.status==="Cancelled" ? 
                                                <span className="text-danger">Cancelled</span> :
                                            order.status==="Declined" ? 
                                                <span className="text-danger">Declined</span> :
                                            order.status==="Refunded" ? 
                                                <span className="text-warning">Refunded</span> : null
                                            }
                                            </Link></td>
                                        </tr>

                                    ))}
                                    
                                    
                                </tbody>
                                </table>
                            </div>
                        }
                <hr/>
                </div>
            </div>
            </div>
            
        </div>
    )
}

export default MyOrdersScreen
