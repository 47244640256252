export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'

export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL'
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET'

export const ORDER_EMAIL_SEND_REQUEST = 'ORDER_EMAIL_SEND_REQUEST'
export const ORDER_EMAIL_SEND_SUCCESS = 'ORDER_EMAIL_SEND_SUCCESS'
export const ORDER_EMAIL_SEND_FAIL = 'ORDER_EMAIL_SEND_FAIL'
export const ORDER_EMAIL_SEND_RESET = 'ORDER_EMAIL_SEND_RESET'

export const ORDER_LIST_MY_REQUEST = 'ORDER_LIST_MY_REQUEST'
export const ORDER_LIST_MY_SUCCESS = 'ORDER_LIST_MY_SUCCESS'
export const ORDER_LIST_MY_FAIL = 'ORDER_LIST_MY_FAIL'
export const ORDER_LIST_MY_RESET = 'ORDER_LIST_MY_RESET'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL'

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST'
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS'
export const ORDER_DELIVER_FAIL = 'ORDER_DELIVER_FAIL'
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET'

export const ORDER_SHIP_REQUEST = 'ORDER_SHIP_REQUEST'
export const ORDER_SHIP_SUCCESS = 'ORDER_SHIP_SUCCESS'
export const ORDER_SHIP_FAIL = 'ORDER_SHIP_FAIL'
export const ORDER_SHIP_RESET = 'ORDER_SHIP_RESET'

export const ORDER_RZP_CHECKOUT_REQUEST = 'ORDER_RZP_CHECKOUT_REQUEST'
export const ORDER_RZP_CHECKOUT_SUCCESS = 'ORDER_RZP_CHECKOUT_SUCCESS'
export const ORDER_RZP_CHECKOUT_FAIL = 'ORDER_RZP_CHECKOUT_FAIL'
export const ORDER_RZP_CHECKOUT_RESET = 'ORDER_RZP_CHECKOUT_RESET'

export const FREIGHT_CHARGES_REQUEST = 'FREIGHT_CHARGES_REQUEST'
export const FREIGHT_CHARGES_SUCCESS = 'FREIGHT_CHARGES_SUCCESS'
export const FREIGHT_CHARGES_FAIL = 'FREIGHT_CHARGES_FAIL'

export const FREIGHT_CHARGE_CREATE_REQUEST = 'FREIGHT_CHARGE_CREATE_REQUEST'
export const FREIGHT_CHARGE_CREATE_SUCCESS = 'FREIGHT_CHARGE_CREATE_SUCCESS'
export const FREIGHT_CHARGE_CREATE_FAIL = 'FREIGHT_CHARGE_CREATE_FAIL'
export const FREIGHT_CHARGE_CREATE_RESET = 'FREIGHT_CHARGE_CREATE_RESET'

export const FREIGHT_CHARGE_UPDATE_REQUEST = 'FREIGHT_CHARGE_UPDATE_REQUEST'
export const FREIGHT_CHARGE_UPDATE_SUCCESS = 'FREIGHT_CHARGE_UPDATE_SUCCESS'
export const FREIGHT_CHARGE_UPDATE_FAIL = 'FREIGHT_CHARGE_UPDATE_FAIL'
export const FREIGHT_CHARGE_UPDATE_RESET = 'FREIGHT_CHARGE_UPDATE_RESET'

export const FREIGHT_CHARGE_DELETE_REQUEST = 'FREIGHT_CHARGE_DELETE_REQUEST'
export const FREIGHT_CHARGE_DELETE_SUCCESS = 'FREIGHT_CHARGE_DELETE_SUCCESS'
export const FREIGHT_CHARGE_DELETE_FAIL = 'FREIGHT_CHARGE_DELETE_FAIL'

