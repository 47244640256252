import React from 'react'

function Message({variant,children}) {
    return (
        <div style={{marginTop:30, justifyContent:"center"}}>
        {variant === 'primary' ? 
        
        <div className="alert alert-primary alert-dismissible fade show text-center margin-bottom-1x">
            <span className="text-medium">{children}</span> 
        </div>
            : variant === 'info' ?
                <div className="alert alert-info alert-dismissible fade show text-center margin-bottom-1x">
                    <span className="text-medium">{children}</span> 
                </div>

            : variant === 'success' ?

                <div className="alert alert-success alert-dismissible fade show text-center margin-bottom-1x">
                    <span className="text-medium">{children}</span>
                </div>

            : variant === 'warning' ?

                <div className="alert alert-warning alert-dismissible fade show text-center margin-bottom-1x">
                    <span className="text-medium">{children}</span> 
                </div>
            : variant === 'danger' ?

                <div className="alert alert-danger alert-dismissible fade show text-center margin-bottom-1x">
                    <span className="text-medium">{children}</span>
                </div>    
            : null   
    }
        
    </div>
    )
}

export default Message
