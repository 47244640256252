import React, { useEffect,useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { listDoctors, deleteDoctor, createDoctor } from '../../actions/doctorActions'
import { DOCTOR_CREATE_RESET, DOCTOR_UPDATE_RESET } from '../../constants/doctorConstants'
// import styled, { css } from "styled-components";

function DoctorListScreen({history,match}) {

    const dispatch = useDispatch()
    const doctorList = useSelector(state => state.doctorList)
    const { loading , error, doctors} = doctorList

    const doctorDelete = useSelector(state => state.doctorDelete)
    const { loading:loadingDelete , error:errorDelete, success:successDelete } = doctorDelete

    const doctorCreate = useSelector(state => state.doctorCreate)
    const { loading:loadingCreate , error:errorCreate, success:successCreate, doctor:createdDoctor } = doctorCreate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const [doctorName, setDoctorName] = useState('')
    const [showModal, setShowModal] = useState(false)

    // let keyword = history.location.search

    useEffect(() => {
        

        if(!userInfo.isAdmin){
            history.push('/login')
        }

        if(successCreate ){
            history.push(`/admin/doctors/${createdDoctor.slug}/edit`)
            dispatch({ type: DOCTOR_CREATE_RESET })
        }else{
            dispatch(listDoctors())
        }
        
    },[dispatch,history,userInfo,successCreate,createdDoctor])

    useEffect(() => {
        if(successDelete){
            dispatch(listDoctors())
        }       
    }, [successDelete,dispatch])

    const deleteHandler = (id,name)=> {
        if (window.confirm(`Are you sure you want to delete Doctor- ${name}?`)) {
            dispatch(deleteDoctor(id))
            dispatch(listDoctors())
        }        
    }

    const createDoctorHandler = (doctorName) =>{
        dispatch({ type: DOCTOR_UPDATE_RESET })
        setShowModal(false)
        dispatch(createDoctor(doctorName))
    } 

    

    return (
        <div>
            {loading ? <Loader/>
                : error ? <Message variant='danger'>{error}</Message>
                : (
                    <>
                    {/* <!-- Page Title--> */}
                    <div className="page-title">
                    <div className="container">
                        <div className="column">
                        <h1>Doctors List</h1>
                        </div>
                        <div className="column">
                        <ul className="breadcrumbs">
                            <li><Link to="/">Home</Link> 
                            </li>
                            <li className="separator">&nbsp;</li>
                            
                            <li className="separator">&nbsp;</li>
                            <li>Doctors List</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    {/* <!-- Page Content--> */}
                    <div className="container padding-bottom-3x mb-2">
                    <div className="row">

                    <div className="col-12">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div >
                            <h5>Doctors List</h5>
                        </div>
                        
                        <button className="btn btn-secondary margin-right-none" 
                                type="button" 
                                data-toggle="modal" 
                                data-target="#modalCentered"
                                onClick={()=> setShowModal(true)}>
                                    <i className="fas fa-plus"></i> Add Doctor
                        </button>

                    </div>
                    
                    {/* <!-- Vertically Centered Modal--> */}
                    <div className={showModal ? "modal fade show" : "modal fade"} 
                         style={{display: showModal ? "block" : "none", paddingRight: "17px" }}
                         id="modalCentered" 
                         tabIndex="-1" 
                         role="dialog"
                         >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Doctor</h4>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <p>Enter the name of the doctor/expert.</p>
                            <input className="form-control" 
                                    type="text" 
                                    id="text-input" 
                                    value={doctorName}
                                    onChange={(e)=>setDoctorName(e.target.value)}
                                    />
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-danger btn-sm" 
                                    type="button" 
                                    data-dismiss="modal"
                                    onClick={()=> setShowModal(false)}
                                    >Cancel</button>
                            <button className="btn btn-primary btn-sm" type="button" onClick={()=>createDoctorHandler(doctorName)}>Add Doctor</button>
                            
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* <!-- End Modal--> */}

                    </div>
                                {/* <div className="col-lg-3"></div> */}
                                <div className="col-lg-12">
                                    <center>{loadingDelete && <Loader/>}</center>
                                    {errorDelete && <Message variant="danger">{errorDelete}</Message>}

                                    <center>{loadingCreate && <Loader/>}</center>
                                    {errorCreate && <Message variant="danger">{errorCreate}</Message>}
                                <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                                <div className="table-responsive">
                                <table className="table table-striped">
                                <thead>
                                    <tr>
                                    <th>ID</th>
                                    <th>Name</th>                            
                                    <th>User</th>                            
                                    <th>Gender</th>
                                    <th>Speciality</th>
                                    <th>Associations</th>
                                    
                                    <th></th>
                                    <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {doctors && doctors.map(doctor => (
                                        <tr key={doctor._id}>
                                        <th scope="row">{doctor.refId}</th>
                                        <td>{doctor.name} </td>
                                        <td>{doctor.user && doctor.user.username} </td>
                                        <td>{doctor.gender} </td>
                                        <td>{doctor.specialty_1 && doctor.specialty_1}{doctor.specialty_2 && ', '+doctor.specialty_2}{doctor.specialty_3 && ', '+doctor.specialty_3} </td>
                                        <td>{doctor.association_1 && doctor.association_1}{doctor.association_2 && ', '+doctor.association_2}{doctor.specialty_3 && ', '+doctor.specialty_3} </td>
                                        
                                        <td><Link to ={`/admin/doctors/${doctor.slug}/edit`}>
                                            <i className='fas fa-edit'></i>
                                            </Link>
                                        </td>
                                        <td><button style={{padding: "0", border: "none", background: "none"}} onClick={()=> deleteHandler(doctor.slug,doctor.name)}>
                                            <i style={{color:'red'}} className='fas fa-trash-alt'></i>
                                            </button>
                                        </td>
                                        </tr>
                                    ))}
                                    
                                    
                                </tbody>
                                </table>
                            </div>
                                <hr/>
                                </div>
                            </div>
                           
                            
                            {/* <Paginate pages={pages} page={page} isAdmin={true} /> */}

                            </div>
                    
                    </>
                    )}
            
        </div>
    )
}

export default DoctorListScreen
