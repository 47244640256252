import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import Service from '../../components/Service'
import Loader from "../../components/Loader";
import Message from "../../components/Message";
// import SearchBox from '../../components/SearchBox'

// import Paginate from '../../components/Paginate'

import { listDoctors } from "../../actions/doctorActions";

import "../../index.css";

function DoctorHomeScreen(history) {
  const dispatch = useDispatch();
  const doctorList = useSelector((state) => state.doctorList);
  const { error, loading, doctors } = doctorList;
  // let keyword = history.location.search
  useEffect(() => {
    dispatch(listDoctors());
  }, [dispatch]);

  const getExperience = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)

  return (
    <div>
      {/* <section  id="banner-section">
            {!keyword && <ProductCarousel images={["/images/slider1.jpg","/images/slider2.jpg","/images/slider3.jpg","/images/slider4.jpg","/images/slider5.jpg","/images/slider6.jpg"]} />}
            
            </section>
            <br /> */}

      <section className="container padding-bottom-2x mb-2">
        {/* <h2 className="h3 pb-3 text-center">{keyword && keyword.split("=")[1].split("&")[0] !== "" ? "Search Result" : "Featured Products"}</h2> */}
        {/* <h2 className="h3 pb-3 text-center mt-3" style={{textAlign:"left"}}>Doctors On Call</h2> */}
        <div
          className="mt-5 mb-0"
          style={{ width: "100%", display: "inline-block", marginTop: "10px" }}
        >
          <div style={{ float: "left" }}>
            <h2 style={{ marginBottom: "0" }}>Meet Our Doctors</h2>
            <br />
          </div>
          <div style={{ float: "right" }}>
            <Link
              className="btn btn-outline-secondary"
              style={{ margin: "0px" }}
              to="/services"
            >
              Doctors On Call
            </Link>
          </div>
        </div>

        <hr />
        <br />
        <br />
        {loading ? (
          <>
            {" "}
            <Loader />
          </>
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <div className="row">
              {doctors.map((doctor) => (
                <div className="col-sm-3 mb-4" key={doctor.slug}>
                  <div className="card text-center">
                    <Link to={`/doctors/${doctor.slug}`}>
                      <img
                        className="card-img-top"
                        height="400"
                        src={doctor.image_1}
                        alt={doctor.name}
                      />
                    </Link>
                    <div className="card-body"style={{paddingBottom: 10}}>
                      <Link
                        to={`/doctors/${doctor.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        <h6 className="card-title mb-1">{doctor.name}</h6>
                      </Link>
                      <div id="des-box-doctor" >
                      <p className="text-md text-muted mb-1">
                        {doctor.professionTitle}
                        {doctor.degree && doctor.professionTitle
                          ? ", " + doctor.degree
                          : doctor.degree && !doctor.professionTitle
                          ? doctor.degree
                          : ""}
                      </p>

                      {/* {(doctor.specialty_1 ||
                        doctor.specialty_2 ||
                        doctor.specialty_3 ||
                        doctor.specialty_4) && (
                        <p className="text-md text-muted mb-1" style={{fontSize:12,textAlign:'left'}}>
                          {Number(doctor.specialty_1 && doctor.specialty_1 ? 1 : 0) +
                            Number(doctor.specialty_2 && doctor.specialty_2 ? 1 : 0)+
                            Number(doctor.specialty_3 && doctor.specialty_3 ? 1 : 0)+
                            Number(doctor.specialty_4 && doctor.specialty_4? 1 : 0 ) >
                          1 ? (
                            <strong>Specializations:</strong>
                          ) : (
                            <strong>Specialization:</strong>
                          )}{" "}
                          {doctor.specialty_1} {doctor.specialty_2 && ', '+doctor.specialty_2}
                          {doctor.specialty_3 && ', '+doctor.specialty_3}
                          {doctor.specialty_4 && ', '+doctor.specialty_4}
                        </p>
                      )} */}
                      {getExperience(doctor.experienceFrom) > 0 &&
                      <p className="text-md text-muted mb-1" style={{fontSize:12, textAlign:'left'}}>
                          <strong>Experience: </strong> {getExperience(doctor.experienceFrom)} Years
                      </p>
                      }
                     
                      </div>
                      {/* <div id={"des-box"}>
                            <Link style={{color:"black",textDecoration: "none"}} to={`/doctors/${doctor.slug}`}>
                            <p id="des-text">

                            {doctor.description}
                            
                            </p>
                            
                            </Link>

                            </div> */}
                      {/* <div style={{textAlign:"right"}}><Link style={{textDecoration:"none"}} to={`/doctors/${doctor.slug}`}>read more</Link></div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="shopping-cart-footer">
              <div className="column">
                <Link className="btn btn-outline-secondary" to="/services">
                  <i className="icon-arrow-left"></i>&nbsp;Back
                </Link>
              </div>
              <div className="column"></div>
            </div>
            {/* <Paginate page={page} pages={pages} services={true} keyword={keyword} /> */}
          </>
        )}
      </section>
    </div>
  );
}

export default DoctorHomeScreen;
