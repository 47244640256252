import React, { useState} from "react";
// import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// import "photoswipe/dist/photoswipe.css";
// import "photoswipe/dist/default-skin/default-skin.css";

import PhotoSwipeWrapper from '../components/PhotoSwipeWrapper';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function ProductCarousel({
  images,
  showThumbs = false,
  infiniteLoop = true,
  clickable = false,
  hasLink = false,
  
}) {

    let items = []
    let i
    for(i=0; i<images.length; i++){
        items.push({src: images[i]['image'],w:"1024",h:"768"})
    }

    // console.log('clckbl',clickable)

    const [clicked, setClicked] = useState(false)
    const [imageNum, setImageNum] = useState(0)

    const onClickItemEvent = (index)=>{
      if(images[index]['link']){
        const win = window.open(images[index]['link'], "_blank");
        win.focus();
      }else{
        setClicked(true)
        setImageNum(index)
      }
        
    }

    const handleClose = ()=>{
        setClicked(false)
    }

  return (
      <div>

        <Carousel
        autoPlay
        infiniteLoop={infiniteLoop}
        showThumbs={showThumbs}
        interval="3000"
        showStatus={false}
        onClickItem={onClickItemEvent}
        >
        {images.map((image) => (
          hasLink ?
            <div key={image['image']}>
            
            <Link to='/contact-us'>
              <img src={image['image']} alt={image['image']} style={{cursor: "pointer"}}/>
            </Link> 
            
            </div> 
            :
            <div key={image['image']}>
              <img src={image['image']} alt={image['image']} style={{cursor: "pointer"}}/>
            </div>        
        
          
        ))}
        </Carousel>
        {clickable && <PhotoSwipeWrapper isOpen={clicked} index={imageNum} items={items} onClose={handleClose} />}
        

        {/* } */}
      </div>
    
  )  
  
}

export default ProductCarousel;
