import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from 'react-router-dom'
import Loader from "../components/Loader";
import Message from "../components/Message";
import {verifyEmail,verifyEmailOtp } from "../actions/userActions";
import { USER_VERIFY_EMAIL_OTP_RESET, USER_VERIFY_EMAIL_RESET, USER_DETAILS_RESET } from "../constants/userConstants";

function VerifyEmailOtpScreen({ location, history }) {

  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [disableResend, setDisableResend] = useState(false);
  const [enableTime] = useState('');
  const [verifySuccess, setVerifySuccess] = useState(false);

  const userVerifyEmail = useSelector((state) => state.userVerifyEmail);
  const {error: errorVerifyEmail, loading: loadingVerifyEmail, otpInfo } = userVerifyEmail;

  const userVerifyEmailOtp = useSelector((state) => state.userVerifyEmailOtp);
  const { error, loading, userInfo } = userVerifyEmailOtp;

  const dispatch = useDispatch();

  const redirect = location.search ? location.search.split("=")[1] : "/";


  useEffect(() => {
    if (userInfo) {
      if (otpInfo){
        setVerifySuccess(true)
        setTimeout(() => {
          history.push('/profile');
          dispatch({ type: USER_VERIFY_EMAIL_OTP_RESET });
          dispatch({ type: USER_VERIFY_EMAIL_RESET });
          dispatch({ type: USER_DETAILS_RESET });
        }, 3000);
      }else{
        history.push('/profile');
        dispatch({ type: USER_VERIFY_EMAIL_OTP_RESET });
        dispatch({ type: USER_VERIFY_EMAIL_RESET });
        dispatch({ type: USER_DETAILS_RESET });
      }      
      
    }
    if (otpInfo) {
      setEmail(otpInfo.email);
    }

    if ((!otpInfo && !loadingVerifyEmail) || (otpInfo && !otpInfo.email && !loadingVerifyEmail) ) {
        history.push('/verify-email')
      }

  }, [history, userInfo, dispatch, redirect, otpInfo, loading, loadingVerifyEmail]);
  

  const submitHandler = (e) => {
    e.preventDefault();
    if (!otpInfo) {
      alert("No Email id provided.");
    }else{
        dispatch(verifyEmailOtp(email, otp));
    }
    
  };

  const resendSubmitHandler = (e) => {
    e.preventDefault();
    if (otpInfo && !otpInfo.email) {
      alert("No Phone number provided.");
    }else if (otpInfo && otpInfo.email){
      dispatch(verifyEmail(otpInfo.email));   
      setDisableResend(true);
        setTimeout(() => {
            setDisableResend(false);
        }, 15000);   
    }else{
        history.push('/verify-email')
    }
   
    
    
    // var i = 15;
    // for (i = 15; i >= 0; i--) {
    //   setTimeout(() => {
    //     setEnableTime(String(i));
    //   }, 1000);
    // }
    
  };

  const goBackHandler = () => {
    dispatch({ type: USER_VERIFY_EMAIL_OTP_RESET });
    dispatch({ type: USER_VERIFY_EMAIL_RESET });        
    history.push('/profile')
    
  }
 
console.log('otpInfo',otpInfo)
  return (
    <div>
      {/* <!-- Page Content--> */}
      <div className="container padding-bottom-3x mb-2 mt-5">
      <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-lg-4 col-md-6 col-sm-10">
        <div className="card" style={{ justifyContent: "center" }}>
        <div className="card-body">

            

            <div className="padding-top-3x hidden-md-up"></div>
            <form className="" method="post" onSubmit={submitHandler}>
              {/* <div className="card-body"> */}
                <h4 className="margin-bottom-1x">OTP Verification</h4>
                {otpInfo && verifySuccess ?
                  <div className="iziToast iziToast-success" style={{width:"100%", fontSize:"15px", marginBottom:"20px"}}>
                  <div style={{paddingLeft:"10px",paddingTop:"8px",textAlign:"center"}}>
                  <p><i class="fas fa-check-circle "></i>&nbsp; Email Verified!</p>
                  </div>
                  
                </div> :
                  <div style={{paddingLeft:"3px"}}>
                    Enter the OTP sent to your Email id: <br />
                    <strong>{otpInfo && otpInfo.email}</strong>.
                  <br/><br/>
                  </div>
                }
                
                <div className="form-group" controlid="name">
                  {/* <label style={{ paddingLeft: "0px" }} htmlFor="otp-verify">
                    Enter OTP
                  </label> */}
                  <input
                    className="form-control"
                    placeholder="Enter OTP Here"
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    id="otp-verify"
                    required
                  />
                </div>
              {/* </div> */}
              <div style={{marginBottom:"-10px",marginTop:"30px"}}>
              {loading && <Loader />}
              {loadingVerifyEmail && <Loader />}
              {error && <Message variant="danger">{error}</Message>}
              {errorVerifyEmail && (
                <Message variant="danger">{errorVerifyEmail}</Message>
              )}
              <button
                  className="btn btn-primary btn-block mb-4"
                  disabled = {verifySuccess}
                  type="submit"
                >
                  Submit
                </button>
              </div>                
              
            </form>
            <div>
            <form className="" method="post" onSubmit={resendSubmitHandler}>              
                
                <div style={{marginBottom:"-20px"}}>
                <button
                  className="btn btn-outline-primary btn-block mb-4"
                  disabled={disableResend || verifySuccess}
                  
                  type="submit">
                  Resend OTP {enableTime ? "in " + enableTime : null}
                </button>
                </div>
              
            </form>
          
            </div>
            
            
            <center>
              <br/>
            <p>Back to <button type="button" 
              className="btn btn-link-primary" 
              style={{border: "none",background: "none", cursor: "pointer", margin: "0", padding: "0"}}
              onClick={goBackHandler}>Profile
            </button></p>
            </center>
            
          
          
          </div>

          
       
        </div>
        </div>
        
        </div>

        </div>

    </div>
  );
}

export default VerifyEmailOtpScreen;
