import {

    APPOINTMENT_CREATE_REQUEST,
    APPOINTMENT_CREATE_SUCCESS,
    APPOINTMENT_CREATE_FAIL,

    APPOINTMENT_CREATE_RESET,

    APPOINTMENT_DETAILS_REQUEST,
    APPOINTMENT_DETAILS_SUCCESS,
    APPOINTMENT_DETAILS_FAIL,

    APPOINTMENT_PAY_REQUEST,
    APPOINTMENT_PAY_SUCCESS,
    APPOINTMENT_PAY_FAIL, 
    APPOINTMENT_PAY_RESET, 

    APPOINTMENT_EMAIL_SEND_REQUEST,
    APPOINTMENT_EMAIL_SEND_SUCCESS,
    APPOINTMENT_EMAIL_SEND_FAIL, 
    APPOINTMENT_EMAIL_SEND_RESET, 

    APPOINTMENT_LIST_MY_REQUEST,
    APPOINTMENT_LIST_MY_SUCCESS,
    APPOINTMENT_LIST_MY_FAIL, 
    APPOINTMENT_LIST_MY_RESET,
    
    APPOINTMENT_LIST_REQUEST,
    APPOINTMENT_LIST_SUCCESS,
    APPOINTMENT_LIST_FAIL, 

    APPOINTMENT_ATTAIN_REQUEST,
    APPOINTMENT_ATTAIN_SUCCESS,
    APPOINTMENT_ATTAIN_FAIL, 
    APPOINTMENT_ATTAIN_RESET,
    
    APPOINTMENT_CANCEL_REQUEST,
    APPOINTMENT_CANCEL_SUCCESS,
    APPOINTMENT_CANCEL_FAIL, 
    APPOINTMENT_CANCEL_RESET,
    
    APPOINTMENT_UPDATE_DOCTOR_REQUEST,
    APPOINTMENT_UPDATE_DOCTOR_SUCCESS,
    APPOINTMENT_UPDATE_DOCTOR_FAIL, 
    APPOINTMENT_UPDATE_DOCTOR_RESET,

    APPOINTMENT_RZP_CHECKOUT_REQUEST,
    APPOINTMENT_RZP_CHECKOUT_SUCCESS,
    APPOINTMENT_RZP_CHECKOUT_FAIL,
    APPOINTMENT_RZP_CHECKOUT_RESET,

    
} from '../constants/appointmentConstants'

export const appointmentCreateReducer = (state ={}, action)=>{
    switch(action.type) {
        case APPOINTMENT_CREATE_REQUEST:
            return {
                loading: true
            }

        case APPOINTMENT_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                appointment: action.payload
            }

        case APPOINTMENT_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case APPOINTMENT_CREATE_RESET:
            return {}
    
        default:
            return state
    }
}


export const appointmentDetailsReducer = (state ={loading:true, appointmentService:{}, appointmentSchedule:{}, patientDetails:{}}, action)=>{
    switch(action.type) {
        case APPOINTMENT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case APPOINTMENT_DETAILS_SUCCESS:
            return {
                loading: false,
                appointment: action.payload
            }

        case APPOINTMENT_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}

export const appointmentPayReducer = (state ={}, action)=>{
    switch(action.type) {
        case APPOINTMENT_PAY_REQUEST:
            return {
                loading: true
            }

        case APPOINTMENT_PAY_SUCCESS:
            return {
                loading: false,
                success: true
            }

        case APPOINTMENT_PAY_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case APPOINTMENT_PAY_RESET:
        return {}

        default:
            return state
    }
}

export const appointmentRazorpayCheckoutReducer = (state ={}, action)=>{
    switch(action.type) {
        case APPOINTMENT_RZP_CHECKOUT_REQUEST:
            return {
                loading: true
            }

        case APPOINTMENT_RZP_CHECKOUT_SUCCESS:
            return {
                loading: false,
                success: true,
                razorpayData : action.payload
            }

        case APPOINTMENT_RZP_CHECKOUT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case APPOINTMENT_RZP_CHECKOUT_RESET:
            return {}

        default:
            return state
    }
}

export const appointmentAttainReducer = (state ={}, action)=>{
    switch(action.type) {
        case APPOINTMENT_ATTAIN_REQUEST:
            return {
                loading: true
            }

        case APPOINTMENT_ATTAIN_SUCCESS:
            return {
                loading: false,
                success: true
            }

        case APPOINTMENT_ATTAIN_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case APPOINTMENT_ATTAIN_RESET:
        return {}

        default:
            return state
    }
}

export const appointmentCancelReducer = (state ={}, action)=>{
    switch(action.type) {
        case APPOINTMENT_CANCEL_REQUEST:
            return {
                loading: true
            }

        case APPOINTMENT_CANCEL_SUCCESS:
            return {
                loading: false,
                success: true
            }

        case APPOINTMENT_CANCEL_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case APPOINTMENT_CANCEL_RESET:
        return {}

        default:
            return state
    }
}

export const appointmentUpdateDoctorReducer = (state ={}, action)=>{
    switch(action.type) {
        case APPOINTMENT_UPDATE_DOCTOR_REQUEST:
            return {
                loading: true
            }

        case APPOINTMENT_UPDATE_DOCTOR_SUCCESS:
            return {
                loading: false,
                success: true
            }

        case APPOINTMENT_UPDATE_DOCTOR_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case APPOINTMENT_UPDATE_DOCTOR_RESET:
        return {}

        default:
            return state
    }
}

export const appointmentEmailSendReducer = (state ={}, action)=>{
    switch(action.type) {
        case APPOINTMENT_EMAIL_SEND_REQUEST:
            return {
                loading: true
            }

        case APPOINTMENT_EMAIL_SEND_SUCCESS:
            return {
                loading: false,
                success: true,
            }


        case APPOINTMENT_EMAIL_SEND_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case APPOINTMENT_EMAIL_SEND_RESET:
        return {}

        default:
            return state
    }
} 

export const appointmentListMyReducer = (state ={appointments:[]}, action)=>{
    switch(action.type) {
        case APPOINTMENT_LIST_MY_REQUEST:
            return {
                loading: true
            }

        case APPOINTMENT_LIST_MY_SUCCESS:
            return {
                loading: false,
                appointments: action.payload
            }

        case APPOINTMENT_LIST_MY_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case APPOINTMENT_LIST_MY_RESET:
        return {
            appointments: []
        }

        default:
            return state
    }
}

export const appointmentListReducer = (state ={appointments:[]}, action)=>{
    switch(action.type) {
        case APPOINTMENT_LIST_REQUEST:
            return {
                loading: true
            }

        case APPOINTMENT_LIST_SUCCESS:
            return {
                loading: false,
                appointments: action.payload
            }

        case APPOINTMENT_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}



