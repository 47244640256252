import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";
import Message from "../components/Message";
import Loader from "../components/Loader";

import {
  getOrderDetails,
  payOrder,
  deliverOrder,
  shipOrder,
  razorpayCheckout,
  sendEmailOrder,
} from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
  ORDER_SHIP_RESET,
  ORDER_RZP_CHECKOUT_RESET,
  ORDER_EMAIL_SEND_RESET,
} from "../constants/orderConstants";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;

    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

// const __DEV__ = document.domain === 'localhost'


function OrderScreen({ match, history, location }) {
  const orderRazorpayCheckout = useSelector((state) => state.orderRazorpayCheckout);
  const {error: errorRazorpayCheckout, success: successRazorpayCheckout, razorpayData } = orderRazorpayCheckout;

  const orderId = match.params.id;

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const orderShip = useSelector((state) => state.orderShip);
  const { loading: loadingShip, success: successShip } = orderShip;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const [sdkReady, setSdkReady] = useState(false);
  const [showResponse, setShowResponse] = useState(false);

  const [processing, setProcessing] = useState(false);
  if (!loading && !error) {
    order.itemsPrice = order.orderItems
      .reduce((acc, item) => acc + item.price * item.qty, 0)
      .toFixed(2);
  }

  async function addPayPalScript() {
    const res = await loadScript(
      "https://www.paypal.com/sdk/js?client-id=AUKeFcRNOHAZVyeJwUPwBFWRgEE0jNBbFFTRESKdBf3xS3Uov6qIoO6Hpfry9fDaAK4hMGLPoRceVPO7"
    );

    if (!res) {
      alert("Razorpay sdk fail to load");
      return;
    } else {
      setSdkReady(true);
    }
  }

  async function displayRazorPay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay sdk fail to load");
      return;
    }
    // console.log('backend rzp id:',razorpayData['id'])
    var rzp_key = "rzp_live_s78V74sWQrZTxA"
    if(window.location.hostname==='localhost' || window.location.hostname==='127.0.0.1'){
      rzp_key = "rzp_test_SGLAfrxKFdEhc1"
    }
    const options = {
      // key: "rzp_test_SGLAfrxKFdEhc1",
      key: rzp_key,
      amount: razorpayData["amount"],
      currency: razorpayData["currency"],
      name: "Etechan Nutri Health",
      description: "Etechan Nutri Health & wellness World",
      image: "/images/logo.png",
      order_id: razorpayData["id"], //Replace with the order_id generated by you in the backend.
      handler: function (response) {
        // console.log('razorpay response',response)
        // alert(response.razorpay_payment_id);
        dispatch(payOrder(orderId,response))
        setProcessing(true);
        setTimeout(() => {
          dispatch(sendEmailOrder(order.slug, "Confirmed"));
          dispatch(getOrderDetails(orderId));
          setProcessing(false);
        }, 1000);

        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },

      prefill: {
        name:
          order.shippingAddress.firstName +
          " " +
          order.shippingAddress.lastName,
        email: order.shippingAddress.email,
        contact: order.shippingAddress.phone,
      },

      theme: {
        color: "#528FF0",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  // const [rzp,setRzp] = useState(false)
  // console.log('rzp',rzp)
  useEffect(() => {
    if (!userInfo) {
      history.push(`/login?redirect=${location.pathname}`)
    }
    if (
      !order ||
      successPay ||
      order.slug !== orderId ||
      successDeliver ||
      successShip
    ) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch({ type: ORDER_SHIP_RESET });
      dispatch({ type: ORDER_EMAIL_SEND_RESET });
      dispatch({ type: ORDER_RZP_CHECKOUT_RESET });
      dispatch(getOrderDetails(orderId));
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript();
      } else {
        setSdkReady(true);
      }

      if (!successRazorpayCheckout && order.paymentMethod === "Razorpay") {
        dispatch(razorpayCheckout(orderId));

      }
    }
  }, [
    order,
    orderId,
    dispatch,
    successPay,
    successDeliver,
    history,
    userInfo,
    successShip,
    successRazorpayCheckout,
    razorpayData,
    location.pathname
  ]);

  const successPaymentHandler = (paymentResult) => {

    if (paymentResult["status"] === "COMPLETED") {
      dispatch(payOrder(orderId, paymentResult));
      dispatch(sendEmailOrder(order.slug, "Confirmed"));
    }
  };

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
    dispatch(sendEmailOrder(order.slug, "Delivered"));
  };

  const shipHandler = () => {
    dispatch(shipOrder(order));
    dispatch(sendEmailOrder(order.slug, "Shipped"));
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div>
      {/* <!-- Page Title--> */}
      <div className="page-title">
        <div className="container">
          <div className="column">
            <h1>{order.isPaid ? "My Order" : "Checkout"}</h1>
          </div>
          <div className="column">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="separator">&nbsp;</li>
              <li>{order.isPaid ? "My Order" : "Checkout"}</li>
            </ul>
          </div>
        </div>
      </div>

      {/* <!-- Page Content--> */}
      <div className="container padding-bottom-3x mb-2">
        <div className="row">
          {/* <!-- Checkout Adress--> */}
          <div className="col-xl-8 col-lg-8">
            {!order.isPaid && (
              <div className="steps flex-sm-nowrap mb-5">
                <Link className="step" to="#">
                  <h4 className="step-title">
                    <i className="icon-check-circle"></i>1. Address
                  </h4>
                </Link>

                {/* <Link className= "step " to= "#">
                        <h4 className= "step-title"><i className= "icon-check-circle"></i>2. Payment Method</h4>
                    </Link>  */}

                <Link className="step " to="#">
                  <h4 className="step-title">
                    <i className="icon-check-circle"></i>2. Place Order
                  </h4>
                </Link>

                <Link className="step active" to="#">
                  <h4 className="step-title">3. Pay</h4>
                </Link>
              </div>
            )}

            {order.isPaid && (
              <>
                <h5 className="margin-bottom-1x">Order Status</h5>
                <div className="steps flex-sm-nowrap">
                  <div className={order.isPaid ? "step active" : "step"}>
                    <i className="icon-shopping-bag"></i>
                    <h4 className="step-title">Order Confirmed</h4>
                  </div>
                  <div className={order.isPaid ? "step active" : "step"}>
                    <i className="icon-settings"></i>
                    <h4 className="step-title">Processing Order</h4>
                  </div>
                  <div className={order.isShipped ? "step active" : "step"}>
                    <i className="icon-truck"></i>
                    <h4 className="step-title">Product Dispatched</h4>
                  </div>
                  <div className={order.isDelivered ? "step active" : "step"}>
                    <i className="icon-check-circle"></i>
                    <h4 className="step-title">Product Delivered</h4>
                  </div>
                </div>
                <br />
                <br />
              </>
            )}
            <h5>Product Details</h5>
            <div className="table-responsive shopping-cart">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">Product Name</th>
                    <th className="text-center">
                      {order.orderItems.length === 1
                        ? order.orderItems[0].weight_name
                        : "Variant"}
                    </th>
                    <th className="text-center">Subtotal</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {order.orderItems.length === 0 ? (
                    <Message variant="info">Order is empty</Message>
                  ) : (
                    <>
                      {order.orderItems.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            <div className="product-item ">
                              <span>
                                <Link
                                  style={{ paddingRight: "0px" }}
                                  className="product-thumb"
                                  to={`/${item.productId}`}
                                >
                                  <img src={item.image} alt={item.name} />
                                </Link>

                                <h3
                                  id="product-h3"
                                  style={{ marginBottom: "0px" }}
                                  className="product-title form-group"
                                >
                                  <Link to={`/${item.productId}`}>
                                    <strong>{item.name}</strong>
                                  </Link>
                                </h3>
                                <small
                                  style={{
                                    justifyContent: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.category}
                                </small>
                              </span>
                            </div>
                          </td>
                          <td
                            data-label={item.weight_name}
                            className="text-center"
                          >
                            <div className="form-group mb-0">
                              <div className="text-center text-lg">
                                {item.weight}
                              </div>
                            </div>
                          </td>

                          <td
                            data-label="Item Subtotal"
                            className="text-center text-lg"
                          >
                            {item.currency === "INR" ? "₹" : "$"}
                            {item.price} x {item.qty} ={" "}
                            {item.currency === "INR" ? "₹" : "$"}
                            {item.price * item.qty}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="shopping-cart-footer">
              <div className="column"></div>
              <div className="column text-lg">
                <span className="text-muted">Cart Subtotal:&nbsp; </span>
                <strong className="text-gray-dark">
                  {order.currency === "INR" ? "₹" : "$"}
                  {(order.totalPrice - order.shippingPrice).toFixed(2)}
                </strong>
              </div>
            </div>

            {order.rzpResponse && userInfo.isAdmin && (
              <>
                <hr />
                <div className="col-xl-12 col-lg-12 mt-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      onChange={(e) => setShowResponse(!showResponse)}
                      id="order_response"
                      checked={showResponse === true}
                    />

                    <label
                      className="custom-control-label"
                      htmlFor="order_response"
                    >
                      Razorpay Response
                    </label>
                  </div>
                  {showResponse && <p>{order.rzpResponse}</p>}
                </div>
              </>
            )}
          </div>
          {/* <!-- Sidebar --> */}

          <div className="col-xl-4 col-lg-4">
            <aside className="sidebar">
              <div className="padding-top-2x hidden-lg-up"></div>
              <div style={{ marginTop: "0px" }}>
                <section
                  style={{ padding: "20px 15px 0px 15px" }}
                  className="widget widget-order-summary card"
                >
                  <h3 className="widget-title">
                    <strong>Order Summary</strong>
                  </h3>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Cart Subtotal:</td>
                        <td className="text-gray-dark">
                          {order.currency === "INR" ? "₹" : "$"}
                          {order.itemsPrice}
                        </td>
                      </tr>
                      {order.CGST > 0 && (
                        <tr>
                          <td>CGST (Included)</td>
                          <td className="text-gray-dark">
                            {order.currency === "INR" ? "₹" : "$"}
                            {order.CGST}
                          </td>
                        </tr>
                      )}

                      {order.SGST > 0 && (
                        <tr>
                          <td>SGST (Included)</td>
                          <td className="text-gray-dark">
                            {order.currency === "INR" ? "₹" : "$"}
                            {order.SGST}
                          </td>
                        </tr>
                      )}

                      {order.IGST > 0 && (
                        <tr>
                          <td>IGST (Included)</td>
                          <td className="text-gray-dark">
                            {order.currency === "INR" ? "₹" : "$"}
                            {order.IGST}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td>Shipping:</td>
                        <td className="text-gray-dark">
                          {order.currency === "INR" ? "₹" : "$"}
                          {order.shippingPrice}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Total</strong>
                        </td>
                        <td
                          className="text-lg text-gray-dark"
                          style={{ fontWeight: "bold" }}
                        >
                          {order.currency === "INR" ? "₹" : "$"}
                          {order.totalPrice}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  {!order.isPaid &&
                    order.paymentMethod === "PayPal" &&
                    order.status === "Initiated" && (
                      <div>
                        {loadingPay && <Loader />}
                        {/* <PayPalButton 
                                amount={order.totalPrice}
                                onSuccess = {successPaymentHandler}
                            /> */}

                        {!sdkReady ? (
                          <Loader />
                        ) : (
                          <PayPalButton
                            amount={order.totalPrice}
                            onSuccess={successPaymentHandler}
                          />
                        )}
                      </div>
                    )}

                  {errorRazorpayCheckout && (
                    <Message variant="danger">{errorRazorpayCheckout}</Message>
                  )}
                  {!order.isPaid &&
                    order.paymentMethod === "Razorpay" &&
                    successRazorpayCheckout && (
                      <div>
                        <button
                          type="button"
                          onClick={displayRazorPay}
                          className="btn btn-primary btn-block"
                          disabled={
                            processing ||
                            order.shippingAddress["countryCode"] !== "IN"
                          }
                        >
                          {processing ? (
                            <>
                              <span
                                className="spinner-grow spinner-grow-sm text-white mr-2"
                                role="status"
                              ></span>
                              Processing..
                            </>
                          ) : (
                            "Pay Now"
                          )}
                        </button>
                        {/* <Message variant="info">
                            <div style={{textAlign:'justify'}}>
                            Running under test mode for a few moment! 
                            Please call us on <strong>+911145056219</strong> to place your order for now. Or {' '}
                            <strong><Link to='/contact-us'>Contact us.</Link></strong></div>
                        </Message> */}
                      </div>
                    )}
                  {order.shippingAddress["countryCode"] !== "IN" && (
                    <Message variant="info">
                      Your order is initiated but not paid yet. We've mailed you
                      the order details. Our executive will contact you soon for
                      the confirmation and payments of the order.
                    </Message>
                  )}
                  {order.isPaid && (
                    <>
                      <br />
                      <div>
                        {/* <h3
                          style={{ marginBottom: "5px" }}
                          className="widget-title"
                        >
                          Payment Method:
                        </h3>
                        <ul className="list-unstyled">
                          <li>
                            <span className="text-muted"></span>
                            <strong>{order.paymentMethod}</strong>
                          </li>
                        </ul> */}
                        <div>
                          {order.isPaid ? (
                            <Message variant="success">
                              Paid on: {order.paidAt.substring(0, 10)}
                            </Message>
                          ) : (
                            <Message variant="warning">Not Paid </Message>
                          )}
                        </div>
                        {userInfo &&
                          order.isPaid &&
                          // userInfo.isAdmin &&                          
                          // !order.isDelivered && 
                          (
                            <div>
                              <Link
                                className="btn btn-primary btn-block"
                                to={`/invoice/${order.slug}/`}
                              >
                                Invoice
                              </Link>
                            </div>
                          )}
                      </div>
                    </>
                  )}
                </section>
              </div>
              <div>
                <section
                  style={{ padding: "20px 15px 0px 15px" }}
                  className="widget widget-order-summary card"
                >
                  <div>
                    <h3 className="widget-title">
                      <strong>Shipping to:</strong>
                    </h3>
                    <ul style={{}} className="list-unstyled">
                      <li>
                        <span className="text-muted">
                          <strong>Name:&nbsp; </strong>
                        </span>
                        {order.shippingAddress.firstName}{" "}
                        {order.shippingAddress.lastName
                          ? order.shippingAddress.lastName
                          : null}
                      </li>
                      <li>
                        <span className="text-muted">Address:&nbsp; </span>
                        {order.shippingAddress.address1}
                        {order.shippingAddress.address2
                          ? ", " + order.shippingAddress.address2
                          : null}
                        , {order.shippingAddress.city},{" "}
                        {order.shippingAddress.state}-
                        {order.shippingAddress.postalCode},{" "}
                        {order.shippingAddress.country}
                      </li>
                      <li>
                        <span className="text-muted">Email:&nbsp; </span>
                        <Link to={`mailto:${order.shippingAddress.email}`}>
                          {order.shippingAddress.email}
                        </Link>
                      </li>
                      <li>
                        <span className="text-muted">Phone:&nbsp; </span>
                        {order.shippingAddress.phone}
                      </li>
                    </ul>
                  </div>

                  <div>
                    {order.isPaid &&
                      (order.isShipped ? (
                        <>
                          <Message variant="success">
                            Shipped on: {order.shippedAt.substring(0, 10)}
                          </Message>
                          {order.isDelivered ? (
                            <Message variant="success">
                              Delivered on: {order.deliveredAt.substring(0, 10)}
                            </Message>
                          ) : (
                            <Message variant="warning">On the Way.</Message>
                          )}
                        </>
                      ) : (
                        <Message variant="warning">Preparing for dispatch.</Message>
                      ))}
                  </div>
                  {loadingShip && <Loader />}
                  {userInfo &&
                    userInfo.isAdmin &&
                    order.isPaid &&
                    !order.isShipped && (
                      <div className="">
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={shipHandler}
                        >
                          Mark as Shipped
                        </button>
                      </div>
                    )}

                  {loadingDeliver && <Loader />}
                  {userInfo &&
                    userInfo.isAdmin &&
                    order.isPaid &&
                    order.isShipped &&
                    !order.isDelivered && (
                      <div className="">
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={deliverHandler}
                        >
                          Mark as Delivered
                        </button>
                      </div>
                    )}
                </section>
              </div>
            </aside>
          </div>
          {order.isPaid && (
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div
                  style={{ paddingLeft: "24px" }}
                  className="d-flex justify-content-between padding-top-1x"
                >
                  <Link className="btn btn-outline-secondary" to="/">
                    <i className="icon-arrow-left"></i>
                    <span className="hidden-xs-down">
                      &nbsp;Back to shopping
                    </span>
                  </Link>
                  {/* <Link className= "btn btn-primary" to="checkout-complete.html">Complete Order</Link> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderScreen;
