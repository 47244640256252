import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message  from '../components/Message'
// import RelatedProducts  from '../components/RelatedProducts'
import { addToCart, removeFromCart, removeAllFromCart } from '../actions/cartActions'
import "../index.css"


function CartScreen({match, location, history}) {
    const productId = match.params.id
    const weightId = location.search ? (location.search.split('?')[1]).split('=')[1] : "1"
    const qty = location.search ? Number((location.search.split('?')[2]).split('=')[1]) : 1

    const currencyValue = useSelector(state => state.currency)
    const {currency} = currencyValue
    
    const dispatch = useDispatch()

    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    const orderListMy = useSelector(state => state.orderListMy)
    const { orders } = orderListMy

    useEffect(()=> {
        if (productId) {
            dispatch(addToCart(productId,weightId,qty,currency))
        }
    },[dispatch,productId,weightId,qty,currency])

    const removeFromCartHandler = (id,weightId,name) => {
        if (window.confirm(`Remove ${name} from cart?`)) {
            dispatch(removeFromCart(id,weightId))
        }
        
    }

    const removeAllFromCartHandler = () => {
        // confirm("Remove all items from cart?")
        dispatch(removeAllFromCart())
    }

    const checkoutHandler = () => {
        history.push('/login?redirect=shipping')
    }

    return (
        <>
            {/* <!-- Page Title--> */}
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>Cart</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Home</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>Cart</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Alert--> */}
                
            
                        {/* <!-- Page Content--> */}
                            <div className="container padding-bottom-3x mb-1" style={{ minHeight:"70vh"}}>
                        {/* <!-- Shopping Cart--> */}
                        {cartItems.length === 0 ? (
                            <div  style={{marginBottom: "30px"}}>
                                <Message  variant='info'>
                                    Your cart is empty. <Link to='/'>Back to Shopping</Link>
                                    { orders.reduce((acc, order) => acc + !order.isPaid, 0) > 0 &&
                                    <><br/><br/>However, you have {orders.reduce((acc, order) => acc + !order.isPaid, 0)} unpaid Orders. <Link to='/profile'>Continue Shopping</Link></>}
                                </Message>
                            </div>
                            ) : ( <div>
                            <div className="table-container table-responsive shopping-cart">
                            <h4 className="pb-3 text-left">Cart Items</h4>
                                
                                <table className="table cart-table" >
                                <thead>
                                    <tr>

                                    <th style={{textAlign:"center"}}>Product </th>
                                    <th style={{textAlign:"center"}}>{cartItems.length === 1 ? cartItems[0].weight_name : "Description"}</th>
                                    <th  >Quantity</th>
                                    <th className="text-center">Subtotal</th>
                                    <th  className="text-center"><button className="btn btn-sm btn-outline-danger" 
                                            onClick={()=> { if (window.confirm('Remove all items from cart?')) removeAllFromCartHandler() } }>Clear Cart</button></th>
                                    </tr>
                                </thead>
                                
                                <tbody>

                                    {cartItems.map(item => (

                                            <tr key={item.product+item.weightId}>
                                            <td   data-label="Product" className="text-center">
                                                <div  className="product-item ">
                                                    <span>
                                                    <Link  style={{paddingRight:"0px"}} className="" to = {`/${item.product}`} ><img className="cart-product-img" src={item.image} alt={item.name} /></Link>
                                                
                                                    
                                                    <h3 id = "product-h3" style={{marginBottom:"0px"}} className="product-title form-group">
                                                        <Link to={`/${item.product}`}><strong>{item.name}</strong></Link></h3>
                                                        <small style={{justifyContent:'center',textAlign: "center"}}>{item.category}</small>
                                                    </span>
                                                
                                                </div>
                                            </td>
                                            <td  data-label={item.weight_name} className="text-center" >

                                            <div  className="form-group mb-0">

                                                <div  className="text-center text-lg">
                                                       
                                                       {item.weight}
                                                        
                                                </div>

                                                {/* <label id = "weight-label" style={{paddingLeft:'0px', display:'flex'}} htmlFor="weight">{item.weight_name} </label>
                                                <select style={{width:"100%"}} 
                                                    className="form-control" 
                                                    as="select"
                                                    value = {item.weightId}
                                                    onChange = {(e) => {
                                                        dispatch(addToCart(item.product,e.target.value,item.qty,item.currency))   
                                                    }
                                                }
                                                    id="weight">
                                                {item.countInStock_1>0 &&
                                                <option value="1">
                                                    {item.weight_1}
                                                </option>
                                                }


                                                { item.countInStock_2>0 && item.weight_2 ?
                                                <option value="2">
                                                    {item.weight_2}
                                                </option> : null}

                                                {item.countInStock_3>0 && item.weight_3 ?
                                                <option value="3">
                                                    {item.weight_3}
                                                </option> : null }   

                                                {item.countInStock_4>0 && item.weight_4 ?
                                                <option value="4">
                                                    {item.weight_4}
                                                </option> : null }          
                                                
                                                
                                                </select>
                                             */}
                                            </div>
                                            </td>
                                            <td data-label="Quantity" className="text-center">
                                            <div id="div-quantity" className="form-group mb-0">
                                                
                                                <label style={{paddingLeft:'0px', justifyContent:'left', display:'flex'}} htmlFor="quantity">Quantity</label>
                                                
                                                <select className="form-control" 
                                                    as="select"
                                                    value = {item.qty}
                                                    onChange = {(e) => dispatch(addToCart(item.product,item.weightId,Number(e.target.value),item.currency))}
                                                    id="cart-quantity">
                                                    
                                                        {
                                                            
                                                            item.weightId === "1" ?

                                                            [...Array(item.countInStock_1).keys()].map((x) => (
                                                                (x <= 9) &&
                                                                <option key={x + 1} value={x + 1}>
                                                                    {x + 1}
                                                                </option>
                                                            )) : item.weightId === "2" ?
                                                            [...Array(item.countInStock_2).keys()].map((x) => (
                                                                (x <= 9) &&
                                                                <option key={x + 1} value={x + 1}>
                                                                    {x + 1}
                                                                </option>
                                                            )) : item.weightId === "3" ?
                                                            [...Array(item.countInStock_3).keys()].map((x) => (
                                                                (x <= 9) &&
                                                            <option key={x + 1} value={x + 1}>
                                                                {x + 1}
                                                            </option>
                                                        )) : item.weightId === "4" ?
                                                        [...Array(item.countInStock_4).keys()].map((x) => (
                                                            (x <= 9) &&
                                                            <option key={x + 1} value={x + 1}>
                                                                {x + 1}
                                                            </option>
                                                        )) : null 
                                                        } 

                                                </select>
                                            </div>
                                            </td>
                                            <td data-label="Item Subtotal" className="text-center text-lg">{currency === 'INR' ?
                                                                                    '₹': '$'}{item.price} x {item.qty} = {currency === 'INR' ?
                                                                                    '₹': '$'}{item.price*item.qty}</td>
                                            <td data-label="Remove Item" className="text-center">
                                            <button className="btn btn-link-danger" 
                                                    type="button"
                                                    onClick={()=> removeFromCartHandler(item.product,item.weightId,item.name)}>
                                                <i className="fa fa-trash-alt fa-bold"></i>
                                            </button>
                                                
                                            </td>
                                            </tr>

                                    ))}
                                   
                                    
                                </tbody>
                                </table>
                                
                            </div>
                            
                            <div className="shopping-cart-footer">
                                {/* <div className="column">
                                <form className="coupon-form" method="post">
                                    <input className="form-control form-control-sm" type="text" placeholder="Coupon code" required />
                                    <button className="btn btn-outline-primary btn-sm" type="submit">Apply Coupon</button>
                                </form>
                                </div> */}
                                <div className="column text-lg text-bold"><span className="text-muted">Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}) items:&nbsp; </span>
                                <span className="text-gray-dark">{cartItems[0].currency === 'INR' ? '₹' : '$'}{cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2)}</span></div>
                            </div>
                            </div>
                            )}
                            <div className="shopping-cart-footer">
                                <div className="column">
                                    <Link className="btn btn-outline-secondary" to="/">
                                        <i className="icon-arrow-left"></i>&nbsp;Back to Shopping
                                    </Link>
                                </div>
                                <div className="column">
                                    
                                    
                                    <button className="btn btn-primary" 
                                            type = 'button'
                                            disabled = {cartItems.length === 0}
                                            onClick= {checkoutHandler}
                                        >
                                            Proceed to Checkout
                                    </button>
                                </div>
                            </div>
                            {/* <RelatedProducts /> */}
                            </div>
        </>
    )
}

export default CartScreen
