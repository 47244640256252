export const PAGE_CONTENTS_REQUEST = 'PAGE_CONTENTS_REQUEST'
export const PAGE_CONTENTS_SUCCESS = 'PAGE_CONTENTS_SUCCESS'
export const PAGE_CONTENTS_FAIL = 'PAGE_CONTENTS_FAIL'

export const PAGE_CONTENT_REQUEST = 'PAGE_CONTENT_REQUEST'
export const PAGE_CONTENT_SUCCESS = 'PAGE_CONTENT_SUCCESS'
export const PAGE_CONTENT_FAIL = 'PAGE_CONTENT_FAIL'

// export const PAGE_CONTENT_DELETE_REQUEST = 'PAGE_CONTENT_DELETE_REQUEST'
// export const PAGE_CONTENT_DELETE_SUCCESS = 'PAGE_CONTENT_DELETE_SUCCESS'
// export const PAGE_CONTENT_DELETE_FAIL = 'PAGE_CONTENT_DELETE_FAIL'

export const PAGE_CONTENT_CREATE_REQUEST = 'PAGE_CONTENT_CREATE_REQUEST'
export const PAGE_CONTENT_CREATE_SUCCESS = 'PAGE_CONTENT_CREATE_SUCCESS'
export const PAGE_CONTENT_CREATE_FAIL = 'PAGE_CONTENT_CREATE_FAIL'
export const PAGE_CONTENT_CREATE_RESET = 'PAGE_CONTENT_CREATE_RESET'

export const PAGE_CONTENT_UPDATE_REQUEST = 'PAGE_CONTENT_UPDATE_REQUEST'
export const PAGE_CONTENT_UPDATE_SUCCESS = 'PAGE_CONTENT_UPDATE_SUCCESS'
export const PAGE_CONTENT_UPDATE_FAIL = 'PAGE_CONTENT_UPDATE_FAIL'
export const PAGE_CONTENT_UPDATE_RESET = 'PAGE_CONTENT_UPDATE_RESET'