import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { listDoctorDetails, updateDoctor } from '../../actions/doctorActions'
import { DOCTOR_UPDATE_RESET } from '../../constants/doctorConstants'
import { listUsers } from '../../actions/userActions'

import axios from 'axios'

import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import "../../index.css"

function DoctorEditScreen({match,history}) {

    const dispatch = useDispatch()

    const doctorDetails = useSelector(state => state.doctorDetails)
    const {error, loading, doctor } = doctorDetails
    const doctorUpdate = useSelector(state => state.doctorUpdate)
    const {error:errorUpdate, loading:loadingUpdate, success:successUpdate } = doctorUpdate

    const userList = useSelector(state => state.userList)
    const { loading:loadingUser , error:errorUser, users } = userList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const doctorId = match.params.id

    // const [userId, setUserId] = useState('')
    const [username, setUsername] = useState(doctor && doctor.user ? doctor.user.username : 'Select User')
    const [name, setName] = useState('')

    const [gender, setGender] = useState('')
    const [calendarDate1,setCalendarDate1] = useState(doctor && doctor.dob ? moment(doctor.dob).toDate() : '')
    const [dob, setDob] = useState(doctor && doctor.dob ? moment(doctor.dob).toDate() : '')
    const [professionTitle, setProfessionTitle] = useState('')
    const [description, setDescription] = useState('')

    const [degree, setDegree] = useState('')
    const [calendarDate2,setCalendarDate2] = useState(doctor && doctor.experienceFrom ? moment(doctor.experienceFrom).toDate() : '')
    const [experienceFrom, setExperienceFrom] = useState(doctor && doctor.experienceFrom ? moment(doctor.experienceFrom).toDate() : '')
    
    const [specialty_1, setSpecialty_1] = useState('')
    const [specialty_2, setSpecialty_2] = useState('')
    const [specialty_3, setSpecialty_3] = useState('')
    const [specialty_4, setSpecialty_4] = useState('')
    
    const [association_1, setAssociation_1] = useState('')
    const [association_2, setAssociation_2] = useState('')
    const [association_3, setAssociation_3] = useState('')
    const [association_4, setAssociation_4] = useState('')

    const [websiteLink, setWebsiteLink] = useState('')

    const [image_1, setImage_1] = useState('')
    const [image_2, setImage_2] = useState('')
    const [image_3, setImage_3] = useState('')
    const [image_4, setImage_4] = useState('')

    const [uploading1, setUploading1] = useState(false)
    const [uploading2, setUploading2] = useState(false)
    const [uploading3, setUploading3] = useState(false)
    const [uploading4, setUploading4] = useState(false)

    const [specialityChoices, setSpecialityChoices] = useState('')
    useEffect(() => {    

        if(successUpdate){
            dispatch({type:DOCTOR_UPDATE_RESET})
            history.push('/admin/doctorlist')
        } else {

            if(doctor && (!doctor.name || doctor.slug !== doctorId) ){
                dispatch(listDoctorDetails(doctorId))
            }else{
                setName(doctor ? doctor.name : '')
                setUsername(doctor && doctor.user ? doctor.user.username : '')
                setGender(doctor ? doctor.gender : '')
                setProfessionTitle(doctor ? doctor.professionTitle : '')

                setCalendarDate1(calendarDate1 ? calendarDate1 : doctor && doctor.dob ? moment(doctor.dob).toDate() : '')                
                setDob(doctor && doctor.dob ? doctor.dob : '')

                setCalendarDate2(calendarDate2 ? calendarDate2 : doctor && doctor.experienceFrom ? moment(doctor.experienceFrom).toDate() : '')
                setExperienceFrom(doctor && doctor.experienceFrom ? doctor.experienceFrom : '')
                
                setDescription(doctor ? doctor.description : '')
                setDegree(doctor ? doctor.degree : '')
                setWebsiteLink(doctor ? doctor.websiteLink : '')
                
                setSpecialty_1(doctor ? doctor.specialty_1 : '')    
                setSpecialty_2(doctor ? doctor.specialty_2 : '')    
                setSpecialty_3(doctor ? doctor.specialty_3 : '')    
                setSpecialty_4(doctor ? doctor.specialty_4 : '')    
                
                setAssociation_1(doctor ? doctor.association_1 : '')    
                setAssociation_2(doctor ? doctor.association_2 : '')    
                setAssociation_3(doctor ? doctor.association_3 : '')    
                setAssociation_4(doctor ? doctor.association_4 : '')    

                setImage_1(doctor ? doctor.image_1 : '')
                setImage_2(doctor ? doctor.image_2 : '')
                setImage_3(doctor ? doctor.image_3 : '')
                setImage_4(doctor ? doctor.image_4 : '')
                
                setSpecialityChoices(doctor ? doctor.specialityChoices : '')
            }

        }
    },[doctor,doctorId,dispatch,history,successUpdate])


    useEffect(() => {
        if(userInfo && userInfo.isAdmin){            
                dispatch(listUsers())            
        }else{
            history.push('/login')
        }
        
    },[dispatch,history,userInfo])

    console.log('dob',dob)

    const submitHandler = (e) => {
        if (dob === 'Invalid date'){
            setDob('')
        }
        if (experienceFrom === 'Invalid date'){
            setExperienceFrom('')
        }
        e.preventDefault()
        dispatch(updateDoctor({
            slug:doctorId,username,name,professionTitle,description,gender,dob,degree,websiteLink,
            experienceFrom,specialty_1,specialty_2,specialty_3,specialty_4, 
            association_1,association_2,association_3,association_4,      
            image_1,image_2,image_3,image_4   
        }))    
    }

    const specializationHandler = (value,idx) => {
        if (idx == 1){
            if(value === 'Select Specialization'){
                setSpecialty_1('')
            }else {
                setSpecialty_1(value)
            }
        } else if (idx == 2){
            if(value === 'Select Specialization'){
                setSpecialty_2('')
            }else {
                setSpecialty_2(value)
            }
        } else if (idx == 3){
            if(value === 'Select Specialization'){
                setSpecialty_3('')
            }else {
                setSpecialty_3(value)
            }
        } else if (idx == 4){
            if(value === 'Select Specialization'){
                setSpecialty_4('')
            }else {
                setSpecialty_4(value)
            }
        }
        
    }

    const config = {
        headers: {
            'content-type':'multipart/form-data',
            Authorization: `Bearer ${userInfo ? userInfo.token : ''}`
        }
    }


    const uploadFileHandler1 = async (e) => {
        const file1 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_1',file1)
        formData.append('doctor_slug',doctorId)

        setUploading1(true)

        try{

            const {data} = await axios.post('/api/doctors/upload/', formData,config)
            
            setImage_1(data)
            setUploading1(false)
        }catch(error){
            setUploading1(false)
        }

    }

    const uploadFileHandler2 = async (e) => {
        const file2 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_2',file2)
        formData.append('doctor_slug',doctorId)

        setUploading2(true)

        try{
            const {data} = await axios.post('/api/doctors/upload/', formData,config)
            
            setImage_2(data)
            setUploading2(false)
        }catch(error){
            setUploading2(false)
        }

    }

    const uploadFileHandler3 = async (e) => {
        const file3 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_3',file3)
        formData.append('doctor_slug',doctorId)

        setUploading3(true)

        try{
           const {data} = await axios.post('/api/doctors/upload/', formData,config)
            
            setImage_3(data)
            setUploading3(false)
        }catch(error){
            setUploading3(false)
        }

    }

    const uploadFileHandler4 = async (e) => {
        const file4 = e.target.files[0]
        const formData = new FormData()

        formData.append('image_4',file4)
        formData.append('doctor_slug',doctorId)

        setUploading4(true)

        try{
            const {data} = await axios.post('/api/doctors/upload/', formData,config)
            
            setImage_4(data)
            setUploading4(false)
        }catch(error){
            setUploading4(false)
        }

    }

    const dobHandler = (date) => {
        console.log('date in hndlr:',date)
        setCalendarDate1(date)
        setDob(moment(date).format("YYYY-MM-DD"))  
    }

    const experienceFromHandler = (date) => {
        setCalendarDate2(date)
        setExperienceFrom(moment(date).format("YYYY-MM-DD"))  
    }
    
    // const userHandler = (user) => {
    //     setUsername(user)
    // }
// console.log('model descritpion:',doctor && doctor.description)
    return (
        <div>
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>Edit Doctor</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Home</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li><Link to="/admin/doctorlist">Doctors List</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>Edit Doctor</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2">
            <div><Link to='/admin/doctorlist'><h6 className="margin-bottom-1x">Go Back</h6></Link></div>

            <div className="row" >
                <div className="col-md-12">
                     <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                    
                    <form className="card" onSubmit={submitHandler}>
                        
                        <div className="col-md-12">
                            <br />
                            <h4 className="margin-bottom-1x">Doctor Info</h4>
                            <center>{loadingUpdate && <Loader/>}</center>
                            
                            {loading ? <Loader/> : error ? <center><Message variant='danger'>{error}</Message></center> : 
                            <>
                            <div className="row">

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="doctor-name">Name</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="doctor-name" 
                                            value={name ? name : ''} 
                                            onChange={(e)=>setName(e.target.value)}
                                            />
                                </div>
                                                                
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="doctorUser">User</label>
                                    <select
                                        className="form-control"
                                        as="select"
                                        value={username ? username : ''}
                                        onChange={(e) => setUsername(e.target.value)}
                                        id="doctorUser"
                                    >
                                        <option key={'select_user'} value="Select User">
                                                    Select User
                                                </option>
                                        {users && users.map(
                                            (user) =>                                                
                                                <option key={user.id} value={user.username}>
                                                    {user.username}
                                                </option>                                                
                                            )
                                        }
                                    </select>                                    
                                
                                </div>

                                

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="doctor-gender">Gender</label>
                                    <select className="form-control" 
                                        as="select"
                                        value = {gender ? gender : ''}
                                        onChange = {(e) => {setGender(e.target.value)}}
                                        required
                                        id="gender-select">
                                        
                                            <option value="">
                                                Select Gender
                                            </option> 
                                            <option value="Male">
                                                Male
                                            </option> 
                                            <option value="Female">
                                                Female
                                            </option> 
                                            <option value="Other">
                                                Other
                                            </option> 
                                        </select>
                                
                                </div>   

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="professionTitle">Profession Title</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="professionTitle" 
                                            value={professionTitle ? professionTitle : ''} 
                                            onChange={(e)=>setProfessionTitle(e.target.value)}
                                            />
                                </div>                           
                                
                            </div>

                            <div className="row">

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="degree">Degree</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="degree" 
                                            value={degree ? degree : ''} 
                                            onChange={(e)=>setDegree(e.target.value)}
                                            />
                                </div>   

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-category">Date of Birth</label>
                                    <br/>
                                    <div className="customDatePickerWidth">
                                        <DatePicker 
                                            selected={calendarDate1 ? calendarDate1 : null} 
                                            isClearable
                                            className="form-control"      
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="Click to select DOB"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            maxDate={addDays(new Date(), 0)}
                                            onChange={(date) => dobHandler(date)} 
                                             />
                                    </div>
                                </div> 

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="service-category">Experience From</label>
                                    <br/>
                                    <div className="customDatePickerWidth">
                                        <DatePicker 
                                            selected={calendarDate2 ? calendarDate2 : null} 
                                            isClearable
                                            className="form-control"      
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="Click to select date"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            maxDate={addDays(new Date(), 0)}
                                            onChange={(date) => experienceFromHandler(date)} 
                                             />
                                    </div>
                                </div> 

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="website-link-doctor">Website Link</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="website-link-doctor" 
                                            value={websiteLink ? websiteLink : ''} 
                                            onChange={(e)=>setWebsiteLink(e.target.value)}
                                            />
                                </div>

                                
                            </div>

                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label style={{paddingLeft:'0px'}} htmlFor="doctor-description">Description</label>
                                    <textarea className="form-control"
                                            name="paragraph_text" 
                                            id="doctor-description" 
                                            value={description ? description : ''} 
                                            onChange={(e)=>setDescription(e.target.value)}
                                            rows="10"></textarea>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="specialty_1">Specialization 1</label>
                                    <select
                                        className="form-control"
                                        as="select"
                                        value={specialty_1 ? specialty_1 : ''}
                                        onChange={(e) => {specializationHandler(e.target.value,1)}}
                                        id="specialty_1"
                                    >
                                        <option key={'select_specialization'} value="Select Specialization">
                                                    Select Specialization 
                                        </option>
                                        {doctor && doctor.specialityChoices && doctor.specialityChoices.map(
                                            (specialization) =>                                                
                                                <option key={specialization} value={specialization}>
                                                    {specialization}
                                                </option>                                                
                                            )
                                        }
                                    </select>                                    
                                
                                </div>
                                
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="specialty_2">Specialization 2</label>
                                    <select
                                        className="form-control"
                                        as="select"
                                        value={specialty_2 ? specialty_2 : ''}
                                        onChange={(e) => {specializationHandler(e.target.value,2)}}
                                        id="specialty_2"
                                    >
                                        <option key={'select_specialization'} value="Select Specialization">
                                                    Select Specialization 
                                        </option>
                                        {specialityChoices && specialityChoices.map(
                                            (specialization) =>                                                
                                                <option key={specialization} value={specialization}>
                                                    {specialization}
                                                </option>                                                
                                            )
                                        }
                                    </select>                                    
                                
                                </div>
                                
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="specialty_3">Specialization 3</label>
                                    <select
                                        className="form-control"
                                        as="select"
                                        value={specialty_3 ? specialty_3 : ''}
                                        onChange={(e) => {specializationHandler(e.target.value,3)}}
                                        id="specialty_3"
                                    >
                                        <option key={'select_specialization'} value="Select Specialization">
                                                    Select Specialization 
                                        </option>
                                        {specialityChoices && specialityChoices.map(
                                            (specialization) =>                                                
                                                <option key={specialization} value={specialization}>
                                                    {specialization}
                                                </option>                                                
                                            )
                                        }
                                    </select>                                    
                                
                                </div>
                                
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="specialty_4">Specialization 4</label>
                                    <select
                                        className="form-control"
                                        as="select"
                                        value={specialty_4 ? specialty_4 : ''}
                                        onChange={(e) => {specializationHandler(e.target.value,4)}}
                                        id="specialty_4"
                                    >
                                        <option key={'select_specialization'} value="Select Specialization">
                                                    Select Specialization 
                                        </option>
                                        {specialityChoices && specialityChoices.map(
                                            (specialization) =>                                                
                                                <option key={specialization} value={specialization}>
                                                    {specialization}
                                                </option>                                                
                                            )
                                        }
                                    </select>                                    
                                
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="association_2">Association 1</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="association_1" 
                                            value={association_1 ? association_1 : ''} 
                                            onChange={(e)=>setAssociation_1(e.target.value)}
                                            />
                                </div>
                                
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="association_2">Association 2</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="association_2" 
                                            value={association_2 ? association_2 : ''} 
                                            onChange={(e)=>setAssociation_2(e.target.value)}
                                            />
                                </div>
                                
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="association_3">Association 3</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="association_3" 
                                            value={association_3 ? association_3 : ''} 
                                            onChange={(e)=>setAssociation_3(e.target.value)}
                                            />
                                </div>
                                
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="association_4">Association 4</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="association_4" 
                                            value={association_4 ? association_4 : ''} 
                                            onChange={(e)=>setAssociation_4(e.target.value)}
                                            />
                                </div>
                            </div>
                            
                      
                            <div className="row">
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="doctor-image-1">Image 1</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="doctor-image-1" 
                                            value={image_1} 
                                            onChange={(e)=>setImage_1(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image1-file'
                                        
                                        onChange={uploadFileHandler1}
                                        />
                                    {uploading1 && <Loader/>}
                                </div>
                                
                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="doctor-image-2">Image 2</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="doctor-image-2" 
                                            value={image_2} 
                                            onChange={(e)=>setImage_2(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image2-file'
                                        
                                        onChange={uploadFileHandler2}
                                        />
                                    {uploading2 && <Loader/>}
                                </div>

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="doctor-image-3">Image 3</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="doctor-image-3" 
                                            value={image_3} 
                                            onChange={(e)=>setImage_3(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image3-file'
                                        
                                        onChange={uploadFileHandler3}
                                        />
                                    {uploading3 && <Loader/>}
                                </div>

                                <div className="form-group col-md-3">
                                    <label style={{paddingLeft:'0px'}} htmlFor="doctor-image-4">Image 4</label>
                                    <input className="form-control" 
                                            type="text" 
                                            id="doctor-image-4" 
                                            value={image_4} 
                                            onChange={(e)=>setImage_4(e.target.value)}
                                            />
                                    <input
                                        type="file"
                                        id = 'image4-file'
                                        
                                        onChange={uploadFileHandler4}
                                        />
                                    {uploading4 && <Loader/>}
                                </div>
                                
                            </div>
                            {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
                            {loadingUser ? <Loader/> : errorUser ? <center><Message variant='danger'>{errorUser}</Message></center> : ""}
                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                                
                                <button className="btn btn-primary margin-right-none" 
                                        type="submit" 
                                        data-toast data-toast-position="topRight" 
                                        data-toast-type="success" 
                                        data-toast-icon="icon-check-circle" 
                                        data-toast-title="Success!" 
                                        data-toast-message="Your profile updated successfuly.">
                                            Update Doctor
                                </button>
                            </div>
                            </>
                            }
                            <br />
                        </div>
                    </form>
                    
               </div>


                
            </div>

            </div>
            
        </div>
    )
}

export default DoctorEditScreen
