import { BrowserRouter,Switch, Route } from "react-router-dom";
// import { Router, Route, browserHistory } from 'react-router';

import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import HomeScreen from "./screens/HomeScreen";
import AllProductsScreen from "./screens/AllProductsScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";

import RegisterScreen from "./screens/RegisterScreen";

import ValidatePhoneOtpScreen from "./screens/ValidatePhoneOtpScreen";
import ValidateEmailOtpScreen from "./screens/ValidateEmailOtpScreen";

import VerifyPhoneScreen from "./screens/VerifyPhoneScreen";
import VerifyEmailScreen from "./screens/VerifyEmailScreen";
import VerifyPhoneOtpScreen from "./screens/VerifyPhoneOtpScreen";
import VerifyEmailOtpScreen from "./screens/VerifyEmailOtpScreen";

import ProfileScreen from "./screens/ProfileScreen";
import MyOrdersScreen from "./screens/MyOrdersScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import OrderListScreen from "./screens/OrderListScreen";
import InvoiceScreen from "./screens/InvoiceScreen";
import PasswordResetScreen from "./screens/PasswordResetScreen";
import PasswordResetConfirmScreen from "./screens/PasswordResetConfirmScreen";
import UploadStaticFilesScreen from "./screens/UploadStaticFilesScreen";
import FreightChargesScreen from "./screens/FreightChargesScreen";

import AboutUsScreen from "./screens/StaticScreens/AboutUsScreen";
import ContactUsScreen from "./screens/StaticScreens/ContactUsScreen";
import OurServicesScreen from "./screens/StaticScreens/OurServicesScreen";
import DiseasesScreen from "./screens/StaticScreens/DiseasesScreen";
import AyurvedaScreen from "./screens/StaticScreens/AyurvedaScreen";
import DietNutritionScreen from "./screens/StaticScreens/DietNutritionScreen";
import HyperbaricScreen from "./screens/StaticScreens/HyperbaricScreen";
import PolicyScreen from "./screens/StaticScreens/PolicyScreen";
import OurExpertsScreen from "./screens/StaticScreens/OurExpertsScreen";
// import PageNotFoundScreen from "./screens/StaticScreens/PageNotFoundScreen";

// Services Screens
import ServiceHomeScreen from "./screens/ServicesScreens/ServiceHomeScreen";
import AllServicesScreen from "./screens/ServicesScreens/AllServicesScreen";
import ServiceScreen from "./screens/ServicesScreens/ServiceScreen";
import ScheduleAppointmentScreen from "./screens/ServicesScreens/ScheduleAppointmentScreen";
import PatientDetailsScreen from "./screens/ServicesScreens/PatientDetailsScreen";
import ConfirmAppointmentScreen from "./screens/ServicesScreens/ConfirmAppointmentScreen";
import AppointmentScreen from "./screens/ServicesScreens/AppointmentScreen";
import ServiceListScreen from "./screens/ServicesScreens/ServiceListScreen";
import MyAppointmentsScreen from "./screens/ServicesScreens/MyAppointmentsScreen";
import ServiceEditScreen from "./screens/ServicesScreens/ServiceEditScreen";
import DoctorListScreen from "./screens/ServicesScreens/DoctorListScreen";
import DoctorEditScreen from "./screens/ServicesScreens/DoctorEditScreen";
import AppointmentListScreen from "./screens/ServicesScreens/AppointmentListScreen";
import DoctorHomeScreen from "./screens/ServicesScreens/DoctorHomeScreen";
import DoctorScreen from "./screens/ServicesScreens/DoctorScreen";

import ErrorBoundary from "./components/ErrorBoundary";

import "./index.css";
import ServiceTimeslotScreen from "./screens/ServicesScreens/ServiceTimeslotScreen";
function App() {
  return (
    <BrowserRouter>
      
        <Header />
        <ScrollToTop />
        <main className="main-body">
        <Switch>
          <Route exact path="/" component={HomeScreen}  />

          
          <Route path="/all-services" component={AllServicesScreen} />

          <Route path="/login" component={LoginScreen} />
          <Route path="/register" component={RegisterScreen} />

          <Route path="/validate-phone-otp" component={ValidatePhoneOtpScreen} />
          <Route path="/validate-email-otp" component={ValidateEmailOtpScreen} />

          <Route path="/verify-phone" component={VerifyPhoneScreen} />
          <Route path="/verify-email" component={VerifyEmailScreen} />
          <Route path="/verify-phone-otp" component={VerifyPhoneOtpScreen} />
          <Route path="/verify-email-otp" component={VerifyEmailOtpScreen} />

          <Route path="/profile" component={ProfileScreen} />
          <Route path="/orders" component={MyOrdersScreen} />
          <Route path="/appointments" component={MyAppointmentsScreen} />
          <Route path="/reset-password" component={PasswordResetScreen} />
          <Route
            path="/password/reset/confirm/:uid/:token"
            component={PasswordResetConfirmScreen}
          />

          <Route path="/shipping" component={ShippingScreen} />

          <Route path="/placeorder" component={PlaceOrderScreen} />
          <Route path="/order/:id" component={OrderScreen} />
          <Route path="/invoice/:id" component={InvoiceScreen} />
          
          <Route exact path="/services" component={ServiceHomeScreen} />
          <Route exact path="/doctors" component={DoctorHomeScreen} />
          
          <Route path="/services/:id" component={ServiceScreen} />
          <Route path="/doctors/:id" component={DoctorScreen} />
          <Route path="/schedule-appointment/:id" component={ScheduleAppointmentScreen} />
          <Route path="/patient-details" component={PatientDetailsScreen} />
          <Route path="/confirm-appointment" component={ConfirmAppointmentScreen} />
          <Route path="/appointment/:id" component={AppointmentScreen} />
          
          <Route path="/cart/:id?" component={CartScreen} />
          <Route path="/admin/:id/edit" component={ProductEditScreen} />
          <Route path="/admin/services/:id/edit" component={ServiceEditScreen} />
          <Route path="/admin/doctors/:id/edit" component={DoctorEditScreen} />

          <Route path="/admin/userlist" component={UserListScreen} />
          <Route path="/admin/productlist" component={ProductListScreen} />
          <Route path="/admin/doctorlist" component={DoctorListScreen} />
          <Route path="/admin/servicelist" component={ServiceListScreen} />
          <Route path="/admin/orderlist" component={OrderListScreen} />
          <Route path="/admin/appointmentlist" component={AppointmentListScreen} />
          <Route path="/admin/user/:id/edit" component={UserEditScreen} />
          <Route path="/admin/upload-static-files" component={UploadStaticFilesScreen}/>
          <Route path="/admin/freight-charges" component={FreightChargesScreen} />
          
          <Route path="/admin/service-timeslots/:id" component={ServiceTimeslotScreen} />
          <Route path="/payment" component={PaymentScreen} />

          <Route path="/about-us" component={AboutUsScreen} />
          <Route path="/contact-us" component={ContactUsScreen} />

          <Route path="/our-products" component={AllProductsScreen} />
          <Route path="/our-services" component={OurServicesScreen} />
          <Route path="/our-experts" component={OurExpertsScreen} />
          <Route path="/our-services/:id" component={OurServicesScreen} />
          <Route path="/diseases/:id" component={DiseasesScreen} />
          <Route path="/refund-cancellation-policy" component={PolicyScreen} />
          <Route path="/privacy-policy" component={PolicyScreen} />
          <Route path="/terms-conditions" component={PolicyScreen} />

          <Route path="/ayurveda" component={AyurvedaScreen} />
          <Route
            path="/diet-and-nutrition"
            component={DietNutritionScreen}
          />
          <Route path="/hyperbaric" component={HyperbaricScreen} />


          <ErrorBoundary>
            <Route path="/:id" component={ProductScreen} />
          </ErrorBoundary>

          </Switch>
        </main>

        <Footer />
      
    </BrowserRouter>
  );
}

export default App;
