import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { SERVICE_TIMESLOT_CREATE_RESET,SERVICE_TIMESLOT_UPDATE_RESET } from '../../constants/serviceConstants'
import { getServiceTimeslots,createServiceTimeslot,updateServiceTimeslot,deleteServiceTimeslot } from '../../actions/serviceActions'
import { listDoctors } from '../../actions/doctorActions'
import { listServiceDetails } from "../../actions/serviceActions";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import "../../index.css"


function ServiceTimeslotScreen({history,match}) {

    const dispatch = useDispatch()
    const serviceId = match.params.id
    const serviceTimeslots = useSelector(state => state.serviceTimeslots)
    const { loading , error, timeslots } = serviceTimeslots

    const serviceDetails = useSelector(state => state.serviceDetails)
    const {error:errorService, loading:loadingService, service } = serviceDetails

    const doctorList = useSelector(state => state.doctorList)
    const { loading:loadingDoctor, error:errorDoctor, doctors} = doctorList

    
    const serviceTimeslotCreate = useSelector(state => state.serviceTimeslotCreate)
    const {error:errorCreate, loading:loadingCreate, success:successCreate, timeslot:createdTimeslot } = serviceTimeslotCreate

    const serviceTimeslotUpdate = useSelector(state => state.serviceTimeslotUpdate)
    const {error:errorUpdate, loading:loadingUpdate, success:successUpdate } = serviceTimeslotUpdate

    const serviceTimeslotDelete = useSelector(state => state.serviceTimeslotDelete)
    const {error:errorDelete, loading:loadingDelete } = serviceTimeslotDelete

    const [newCreate, setNewCreate] = useState(false)
    const [update, setUpdate] = useState(false)
    
    // UPDATE TIMESLOT VARIABLES
    const [timeslotId,setTimeslotId] = useState('')
    const [calendarDate,setCalendarDate] = useState('')

    const [date, setDate] = useState('')

    const [startTime, setStartTime] = useState('')
    const [startHour, setStartHour] = useState('')
    const [startMinute, setStartMinute] = useState('')

    const [endTime, setEndTime] = useState('')
    const [endHour, setEndHour] = useState('')
    const [endMinute, setEndMinute] = useState('')

    const [doctorSlug, setDoctorSlug] = useState('')

    const [repeatDays, setRepeatDays] = useState(1)
    const [selectRepeat, setSelectRepeat] = useState(false)

    // CREATE TIMESLOT VARIABLES
    //  TIMESLOT 1
    const [calendarDate1,setCalendarDate1] = useState('')

    const [date1, setDate1] = useState('')

    const [startTime1, setStartTime1] = useState('')
    const [startHour1, setStartHour1] = useState('')
    const [startMinute1, setStartMinute1] = useState('')

    const [endTime1, setEndTime1] = useState('')
    const [endHour1, setEndHour1] = useState('')
    const [endMinute1, setEndMinute1] = useState('')
    const [doctorSlug1, setDoctorSlug1] = useState('')

    // TIMESLOT 2    
    const [calendarDate2,setCalendarDate2] = useState('')

    const [date2, setDate2] = useState('')

    const [startTime2, setStartTime2] = useState('')
    const [startHour2, setStartHour2] = useState('')
    const [startMinute2, setStartMinute2] = useState('')

    const [endTime2, setEndTime2] = useState('')
    const [endHour2, setEndHour2] = useState('')
    const [endMinute2, setEndMinute2] = useState('')

    const [doctorSlug2, setDoctorSlug2] = useState('')
    
    // TIMESLOT 3    
    const [calendarDate3,setCalendarDate3] = useState('')

    const [date3, setDate3] = useState('')

    const [startTime3, setStartTime3] = useState('')
    const [startHour3, setStartHour3] = useState('')
    const [startMinute3, setStartMinute3] = useState('')

    const [endTime3, setEndTime3] = useState('')
    const [endHour3, setEndHour3] = useState('')
    const [endMinute3, setEndMinute3] = useState('')

    const [doctorSlug3, setDoctorSlug3] = useState('')
    
    // TIMESLOT 4    
    const [calendarDate4,setCalendarDate4] = useState('')

    const [date4, setDate4] = useState('')

    const [startTime4, setStartTime4] = useState('')
    const [startHour4, setStartHour4] = useState('')
    const [startMinute4, setStartMinute4] = useState('')

    const [endTime4, setEndTime4] = useState('')
    const [endHour4, setEndHour4] = useState('')
    const [endMinute4, setEndMinute4] = useState('')

    const [doctorSlug4, setDoctorSlug4] = useState('')
    
    // TIMESLOT 5    
    const [calendarDate5,setCalendarDate5] = useState('')

    const [date5, setDate5] = useState('')

    const [startTime5, setStartTime5] = useState('')
    const [startHour5, setStartHour5] = useState('')
    const [startMinute5, setStartMinute5] = useState('')

    const [endTime5, setEndTime5] = useState('')
    const [endHour5, setEndHour5] = useState('')
    const [endMinute5, setEndMinute5] = useState('')

    const [doctorSlug5, setDoctorSlug5] = useState('')

    var hours = [];
    var i
    for (i = 0; i <= 23; i++) {
        if(i <= 9){
            hours.push('0'+String(i));
        }else{
            hours.push(String(i));
        }
    }
    
    var minutes = [];
    
    for (i = 0; i <= 59; i++) {
        if(i <= 9){
            minutes.push('0'+String(i));
        }else{
            minutes.push(String(i));
        }
    }
    
    var repeatDaysNum = [];
    
    for (i = 1; i <= 60; i++) {        
        repeatDaysNum.push(i);
    }

    if (Array.prototype.allValuesSame === undefined) {
        Array.prototype.allValuesSame = function() {
          for (let i = 1; i < this.length; i++) {
            if (this[i] !== this[0]) {
              return false;
            }
          }
          return true;
        }
      }
      

    const startHourHandler = (hour,endHour,startMinute,endMinute,idx) => {

        if(hour && endHour && Number(hour) > Number(endHour)){
            alert('START HOUR can not be greater than END HOUR!')
        }else if(hour && endHour && startMinute && endMinute && Number(hour) === Number(endHour) && Number(startMinute)>Number(endMinute)){
            alert('START Time can not be greater than END Time!')
        }else if(hour && endHour && startMinute && endMinute && Number(hour) === Number(endHour) && Number(startMinute) === Number(endMinute)){
            alert('START Time can not be EQUAL to END Time!')
        }else{
            if(idx===0){
                setStartHour(hour)
                setStartTime(`${hour}:${startMinute}:00`)
            }else if (idx===1){
                if (!date1 || (date1 && date1 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setStartHour1(hour)
                    setStartTime1(`${hour}:${startMinute}:00`)
                }                
            }
            else if (idx===2){
                if(!date2 || (date2 && date2 === "Invalid date")){
                    alert('Select the date first!')
                }else{
                    setStartHour2(hour)
                    setStartTime2(`${hour}:${startMinute}:00`)
                } 
            }else if (idx===3){
                if(!date3 || (date3 && date3 === "Invalid date")){
                    alert('Select the date first!')
                }else{
                    setStartHour3(hour)
                    setStartTime3(`${hour}:${startMinute}:00`)
                } 
                
            }else if (idx===4){
                if(!date4 || (date4 && date4 === "Invalid date")){
                    alert('Select the date first!')
                }else{
                    setStartHour4(hour)
                    setStartTime4(`${hour}:${startMinute}:00`)
                }                 
                
            }else if (idx===5){

                if(!date4 || (date4 && date4 === "Invalid date")){
                    alert('Select the date first!')
                }else{
                    setStartHour5(hour)
                    setStartTime5(`${hour}:${startMinute}:00`)
                } 
                
            }
            
        }
    }

    const startMinuteHandler = (minute,startHour,endHour,endMinute,idx) => {

        if(startHour && endHour && minute && endMinute && Number(startHour)===Number(endHour) && (Number(minute) > Number(endMinute))){            
            alert('START MINUTE can not be greater than END MINUTE!')}
        else if(startHour && endHour && minute && endMinute && Number(startHour)===Number(endHour) && (Number(minute) === Number(startMinute))){
                alert('START MINUTE can not be equal to END MINUTE!')
        }else{
            if(idx===0){
                
                setStartMinute(minute)
                setStartTime(`${startHour}:${minute}:00`) 
            }else if (idx===1){
                if (!date1 || (date1 && date1 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setStartMinute1(minute)
                    setStartTime1(`${startHour}:${minute}:00`) 
                } 
               
            }
            else if (idx===2){
                if (!date2 || (date2 && date2 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setStartMinute2(minute)
                    setStartTime2(`${startHour}:${minute}:00`)
                } 
                 
            }else if (idx===3){
                if (!date3 || (date3 && date3 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setStartMinute3(minute)
                    setStartTime3(`${startHour}:${minute}:00`)
                } 
                
            }else if (idx===4){
                if (!date4 || (date4 && date4 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setStartMinute4(minute)
                    setStartTime4(`${startHour}:${minute}:00`) 
                } 
               
            }else if (idx===5){
                if (!date5 || (date5 && date5 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setStartMinute5(minute)
                    setStartTime5(`${startHour}:${minute}:00`) 
                } 
               
            }
            
        }
    }

    const endHourHandler = (hour,startHour,startMinute,endMinute,idx) => {

        if(Number(hour) < Number(startHour)){
            alert('END HOUR can not be lower than START HOUR!')
        }else if(hour && startHour && startMinute && endMinute && Number(hour) === Number(startHour) && Number(startMinute)>Number(endMinute)){
            alert('START Time can not be greater than END Time!')
        }else if(hour && startHour && startMinute && endMinute && Number(hour) === Number(startHour) && Number(startMinute) === Number(endMinute)){
            alert('START Time can not be EQUAL to END Time!')
        }else{

            if(idx===0){
                setEndHour(hour)
                setEndTime(`${hour}:${endMinute}:00`)
            }else if (idx===1){
                if (!date1 || (date1 && date1 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setEndHour1(hour)
                    setEndTime1(`${hour}:${endMinute}:00`)
                } 
              
            }
            else if (idx===2){
                if (!date2 || (date2 && date2 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setEndHour2(hour)
                    setEndTime2(`${hour}:${endMinute}:00`)
                } 
                
            }else if (idx===3){
                if (!date3 || (date3 && date3 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setEndHour3(hour)
                    setEndTime3(`${hour}:${endMinute}:00`)
                } 
               
            }else if (idx===4){
                if (!date4 || (date4 && date4 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setEndHour4(hour)
                    setEndTime4(`${hour}:${endMinute}:00`)
                } 
               
            }else if (idx===5){
                if (!date5 || (date5 && date5 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setEndHour5(hour)
                    setEndTime5(`${hour}:${endMinute}:00`)  
                } 
               
            }

        }
        
    }

    const endMinuteHandler = (minute,startHour,endHour,startMinute,idx) => {
        if(startHour && startHour && startMinute && minute && Number(startHour)===Number(endHour) && (Number(minute) < Number(startMinute))){            
                alert('END MINUTE can not be lower than START MINUTE!')}
        else if(startHour && startHour && startMinute && minute && Number(startHour)===Number(endHour) && (Number(minute) === Number(startMinute))){
                alert('END MINUTE can not be equal to START MINUTE!')
        }else{

            if(idx===0){
                setEndMinute(minute)
                setEndTime(`${endHour}:${minute}:00`)
            }else if (idx===1){
                if (!date1 || (date1 && date1 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setEndMinute1(minute)
                    setEndTime1(`${endHour}:${minute}:00`)
                } 
                
            }
            else if (idx===2){
                if (!date2 || (date2 && date2 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setEndMinute2(minute)
                    setEndTime2(`${endHour}:${minute}:00`)
                } 
               
            }else if (idx===3){
                if (!date3 || (date3 && date3 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setEndMinute3(minute)
                    setEndTime3(`${endHour}:${minute}:00`)
                } 
               
            }else if (idx===4){
                if (!date4 || (date4 && date4 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setEndMinute4(minute)
                    setEndTime4(`${endHour}:${minute}:00`)
                } 
                
            }else if (idx===5){
                if (!date5 || (date5 && date5 === "Invalid date"))
                {
                    alert('Select the date first!')
                }else{
                    setEndMinute5(minute)
                    setEndTime5(`${endHour}:${minute}:00`) 
                } 
               
            }
           
        }
    }
    
    


    const dateHandler = (date) => {
        setCalendarDate(date)
        setDate(moment(date).format("YYYY-MM-DD"))  
    }
    
    const dateHandler1 = (date) => {
        setCalendarDate1(date)
        setDate1(moment(date).format("YYYY-MM-DD"))  
    }
    const dateHandler2 = (date) => {
        setCalendarDate2(date)
        setDate2(moment(date).format("YYYY-MM-DD"))  
    }
    const dateHandler3 = (date) => {
        setCalendarDate3(date)
        setDate3(moment(date).format("YYYY-MM-DD"))  
    }
    const dateHandler4 = (date) => {
        setCalendarDate4(date)
        setDate4(moment(date).format("YYYY-MM-DD"))  
    }
    
    const dateHandler5 = (date) => {
        setCalendarDate5(date)
        setDate5(moment(date).format("YYYY-MM-DD"))  
    }

    useEffect(() => {  
         
        if(successCreate){
            setNewCreate(false)
            dispatch({ type: SERVICE_TIMESLOT_CREATE_RESET })

            setCalendarDate1('') 
            setDate1('')
            setStartTime1('')
            setStartHour1('')
            setStartMinute1('')
            setEndTime1('')
            setEndHour1('')
            setEndMinute1('')
            setDoctorSlug1('')
            
            setCalendarDate2('')
            setDate2('')
            setStartTime2('')
            setStartHour2('')
            setStartMinute2('')            
            setEndTime2('')
            setEndHour2('')
            setEndMinute2('')
            setDoctorSlug2('')

            setCalendarDate3('')
            setDate3('')
            setStartTime3('')
            setStartHour3('')
            setStartMinute3('')
            setEndTime3('')
            setEndHour3('')
            setEndMinute3('')
            setDoctorSlug3('')
            
            setCalendarDate4('')
            setDate4('')
            setStartTime4('')
            setStartHour4('')
            setStartMinute4('')
            setEndTime4('')
            setEndHour4('')
            setEndMinute4('')
            setDoctorSlug4('')
            
            setCalendarDate5('')
            setDate5('')
            setStartTime5('')
            setStartHour5('')
            setStartMinute5('')
            setEndTime5('')
            setEndHour5('')
            setEndMinute5('')
            setDoctorSlug5('')

            setRepeatDays('')
            setSelectRepeat('')

            dispatch(getServiceTimeslots(serviceId))
        }
        
        else if(successUpdate){
            dispatch({type:SERVICE_TIMESLOT_UPDATE_RESET})            
            setUpdate(false)
            dispatch(getServiceTimeslots(serviceId))
       
        }else if(!service || (service && (!service.name || service.slug !== serviceId)) ){
            dispatch(listServiceDetails(serviceId))
            dispatch(getServiceTimeslots(serviceId))
            dispatch(listDoctors())
        } 
    },[history,dispatch,timeslots,service,serviceId,createdTimeslot,successCreate,successUpdate])
    
    useEffect(() => {  
         
        if(!doctors){
            dispatch(listDoctors())
        }
    },[dispatch,doctors])
    const createServiceTimeslotHandler = () =>{
        setNewCreate(true)
        setUpdate(false)
        // dispatch(createServiceTimeslot(serviceId))
    } 

    const convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');
      
        let [hours, minutes] = time.split(':');
      
        if (hours === '12') {
          hours = '00';
        }
      
        if (modifier === 'PM') {
          hours = parseInt(hours, 10) + 12;
        }
      
        return [hours,minutes]
      }

    const updateClickHandler = (timeslot) =>{
        
        setUpdate(true)
        setNewCreate(false)
        setTimeslotId(timeslot._id)  
        setCalendarDate(moment(timeslot.date).toDate())
        setDate(moment(timeslot.date).format("YYYY-MM-DD"))
        // setDate(timeslot.date)
        setStartHour(convertTime12to24(timeslot.startTime)[0])
        setStartMinute(convertTime12to24(timeslot.startTime)[1])
        setEndHour(convertTime12to24(timeslot.endTime)[0])
        setEndMinute(convertTime12to24(timeslot.endTime)[1])

        setStartTime(`${convertTime12to24(timeslot.startTime)[0]}:${convertTime12to24(timeslot.startTime)[1]}:00`)
        setEndTime(`${convertTime12to24(timeslot.endTime)[0]}:${convertTime12to24(timeslot.endTime)[1]}:00`)
        setDoctorSlug(timeslot.doctor ? timeslot.doctor.slug : '')
        // setCurrentTimeslot(timeslot)


    } 

    
    const submitCreateHandler = (e) => {
        e.preventDefault()
        var selectedDates = [];

        if(date1 === "Invalid date"){
            setDate1('')
        }        
        if(date1 && date1 !== "Invalid date"){
            selectedDates.push(date1);
        }
        
        if(date2 === "Invalid date"){
            setDate2('')
        }
        if(date2 && date2 !== "Invalid date") {
            selectedDates.push(date2);
        }
        
        if(date3 === "Invalid date"){
            setDate3('')
        }
        if(date3 && date3 !== "Invalid date") {
            selectedDates.push(date3);
        }
        
        if(date4 === "Invalid date"){
            setDate4('')
        }
        if(date4 && date4 !== "Invalid date") {
            selectedDates.push(date4);
        }
        
        if(date5 === "Invalid date"){
            setDate5('')
        }
        if(date5 && date5 !== "Invalid date") {
            selectedDates.push(date5);
        }



        
        let allSameDate = selectedDates.allValuesSame();

        if (selectRepeat && !allSameDate){
            alert('To repeat the timeslots for multiple days, all dates must be same. Either make all the dates same or uncheck the repeat checkbox.')
        }
        else if(!date1 && !date2 && !date3 && !date4 && !date5){
            alert('No dates selected!')
        }
        else if (date1 && date1 !== "Invalid date" && 
                (!startHour1 || startHour1 === 'hour' || !startMinute1 || startMinute1 === 'minute' ||
                    !endHour1 || endHour1 === 'hour' || !endMinute1 || endMinute1 === 'minute'
                )){
                alert('Start or End Time is not selected in the 1st row!')
        }
        
        else if (date2 && date2 !== "Invalid date" && 
                (!startHour2 || startHour2 === 'hour' || !startMinute2 || startMinute2 === 'minute' ||
                    !endHour2 || endHour2 === 'hour' || !endMinute2 || endMinute2 === 'minute'
                )){
                alert('Start or End Time is not selected in the 2nd row!')
        }
        
        else if (date3 && date3 !== "Invalid date" && 
                (!startHour3 || startHour3 === 'hour' || !startMinute3 || startMinute3 === 'minute' ||
                    !endHour3 || endHour3 === 'hour' || !endMinute3 || endMinute3 === 'minute'
                )){
                alert('Start or End Time is not selected in the 3rd row!')
        }
        
        else if (date4 && date4 !== "Invalid date" && 
                (!startHour4 || startHour4 === 'hour' || !startMinute4 || startMinute4 === 'minute' ||
                    !endHour4 || endHour4 === 'hour' || !endMinute4 || endMinute4 === 'minute'
                )){
                alert('Start or End Time is not selected in the 4th row!')
        }
        
        else if (date5 && date5 !== "Invalid date" && 
                (!startHour5 || startHour5 === 'hour' || !startMinute5 || startMinute5 === 'minute' ||
                    !endHour5 || endHour5 === 'hour' || !endMinute5 || endMinute5 === 'minute'
                )){
                alert('Start or End Time is not selected in the 5th row!')
        }
        
        else{
            dispatch(createServiceTimeslot(
                serviceId,
                {
                date1,startTime1,
                endTime1,doctorSlug1,
                
                date2,startTime2,
                endTime2,doctorSlug2,
                
                date3,startTime3,
                endTime3,doctorSlug3,
                
                date4,startTime4,
                endTime4,doctorSlug4,
                
                date5,startTime5,
                endTime5,doctorSlug5, 
                
                repeatDays,
            
                }
                ))
        }
        
        // if(startHour==='hour' || startHour===''){
        //     alert('Please select the Start Time HOUR!')
        // }else if(startMinute==='minute' || startMinute===''){
        //     alert('Please select the Start Time MINUTE!')
        // }else if(endHour==='hour' || endHour===''){
        //     alert('Please select the End Time HOUR!')
        // }else if(endMinute==='minute' || endMinute===''){
        //     alert('Please select the End Time MINUTE!')
        // }else{

            // if(doctorSlug==='selectDoctor' || doctorSlug===''){
            //    if (window.confirm('Create Timeslot without Expert?')){
            //         dispatch(createServiceTimeslot(
            //             serviceId,
            //             {date,startTime,
            //             endTime,doctorSlug}
            //             ))    
            //         dispatch({type:SERVICE_TIMESLOT_CREATE_RESET})
            //         setCalendarDate('')
            //         setDate('')
            //         setStartTime('')
            //         setEndTime('')
            //         setStartHour('')
            //         setStartMinute('')
            //         setEndHour('')
            //         setEndMinute('')
            //         setDoctorSlug('')
            //         setCurrentTimeslot({})
            //    }
            // }else{
            //     dispatch(createServiceTimeslot(
            //         serviceId,
            //         {date,startTime,
            //         endTime,doctorSlug}
            //         ))    
            //     dispatch({type:SERVICE_TIMESLOT_UPDATE_RESET})
            //     setCalendarDate('')
            //     setDate('')
            //     setStartTime('')
            //     setEndTime('')
            //     setStartHour('')
            //     setStartMinute('')
            //     setEndHour('')
            //     setEndMinute('')
            //     setDoctorSlug('')
            //     setCurrentTimeslot({})
            // }
            
        // }
        
    }
    const submitUpdateHandler = (e) => {
        e.preventDefault()

        dispatch(updateServiceTimeslot({
            _id:timeslotId,
            date,startTime,
            endTime,doctorSlug
        }))    
        dispatch({type:SERVICE_TIMESLOT_UPDATE_RESET})
        setTimeslotId('')
        setCalendarDate('')
        setDate('')
        setStartTime('')
        setEndTime('')
        setStartHour('')
        setStartMinute('')
        setEndHour('')
        setEndMinute('')
        setDoctorSlug('')
        // setCurrentTimeslot({})
    }

    const deleteHandler = (timeslot)=> {
        if (window.confirm(`Are you sure you want to delete the Timeslot - ${timeslot.date} (${timeslot.startTime+" - "+timeslot.endTime})?`)) {
            dispatch(deleteServiceTimeslot(timeslot._id))
            // window.location.reload();
            dispatch(getServiceTimeslots(serviceId))
        }
        
    }


    const cancelCreateHandler = (e)=>{ 
        e.preventDefault()        
        setNewCreate(false)
        setCalendarDate1('') 
        
        // setDate1('')
        // setStartTime1('')
        // setStartHour1('')
        // setStartMinute1('')
        // setEndTime1('')
        // setEndHour1('')
        // setEndMinute1('')
        // setDoctorSlug1('')
        
        // setCalendarDate2('')
        // setDate2('')
        // setStartTime2('')
        // setStartHour2('')
        // setStartMinute2('')            
        // setEndTime2('')
        // setEndHour2('')
        // setEndMinute2('')
        // setDoctorSlug2('')

        // setCalendarDate3('')
        // setDate3('')
        // setStartTime3('')
        // setStartHour3('')
        // setStartMinute3('')
        // setEndTime3('')
        // setEndHour3('')
        // setEndMinute3('')
        // setDoctorSlug3('')
        
        // setCalendarDate4('')
        // setDate4('')
        // setStartTime4('')
        // setStartHour4('')
        // setStartMinute4('')
        // setEndTime4('')
        // setEndHour4('')
        // setEndMinute4('')
        // setDoctorSlug4('')
        
        // setCalendarDate5('')
        // setDate5('')
        // setStartTime5('')
        // setStartHour5('')
        // setStartMinute5('')
        // setEndTime5('')
        // setEndHour5('')
        // setEndMinute5('')
        // setDoctorSlug5('')
        // setCurrentTimeslot({})

        setRepeatDays('')
        setSelectRepeat('')

        dispatch(getServiceTimeslots(serviceId))        
        dispatch({ type: SERVICE_TIMESLOT_CREATE_RESET })
    }
    
    const cancelUpdateHandler = (e)=>{ 
        e.preventDefault()                
        setUpdate(false)
        setNewCreate(false)
        setTimeslotId('')
        setCalendarDate('')
        setDate('')
        setStartTime('')
        setEndTime('')
        setStartHour('')
        setStartMinute('')
        setEndHour('')
        setEndMinute('')
        setDoctorSlug('')
        // setCurrentTimeslot({})
        // SetCancelCreate(false)
        dispatch(getServiceTimeslots(serviceId))        
        dispatch({ type: SERVICE_TIMESLOT_CREATE_RESET })
        dispatch({type:SERVICE_TIMESLOT_UPDATE_RESET})
    }
    
    const [status, setStatus] = useState('active')

    console.log('loading:',loading)
    return (
        <div>
            {
            loading ?
            
            <Loader/>
                : 
                error ? <Message variant='danger'>{error}</Message>
                : (
                    <>
                    {/* <!-- Page Title--> */}
                    <div className="page-title">
                    <div className="container">
                        <div className="column">
                        <h1>Appointment Timeslots - {service.name}</h1>
                        </div>
                        <div className="column">
                        <ul className="breadcrumbs">
                            <li><Link to="index.html">Home</Link> 
                            </li>                           
                            <li className="separator">&nbsp;</li>
                            <li>Appointment Timeslots</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    {/* <!-- Page Content--> */}
                    <div className="container padding-bottom-3x mb-2">

                    {/* <div><Link to='/admin/productlist'><h6 className="margin-bottom-1x">Go Back</h6></Link></div> */}

                    <div className="row" >
                    <center>{loadingDelete && <Loader/>}</center>
                    <center>{loadingService && <Loader/>}</center>
                    <center>{loadingDoctor && <Loader/>}</center>
                    

                    <center>{loadingCreate && <Loader/>}</center>
                    <div className="col-12">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div >
                            <h5>Appointment Timeslots</h5>
                            </div>
                            <button className="btn btn-secondary margin-right-none" 
                                    type="button"
                                    onClick={createServiceTimeslotHandler}
                                    >
                                    <i className="fas fa-plus"></i> Add New
                            </button>
                        </div>
                        </div>
                        
                        {newCreate && 
                        <div className="col-md-12">
                            <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                            
                            <form className="card" onSubmit={submitCreateHandler}>
                                
                                <div className="col-md-12">
                                    <br />
                                    <h4 className="margin-bottom-1x">Create Timeslots</h4>
                                    <>
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="source-to-dest">Date</label><br/>
                                            <div className="customDatePickerWidth">
                                                <DatePicker 
                                                    selected={calendarDate1 ? calendarDate1 : null} 
                                                    className="form-control"      
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Click to select Date"
                                                    isClearable
                                                    peekNextMonth
                                                    // showMonthDropdown
                                                    // showYearDropdown
                                                    dropdownMode="select"
                                                    minDate={addDays(new Date(), 0)}
                                                    onChange={(date) => dateHandler1(date)} 
                                                     />
                                            </div>
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="start_time">Start Time (HH:MM)</label>
                                            <div >
                                                <select
                                                    style={{width:"50%",display:"inline-block"}}
                                                    className="form-control"
                                                    as="select"
                                                    value={startHour1 || ''}
                                                    onChange={(e) => startHourHandler(e.target.value,endHour1,startMinute1,endMinute1,1)}
                                                    id="start_time"
                                                >
                                                    <option key="start-hour-create" value="hour">
                                                                Hour
                                                    </option>
                                                    {hours.map(
                                                        (x) =>
                                                            
                                                            <option key={x} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                <select
                                                className="form-control"
                                                
                                                style={{width:"50%",display:"inline-block"}}
                                                as="select"
                                                value={startMinute1 || ''}
                                                onChange={(e) => startMinuteHandler(e.target.value,startHour1,endHour1,endMinute1,1)}
                                                id="start_min"
                                            >
                                                <option key="start-minute-create" value="minute">
                                                            Minute
                                                </option>
                                                {minutes.map(
                                                    (x) =>
                                                        
                                                        <option key={x} value={x}>
                                                            {x}
                                                        </option>
                                                        
                                                    )
                                                }
                                            </select>
                                            </div>   
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="end_time">End Time (HH:MM)</label>
                                            <div>
                                                <select
                                                    className="form-control"
                                                    style={{width:"50%",display:"inline-block"}}
                                                    as="select"
                                                    type="number"
                                                    value={endHour1 || ''}
                                                    onChange={(e) => endHourHandler(e.target.value,startHour1,startMinute1,endMinute1,1)}
                                                    id="end_time"
                                                >
                                                    <option key="end-hour-create" value="hour">
                                                                 Hour
                                                    </option>
                                                    {hours.map(
                                                        (x) =>
                                                            
                                                            <option key={x} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                <select
                                                className="form-control"
                                                style={{width:"50%",display:"inline-block"}}
                                                as="select"
                                                type="number"
                                                value={endMinute1 || ''}
                                                onChange={(e) => endMinuteHandler(e.target.value,startHour1,endHour1,startMinute1,1)}
                                                id="start_min"
                                            >
                                                <option key="end-minute-create" value="minute">
                                                            Minute
                                                </option>
                                                {minutes.map(
                                                    (x) =>
                                                        
                                                        <option key={x} value={x}>
                                                            {x}
                                                        </option>
                                                        
                                                    )
                                                }
                                            </select>
                                            </div>   
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="expert">Expert</label>
                                            <select
                                                className="form-control"
                                                as="select"
                                                value={doctorSlug1 || ''}
                                                onChange={(e)=>setDoctorSlug1(e.target.value)}
                                                id="expert"
                                            >
                                                <option key={'select_doctor'} value="selectDoctor">
                                                            Select Doctor 
                                                </option>
                                                {doctors && doctors.map(
                                                    (doctor) =>                                                
                                                        <option key={doctor.slug} value={doctor.slug}>
                                                            {doctor.name}
                                                        </option>                                                
                                                    )
                                                }
                                            </select> 
                                        </div>

                                        
                                    </div>
                                    
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="date2">Date</label><br/>
                                            <div className="customDatePickerWidth">
                                                <DatePicker 
                                                    id="date2"
                                                    isClearable
                                                    selected={calendarDate2 ? calendarDate2 : null} 
                                                    className="form-control"      
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Click to select Date"
                                                    peekNextMonth
                                                    // showMonthDropdown
                                                    // showYearDropdown
                                                    dropdownMode="select"
                                                    minDate={addDays(new Date(), 0)}
                                                    onChange={(date) => dateHandler2(date)} 
                                                     />
                                            </div>
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="start_time2">Start Time (HH:MM)</label>
                                            <div >
                                                <select
                                                    style={{width:"50%",display:"inline-block"}}
                                                    className="form-control"
                                                    as="select"
                                                    value={startHour2 || ''}
                                                    onChange={(e) => startHourHandler(e.target.value,endHour2,startMinute2,endMinute2,2)}
                                                    id="start_time2"
                                                >
                                                    <option key="start-hour-create2" value="hour">
                                                                Hour
                                                    </option>
                                                    {hours.map(
                                                        (x) =>
                                                            
                                                            <option key={x+'2'} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                <select
                                                className="form-control"
                                                
                                                style={{width:"50%",display:"inline-block"}}
                                                as="select"
                                                value={startMinute2 || ''}
                                                onChange={(e) => startMinuteHandler(e.target.value,startHour2,endHour2,endMinute2,2)}
                                                id="start_min2"
                                            >
                                                <option key="start-minute-create2" value="minute">
                                                            Minute
                                                </option>
                                                {minutes.map(
                                                    (x) =>
                                                        
                                                        <option key={x+'22'} value={x}>
                                                            {x}
                                                        </option>
                                                        
                                                    )
                                                }
                                            </select>
                                            </div>   
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="end_time2">End Time (HH:MM)</label>
                                            <div>
                                                <select
                                                    className="form-control"
                                                    style={{width:"50%",display:"inline-block"}}
                                                    as="select"
                                                    type="number"
                                                    value={endHour2 || ''}
                                                    onChange={(e) => endHourHandler(e.target.value,startHour2,startMinute2,endMinute2,2)}
                                                    id="end_time2"
                                                >
                                                    <option key="end-hour-create22" value="hour">
                                                                 Hour
                                                    </option>
                                                    {hours.map(
                                                        (x) =>
                                                            
                                                            <option key={x+'222'} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                <select
                                                className="form-control"
                                                style={{width:"50%",display:"inline-block"}}
                                                as="select"
                                                type="number"
                                                value={endMinute2 || ''}
                                                onChange={(e) => endMinuteHandler(e.target.value,startHour2,endHour2,startMinute2,2)}
                                                id="start_min2"
                                            >
                                                <option key="end-minute-create222" value="minute">
                                                            Minute
                                                </option>
                                                {minutes.map(
                                                    (x) =>
                                                        
                                                        <option key={x+'2222'} value={x}>
                                                            {x}
                                                        </option>
                                                        
                                                    )
                                                }
                                            </select>
                                            </div>   
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="expert2">Expert</label>
                                            <select
                                                className="form-control"
                                                as="select"
                                                value={doctorSlug2 || ''}
                                                onChange={(e)=>setDoctorSlug2(e.target.value)}
                                                id="expert2"
                                            >
                                                <option key={'select_doctor2'} value="selectDoctor">
                                                            Select Doctor 
                                                </option>
                                                {doctors && doctors.map(
                                                    (doctor) =>                                                
                                                        <option key={doctor.slug+'2'} value={doctor.slug}>
                                                            {doctor.name}
                                                        </option>                                                
                                                    )
                                                }
                                            </select> 
                                        </div>

                                        
                                    </div>
                                    
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="date3">Date</label><br/>
                                            <div className="customDatePickerWidth">
                                                <DatePicker 
                                                    id='date3'
                                                    isClearable
                                                    selected={calendarDate3 ? calendarDate3 : null} 
                                                    className="form-control"      
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Click to select Date"
                                                    peekNextMonth
                                                    // showMonthDropdown
                                                    // showYearDropdown
                                                    dropdownMode="select"
                                                    minDate={addDays(new Date(), 0)}
                                                    onChange={(date) => dateHandler3(date)} 
                                                     />
                                            </div>
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="start_time3">Start Time (HH:MM)</label>
                                            <div >
                                                <select
                                                    style={{width:"50%",display:"inline-block"}}
                                                    className="form-control"
                                                    as="select"
                                                    value={startHour3 || ''}
                                                    onChange={(e) => startHourHandler(e.target.value,endHour3,startMinute3,endMinute3,3)}
                                                    id="start_time3"
                                                >
                                                    <option key="start-hour-create3" value="hour">
                                                                Hour
                                                    </option>
                                                    {hours.map(
                                                        (x) =>
                                                            
                                                            <option key={x+'3'} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                <select
                                                className="form-control"
                                                
                                                style={{width:"50%",display:"inline-block"}}
                                                as="select"
                                                value={startMinute3 || ''}
                                                onChange={(e) => startMinuteHandler(e.target.value,startHour3,endHour3,endMinute3,3)}
                                                id="start_min3"
                                            >
                                                <option key="start-minute-create3" value="minute">
                                                            Minute
                                                </option>
                                                {minutes.map(
                                                    (x) =>
                                                        
                                                        <option key={x+'33'} value={x}>
                                                            {x}
                                                        </option>
                                                        
                                                    )
                                                }
                                            </select>
                                            </div>   
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="end_time3">End Time (HH:MM)</label>
                                            <div>
                                                <select
                                                    className="form-control"
                                                    style={{width:"50%",display:"inline-block"}}
                                                    as="select"
                                                    type="number"
                                                    value={endHour3 || ''}
                                                    onChange={(e) => endHourHandler(e.target.value,startHour3,startMinute3,endMinute3,3)}
                                                    id="end_time3"
                                                >
                                                    <option key="end-hour-create33" value="hour">
                                                                 Hour
                                                    </option>
                                                    {hours.map(
                                                        (x) =>
                                                            
                                                            <option key={x+'333'} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                <select
                                                className="form-control"
                                                style={{width:"50%",display:"inline-block"}}
                                                as="select"
                                                type="number"
                                                value={endMinute3 || ''}
                                                onChange={(e) => endMinuteHandler(e.target.value,startHour3,endHour3,startMinute3,3)}
                                                id="start_min3"
                                            >
                                                <option key="end-minute-create333" value="minute">
                                                            Minute
                                                </option>
                                                {minutes.map(
                                                    (x) =>
                                                        
                                                        <option key={x+'3333'} value={x}>
                                                            {x}
                                                        </option>
                                                        
                                                    )
                                                }
                                            </select>
                                            </div>   
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="expert3">Expert</label>
                                            <select
                                                className="form-control"
                                                as="select"
                                                value={doctorSlug3 || ''}
                                                onChange={(e)=>setDoctorSlug3(e.target.value)}
                                                id="expert3"
                                            >
                                                <option key={'select_doctor3'} value="selectDoctor">
                                                            Select Doctor 
                                                </option>
                                                {doctors && doctors.map(
                                                    (doctor) =>                                                
                                                        <option key={doctor.slug+'3'} value={doctor.slug}>
                                                            {doctor.name}
                                                        </option>                                                
                                                    )
                                                }
                                            </select> 
                                        </div>

                                        
                                    </div>
                                    
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="date4">Date</label><br/>
                                            <div className="customDatePickerWidth">
                                                <DatePicker 
                                                    id='date4'
                                                    isClearable
                                                    selected={calendarDate4 ? calendarDate4 : null} 
                                                    className="form-control"      
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Click to select Date"
                                                    peekNextMonth
                                                    // showMonthDropdown
                                                    // showYearDropdown
                                                    dropdownMode="select"
                                                    minDate={addDays(new Date(), 0)}
                                                    onChange={(date) => dateHandler4(date)} 
                                                     />
                                            </div>
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="start_time4">Start Time (HH:MM)</label>
                                            <div >
                                                <select
                                                    style={{width:"50%",display:"inline-block"}}
                                                    className="form-control"
                                                    as="select"
                                                    value={startHour4 || ''}
                                                    onChange={(e) => startHourHandler(e.target.value,endHour4,startMinute4,endMinute4,4)}
                                                    id="start_time4"
                                                >
                                                    <option key="start-hour-create4" value="hour">
                                                                Hour
                                                    </option>
                                                    {hours.map(
                                                        (x) =>
                                                            
                                                            <option key={x+'4'} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                <select
                                                className="form-control"
                                                
                                                style={{width:"50%",display:"inline-block"}}
                                                as="select"
                                                value={startMinute4 || ''}
                                                onChange={(e) => startMinuteHandler(e.target.value,startHour4,endHour4,endMinute4,4)}
                                                id="start_min4"
                                            >
                                                <option key="start-minute-create4" value="minute">
                                                            Minute
                                                </option>
                                                {minutes.map(
                                                    (x) =>
                                                        
                                                        <option key={x+'44'} value={x}>
                                                            {x}
                                                        </option>
                                                        
                                                    )
                                                }
                                            </select>
                                            </div>   
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="end_time4">End Time (HH:MM)</label>
                                            <div>
                                                <select
                                                    className="form-control"
                                                    style={{width:"50%",display:"inline-block"}}
                                                    as="select"
                                                    type="number"
                                                    value={endHour4 || ''}
                                                    onChange={(e) => endHourHandler(e.target.value,startHour4,startMinute4,endMinute4,4)}
                                                    id="end_time4"
                                                >
                                                    <option key="end-hour-create44" value="hour">
                                                                 Hour
                                                    </option>
                                                    {hours.map(
                                                        (x) =>
                                                            
                                                            <option key={x+'444'} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                <select
                                                className="form-control"
                                                style={{width:"50%",display:"inline-block"}}
                                                as="select"
                                                type="number"
                                                value={endMinute4 || ''}
                                                onChange={(e) => endMinuteHandler(e.target.value,startHour4,endHour4,startMinute4,4)}
                                                id="start_min4"
                                            >
                                                <option key="end-minute-create444" value="minute">
                                                            Minute
                                                </option>
                                                {minutes.map(
                                                    (x) =>
                                                        
                                                        <option key={x+'4444'} value={x}>
                                                            {x}
                                                        </option>
                                                        
                                                    )
                                                }
                                            </select>
                                            </div>   
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="expert4">Expert</label>
                                            <select
                                                className="form-control"
                                                as="select"
                                                value={doctorSlug4 || ''}
                                                onChange={(e)=>setDoctorSlug4(e.target.value)}
                                                id="expert4"
                                            >
                                                <option key={'select_doctor4'} value="selectDoctor">
                                                            Select Doctor 
                                                </option>
                                                {doctors && doctors.map(
                                                    (doctor) =>                                                
                                                        <option key={doctor.slug+'4'} value={doctor.slug}>
                                                            {doctor.name}
                                                        </option>                                                
                                                    )
                                                }
                                            </select> 
                                        </div>

                                        
                                    </div>
                                    
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="date5">Date</label><br/>
                                            <div className="customDatePickerWidth">
                                                <DatePicker 
                                                    id='date5'
                                                    isClearable
                                                    selected={calendarDate5 ? calendarDate5 : null} 
                                                    className="form-control"      
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Click to select Date"
                                                    peekNextMonth
                                                    // showMonthDropdown
                                                    // showYearDropdown
                                                    dropdownMode="select"
                                                    minDate={addDays(new Date(), 0)}
                                                    onChange={(date) => dateHandler5(date)} 
                                                     />
                                            </div>
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="start_time5">Start Time (HH:MM)</label>
                                            <div >
                                                <select
                                                    style={{width:"50%",display:"inline-block"}}
                                                    className="form-control"
                                                    as="select"
                                                    value={startHour5 || ''}
                                                    onChange={(e) => startHourHandler(e.target.value,endHour5,startMinute5,endMinute5,5)}
                                                    id="start_time5"
                                                >
                                                    <option key="start-hour-create5" value="hour">
                                                                Hour
                                                    </option>
                                                    {hours.map(
                                                        (x) =>
                                                            
                                                            <option key={x+'5'} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                <select
                                                className="form-control"
                                                
                                                style={{width:"50%",display:"inline-block"}}
                                                as="select"
                                                value={startMinute5 || ''}
                                                onChange={(e) => startMinuteHandler(e.target.value,startHour5,endHour5,endMinute5,5)}
                                                id="start_min5"
                                            >
                                                <option key="start-minute-create5" value="minute">
                                                            Minute
                                                </option>
                                                {minutes.map(
                                                    (x) =>
                                                        
                                                        <option key={x+'55'} value={x}>
                                                            {x}
                                                        </option>
                                                        
                                                    )
                                                }
                                            </select>
                                            </div>   
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="end_time5">End Time (HH:MM)</label>
                                            <div>
                                                <select
                                                    className="form-control"
                                                    style={{width:"50%",display:"inline-block"}}
                                                    as="select"
                                                    type="number"
                                                    value={endHour5 || ''}
                                                    onChange={(e) => endHourHandler(e.target.value,startHour5,startMinute5,endMinute5,5)}
                                                    id="end_time5"
                                                >
                                                    <option key="end-hour-create55" value="hour">
                                                                 Hour
                                                    </option>
                                                    {hours.map(
                                                        (x) =>
                                                            
                                                            <option key={x+'555'} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                <select
                                                className="form-control"
                                                style={{width:"50%",display:"inline-block"}}
                                                as="select"
                                                type="number"
                                                value={endMinute5 || ''}
                                                onChange={(e) => endMinuteHandler(e.target.value,startHour5,endHour5,startMinute5,5)}
                                                id="start_min5"
                                            >
                                                <option key="end-minute-create555" value="minute">
                                                            Minute
                                                </option>
                                                {minutes.map(
                                                    (x) =>
                                                        
                                                        <option key={x+'5555'} value={x}>
                                                            {x}
                                                        </option>
                                                        
                                                    )
                                                }
                                            </select>
                                            </div>   
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="expert5">Expert</label>
                                            <select
                                                className="form-control"
                                                as="select"
                                                value={doctorSlug5 || ''}
                                                onChange={(e)=>setDoctorSlug5(e.target.value)}
                                                id="expert5"
                                            >
                                                <option key={'select_doctor5'} value="selectDoctor">
                                                            Select Doctor 
                                                </option>
                                                {doctors && doctors.map(
                                                    (doctor) =>                                                
                                                        <option key={doctor.slug+'5'} value={doctor.slug}>
                                                            {doctor.name}
                                                        </option>                                                
                                                    )
                                                }
                                            </select> 
                                        </div>

                                        
                                    </div>
                                    
                                    <div>
                                        <div className="custom-control custom-checkbox" style={{display:"inline-block"}}>
                                            <input className="custom-control-input" 
                                                    
                                                    type="checkbox" 
                                                    id='selectRepeat'
                                                    value={selectRepeat}
                                                    onChange={(e)=> setSelectRepeat(!selectRepeat)}
                                                    checked={selectRepeat  ? true : false}/>
                                            <label className="custom-control-label" htmlFor="selectRepeat">{"  "}</label>
                                        </div>
                                        <div style={{display:"inline-block",fontSize:"16px"}}>
                                                Repeat 
                                                {selectRepeat &&
                                                  <>{' '}
                                                for &nbsp;<select
                                                    style={{width:"75px",height:"25px", display:"inline-block"}}
                                                    className="form-control"
                                                    as="select"
                                                    value={repeatDays || ''}
                                                    onChange={(e) => setRepeatDays(e.target.value)}
                                                    id="start_time"
                                                >
                                                    
                                                    {repeatDaysNum.map(
                                                        (x) =>
                                                            
                                                            <option key={'repeatDays'+x} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                {' '} days. 
                                               </>   
                                            }
                                        </div>

                                    </div>
                                    
                                    <div className="row" style={{textAlign:"right"}}>
                                    <div className="form-group col-md-12">
                                        <button style={{marginTop:"28px"}} className="btn btn-primary margin-right-none" 
                                                type="submit" 
                                            >
                                                    Create Timeslots 
                                        </button>
                                        <button style={{marginTop:"28px"}} className="btn btn-primary btn-danger margin-right-none" 
                                                type="button" 
                                            onClick={cancelCreateHandler}
                                            >
                                                    Cancel 
                                        </button>
                                        </div>
                                        </div>
                                    </>
                                    
                                    <br />
                                </div>
                            </form>
                            
                        
                        </div>
                        }

                        {update && 
                        
                        <div className="col-md-12">
                            <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                            
                            <form className="card" onSubmit={submitUpdateHandler}>
                                
                                <div className="col-md-12">
                                    <br />
                                    <h4 className="margin-bottom-1x">Update Timeslots</h4>
                                    {loadingUpdate ? <Loader/> : errorUpdate ? <center><Message variant='danger'>{errorUpdate}</Message></center> : 
                                    <>
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="source-to-dest">Date</label><br/>
                                            <div className="customDatePickerWidth">
                                                <DatePicker 
                                                    selected={calendarDate ? calendarDate : null} 
                                                    className="form-control"      
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Click to select Date"
                                                    peekNextMonth
                                                    isClearable
                                                    // showMonthDropdown
                                                    // showYearDropdown
                                                    dropdownMode="select"
                                                    minDate={addDays(new Date(), 0)}
                                                    onChange={(date) => dateHandler(date)} 
                                                    required />
                                            </div>
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="start_time">Start Time (HH:MM)</label>
                                           <div>
                                           
                                                <select
                                                    className="form-control"
                                                    style={{width:"50%",display:"inline-block"}}
                                                    as="select"
                                                    value={startHour || ''}
                                                    onChange={(e) => startHourHandler(e.target.value,endHour,startMinute,endMinute,0)}
                                                    id="start_time"
                                                >
                                                    {hours.map(
                                                        (x) =>
                                                            
                                                            <option key={x} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                <select
                                                className="form-control"
                                                style={{width:"50%",display:"inline-block"}}
                                                as="select"
                                                value={startMinute || ''}
                                                onChange={(e) => startMinuteHandler(e.target.value,startHour,endHour,endMinute,0)}
                                                id="start_min"
                                            >
                                                {minutes.map(
                                                    (x) =>
                                                        
                                                        <option key={x} value={x}>
                                                            {x}
                                                        </option>
                                                        
                                                    )
                                                }
                                            </select>
                                            </div>    
                                        </div>
                                        
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="end_time">End Time (HH:MM)</label>
                                            <div>
                                                <select
                                                    className="form-control"
                                                    style={{width:"50%",display:"inline-block"}}
                                                    as="select"
                                                    type="number"
                                                    value={endHour || ''}
                                                    onChange={(e) => endHourHandler(e.target.value,startHour,startMinute,endMinute,0)}
                                                    id="end_time"
                                                >
                                                    {hours.map(
                                                        (x) =>
                                                            
                                                            <option key={x} value={x}>
                                                                {x}
                                                            </option>
                                                            
                                                        )
                                                    }
                                                </select>

                                                <select
                                                className="form-control"
                                                style={{width:"50%",display:"inline-block"}}
                                                as="select"
                                                type="number"
                                                value={endMinute || ''}
                                                onChange={(e) => endMinuteHandler(e.target.value,startHour,endHour,startMinute,0)}
                                                id="start_min"
                                            >
                                                {minutes.map(
                                                    (x) =>
                                                        
                                                        <option key={x} value={x}>
                                                            {x}
                                                        </option>
                                                        
                                                    )
                                                }
                                            </select>
                                            </div>   
                                        </div>
                                        
                                        <div className="form-group col-md-3">
                                            <label style={{paddingLeft:'0px'}} htmlFor="expert">Expert</label>
                                            <select
                                                className="form-control"
                                                as="select"
                                                value={doctorSlug || ''}
                                                onChange={(e)=>setDoctorSlug(e.target.value)}
                                                id="specialty_4"
                                            >
                                                <option key={'select_specialization'} value="Select User">
                                                            Select Specialization 
                                                </option>
                                                {doctors && doctors.map(
                                                    (doctor) =>                                                
                                                        <option key={doctor.slug} value={doctor.slug}>
                                                            {doctor.name}
                                                        </option>                                                
                                                    )
                                                }
                                            </select> 
                                        </div>

                                        
                                    </div>
                                    {errorDelete && <Message variant="danger">{errorDelete}</Message>}
                                    {errorService && <Message variant="danger">{errorService}</Message>}
                                    {errorDoctor && <Message variant="danger">{errorDoctor}</Message>}
                                    {errorCreate && <Message variant="danger">{errorCreate}</Message>}
                                    <div className="row" style={{textAlign:"right"}}>
                                    <div className="form-group col-md-12">
                                        <button style={{marginTop:"28px"}} className="btn btn-primary margin-right-none" 
                                                type="submit" 
                                            >
                                                    Update 
                                        </button>
                                        <button style={{marginTop:"28px"}} className="btn btn-primary btn-danger margin-right-none" 
                                                type="button" 
                                            onClick={cancelUpdateHandler}
                                            >
                                                    Cancel 
                                        </button>
                                        </div>
                                        </div>
                                    </>
                                    }
                                    <br />
                                </div>
                            </form>
                            
                        
                        </div>
                        
                        }


                        
                    </div>



                    <div className="row" >
                       
                        <div className="col-lg-12">
                        <br/>
                        <ul className="nav nav-tabs " role="tablist">
                        
                        <li className="nav-item">
                            <button 
                                style={{width:"100%", background:"none",margin:"0px",
                                        color:status === 'active' ? "#0055FF" : null
                                        }}
                                className={status === 'active' ? "nav-link active" : "nav-link"} 
                                type="button" 
                                onClick={()=>setStatus('active')} 
                                data-toggle="tab" role="tab">Active
                            </button>
                        </li>
                        <li className="nav-item" style={{color:"blue"}}>
                            <button 
                                style={{width:"100%", background:"none",margin:"0px",
                                color:status === 'cancelled' ? "#0055FF" : null
                            }}
                                className={status === 'cancelled' ? "nav-link active" : "nav-link"}
                                type="button" 
                                onClick={()=>setStatus('cancelled')} 
                                data-toggle="tab" role="tab">Cancelled                                
                            </button>
                        </li>
                        <li className="nav-item" style={{color:"blue"}}>
                            <button 
                                style={{width:"100%", background:"none",margin:"0px",
                                color:status === 'all' ? "#0055FF" : null
                            }}
                                className={status === 'all' ? "nav-link active" : "nav-link"}
                                type="button" 
                                onClick={()=>setStatus('all')}
                                data-toggle="tab" role="tab">All                                
                            </button>
                        </li>
                    </ul><br/>
                        <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                        <div className="table-responsive">
                        <table className="table table-striped">
                        <thead>
                            <tr>
                            {/* <th>ID</th> */}
                            
                            <th>Date</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Status</th>
                            <th>Expert</th>
                                                       
                            <th></th>
                            <th></th>
                            <th></th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            {timeslots && timeslots.length > 0 && timeslots.map(timeslot => 
                            
                            ((status === 'active' && (timeslot.status === "Available" || timeslot === "Cancelled")) ||
                            (status === 'cancelled' && (timeslot.status === "Cancelled")) ||
                            (status === 'all')) &&

                            (
                                
                                <tr key={timeslot._id}>

                                <th>{timeslot.date}</th>

                                <td>{timeslot.startTime}</td>
                                <td >{timeslot.endTime }</td>
                                <td >{timeslot.status }</td>

                                <td>{timeslot.doctor && timeslot.doctor.name}</td>
                                <td>{timeslot.appointmentId && <Link to={`/appointment/${timeslot.appointmentId}`}>Appointment</Link>}</td>
                                                  

                                <td><Link to="#" onClick={() => updateClickHandler(timeslot)}>
                                    <i className='fas fa-edit'></i>
                                    </Link>
                                </td>
                                <td><button style={{padding: "0", border: "none", background: "none"}} onClick={()=> deleteHandler(timeslot)}>
                                    <i style={{color:'red'}} className='fas fa-trash-alt'></i>
                                    </button>
                                </td>
                                
                               
                                </tr>
                            ))}
                            
                            
                        </tbody>
                        
                        </table>
                    </div>
                        <hr/>
                        </div>
                    </div>
                    </div>
                    
                </>
                )}
            
        </div>
    )
}

export default ServiceTimeslotScreen
