import React from 'react'

function Rating({value, text,color,size}) {
    return (        
        <div className="rating-stars">
        <span style={{ marginRight: "3px"}}>
            <i style={{ color }} className={
                value >= 1
                    ? size==='sm' ? 'fas fa-star fa-sm' : 'fas fa-star'
                    : value >= 0.5
                        ? size==='sm' ? 'fas fa-star-half-alt fa-sm' : 'fas fa-star-half-alt'
                        : size==='sm' ? 'far fa-star fa-sm' : 'far fa-star'
            }>

            </i>
        </span>

        <span style={{ marginRight: "3px"}}>
            <i style={{ color }} className={
                value >= 2
                    ? size==='sm' ? 'fas fa-star fa-sm' : 'fas fa-star'
                    : value >= 1.5
                        ? size==='sm' ? 'fas fa-star-half-alt fa-sm' : 'fas fa-star-half-alt'
                        : size==='sm' ? 'far fa-star fa-sm' : 'far fa-star'
            }>

            </i>
        </span>

        <span style={{ marginRight: "3px"}}>
            <i style={{ color }} className={
                value >= 3
                    ? size==='sm' ? 'fas fa-star fa-sm' : 'fas fa-star'
                    : value >= 2.5
                        ? size==='sm' ? 'fas fa-star-half-alt fa-sm' : 'fas fa-star-half-alt'
                        : size==='sm' ? 'far fa-star fa-sm' : 'far fa-star'
            }>

            </i>
        </span>

        <span style={{ marginRight: "3px"}}>
            <i style={{ color }} className={
                value >= 4
                    ? size==='sm' ? 'fas fa-star fa-sm' : 'fas fa-star'
                    : value >= 3.5
                        ? size==='sm' ? 'fas fa-star-half-alt fa-sm' : 'fas fa-star-half-alt'
                        : size==='sm' ? 'far fa-star fa-sm' : 'far fa-star'
            }>

            </i>
        </span>

        <span style={{ marginRight: "3px"}}>
            <i style={{ color }} className={
                value >= 5
                    ? size==='sm' ? 'fas fa-star fa-sm' : 'fas fa-star'
                    : value >= 4.5
                        ? size==='sm' ? 'fas fa-star-half-alt fa-sm' : 'fas fa-star-half-alt'
                        : size==='sm' ? 'far fa-star fa-sm' : 'far fa-star'
            }>

            </i>
        </span>
            
        <span style={{ fontSize: 12 }}> {text && text}</span>
    </div>
)
}

export default Rating
