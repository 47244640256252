import React from 'react'
import { Link } from 'react-router-dom'

function OurExpertsScreen() {
    return (
        <div>

            {/* <!-- Page Title--> */}
                <div className="page-title">
                <div className="container">
                    <div className="column">
                    <h1>Our Experts</h1>
                    </div>
                    <div className="column">
                    <ul className="breadcrumbs">
                        <li><Link to="/">Home</Link>
                        </li>
                        <li className="separator">&nbsp;</li>
                        <li>Our Experts</li>
                    </ul>
                    </div>
                </div>
                </div>
                {/* <!-- Page Content--> */}
                <div className="container padding-bottom-2x mb-2">
                <h3 style={{textAlign:"left"}} >Meet Our Experts</h3>
                    <div className="row justify-content-center">
                        {/* <!-- Content--> */}
                        
                        
                            <hr id="meet-our-doctors-hr"/>
                            <div  className="card text-center mt-4 mb-4">
                               
                                <div id="meet-our-doctors-card" className="card-body">
                                
                                <div className="row" style={{fontSize:"16px",color:"black",borderRadius:"0"}}>

                                    <div className= "col-xl-6 col-lg-6">
                                    <h5 style={{textAlign:"left"}} className="card-title"><span>Dr. A K Tiwari <small className="text-sm">Ayurvedic Practitioner</small></span></h5><hr className="margin-bottom-1x"/>

                                    <p style={{textAlign:"justify"}} className="card-text">Dr. A K Tiwari is an integrative practitioner in Indian system of 
                                        medicines & treatment. He is a third-generation classically trained Vaidya in his family . He has been practicing 
                                        alternative medicine in India for more than 25 years. He has treated many patients suffering from deadly diseases 
                                        like Cancer, Neuron, Kidney ailment, Liver, Diabetes, Pancreas, Sugar, Heart ailment and many more.
                                        </p>
                                    </div>
                                    

                                    <div className= "col-xl-6 col-lg-6">
                                    <img width="500"  src="/images/dr1.jpg" alt="hww-step1" />

                                    </div>
                                    
                                </div>
                                <br/>
                                <hr/>
                                <br/>
                                <div className="row" style={{fontSize:"16px",color:"black",borderRadius:"0"}}>

                                    
                                    <div className= "col-xl-6 col-lg-6">
                                    <h5 style={{textAlign:"left"}} className="card-title"><span>Dr. S.N. Thakur
                                         <small className="text-sm">Ayurvedic Practitioner</small></span></h5><hr className="margin-bottom-1x"/>

                                    <p style={{textAlign:"justify"}} className="card-text">Dr. S N Thakur is a Scientist, researcher and an integrative practitioner in Indian 
                                            system of medicine and treatment. He is an AYURVED RATNA of India. He is a third generation professionally and 
                                            classically trained doctor in his family having more than 40 years of experience. He has treated many patients of 
                                            Bronchitis, liver, infertility, psoriasis, sugar, blood diseases, brain injury etc.
                                        </p>
                                    </div>
                                    <div className= "col-xl-6 col-lg-6">
                                    <img width="500"  src="/images/dr2.jpg" alt="hww-step1" />

                                    </div>
                                </div>
                                
                                </div>
                            </div>

                    </div>
                    
                
                    
                    
                    
                </div>
    
            </div>
    )
}

export default OurExpertsScreen
