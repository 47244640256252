import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import "../index.css"


function SearchBox({showCategoryBtn=true,mobileView=false,services=false, toolbarView=false, id}) {
    const [keyword, setKeyword] = useState('')
    
    let history = useHistory()

    const submitHandler = (e) => {
        e.preventDefault()
        
        // console.log('history.location.pathname:',history.location.pathname)
        if(services){

            if(keyword){
                history.location.pathname = '/services'
                history.location.search = ''
                history.push(`services/?keyword=${keyword}&page=1`)
            }else{
                // console.log('location pthname trggerd')
                history.push(history.push(history.location.pathname))
            }
        }else{
            if(keyword){
                history.push(`/?keyword=${keyword}&page=1`)
            }else{
                history.push(history.push(history.location.pathname))
            }
        }
            
        
    }
    
    return (
        <div style={{ width:"100%"}}>
                <div className="search-box d-flex">
                {showCategoryBtn &&
                <div className="btn-group categories-btn">
                    <button className="btn btn-secondary " style={{paddingRight:"100px"}} data-toggle="dropdown"></button>
                    <div className="dropdown-menu mega-dropdown">
                    <div className="row">
                        
                    </div>
                    
                    </div>
                </div>
                }
               
                <form className="input-group form-group" 
                        onSubmit={submitHandler}
                        method="get"
                        style={{width:"100%", marginBottom:"0"}}
                        >
                    <div >
                    <input  id= {id} 
                            style={{  paddingLeft: '17px',
                                      paddingRight:"0px",border:mobileView ? 0 : "",
                                      width: mobileView ? "88%" : "100%",
                                  }} 
                            className="form-control searchInput" 
                            onChange={(e)=>setKeyword(e.target.value)}
                          
                            type="search" placeholder={toolbarView ? "Search" : services ? "Search Treatment" :"Search Product"} />
                            
                    <div  style={{display:"flex", marginLeft:"0px",border:"1px "}} className="btn-group input-group-btn">
                        <button style={{paddingRight:"0px"}} type="submit"><i className="icon-search"></i></button>
                    </div>
                    {/* <div  style={{}} className=" input-group-btn">
                        <button style={{}} type="submit"><i className="icon-search"></i></button>
                    </div> */}
                    </div>

                </form>
                </div>

            </div>
    )
}

export default SearchBox
