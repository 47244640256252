import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { resetPasswordConfirm } from '../actions/userActions'

function PasswordResetConfirmScreen({match,history}) {

    
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const dispatch = useDispatch()

    // const redirect = location.search ? location.search.split('=')[1] : '/'
    const userPasswordResetConfirm = useSelector(state => state.userPasswordResetConfirm)
    const {error, loading,success } = userPasswordResetConfirm
    // useEffect(() => {
    //     if (userInfo) {
    //         history.push(redirect)
    //     }
    // },[history,userInfo,redirect])

    const submitHandler = (e) => {
        e.preventDefault()
        const uid = match.params.uid
        const token = match.params.token
        if (password !== confirmPassword){
            alert('Passwords do not match')
        } else {
                dispatch(resetPasswordConfirm(uid,token,password,confirmPassword))
        }
    }

    if (success){
        history.push('/login')
    }

    return (
        <div>
             {/* <!-- Page Title--> */}
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>Password Recovery</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Home</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li><Link to="#">Account</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>Password Recovery</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10">
                <h2>Reset Password</h2>
                
                {error && <Message variant='danger'>{error}</Message>}
                {success && <Message variant='success'>Password Reset Successfull.</Message>}
                {loading && <Loader />}
                <form className="card mt-4" method="post"  onSubmit={submitHandler}>
                    <div className="card-body">
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="pass1">New Password</label>
                        <input className="form-control" id="pass1" type="password" 
                                placeholder="Enter New Password" 
                                onChange={(e)=>setPassword(e.target.value)}
                               required/>
                    </div>
                    <div className="form-group">
                        <label style={{paddingLeft:'0px'}} htmlFor="pass2">Confirm Password</label>
                        <input className="form-control"  id="pass2" 
                                type="password" 
                                placeholder="Confirm Password" 
                                onChange={(e)=>setConfirmPassword(e.target.value)}
                               required/>
                    </div>
                    </div>
                    <div className="card-footer">
                    <button className="btn btn-primary" type="submit">Reset Password</button>
                    
                    </div>
                    {/* <Link to="/login">Back to Login</Link> */}
                </form>
                </div>
            </div>
            </div>

            
        </div>
    )
}

export default PasswordResetConfirmScreen
