import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Service from '../../components/Service'
import Loader from '../../components/Loader'
import Message from '../../components/Message'

import { listServices } from '../../actions/serviceActions'

function AllServicesScreen(history) {

    const dispatch = useDispatch()
    const serviceList = useSelector(state => state.serviceList)
    const {error, loading, services} = serviceList

    const currencyValue = useSelector(state => state.currency)
    const {currency} = currencyValue

    useEffect(()=> {   
        dispatch(listServices('?keyword=all'))
    }, [dispatch])

    
    return (
        <div>
            
            <section className="container padding-bottom-2x mt-4 mb-1" >
            <h2 className="h3 pb-3 text-center">Our Services</h2>
            { loading ? <Loader />
                : error ? <Message variant='danger'>{error}</Message>
                :
            <>
            <div className="row">

                {services.map(service => (

                        <div className="col-lg-3 col-md-4 col-sm-6" key={service._id}>
                            
                            <Service service={service} currency={currency}/>
                            
                        </div>

                ))}

            </div>
            

            </>
            }
            <div className="text-center"><Link className="btn btn-outline-secondary" to="/services">Back to Services</Link></div>
            </section>
            
        </div>
        
    )
}

export default AllServicesScreen
