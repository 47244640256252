import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Service from '../../components/Service'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import SearchBox from '../../components/SearchBox'

import Paginate from '../../components/Paginate'
// import ProductCarousel from '../components/ProductCarousel'

import { listServices } from '../../actions/serviceActions'

import "../../index.css"

function ServiceHomeScreen(history) {
    const dispatch = useDispatch()
    const serviceList = useSelector(state => state.serviceList)
    const {error, loading, services, page, pages} = serviceList         
    let keyword = history.location.search

    useEffect(()=> {
        dispatch(listServices(keyword))
        
    }, [dispatch,keyword])

    const currencyValue = useSelector(state => state.currency)
    const {currency} = currencyValue

    return (
        <div >
            
            {/* <section  id="banner-section">
            {!keyword && <ProductCarousel images={["/images/slider1.jpg","/images/slider2.jpg","/images/slider3.jpg","/images/slider4.jpg","/images/slider5.jpg","/images/slider6.jpg"]} />}
            
            </section>
            <br /> */}
            
            <section className="container padding-bottom-2x mb-2" >
            {/* <h2 className="h3 pb-3 text-center">{keyword && keyword.split("=")[1].split("&")[0] !== "" ? "Search Result" : "Featured Products"}</h2> */}
            {/* <h2 className="h3 pb-3 text-center mt-3" style={{textAlign:"left"}}>Doctors On Call</h2> */}
            <div className="mt-5 mb-0" style={{ width: "100%", display: "inline-block", marginTop: "10px"}}>
                <div  style={{float: "left", width:"50%",display: "inline-block"}}>
                    <h2 style={{marginBottom:"0"}}>Doctors On Call</h2>
                    <p id="doctors-on-call-heading" style={{fontSize:"15px", color:"#505050"}}>Book appointment to consult our top specialists for any health concern.</p>
                </div>
                
                <div id="service-search" style={{float: "right", width:"50%", marginRight:"0px",marginTop: "10px"}}>
                    
                    <div style={{  width:'60%',float: "right"}}>
                        <SearchBox style={{paddingLeft:"0"}} showCategoryBtn={false} services={true} id={'service-search1'}/>
                    </div>
                    <div id="" style={{  float: "right", marginRight:"10px"}}>
                        <Link className="btn btn-outline-secondary" style={{margin:"0px"}} to="/doctors">Meet Our Experts</Link>
                    </div>
                </div>
            </div>
            <div id="service-search-mobile" style={{width:"100%", display:"none"}}>
            
                <div  style={{  float: "right",width:"50%",}}>
                    <SearchBox style={{paddingLeft:"0"}} showCategoryBtn={false} services={true} id={'service-search2'}/>
                </div>
                <div id="" style={{  float: "left", marginRight:"10px"}}>
                        <Link className="btn btn-outline-secondary" style={{margin:"0px"}} to="/doctors">Meet Our Experts</Link>
                </div>
            </div>
            <hr/><br /><br />
            { loading ? <> <Loader /></>
                : error ? <Message variant='danger'>{error}</Message>
                : services.length === 0 ?
                <center>
                <Message variant="warning">No Services found with this keyword!</Message>
                </center>
                :
            <>
            <div className="row">

                {services.map(service => (

                        <div className="col-lg-3 col-md-4 col-sm-6" key={service._id}>
                            
                            <Service service={service} currency={currency}/>
                            
                        </div>

                ))}

            </div>
            <Paginate page={page} pages={pages} services={true} keyword={keyword} />

            </>
            }
            <div className="text-center"><Link className="btn btn-outline-secondary" to="/all-services">View All Services</Link></div>
            </section>

        </div>
        
    )
}

export default ServiceHomeScreen
