import {
    SET_CURRENCY,
} from '../constants/currencyConstants'

export const setCurrencyinAction = (currency) => (dispatch, getState) => {
    dispatch({
        type: SET_CURRENCY,
        payload: currency,
    })

    localStorage.setItem('currency',currency)

}

