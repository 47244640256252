import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { getUserDetails, updateUser } from '../actions/userActions'

import { USER_UPDATE_RESET } from '../constants/userConstants'

function UserEditScreen({match,history}) {

    const userId = match.params.id

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [isAdmin, setIsAdmin] = useState('')

    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails)
    const {error, loading, user } = userDetails

    const orderList = useSelector(state => state.orderList)
    const { loading:loadingOrders , error:errorOrders, orders } = orderList

    const userUpdate = useSelector(state => state.userUpdate)
    const {error:errorUpdate, loading:loadingUpdate, success:successUpdate } = userUpdate

    console.log('order:',userId)
    useEffect(() => {

        if(successUpdate){
            dispatch({type: USER_UPDATE_RESET})
            history.push('/admin/userlist')
        }else{
            if(!user.name || user._id !== Number(userId)){
                dispatch(getUserDetails(userId))
            }else{
                setName(user.name)
                setEmail(user.email)
                setIsAdmin(user.isAdmin)
                
            }
        }
  
    },[user,userId,dispatch,history,successUpdate])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateUser({_id:user._id,name,email,isAdmin}))
        
    }
    return (
        <div>
            <div className="page-title">
            <div className="container">
                <div className="column">
                <h1>Edit User</h1>
                </div>
                <div className="column">
                <ul className="breadcrumbs">
                    <li><Link to="/">Home</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li><Link to="account-orders.html">Account</Link>
                    </li>
                    <li className="separator">&nbsp;</li>
                    <li>Edit User</li>
                </ul>
                </div>
            </div>
            </div>
            {/* <!-- Page Content--> */}
            <div className="container padding-bottom-3x mb-2">
            <div><Link to='/admin/userlist'><h6 className="margin-bottom-1x">Go Back</h6></Link></div>
            <div className="row">
                <div className="col-md-4">
                    {loadingUpdate && <Loader/>}
                    {errorUpdate && <Message variant="danger">{error}</Message>}
                    <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                    
                    <form className="card" onSubmit={submitHandler}>
                        
                        <div className="col-md-12">
                            <br />
                            <h4 className="margin-bottom-1x">User Info</h4>
                            {loading ? <Loader/> : error ? <center><Message variant='danger'>{error}</Message></center> : 
                            <><div className="form-group">
                                <label style={{paddingLeft:'0px'}} htmlFor="account-fn">Name</label>
                                <input className="form-control" 
                                        type="text" 
                                        id="account-fn" 
                                        value={name} 
                                        onChange={(e)=>setName(e.target.value)}
                                         />
                            </div>
                        
                            <div className="form-group">
                                <label style={{paddingLeft:'0px'}} htmlFor="account-email">E-mail Address</label>
                                <input className="form-control" 
                                type="email" 
                                id="account-email" 
                                value={email} 
                                onChange={(e)=>setEmail(e.target.value)}
                                 />
                            </div>
                        
                            {/* <div className="col-md-6">
                            <div className="form-group">
                                <label for="account-phone">Phone Number</label>
                                <input className="form-control" type="text" id="account-phone" value="+7 (805) 348 95 72" required /> 
                            </div>
                            </div> */}

                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" 
                                    type="checkbox" 
                                    id="isAdmin" 
                                    onChange={(e)=>setIsAdmin(e.target.checked)}
                                    checked={isAdmin}
                                    
                                    />
                                <label className="custom-control-label" htmlFor="isAdmin">Is Admin</label>
                            </div>


                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                                
                                <button className="btn btn-primary margin-right-none" 
                                        type="submit" 
                                        data-toast data-toast-position="topRight" 
                                        data-toast-type="success" 
                                        data-toast-icon="icon-check-circle" 
                                        data-toast-title="Success!" 
                                        data-toast-message="Your profile updated successfuly.">
                                            Update 
                                </button>
                            </div>
                            </>
                            }
                            <br />
                        </div>
                    </form>
                    
                {/* <aside className="user-info-wrapper">
                    <div className="user-cover" style={{backgroundImage: "url(/assets/img/account/user-cover-img.jpg)"}}>
                    <div className="info-label" data-toggle="tooltip" title="You currently have 290 Reward Points to spend"><i className="icon-award"></i>290 points</div>
                    </div>
                    <div className="user-info">
                    <div className="user-avatar"><Link className="edit-avatar" to="#"></Link><img src="/images/img/account/user-ava.jpg" alt="User" /></div>
                    <div className="user-data">
                        <h4 className="h5">Daniel Adams</h4><span>Joined February 06, 2017</span>
                    </div>
                    </div>
                </aside>
                <nav className="list-group"><Link className="list-group-item with-badge" to="account-orders.html"><i className="icon-shopping-bag"></i>Orders<span className="badge badge-default badge-pill">6</span></Link><Link className="list-group-item active" to="account-profile.html"><i className="icon-user"></i>Profile</Link><Link className="list-group-item" to="account-address.html"><i className="icon-map-pin"></i>Addresses</Link><Link className="list-group-item with-badge" to="account-wishlist.html"><i className="icon-heart"></i>Wishlist<span className="badge badge-default badge-pill">3</span></Link><Link className="list-group-item with-badge" to="account-tickets.html"><i className="icon-tag"></i>My Tickets<span className="badge badge-default badge-pill">4</span></Link></nav> */}
                </div>


                <div className="col-lg-8">
                <h4 className="margin-bottom-1x">User Orders</h4>
                <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                {loadingOrders ? (
                        <Loader />
                        ): errorOrders ? (
                            <Message variant='danger'>{error}</Message>
                        ) :
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                <thead>
                                    <tr>
                                    <th>Order #</th>
                                    <th>Date Purchased</th>
                                    <th>Total</th>
                                    <th>Paid</th>
                                    <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {orders.map(order => (
                                        
                                        order.user._id === userId ?
                                        <tr key={order._id}>
                                        
                                        <td><Link className="navi-link" to="#" data-toggle="modal" data-target="#orderDetails">{order._id}</Link></td>
                                        <td>{order.createdAt.substring(0,10)}</td>
                                        <td><span>{order.currency === "INR" ? '₹' : order.currency === "USD" ? '$' : null}{order.totalPrice}</span></td>
                                        <td>{order.isPaid ? <span className="text-success">{order.paidAt.substring(0,10)}</span> : (<span className="text-danger"><i className='fas fa-times'></i></span>)}</td>
                                        <td><Link to={`/order/${order.slug}`}>{order.isDelivered ? <span className="text-success">Delivered</span> : (<span className="text-danger">Not Delivered</span>)}</Link></td>
                                        </tr> 
                                        : null 

                                    ))}
                                    
                                    
                                </tbody>
                                </table>
                            </div>
                        }
                <hr/>
                </div>
            </div>
            </div>
            
        </div>
    )
}

export default UserEditScreen
