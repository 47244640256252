import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listOrders,sendEmailOrder, deliverOrder,shipOrder, } from '../actions/orderActions'


function OrderListScreen({history}) {

    const dispatch = useDispatch()

    const orderList = useSelector(state => state.orderList)
    const { loading , error, orders } = orderList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin


    const statusHandler = (order,status) => {
        if(status==="Shipped"){
            if (window.confirm('Update Order to Shipped?')) {
                dispatch(shipOrder(order))
            }            
            if (window.confirm('Send Email for Order Shipped?')) {
                dispatch(sendEmailOrder(order.slug,"Shipped"))
            }
            dispatch(listOrders())
            
        }else if (status==="Delivered"){
            if (window.confirm('Update Order to Delivered?')) {
                dispatch(deliverOrder(order))
            }            
            if (window.confirm('Send Email for Order Delivered?')) {
                dispatch(sendEmailOrder(order.slug,"Delivered"))
            }
            dispatch(listOrders())
            
        }else if (status==="Confirmed"){     
            if (window.confirm('Send Email for Order Confirmation?')) {
                dispatch(sendEmailOrder(order.slug,"Confirmed"))
                dispatch(listOrders())  
            }
             
            
            
        }
        
    }

    useEffect(() => {
        if(userInfo && userInfo.isAdmin){
            dispatch(listOrders())
        }else{
            history.push('/login')
        }
        
    },[dispatch,history,userInfo])

    
    return (
        <div>
            {loading ? <Loader/>
                : error ? <Message variant='danger'>{error}</Message>
                : (
                    <>
                    {/* <!-- Page Title--> */}
                    <div className="page-title">
                    <div className="container">
                        <div className="column">
                        <h1>Orders</h1>
                        </div>
                        <div className="column">
                        <ul className="breadcrumbs">
                            <li><Link to="index.html">Home</Link> 
                            </li>
                            
                            <li className="separator">&nbsp;</li>
                            <li>Orders List</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    {/* <!-- Page Content--> */}
                    <div className="container padding-bottom-3x mb-2">
                    <div className="row" style={{height:"100vh",overflowY:"scroll"}}>
                       
                        <div className="col-lg-12">
                        <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                        <div className="table-responsive">
                        <table className="table table-striped">
                        <thead>
                            <tr>
                            <th>ID</th>
                            <th>USER</th>
                            <th>DATE</th>
                            <th>ITEMS</th>
                            <th>TOTAL PRICE</th>
                            <th>PAID</th>                            
                            <th>DELIVERED</th>
                            <th>UPDATE</th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map(order => (
                                <tr key={order._id}>
                                <th>{order.refId}</th>
                                <th scope="row">{order.user && order.user.name}</th>
                                <td>{order.createdAt.substring(0,10)}</td>
                                <td style={{maxWidth: "150px", wordWrap: "break-word"}}>{order.orderItems.map((item)=>(
                                    item.name+', '
                                    ))}
                                </td>
                                <td>{order.currency==="INR"?'₹' : '$'}{order.totalPrice}</td>
                                <td  style={{ color: 'green',fontWeight:'bold' }}>{order.isPaid ? 
                                order.paidAt.substring(0,10) :
                                <i className='fas fa-check' style={{ color: 'red' }}></i>
                                }</td>
                                <td>{order.isDelivered ? 
                                order.deliveredAt.substring(0,10) :
                                <i className='fas fa-check' style={{ color: 'red' }}></i>
                                }</td>
                                <td>

                                        <select className="form-control" 
                                            as="select"
                                            value = {order.status}
                                            onChange = {(e) => {
                                                statusHandler(order,e.target.value)                                            }
                                            }
                                                id="product-mfg">
                                            
                                                <option value="Select">
                                                    Select 
                                                </option> 
                                                <option value="Confirmed">
                                                    Confirmed
                                                </option> 
                                                <option value="Shipped">
                                                    Shipped
                                                </option>
                                                <option value="Delivered">
                                                    Delivered
                                                </option>
                                        

                                            </select>
                                        
                                    
                                </td>

                                <td><Link to ={`/order/${order.slug}`}>
                                    Details
                                    </Link>
                                </td>
                               
                                </tr>
                            ))}
                            
                            
                        </tbody>
                        </table>
                    </div>
                        <hr/>
                        </div>
                    </div>
                    </div>
                    
                </>
                )}
            
        </div>
    )
}

export default OrderListScreen
