export const APPOINTMENT_CREATE_REQUEST = 'APPOINTMENT_CREATE_REQUEST'
export const APPOINTMENT_CREATE_SUCCESS = 'APPOINTMENT_CREATE_SUCCESS'
export const APPOINTMENT_CREATE_FAIL = 'APPOINTMENT_CREATE_FAIL'

export const APPOINTMENT_CREATE_RESET = 'APPOINTMENT_CREATE_RESET'

export const APPOINTMENT_DETAILS_REQUEST = 'APPOINTMENT_DETAILS_REQUEST'
export const APPOINTMENT_DETAILS_SUCCESS = 'APPOINTMENT_DETAILS_SUCCESS'
export const APPOINTMENT_DETAILS_FAIL = 'APPOINTMENT_DETAILS_FAIL'

export const APPOINTMENT_PAY_REQUEST = 'APPOINTMENT_PAY_REQUEST'
export const APPOINTMENT_PAY_SUCCESS = 'APPOINTMENT_PAY_SUCCESS'
export const APPOINTMENT_PAY_FAIL = 'APPOINTMENT_PAY_FAIL'
export const APPOINTMENT_PAY_RESET = 'APPOINTMENT_PAY_RESET'

export const APPOINTMENT_EMAIL_SEND_REQUEST = 'APPOINTMENT_EMAIL_SEND_REQUEST'
export const APPOINTMENT_EMAIL_SEND_SUCCESS = 'APPOINTMENT_EMAIL_SEND_SUCCESS'
export const APPOINTMENT_EMAIL_SEND_FAIL = 'APPOINTMENT_EMAIL_SEND_FAIL'
export const APPOINTMENT_EMAIL_SEND_RESET = 'APPOINTMENT_EMAIL_SEND_RESET'

export const APPOINTMENT_LIST_MY_REQUEST = 'APPOINTMENT_LIST_MY_REQUEST'
export const APPOINTMENT_LIST_MY_SUCCESS = 'APPOINTMENT_LIST_MY_SUCCESS'
export const APPOINTMENT_LIST_MY_FAIL = 'APPOINTMENT_LIST_MY_FAIL'
export const APPOINTMENT_LIST_MY_RESET = 'APPOINTMENT_LIST_MY_RESET'

export const APPOINTMENT_LIST_REQUEST = 'APPOINTMENT_LIST_REQUEST'
export const APPOINTMENT_LIST_SUCCESS = 'APPOINTMENT_LIST_SUCCESS'
export const APPOINTMENT_LIST_FAIL = 'APPOINTMENT_LIST_FAIL'

export const APPOINTMENT_ATTAIN_REQUEST = 'APPOINTMENT_ATTAIN_REQUEST'
export const APPOINTMENT_ATTAIN_SUCCESS = 'APPOINTMENT_ATTAIN_SUCCESS'
export const APPOINTMENT_ATTAIN_FAIL = 'APPOINTMENT_ATTAIN_FAIL'
export const APPOINTMENT_ATTAIN_RESET = 'APPOINTMENT_ATTAIN_RESET'

export const APPOINTMENT_CANCEL_REQUEST = 'APPOINTMENT_CANCEL_REQUEST'
export const APPOINTMENT_CANCEL_SUCCESS = 'APPOINTMENT_CANCEL_SUCCESS'
export const APPOINTMENT_CANCEL_FAIL = 'APPOINTMENT_CANCEL_FAIL'
export const APPOINTMENT_CANCEL_RESET = 'APPOINTMENT_CANCEL_RESET'

export const APPOINTMENT_UPDATE_DOCTOR_REQUEST = 'APPOINTMENT_UPDATE_DOCTOR_REQUEST'
export const APPOINTMENT_UPDATE_DOCTOR_SUCCESS = 'APPOINTMENT_UPDATE_DOCTOR_SUCCESS'
export const APPOINTMENT_UPDATE_DOCTOR_FAIL = 'APPOINTMENT_UPDATE_DOCTOR_FAIL'
export const APPOINTMENT_UPDATE_DOCTOR_RESET = 'APPOINTMENT_UPDATE_DOCTOR_RESET'

export const APPOINTMENT_RZP_CHECKOUT_REQUEST = 'APPOINTMENT_RZP_CHECKOUT_REQUEST'
export const APPOINTMENT_RZP_CHECKOUT_SUCCESS = 'APPOINTMENT_RZP_CHECKOUT_SUCCESS'
export const APPOINTMENT_RZP_CHECKOUT_FAIL = 'APPOINTMENT_RZP_CHECKOUT_FAIL'
export const APPOINTMENT_RZP_CHECKOUT_RESET = 'APPOINTMENT_RZP_CHECKOUT_RESET'