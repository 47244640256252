import axios from 'axios'
import { 
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,

    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,

    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAIL, 

    ORDER_EMAIL_SEND_REQUEST,
    ORDER_EMAIL_SEND_SUCCESS,
    ORDER_EMAIL_SEND_FAIL, 

    ORDER_LIST_MY_REQUEST,
    ORDER_LIST_MY_SUCCESS,
    ORDER_LIST_MY_FAIL, 

    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL,

    ORDER_DELIVER_REQUEST,
    ORDER_DELIVER_SUCCESS,
    ORDER_DELIVER_FAIL, 

    ORDER_SHIP_REQUEST,
    ORDER_SHIP_SUCCESS,
    ORDER_SHIP_FAIL,

    ORDER_RZP_CHECKOUT_REQUEST,
    ORDER_RZP_CHECKOUT_SUCCESS,
    ORDER_RZP_CHECKOUT_FAIL,

    FREIGHT_CHARGES_REQUEST,
    FREIGHT_CHARGES_SUCCESS,
    FREIGHT_CHARGES_FAIL,

    FREIGHT_CHARGE_CREATE_REQUEST,
    FREIGHT_CHARGE_CREATE_SUCCESS,
    FREIGHT_CHARGE_CREATE_FAIL,

    FREIGHT_CHARGE_UPDATE_REQUEST,
    FREIGHT_CHARGE_UPDATE_SUCCESS,
    FREIGHT_CHARGE_UPDATE_FAIL,

    FREIGHT_CHARGE_DELETE_REQUEST,
    FREIGHT_CHARGE_DELETE_SUCCESS,
    FREIGHT_CHARGE_DELETE_FAIL,
} from '../constants/orderConstants'

import { CART_CLEAR_ITEMS } from '../constants/cartConstants'

export const createOrder = (order) => async (dispatch,getState) => {
    try {
        dispatch({
            type: ORDER_CREATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }


        const { data } = await axios.post(
            `/api/orders/add/`,   
            order,         
            config
        )

        dispatch({
            type: ORDER_CREATE_SUCCESS,
            payload: data
        })
        
        dispatch({
            type: CART_CLEAR_ITEMS,
            payload: data
        })

        localStorage.removeItem('cartItems')


    } catch (error) {
        dispatch({
            type: ORDER_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const getOrderDetails = (id) => async (dispatch,getState) => {
    try {
        dispatch({
            type: ORDER_DETAILS_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/orders/${id}/`,            
            config
        )

        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const payOrder = (id,paymentResult) => async (dispatch,getState) => {
    try {
        dispatch({
            type: ORDER_PAY_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.put(
            `/api/orders/${id}/pay/`,  
            paymentResult,          
            config
        )

        dispatch({
            type: ORDER_PAY_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ORDER_PAY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const sendEmailOrder = (id,status) => async (dispatch,getState) => {
    try {
        dispatch({
            type: ORDER_EMAIL_SEND_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        // console.log('sendMail config:',config)

        const { data } = await axios.put(
            `/api/orders/${id}/email/${status}/`,     
            {},       
            config
        )

        dispatch({
            type: ORDER_EMAIL_SEND_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ORDER_EMAIL_SEND_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const razorpayCheckout = (id) => async (dispatch,getState) => {
    try {
        dispatch({
            type: ORDER_RZP_CHECKOUT_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        console.log('order action config:',config)
        const { data } = await axios.post(
            `/api/orders/${id}/razorpayCheckout/`,  
            {},
            config
        )

        dispatch({
            type: ORDER_RZP_CHECKOUT_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ORDER_RZP_CHECKOUT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listMyOrders = (id) => async (dispatch,getState) => {
    try {
        dispatch({
            type: ORDER_LIST_MY_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/orders/myorders/`,            
            config
        )

        dispatch({
            type: ORDER_LIST_MY_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ORDER_LIST_MY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listOrders = (id) => async (dispatch,getState) => {
    try {
        dispatch({
            type: ORDER_LIST_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/orders/`,            
            config
        )

        dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ORDER_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deliverOrder = (order) => async (dispatch,getState) => {
    try {
        dispatch({
            type: ORDER_DELIVER_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/orders/${order.slug}/deliver/`,  
            {},          
            config
        )

        dispatch({
            type: ORDER_DELIVER_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ORDER_DELIVER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const shipOrder = (order) => async (dispatch,getState) => {
    try {
        dispatch({
            type: ORDER_SHIP_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/orders/${order.slug}/ship/`,  
            {},          
            config
        )

        dispatch({
            type: ORDER_SHIP_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ORDER_SHIP_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getFreightCharges = () => async (dispatch,getState) => {
    try {
        dispatch({
            type: FREIGHT_CHARGES_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/orders/freightCharges/`,            
            config
        )

        dispatch({
            type: FREIGHT_CHARGES_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: FREIGHT_CHARGES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createFreightCharge = () => async (dispatch,getState) => {
    try {
        dispatch({
            type: FREIGHT_CHARGE_CREATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/orders/createFreightCharge/`,   
            {},         
            config
        )

        dispatch({
            type: FREIGHT_CHARGE_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: FREIGHT_CHARGE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateFreightCharge = (charge) => async (dispatch,getState) => {
    try {
        dispatch({
            type: FREIGHT_CHARGE_UPDATE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/orders/updateFreightCharge/${charge._id}/`,   
            charge,         
            config
        )

        dispatch({
            type: FREIGHT_CHARGE_UPDATE_SUCCESS,
            payload: data,
        })

        // dispatch({ 
        //     type: FREIGHT_CHARGES_SUCCESS,  
        //     payload: data,
        // })


    } catch (error) {
        dispatch({
            type: FREIGHT_CHARGE_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteFreightCharge = (id) => async (dispatch,getState) => {
    try {
        dispatch({
            type: FREIGHT_CHARGE_DELETE_REQUEST
        })

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/orders/deleteFreightCharge/${id}/`,            
            config
        )

        dispatch({
            type: FREIGHT_CHARGE_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: FREIGHT_CHARGE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


